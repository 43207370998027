import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Checkbox, Select, Input, notification } from "antd";

import REQUESTS from "../../../server/requests";

import SaveButtonComponent from "../../elements/SaveButtonComponent";
import ErrorMessage from "../../elements/ErrorMessage";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";

export default function DateFormatDrawer({ visible, onClose, config, getConfig }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const translate = useSelector((state) => state.globalState.translate);

  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    if (visible) {
      if (config.items) {
        const { date_format, display_date, date_hour_format } = config.items;

        form.setFields([
          { name: "date_format", value: date_format },
          { name: "display_date", value: display_date },
          { name: "date_hour_format", value: date_hour_format },
        ]);
      }
    } else {
      form.resetFields();
      setErrorText(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      display_date: values.display_date,
      date_hour_format: values.date_hour_format,
      date_format: values.date_format,
    };

    function callback() {
      setLoading(false);
      onClose();
      getConfig();
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (config.items) {
      REQUESTS.TV_APPLICATION.CONFIG.EDIT(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  const dateHourFormat = [
    {
      label: "12",
      value: "12",
    },
    {
      label: "24",
      value: "24",
    },
  ];

  const valuesList = [
    { key: "@WDL", value: "Weekday Long" },
    { key: "@WDS", value: "Weekday Short" },
    { key: "@MTL", value: "Month Long" },
    { key: "@MTS", value: "Month Short" },
    { key: "@DD", value: "Day as a number" },
    { key: "@YYL", value: "Year Long as a number" },
    { key: "@YYS", value: "Year Short as a number" },
    { key: "@PM", value: "Display “PM” or “AM” yes/no option" },
    { key: "@HH", value: "Hour as a number" },
    { key: "@MM", value: "Minutes as a number" },
  ];

  return (
    <Drawer
      title={translate["Edit"] || EN["Edit"]}
      placement="right"
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      visible={visible}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="edit-text-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="display_date" valuePropName="checked" noStyle>
          <Checkbox>{translate["Show Date"] || EN["Show Date"]}</Checkbox>
        </Form.Item>

        <Form.Item
          label={translate["Date hour format"] || EN["Date hour format"]}
          tooltip={{
            title: "Date hour format",
            icon: ICONS.INFO,
          }}
          name="date_hour_format"
        >
          <Select
            style={{ width: "100%" }}
            showArrow
            allowClear={true}
            options={dateHourFormat}
          />
        </Form.Item>

        <div style={{ marginBottom: 10 }}>
          {valuesList.map((item) => {
            return (
              <div className="date-values" key={item.key}>
                <div style={{ width: 37, color: "#1890ff" }}>{item.key}</div>
                <div>{item.value}</div>
              </div>
            );
          })}
        </div>

        <Form.Item
          label={translate["Date format"] || EN["Date format"]}
          tooltip={{
            title: "Date format",
            icon: ICONS.INFO,
          }}
        >
          <Form.Item name="date_format" noStyle>
            <Input />
          </Form.Item>
        </Form.Item>

        <div style={{ fontSize: 10, marginBottom: 10 }}>
          <div>Example `</div>
          <div>@WDL, @MTS @DD, @HH:@MM @PM</div>
          <div>“Friday, Oct 15, 9:29 PM”</div>
        </div>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>

        {/* <ErrorMessage>{errorText}</ErrorMessage> */}
      </Form>
    </Drawer>
  );
}
