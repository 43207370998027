import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Card, Rate } from "antd";

import REQUESTS from "../../../server/requests";

import EN from "../../../config/en";

import TitleComponent from "../../elements/TitleComponent";

export default function FeedbackCharts() {
  const translate = useSelector((state) => state.globalState.translate);

  const [statistics, setStatistics] = useState([]);

  useEffect(() => {
    getDashboardCharts();
  }, []);

  const getDashboardCharts = () => {
    const query = {};

    REQUESTS.DASHBOARD.STATISTICS_FEEDBACK_QUESTIONS(query, (data) => {
      setStatistics(data);
    });
  };

  return (
    <div style={{ marginTop: 20 }}>
      <TitleComponent>{translate["Feedback"] || EN["Feedback"]}</TitleComponent>

      <div className="inline-feedbacks-cards">
        {statistics.map((item) => {
          return (
            <Card key={item.id} type="inner" title={item.questions}>
              {item.answer &&
                item.answer[0] &&
                Object.keys(item.answer[0]).map((answer, index) => {
                  return (
                    <div key={index}>
                      <Rate disabled defaultValue={5 - index} />

                      <span style={{ marginLeft: 10 }}>
                        - {item.answer[0][answer].count}
                      </span>
                    </div>
                  );
                })}
            </Card>
          );
        })}
      </div>
    </div>
  );
}
