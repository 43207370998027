import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, Input, Select, InputNumber, Radio, notification } from "antd";

import { useRoomsGroupsOptions } from "../../../../hooks/options";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";

import ErrorMessage from "../../../elements/ErrorMessage";

import SaveButtonComponent from "../../../elements/SaveButtonComponent";

export default function NewRoomDrawer({
  visible,
  onClose,
  newroom,
  getRoomsList,
  bookingRoomOptions,
}) {
  const translate = useSelector((state) => state.globalState.translate);

  const groupsOptions = useRoomsGroupsOptions();

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      if (newroom) {
        form.setFields([
          { name: "location_name", value: newroom.location_name },
          { name: "location_no", value: newroom.location_no },
          { name: "group_id", value: newroom.group_id },
          { name: "booking_room_id", value: newroom.booking_room_id },
        ]);
      }
    } else {
      setErrorText(null);
    }
  }, [visible]);

  useEffect(() => {
    if (!visible && newroom) {
      form.resetFields();
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);
    setErrorText(null);

    let body = {
      location_name: values.location_name,
      location_no: values.location_no,
      group_id: values.group_id,
      booking_room_id: values.booking_room_id || "",
    };

    function callback() {
      setLoading(false);
      onClose();
      getRoomsList();
      form.resetFields();
    }

    function errorCallback(err) {
      setLoading(false);
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
    }
    if (newroom) {
      body.id = newroom.id;
      REQUESTS.ROOMS.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.ROOMS.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };
  const validatePositive = (rule, value, callback) => {
    if (value < 0) {
      callback("Please enter a positive value");
    } else {
      callback();
    }
  };

  return (
    <Drawer
      title={
        newroom
          ? translate["Edit Location"] || EN["Edit Location"]
          : translate["Add New Location"] || EN["Add New Location"]
      }
      placement="right"
      width={500}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      visible={visible}
      destroyOnClose={true}
    >
      <h2>{translate["Location information"] || EN["Location information"]}</h2>

      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Location name"] || EN["Location name"]}
          tooltip={{
            title: "Name Of Location",
            icon: ICONS.INFO,
          }}
          name="location_name"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Location number"] || EN["Location number"]}
          tooltip={{
            title: "Number Of Location",
            icon: ICONS.INFO,
          }}
          name="location_no"
          rules={[
            {
              required: true,
              message:
                translate["The number of location is required and must be a number"] ||
                EN["The number of location is required and must be a number"],
            },
            {
              validator: validatePositive,
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label={translate["Booking Room"] || EN["Booking Room"]}
          tooltip={{
            title: translate["Booking Room"] || EN["Booking Room"],
            icon: ICONS.INFO,
          }}
          name="booking_room_id"
        >
          <Select options={bookingRoomOptions} showArrow allowClear={true} showSearch />
        </Form.Item>

        <Form.Item
          label={translate["Category"] || EN["Category"]}
          tooltip={{
            title: "Choose a category",
            icon: ICONS.INFO,
          }}
          name="group_id"
          rules={[
            {
              required: true,
              message: `${translate["Please select"] || EN["Please select"]} ${
                translate["Category"] || EN["Category"]
              }`,
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            showArrow
            allowClear={true}
            options={groupsOptions}
            showSearch
          />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
