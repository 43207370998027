import { useEffect, useState } from "react";
import { notification, Table, Typography, Tag, Tooltip } from "antd";
import Page from "../../../elements/Page";

import HeaderItem from "../../../elements/HeaderItem";
import TableHead from "./TableHead";
import REQUESTS from "../../../../server/requests";
import ICONS from "../../../../config/icons";
import TableButtons from "../../../elements/TableButtons";
import ImageComponent from "../../../elements/ImageComponent";
import confirm from "antd/lib/modal/confirm";
import RadioDrawer from "./drawer/RadioDrawer";
import { useSelector } from "react-redux";
import EN from "../../../../config/en";
import AddButtonComponent from "../../../elements/AddButtonComponent";
import CsvLinkComponent from "../../../elements/CsvLinkComponent";
import ViewSettingsDrawer from "../../e-commerce/products/ViewSettingsDrawer";

const RadioPAge = () => {
  const translation = useSelector((state) => state.globalState.translate);

  let debounceTimeOut;

  const [dataSource, setDataSource] = useState([]);
  const [genresList, setGenresList] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [editable, setEditable] = useState(null);
  const [loading, setLoading] = useState(false);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 50,
      fixed: "left",
    },
    {
      title: translation["Name"] || EN["Name"],
      dataIndex: "name",
      key: "name",
      align: "center",
      ellipsis: true,
    },
    {
      title: translation["Icon"] || EN["Icon"],
      dataIndex: "icon",
      key: "icon",
      align: "center",

      render: (text, record) => {
        return <ImageComponent src={text} className="tableImage" />;
      },
    },
    {
      title: translation["Description"] || EN["Description"],
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (text, record) => {
        return (
          <Typography.Paragraph>
            {record?.description ? text : "N/A"}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: translation["Category"] || EN["Category"],
      dataIndex: "category_id",
      key: "category_id",
      align: "center",
      ellipsis: true,
      width: 300,
      render: (text, record) => {
        const newCategories = record.radios_categories?.map((item) => {
          return genresList.find((i) => i.value === item.category_id);
        });

        const displayedCategories = newCategories?.slice(0, 1);
        const remainingCategories = newCategories?.slice(1);

        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {displayedCategories?.map((item, index) => (
              <Typography.Paragraph key={index}>
                <Tag color="blue">{item?.label}</Tag>
              </Typography.Paragraph>
            ))}

            {remainingCategories?.length > 0 && (
              <Tooltip
                color="#eff2f4"
                title={remainingCategories?.map((item) => (
                  <Tag color="blue" key={item?.value}>
                    {item?.label}
                  </Tag>
                ))}
              >
                <Typography.Paragraph>
                  <Tag color="blue">
                    +{remainingCategories.length} {translation["More"] || EN["More"]}
                  </Tag>
                </Typography.Paragraph>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    // {
    //   title: translation["Categories"] || EN["Categories"],
    //   dataIndex: "category_id",
    //   key: "category_id",
    //   align: "center",

    //   render: (text, record) => {
    //     const newCategories = record.radios_categories?.map((item) => {
    //       return genresList?.find((i) => i.value === item.category_id);
    //     });

    //     return (
    //       <div
    //         style={{
    //           display: "flex",
    //           flexWrap: "wrap",
    //           justifyContent: "center",
    //           alignItems: "center",
    //         }}
    //       >
    //         {newCategories?.map((item) => {
    //           console.log(item);

    //           return (
    //             <Typography.Paragraph>
    //               <Tag color="blue">{item?.label}</Tag>
    //             </Typography.Paragraph>
    //           );
    //         })}
    //       </div>
    //     );
    //   },
    // },
    {
      title: translation["Url"] || EN["Url"],
      dataIndex: "url",
      key: "url",
      align: "center",
      ellipsis: true,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "center",

      render: (text, record) => {
        return (
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e.key, record)}
            buttons={[
              { key: "edit", text: translation["Edit"] || EN["Edit"], icon: ICONS.EDIT },
              {
                key: "delete",
                text: translation["Delete"] || EN["Delete"],
                icon: ICONS.DELETE,
              },
            ]}
          />
        );
      },
    },
  ];

  const getDataSource = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      total,
      sort,
    };
    try {
      const successCallBack = (data) => {
        setLoading(false);
        setDataSource(data);
      };

      const errorCallBack = (err) => {
        setLoading(false);
        console.log(err);
      };

      REQUESTS.RADIO.GET(query, successCallBack, errorCallBack);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleMenuClick = (key, record) => {
    switch (key) {
      case "edit":
        setEditable(record);
        setShowDrawer(true);

        break;
      case "delete":
        confirm({
          title: translation["Do you want to delete"] || EN["Do you want to delete"],
          onOk() {
            handleDelete(record.id);
          },
        });
        break;
      default:
        break;
    }
  };

  const getRadioCategories = () => {
    try {
      REQUESTS.RADIO_CATEGORIES.GET(
        {},
        (data) => {
          const newGenresList = data.map((item) => {
            return { label: item.name, value: item.id };
          });
          setGenresList(newGenresList);
        },
        (error) => {
          console.log(error);

          notification.error({
            message: translation["Error"] || EN["Error"],
          });
        }
      );
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const handleAddMusic = (values) => {
    setLoading(true);

    clearTimeout(debounceTimeOut);

    debounceTimeOut = setTimeout(() => {
      if (editable) {
        try {
          REQUESTS.RADIO.EDIT(
            { ...values, id: editable.id },

            (data) => {
              setLoading(false);
              notification.success({
                message:
                  translation["Radio updated successfully"] ||
                  EN["Radio updated successfully"],
              });
              setEditable(null);
              setShowDrawer(false);
              getDataSource();
            },
            (error) => {
              setLoading(false);

              notification.error({
                message: translation["An error occurred"] || EN["An error occurred"],
              });

              console.log(error);
            }
          );
        } catch (error) {
          setLoading(false);

          console.log(error);
        }
      } else {
        try {
          REQUESTS.RADIO.ADD(
            values,
            (data) => {
              setLoading(false);

              notification.success({
                message:
                  translation["Radio added successfully"] ||
                  EN["Radio added successfully"],
              });
              setShowDrawer(false);
              getDataSource();
            },
            (error) => {
              setLoading(false);

              console.log(error);
            }
          );
        } catch (error) {
          setLoading(false);

          console.log(error);
        }
      }
    }, 1000);
  };

  const handleDelete = (id) => {
    setLoading(true);
    try {
      REQUESTS.RADIO.DELETE(
        id,
        (data) => {
          setLoading(false);
          getDataSource();
          notification.success({
            message:
              translation["Radio deleted successfully"] ||
              EN["Radio deleted successfully"],
          });
        },
        (error) => {
          setLoading(false);
          console.log(error);
        }
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
    setTotal(pagination.total);
  };

  const onClicCsv = (event, done) => {
    if (!loading) {
      try {
        function callback(data) {
          const newList = data.map((list) => {
            let categories = list.radios_categories.map((item) => {
              return genresList.find((i) => i.value === item.category_id).label;
            });

            return {
              "#": list.id,
              Name: list.name,
              Icon: list.icon,
              Description: list.description,
              Categories: categories.join(", "),
              Url: list.url,
            };
          });
          done(newList);
        }

        function errorCallback() {
          console.log("error");
        }

        REQUESTS.RADIO.GET({}, callback, errorCallback);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getDataSource();
  }, [currentPage, limit, total]);

  useEffect(() => {
    getRadioCategories();
  }, []);

  return (
    <Page>
      <div className="page-body">
        <h1>{translation["Radio_channels"] || EN["Radio_channels"]}</h1>
        <HeaderItem title={"Total"} total={dataSource?.length} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <TableHead>
            <div
              style={{
                display: "flex",
                justifyContent: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <AddButtonComponent
                text={translation["Add Radio"] || EN["Add Radio"]}
                onClick={() => {
                  setShowDrawer(true);
                  setEditable(null);
                }}
              />
              <CsvLinkComponent
                filename="radio-channels"
                data={dataSource}
                onClick={onClicCsv}
              />
              {/* <ViewSettingsDrawer /> */}
            </div>
          </TableHead>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={handleTableChange}
          loading={loading}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: limit,
            total: total,
            limit,
            showSizeChanger: true,
          }}
        />
      </div>
      <RadioDrawer
        visible={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setEditable(null);
        }}
        editable={editable}
        genres={genresList}
        addMusic={handleAddMusic}
        loading={loading}
      />
    </Page>
  );
};

export default RadioPAge;
