import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Table, Input } from "antd";

import REQUESTS from "../../../server/requests";

import ICONS from "../../../config/icons";
import confirmModal from "../../../config/confirmModal";
import { dateFormat } from "../../../config/formats";

import TableButtons from "../../elements/TableButtons";
import AddButtonComponent from "../../elements/AddButtonComponent";
import ImageComponent from "../../elements/ImageComponent";

import AddGuiDrawer from "./AddGuiDrawer";
import GuiTableHead from "./GuiTableHead";
import EN from "../../../config/en";

export default function GuiDrawer({ visible, onClose }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [visibleAddDrawer, setVisibleAddDrawer] = useState(false);

  const [guiList, setGuiList] = useState(false);
  const [currentGui, setCurrentGui] = useState(null);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [titleSearch, setTitleSearch] = useState(null);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!visibleAddDrawer) {
      setCurrentGui(null);
    }
  }, [visibleAddDrawer]);

  useEffect(() => {
    getGuiList();
  }, [currentPage, limit, sort]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getGuiList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [titleSearch]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      fixed: "left",
      key: "id",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    },

    {
      width: 100,
      title: "",
      dataIndex: "image",
      render: (text, record, index) => (
        <ImageComponent src={text} className="tableImage" />
      ),
    },

    {
      title: translate["Title"] || EN["Title"],
      dataIndex: "title",
      key: "title",
      sorter: true,
      render: (text, record, index) => <p className="text-ellipsis">{text}</p>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            allowClear
            autoFocus
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: () => {
        return <>{ICONS.SEARCHOUTLINED}</>;
      },
      onFilter: (value, record) => {
        return record.title.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: translate["Date"] || EN["Date"],
      key: "createdAt",
      sorter: true,
      dataIndex: "createdAt",
      render: (text, record, index) => dateFormat(text),
    },

    {
      title: "",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          style={{ minWidth: 130 }}
          buttons={[
            { key: "edit", text: translate["Edit"] || EN["Edit"], icon: ICONS.EDIT },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE,
            },
          ]}
        />
      ),
    },
  ];
  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setCurrentGui(item);
        setVisibleAddDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
            item.name
          }`,
          action: () => deleteGui(item),
        });
        break;

      default:
        break;
    }
  };
  const deleteGui = (item) => {
    REQUESTS.GUI.DELETE(item.id, getGuiList);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    if (Object.values(filters)[0]) {
      setTitleSearch(Object.values(filters)[0].join(""));
    } else {
      setTitleSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const getGuiList = () => {
    setLoading(true);
    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage,
    };

    if (titleSearch) {
      query.search = JSON.stringify({ title: titleSearch });
    }

    function callback(data) {
      setGuiList(data.rows);
      setLimit(data.limit);
      setTotal(data.count);
      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }
    REQUESTS.GUI.GET(query, callback, errorCallback);
  };

  return (
    <Drawer
      title={translate["GUI Settings"] || EN["GUI Settings"]}
      placement="right"
      width={800}
      visible={visible}
      onClose={onClose}
      destroyOnClose={true}
    >
      <div className="global_header-title-drawer">
        <div className="title-in-drawer">
          <h2>{translate["GUI Settings"] || EN["GUI Settings"]}</h2>
        </div>

        <AddButtonComponent
          text={translate["Add GUI"] || EN["Add GUI"]}
          onClick={() => setVisibleAddDrawer(true)}
        />
      </div>

      <div className="settings_icon_pt">
        <GuiTableHead />
      </div>

      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={guiList}
        onChange={handleTableChange}
        pagination={{
          position: ["topCenter", "bottomCenter"],
          current: currentPage,
          total: total,
          showSizeChanger: true,
        }}
        sticky
      />
      <AddGuiDrawer
        visible={visibleAddDrawer}
        onClose={() => setVisibleAddDrawer(false)}
        currentGui={currentGui}
        getGuiList={getGuiList}
      />
    </Drawer>
  );
}
