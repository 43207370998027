import { useContext } from "react";
import { useSelector } from "react-redux";
import { Checkbox, Form, Select, Switch } from "antd";

import ICONS from "../../../../config/icons";

import Context from "./Context";
import EN from "../../../../config/en";

export default function WidgetsSection() {
  const { enableWidgets, setEnableWidgets, accessToAllWidgets, setAccessToAllWidgets } =
    useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const widgetsOptions = [];

  return (
    <div>
      <div className="switch-block">
        <p>{translate["Widgets"] || EN["Widgets"]}</p>
        <Switch
          checked={enableWidgets}
          onChange={() => setEnableWidgets((value) => !value)}
        />
      </div>

      {enableWidgets && (
        <div className="packages-sections-setyle">
          <Checkbox
            style={{ marginTop: 10, marginBottom: 20 }}
            checked={accessToAllWidgets}
            onChange={(e) => setAccessToAllWidgets(e.target.checked)}
          >
            {translate["Access to all widgets"] || EN["Access to all widgets"]}
          </Checkbox>

          <Form.Item
            label={translate["Widgets"] || EN["Widgets"]}
            tooltip={{
              title: "Widgets",
              icon: ICONS.INFO,
            }}
          >
            <Form.Item name="widget" noStyle>
              <Select
                mode="multiple"
                disabled={accessToAllWidgets}
                options={widgetsOptions}
              />
            </Form.Item>
          </Form.Item>
        </div>
      )}
    </div>
  );
}
