import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, notification, Select } from "antd";

import NewsContext from "./NewsContext";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";

import { usePromotionsCategoriesOptions } from "../../../../hooks/options";
import FileChooser from "../../../elements/FileChooser";
import Translation from "../../../elements/Translation";

export default function NewsDrawer() {
  const { visibleNewsDrawer, setVisibleNewsDrawer, currentNews, getNewsList } =
    useContext(NewsContext);

  const translate = useSelector((state) => state.globalState.translate);

  const categoriesOptions = usePromotionsCategoriesOptions();

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  const [form] = Form.useForm();

  const [titleTranslations, setTitleTranslations] = useState([]);

  const [descriptionTranslations, setDescriptionTranslations] = useState([]);

  useEffect(() => {
    if (visibleNewsDrawer) {
      if (currentNews) {
        form.setFields([
          { name: "categori_id", value: currentNews.categori_id },
          { name: "image", value: currentNews.image },
        ]);

        setTitleTranslations(currentNews.title_translations || []);

        setDescriptionTranslations(currentNews.description_translations || []);

        if (currentNews.image) {
          setImageSrc(currentNews.image);
        }
      }
    } else {
      setErrorText(null);
      setTitleTranslations([]);
      setDescriptionTranslations([]);
    }
  }, [visibleNewsDrawer]);

  useEffect(() => {
    if (!visibleNewsDrawer && currentNews) {
      form.resetFields();
      setImageSrc(null);
    }
  }, [visibleNewsDrawer]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    let body = {
      categori_id: values.categori_id,
      image: values.image,
      title: JSON.stringify(titleTranslations || []),
      description: JSON.stringify(descriptionTranslations || []),
    };

    function callback() {
      setLoading(false);
      setVisibleNewsDrawer();
      getNewsList();
      form.resetFields();
      setImageSrc(null);
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (currentNews) {
      body.id = currentNews.id;

      REQUESTS.PROMOTIONS.NEWS.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.PROMOTIONS.NEWS.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "image", value: null }]);
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    form.setFields([{ name: "image", value: item.file_url }]);
  };

  const onChangeTitle = (text, id) => {
    const title = {
      text,
      language_id: id,
    };

    const newTranslations = [...titleTranslations];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = title;
    } else {
      newTranslations.push(title);
    }

    setTitleTranslations(newTranslations);
  };

  const onChangeDesctiption = (text, id) => {
    const description = {
      text,
      language_id: id,
    };

    const newTranslations = [...descriptionTranslations];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = description;
    } else {
      newTranslations.push(description);
    }

    setDescriptionTranslations(newTranslations);
  };

  return (
    <Drawer
      title={
        currentNews
          ? translate["Edit News"] || EN["Edit News"]
          : translate["Add News"] || EN["Add News"]
      }
      placement="right"
      width={600}
      onClose={() => {
        setVisibleNewsDrawer(false);
        form.resetFields();
      }}
      visible={visibleNewsDrawer}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="news-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Image"] || EN["Image"]}
          tooltip={{
            title: "Choose an image",
            icon: ICONS.INFO,
          }}
          name="image"
          rules={[
            {
              required: true,
              message: `${translate["Please choose"] || EN["Please choose"]} ${
                translate["Image"] || EN["Image"]
              }`,
            },
          ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        {visibleNewsDrawer && (
          <Translation
            render={(item, index) => {
              const title = titleTranslations.find(
                (translation) => translation.language_id === item.id
              )?.text;
              const description = descriptionTranslations.find(
                (translation) => translation.language_id === item.id
              )?.text;

              return (
                <div>
                  <Form.Item
                    label={translate["Title"] || EN["Title"]}
                    tooltip={{
                      title: "News Title",
                      icon: ICONS.INFO,
                    }}
                    rules={[
                      {
                        required: true,
                        message: `${translate["Please input"] || EN["Please input"]} ${
                          translate["Title"] || EN["Title"]
                        }`,
                      },
                    ]}
                  >
                    <Input
                      value={title}
                      onChange={(e) => onChangeTitle(e.target.value, item.id)}
                    />
                  </Form.Item>
                  <Form.Item
                    label={translate["Description"] || EN["Description"]}
                    tooltip={{
                      title: "Service description",
                      icon: ICONS.INFO,
                    }}
                    rules={[
                      {
                        required: true,
                        message: `${translate["Please input"] || EN["Please input"]} ${
                          translate["Description"] || EN["Description"]
                        }`,
                      },
                    ]}
                  >
                    <Input.TextArea
                      value={description}
                      onChange={(e) => onChangeDesctiption(e.target.value, item.id)}
                      rows={5}
                    />
                  </Form.Item>
                </div>
              );
            }}
          />
        )}

        <Form.Item
          label={translate["Category"] || EN["Category"]}
          tooltip={{
            title: "Choose category",
            icon: ICONS.INFO,
          }}
          name="categori_id"
          rules={[
            {
              required: true,
              message: `${translate["Please select"] || EN["Please select"]} ${
                translate["Category"] || EN["Category"]
              }`,
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            showArrow
            allowClear={true}
            options={categoriesOptions}
          />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
