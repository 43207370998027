var pjson = require("../../package.json");

export const APP_VERSION = pjson.version;

export const emailValidator = (rule, value, callback) => {
  // Regular expression to validate the email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (value && !emailRegex.test(value)) {
    callback("Please enter a valid email address");
  } else {
    callback();
  }
};
