import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import TvChannelsContext from "./TvChannelsContext";

import REQUESTS from "../../../../../server/requests";

import ICONS from "../../../../../config/icons";
import EN from "../../../../../config/en";

import ButtonComponent from "../../../../elements/ButtonComponent";
import AddButtonComponent from "../../../../elements/AddButtonComponent";

import HeaderItem from "../../../../elements/HeaderItem";
import PageHeader from "../../../../elements/PageHeader";

export default function TvChannelHeader({ selectedRows, deleteChannel }) {
  const translate = useSelector((state) => state.globalState.translate);

  const { total, setVisibleChannelDrawer, setVisibleImportDrawer } =
    useContext(TvChannelsContext);

  const [totalValue, setTotalValue] = useState(0);
  const [removeButtonText, setRemoveButtonText] = useState(
    translate["Remove"] || EN["Remove"]
  );

  useEffect(() => {
    REQUESTS.TV_CHANNELS.STATISTICS((data) => {
      setTotalValue(data.count);
    });
  }, [total, selectedRows]);

  useEffect(() => {
    if (selectedRows.length === 1) {
      setRemoveButtonText(translate["Remove"] || EN["Remove"]);
    } else if (selectedRows.length > 1) {
      setRemoveButtonText(translate["Remove Selected"] || EN["Remove Selected"]);
    }
  }, [selectedRows]);

  const handleRowKeysDelete = () => {
    deleteChannel();
  };

  return (
    <PageHeader title={translate["Manage TV Channels"] || EN["Manage TV Channels"]}>
      <HeaderItem title={translate["Total"] || EN["Total"]} total={totalValue} />

      <div className="two_button_flex">
        <ButtonComponent
          style={{ width: 190 }}
          text={removeButtonText}
          icon={ICONS.DELETE_WHITE}
          onClick={handleRowKeysDelete}
          disabled={!selectedRows?.length}
          buttonClass={selectedRows?.length ? "danger" : "danger_disabled"}
        />
        <ButtonComponent
          style={{ width: 180 }}
          text={translate["Import m3u file"] || EN["Import m3u file"]}
          icon={ICONS.ARROW_RIGHT_OUTLINED}
          onClick={() => setVisibleImportDrawer(true)}
        />

        <AddButtonComponent
          text={translate["Add Channel"] || EN["Add Channel"]}
          onClick={() => setVisibleChannelDrawer(true)}
        />
      </div>
    </PageHeader>
  );
}
