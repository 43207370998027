import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, notification, Select } from "antd";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";
import REQUESTS from "../../../server/requests";

import ErrorMessage from "../../elements/ErrorMessage";
import SaveButtonComponent from "../../elements/SaveButtonComponent";

export default React.memo(function NewMessageDrawer({ visible, onClose }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [locations, setLocations] = useState([]);
  const [devices, setDevices] = useState([]);

  const [selectedLoacation, setSelectedLoacation] = useState(null);

  const [selectedDevice, setSelectedDevice] = useState(null);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setLoading(false);
      setErrorText(null);
      setSelectedLoacation(null);
      setSelectedDevice(null);
      getLocations();
    }
  }, [visible]);

  useEffect(() => {
    if (selectedLoacation) {
      getDevices();
    }
  }, [selectedLoacation]);

  const getLocations = () => {
    REQUESTS.ROOMS.GET({}, (data) => {
      const list = data.map((item) => ({
        label: item.location_name,
        value: item.id,
      }));

      setLocations(list);
    });
  };

  const getDevices = () => {
    setSelectedDevice(null);

    REQUESTS.ROOMS.DEVICES.GET({ id: selectedLoacation }, (data) => {
      const list = data.map((item) => ({
        label: item.device ? item.device.name : "",
        value: item.device ? item.device.id : "",
      }));

      setDevices(list);
    });
  };

  const onFinish = (values) => {
    setLoading(true);
    setErrorText(null);

    const body = {
      device_id: selectedDevice,
      room_id: selectedLoacation,
    };

    REQUESTS.CONVERSATIONS.ADD(
      body,
      (data) => {
        onClose();
      },
      (err) => {
        // setErrorText(err);
        notification.error({
          message:
            typeof err === "string"
              ? err
              : translate["An error occurred"] || EN["An error occurred"],
        });
        setLoading(false);
      }
    );
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  return (
    <Drawer
      title={translate["New message"] || EN["New message"]}
      placement="right"
      visible={visible}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="news-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Location"] || EN["Location"]}
          tooltip={{
            title: "Location",
            icon: ICONS.INFO,
          }}
          rules={[
            {
              required: true,
              message: `${translate["Please select"] || EN["Please select"]} ${
                translate["Location"] || EN["Location"]
              }`,
            },
          ]}
        >
          <Select
            showArrow
            allowClear={true}
            options={locations}
            value={selectedLoacation}
            onChange={setSelectedLoacation}
          />
        </Form.Item>

        <Form.Item
          label={translate["Device"] || EN["Device"]}
          tooltip={{
            title: "Device",
            icon: ICONS.INFO,
          }}
          rules={[
            {
              required: true,
              message: `${translate["Please select"] || EN["Please select"]} ${
                translate["Device"] || EN["Device"]
              }`,
            },
          ]}
        >
          <Select
            showArrow
            allowClear={true}
            options={devices}
            value={selectedDevice}
            onChange={setSelectedDevice}
            disabled={selectedLoacation ? false : true}
          />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent
            loading={loading}
            disabled={selectedDevice ? false : true}
            onClick={() => form.submit()}
          />
        </Form.Item>

        {/* <ErrorMessage>{errorText}</ErrorMessage> */}
      </Form>
    </Drawer>
  );
});
