import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Button, Tabs } from "antd";

import ICONS from "../../../config/icons";
import EN from "../../../config/en";

import AdminQuickMessages from "./quick-messages/admin/AdminQuickMessages";
import ClientQuickMessages from "./quick-messages/client/ClientQuickMessages";
import AdminQuickMessageDrawer from "./quick-messages/admin/AdminQuickMessageDrawer";
import ClientQuickMessageDrawer from "./quick-messages/client/ClientQuickMessageDrawer";

export default React.memo(function QuickMessages({ activeChat }) {

    const translate = useSelector((state) => state.globalState.translate);

    const [visibleAdminQuickMessageDrawer, setVisibleAdminQuickMessageDrawer] = useState(false);
    const [visibleClientQuickMessageDrawer, setVisibleClientQuickMessageDrawer] = useState(false);

    const [activeKey, setActiveKey] = useState("admin");

    const onChange = (active) => {
        setActiveKey(active);
    };

    const onClickAdd = () => {
        activeKey === "admin"
            ? setVisibleAdminQuickMessageDrawer(true)
            : setVisibleClientQuickMessageDrawer(true);
    };

    return (
        <>
            <div className="quick-messages">
                <header>
                    {translate['Quick messages'] || EN['Quick messages']}
                    <Button type="primary" icon={ICONS.PLUS} onClick={onClickAdd} />
                </header>

                <Tabs
                    activeKey={activeKey}
                    centered
                    style={{ height: "100%" }}
                    onChange={onChange}
                    className="tab-list"
                >
                    <Tabs.TabPane tab={translate['Admin'] || EN['Admin']} key="admin" forceRender={true}>
                        <AdminQuickMessages
                            activeChat={activeChat}
                            visibleAdminQuickMessageDrawer={visibleAdminQuickMessageDrawer}
                        />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={translate['Client'] || EN['Client']} key="guest" forceRender={true}>
                        <ClientQuickMessages
                            visibleClientQuickMessageDrawer={visibleClientQuickMessageDrawer}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </div>

            <AdminQuickMessageDrawer
                visible={visibleAdminQuickMessageDrawer}
                onClose={() => setVisibleAdminQuickMessageDrawer(false)}
            />

            <ClientQuickMessageDrawer
                visible={visibleClientQuickMessageDrawer}
                onClose={() => setVisibleClientQuickMessageDrawer(false)}
            />
        </>
    );
});
