import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";

import PageHeader from "../../../elements/PageHeader";
import HeaderItem from "../../../elements/HeaderItem";
import AddButtonComponent from "../../../elements/AddButtonComponent";
import ButtonComponent from "../../../elements/ButtonComponent";

export default function RoomsHeader({
  total,
  roomsList,
  setIsVisibleAddNewRoomModal,
  openImportFromCsvDrawer
}) {
  const [statistics, setStatistics] = useState({
    free: 0,
    is_cheked_in: 0,
    rooms: 0
  });

  useEffect(() => {
    REQUESTS.ROOMS.STATISTICS((data) => {
      setStatistics(data);
    });
  }, [total, roomsList]);
  const translate = useSelector((state) => state.globalState.translate);

  return (
    <PageHeader title={translate["Locations"] || EN["Locations"]}>
      <div className="info-section">
        <HeaderItem
          title={translate["All rooms"] || EN["All rooms"]}
          total={statistics.rooms}
        />
        <HeaderItem
          title={translate["Activated"] || EN["Activated"]}
          total={statistics.free}
        />
        <HeaderItem
          title={translate["Occupied"] || EN["Occupied"]}
          total={statistics.is_cheked_in}
        />
      </div>

      <div style={{ display: "flex", gap: 10 }}>
        <ButtonComponent
          text={translate["Import from CSV"] || EN["Import from CSV"]}
          onClick={() => openImportFromCsvDrawer(true)}
        />

        <AddButtonComponent
          text={translate["Add Location"] || EN["Add Location"]}
          onClick={() => setIsVisibleAddNewRoomModal(true)}
        />
      </div>
    </PageHeader>
  );
}
