import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, notification } from "antd";

import Context from "./Context";

import REQUESTS from "../../../../../server/requests";

import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";

import ErrorMessage from "../../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../../elements/SaveButtonComponent";

export default function EpgLinksDrawer() {
  const { currentEpgLinks, visibleDrawer, getEpgLinksList, setVisibleDrawer } =
    useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    if (visibleDrawer) {
      if (currentEpgLinks) {
        form.setFields([
          { name: "name", value: currentEpgLinks.name },
          { name: "url", value: currentEpgLinks.url },
        ]);
      }
    } else {
      setErrorText(null);
    }
  }, [visibleDrawer]);

  useEffect(() => {
    if (!visibleDrawer && currentEpgLinks) {
      form.resetFields();
    }
  }, [visibleDrawer]);

  const onFinish = (values) => {
    setLoading(true);
    setErrorText(null);

    let body = {
      name: values.name,
      url: values.url,
    };

    function callback() {
      setVisibleDrawer(false);
      getEpgLinksList();

      setLoading(false);
      form.resetFields();
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (currentEpgLinks) {
      body.id = currentEpgLinks.id;
      REQUESTS.EPG.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.EPG.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  return (
    <Drawer
      title={
        currentEpgLinks
          ? translate["Edit Epg Link"] || EN["Edit Epg Link"]
          : translate["Add New Epg Link"] || EN["Add New Epg Link"]
      }
      placement="right"
      width={400}
      onClose={() => {
        setVisibleDrawer(false);
        form.resetFields();
      }}
      visible={visibleDrawer}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Name"] || EN["Name"]}
          tooltip={{
            title: "Epg Links name",
            icon: ICONS.INFO,
          }}
          name="name"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Name"] || EN["Name"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Url"] || EN["Url"]}
          tooltip={{
            title: "Epg Links url",
            icon: ICONS.INFO,
          }}
          name="url"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Url"] || EN["Url"]
              }`,
            },
          ]}
        >
          <Input type="url" />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
