import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Context from "./Context";
import REQUESTS from "../../../../../server/requests";

import ICONS from "../../../../../config/icons";
import EN from "../../../../../config/en";

import IconButton from "../../../../elements/IconButton";
import TableHeadComponent from "../../../../elements/TableHeadComponent";
import CsvLinkComponent from "../../../../elements/CsvLinkComponent";
import TitleComponent from "../../../../elements/TitleComponent";

import ViewSettingsDrawer from "./ViewSettingsDrawer";
import FilterDrawer from "./FilterDrawer";

export default function TableHead() {
  const {
    room_id,
    setVisibleViewSettingsDrawer,
    setVisibleFilterDrawer,
    selectedPublishedStatus,
    ordersTypeId,
  } = useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const [clickedCsv, setClickedCsv] = useState(false);

  const [dataList, setDataList] = useState([]);

  const [isFilterActive, setIsFilterActive] = useState(false);

  useEffect(() => {
    if (ordersTypeId || selectedPublishedStatus != null) {
      setIsFilterActive(true);

      return;
    }

    setIsFilterActive(false);
  }, [ordersTypeId, selectedPublishedStatus]);

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);

      function callback(data) {
        const newArr = [];
        data.map((list) => {
          if (list.room_id === room_id) {
            newArr.push(list);
          }
        });
        const newList = newArr.map((list) => {
          return {
            Logo: list.image,
            Name: list.name,
            Type: list.type,
            Host_name: list.guest?.name,
            Completed: list.completed,
            Price: list.price,
            Data: list.createdAt,
          };
        });

        setDataList(newList);

        setClickedCsv(false);
      }

      function errorCallback() {
        setClickedCsv(false);
      }

      REQUESTS.ORDERS.GET({}, callback, errorCallback);
    }
  };

  return (
    <>
      <div className="conteiner-head-table">
        <TitleComponent>
          {translate["Orders History"] || EN["Orders History"]}
        </TitleComponent>

        <TableHeadComponent>
          <CsvLinkComponent
            filename="Orders-History-List.csv"
            data={dataList}
            onClick={onClicCsv}
          />

          <IconButton
            icon={ICONS.SETTINGS}
            onClick={() => setVisibleViewSettingsDrawer(true)}
          />

          <IconButton
            active={isFilterActive}
            icon={ICONS.FILTER}
            onClick={() => setVisibleFilterDrawer(true)}
          />
        </TableHeadComponent>

        <ViewSettingsDrawer />
        <FilterDrawer />
      </div>
    </>
  );
}
