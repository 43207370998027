import React from "react";

export default function ReachFormatTextComponent({ children, className }) {

  const content = <div dangerouslySetInnerHTML={{ __html:  children}} />;

  return (
    <div className={className}>
      {content}
    </div>
  );
}
