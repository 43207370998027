import React from "react";
import { useSelector } from "react-redux";
import EN from "../../../config/en";

import HeaderItem from "../../elements/HeaderItem";
import PageHeader from "../../elements/PageHeader";

export default function Head({ total }) {
    const translate = useSelector((state) => state.globalState.translate);

    return (
        <PageHeader title={translate['Translation'] || EN['Translation']}>
            <HeaderItem title={translate['Total'] || EN['Total']} total={total ? total : 0} />
        </PageHeader>
    );
}
