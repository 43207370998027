import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";

import HeaderItem from "../../../elements/HeaderItem";
import PageHeader from "../../../elements/PageHeader";
import AddButtonComponent from "../../../elements/AddButtonComponent";

export default function Head({ onClick, total }) {

    const translate = useSelector((state) => state.globalState.translate);

    const [totalValue, setTotalValue] = useState(0);

    useEffect(() => {
        REQUESTS.ROOMS.GUESTS_GROUPS.STATISTICS((data) => {
            setTotalValue(data.count);
        });
    }, [total]);

    return (
        <PageHeader title={translate['Guest groups'] || EN['Guest groups']}>
            <HeaderItem title={translate['Total'] || EN['Total']} total={totalValue} />

            <AddButtonComponent text={translate['Add Group']  || EN['Add Group']} onClick={onClick} />
        </PageHeader>
    );
}
