import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import REQUESTS from "../../../server/requests";

import EN from "../../../config/en";

import HeaderItem from "../../elements/HeaderItem";
import PageHeader from "../../elements/PageHeader";
import AddButtonComponent from "../../elements/AddButtonComponent";

export default function Head({total, setVisibleDrawer}) {
    
    const translate = useSelector((state) => state.globalState.translate);

    const [totalValue, setTotalValue] = useState({
        all: 0,
        audio: 0,
        image: 0,
        video: 0,
    });

    useEffect(() => {
        REQUESTS.MEDIA_LIBRARY.STATISTICS((data) => {
            setTotalValue(data);
        });
    }, [total]);

    return (
        <PageHeader title={translate['Media Library'] || EN['Media Library']}>
            <HeaderItem title={translate['Total'] || EN['Total']} total={totalValue.all} /> 
            <HeaderItem title={translate['Audio'] || EN['Audio']} total={totalValue.audio} /> 
            <HeaderItem title={translate['Image'] || EN['Image']} total={totalValue.image} /> 
            <HeaderItem title={translate['Video'] || EN['Video']} total={totalValue.video} />  
            <AddButtonComponent
                style={{ width: 200 }}
                text={translate['Add Media Library'] || EN['Add Media Library']}
                onClick={() => setVisibleDrawer(true)}
            />
        </PageHeader>
    );
}
