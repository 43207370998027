import { useSelector } from "react-redux";
import EN from "../../../../../config/en";
import { dateFormatBySlash, formatTime } from "../../../../../config/formats";
import ImageComponent from "../../../../elements/ImageComponent";

export default function MovieInfo({ movie }) {
    const translate = useSelector((state) => state.globalState.translate);
    return (
        <div className="movie-info">
            <ImageComponent src={movie.poster} />
            <div>
                <h1
                    style={{
                        width: 140,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        display: "inline-block",
                        verticalAlign: "bottom",
                    }}
                >
                    {movie.name}
                </h1>
                <span>
                    <b>{translate["Year"] || EN["Year"]}:</b> {dateFormatBySlash(movie.year)}
                </span>
                <br />
                <span>
                    <b>{translate["Time"] || EN["Time"]}:</b> {formatTime(movie.runtime)}
                </span>
            </div>
        </div>
    );
}
