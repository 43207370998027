import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Drawer, Select } from "antd";

import { useMediaTypesOptions } from "../../../hooks/options";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";

import ButtonComponent from "../../elements/ButtonComponent";

export default function FilterDrawer({
  visible,
  onClose,
  setSelectedFileType,
  setIsFilterActive,
  selectedFileType,
}) {
  const translate = useSelector((state) => state.globalState.translate);

  const mediaTypesOptions = useMediaTypesOptions();

  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    if (selectedFileType) {
      setIsFilterActive(true);

      return;
    }

    setIsFilterActive(false);
  }, [selectedFileType]);

  const onClickFilter = () => {
    onClose();
    setSelectedFileType(selectedType);
  };

  return (
    <Drawer
      title={translate["Filter"] || EN["Filter"]}
      placement="right"
      visible={visible}
      onClose={() => {
        onClose();
        setSelectedType(null);
      }}
      destroyOnClose={true}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h1>{translate["Select filter parameters"] || EN["Select filter parameters"]}</h1>

        <p>{translate["Type"] || EN["Type"]}:</p>
        <Select
          showArrow
          allowClear={true}
          value={selectedType}
          onChange={setSelectedType}
          options={mediaTypesOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <ButtonComponent
          text={translate["Filter"] || EN["Filter"]}
          icon={ICONS.ARROW_RIGHT_OUTLINED}
          onClick={onClickFilter}
        />
      </div>
    </Drawer>
  );
}
