import { useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Checkbox } from "antd";

import Context from "./Context";

import EN from "../../../../../config/en";

export default function ViewSettingsDrawer() {
  const {
    visibleViewSettingsDrawer,
    setVisibleViewSettingsDrawer,
    viewSettingsValues,
    setViewSettingsValues,
  } = useContext(Context);

  const onChangeSettingsValues = (event) => {
    viewSettingsValues[event.target.name] = event.target.checked;
    setViewSettingsValues({ ...viewSettingsValues });

    localStorage.setItem("ORDERS_COLUMNS", JSON.stringify(viewSettingsValues));
  };

  const translate = useSelector((state) => state.globalState.translate);

  return (
    <Drawer
      title={translate["View settings"] || EN["View settings"]}
      placement="right"
      onClose={() => setVisibleViewSettingsDrawer(false)}
      visible={visibleViewSettingsDrawer}
      destroyOnClose={true}
    >
      <h2>{translate["Show columns"] || EN["Show columns"]}</h2>

      <div className="view-settings-checkboxes">
        <Checkbox
          name="image"
          checked={viewSettingsValues.image}
          onChange={onChangeSettingsValues}
        >
          {translate["Image"] || EN["Image"]}
        </Checkbox>

        <Checkbox
          name="name"
          checked={viewSettingsValues.name}
          onChange={onChangeSettingsValues}
        >
          {translate["Name"] || EN["Name"]}
        </Checkbox>

        <Checkbox
          name="type"
          checked={viewSettingsValues.type}
          onChange={onChangeSettingsValues}
        >
          {translate["Type"] || EN["Type"]}
        </Checkbox>

        <Checkbox
          name="price"
          checked={viewSettingsValues.price}
          onChange={onChangeSettingsValues}
        >
          {translate["Price"] || EN["Price"]}
        </Checkbox>

        <Checkbox
          name="completed"
          checked={viewSettingsValues.completed}
          onChange={onChangeSettingsValues}
        >
          {translate["Status"] || EN["Status"]}
        </Checkbox>

        <Checkbox
          name="guest"
          checked={viewSettingsValues.guest}
          onChange={onChangeSettingsValues}
        >
          {translate["Guest Name"] || EN["Guest Name"]}
        </Checkbox>

        <Checkbox
          name="quantity"
          checked={viewSettingsValues.quantity}
          onChange={onChangeSettingsValues}
        >
          {translate["Quantity"] || EN["Quantity"]}
        </Checkbox>

        <Checkbox
          name="createdAt"
          checked={viewSettingsValues.createdAt}
          onChange={onChangeSettingsValues}
        >
          {translate["Date"] || EN["Date"]}
        </Checkbox>

        <Checkbox
          name="accepted_date"
          checked={viewSettingsValues.accepted_date}
          onChange={onChangeSettingsValues}
        >
          {translate["Accepted Date"] || EN["Accepted Date"]}
        </Checkbox>

        <Checkbox
          name="rejected_date"
          checked={viewSettingsValues.rejected_date}
          onChange={onChangeSettingsValues}
        >
          {translate["Rejected Date"] || EN["Rejected Date"]}
        </Checkbox>
      </div>
    </Drawer>
  );
}
