import axios from "axios";
import requestQuery from "./requestQuery";

const TMDB_HOST = "https://api.themoviedb.org/3/";

const TMDB_SEARCH = `${TMDB_HOST}search/`;

const API_KEY = "30888e8f271f1698dcfb0228f589bec1";

export const TMDB_IMAGE_HOST = "https://image.tmdb.org/t/p/w500";

const TMDB = {
    SEARCH: {
        MOVIES: (name, callback, errorCallback) => {
            axios
                .get(`${TMDB_SEARCH}multi${requestQuery({ query: name, api_key: API_KEY })}`, {})
                .then((data) => {
                    callback(data.data.results);
                });
        },
    },

    GET: (type, id, callback, errorCallback) => {
        axios
            .get(
                `${TMDB_HOST}${type}/${id}${requestQuery({
                    append_to_response: "videos,images,credits,translations",
                    api_key: API_KEY,
                })}`,
                {}
            )
            .then((data) => {
                callback(data.data);
            });
    },
};

export default TMDB;
