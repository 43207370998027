import React from "react";
import IMAGES from "../../../config/images";

export default function MenuItem({ item }) {
    return (
        <div className="tv-app-menu-item">
            <div className={`container ${item.enable === false && "disabled"}`}>
                <div
                    className="image"
                    style={{
                        backgroundImage: `url('${item.image}')`,
                    }}
                    onError={(e) => (e.target.src = IMAGES.WHITE_IMAGE_ERROR)}
                ></div>

                <p>{item.name}</p>
            </div>
        </div>
    );
}
