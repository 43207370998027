import { useContext } from "react";
import { useSelector } from "react-redux";
import { Checkbox, Select, Switch, Form, Input } from "antd";
import ICONS from "../../../../config/icons";
import EN from "../../../../config/en";
import {
  useCountriesOptions,
  useLanguagesOptions,
  useTvChannelsCategoriesOptions,
} from "../../../../hooks/options";
import Context from "./Context";
let languageSearchTimeout = null;

export default function TvChannelsSection() {
  const {
    enableTvChannels,
    setEnableTvChannels,
    accessToAllChannels,
    setAccessToAllChannels,
  } = useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const countriesOptions = useCountriesOptions();
  const languagesOptions = useLanguagesOptions();

  const tvChannelsCategoriesOptions = useTvChannelsCategoriesOptions();

  const onSearch = (value) => {
    languagesOptions.filter((item) => {
      if (item.label.toLowerCase().includes(value.toLowerCase())) {
        return item;
      }
    });
  };

  const onSearchCountry = (value) => {
    countriesOptions.filter((item) => {
      if (item.label.toLowerCase().includes(value.toLowerCase())) {
        return item;
      }
    });
  };

  return (
    <div>
      <div className="switch-block">
        <p>{translate["Tv Channels"] || EN["Tv Channels"]}</p>
        <Switch
          checked={enableTvChannels}
          onChange={() => setEnableTvChannels((value) => !value)}
        />
      </div>

      {enableTvChannels && (
        <div className="packages-sections-setyle">
          <Checkbox
            style={{ marginTop: 10, marginBottom: 20 }}
            checked={accessToAllChannels}
            onChange={(e) => setAccessToAllChannels(e.target.checked)}
          >
            {translate["Access to all channels"] || EN["Access to all channels"]}
          </Checkbox>
          <Form.Item
            label={translate["Categories"] || EN["Categories"]}
            tooltip={{
              title: "Categories",
              icon: ICONS.INFO,
            }}
          >
            <Form.Item name="tv_channels_categories" noStyle>
              <Select
                mode="multiple"
                options={tvChannelsCategoriesOptions}
                disabled={accessToAllChannels}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            label={translate["Country"] || EN["Country"]}
            tooltip={{
              title: "Country",
              icon: ICONS.INFO,
            }}
          >
            <Form.Item name="tv_channels_countries" noStyle>
              <Select
                mode="multiple"
                options={countriesOptions}
                disabled={accessToAllChannels}
                optionFilterProp="label"
                onSearch={onSearchCountry}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            label={translate["Language"] || EN["Language"]}
            tooltip={{
              title: "Language",
              icon: ICONS.INFO,
            }}
          >
            <Form.Item name="tv_channels_languages" noStyle>
              <Select
                mode="multiple"
                options={languagesOptions}
                disabled={accessToAllChannels}
                optionFilterProp="label"
                onSearch={onSearch}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            label={translate["Rating"] || EN["Rating"]}
            tooltip={{
              title: "Rating",
              icon: ICONS.INFO,
            }}
            rules={[
              {
                type: "number",
                min: 1,
                max: 10,
                message: `${
                  translate["Rating must be between 1 and 10"] ||
                  EN["Rating must be between 1 and 10"]
                }`,
              },
            ]}
          >
            <Form.Item name="tv_channels_rating" noStyle>
              <Input type="text" disabled={accessToAllChannels} />
            </Form.Item>
          </Form.Item>
        </div>
      )}
    </div>
  );
}
