import { useSelector } from "react-redux";
import EN from "../../config/en";

export default function CompletedComponent({ check }) {
   const translate = useSelector((state) => state.globalState.translate);

   if(check === "0") {
      return  translate['Pending'] || EN['Pending']
   }
   else if(check === "1"){
      return  translate['Accept'] || EN['Accept']
   }
   else if(check === "2"){
      return  translate['Reject'] || EN['Reject']
   }
}

