import React, { useContext, useState, useEffect } from "react";

import NewsContext from "./NewsContext";
import REQUESTS from "../../../../server/requests";

import ICONS from "../../../../config/icons";
import IconButton from "../../../elements/IconButton";
import TableHeadComponent from "../../../elements/TableHeadComponent";
import CsvLinkComponent from "../../../elements/CsvLinkComponent";

import FilterDrawer from "./FilterDrawer";
import ViewSettingsDrawer from "./ViewSettingsDrawer";

export default function TableHead() {
  const { setVisibleViewSettingsDrawer, setVisibleFilterDrawer, selectedCategoryId } =
    useContext(NewsContext);

  const [isFilterActive, setIsFilterActive] = useState(false);

  const [clickedCsv, setClickedCsv] = useState(false);

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (selectedCategoryId) {
      setIsFilterActive(true);
      return;
    }
    setIsFilterActive(false);
  }, [selectedCategoryId]);

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);

      function callback(data) {
        const newList = data.map((list) => {
          return {
            Title: list.title,
            Category: list.news_category ? list.news_category.name : "-",
            Description: list.description.replace(/(<([^>]+)>|&nbsp;)/gi, ""),
          };
        });
        done(newList);
        setDataList(newList);

        setClickedCsv(false);
      }

      function errorCallback() {
        setClickedCsv(false);
      }

      REQUESTS.PROMOTIONS.NEWS.GET({}, callback, errorCallback);
    }
  };

  return (
    <>
      <TableHeadComponent>
        <CsvLinkComponent filename="News-List.csv" data={dataList} onClick={onClicCsv} />

        <IconButton
          icon={ICONS.SETTINGS}
          onClick={() => setVisibleViewSettingsDrawer(true)}
        />

        <IconButton
          active={isFilterActive}
          icon={ICONS.FILTER}
          onClick={() => setVisibleFilterDrawer(true)}
        />
      </TableHeadComponent>

      <ViewSettingsDrawer />
      <FilterDrawer />
    </>
  );
}
