import React from "react";
import PropTypes from "prop-types";

import "./_settings.scss";

/**
 * @param {node} icon -> icons.js
 * @param {string} text
 * @param {func} onClick.isRequired
 * @returns
 */

export default function ItemSettings({ icon, title, description, onClick, style }) {
    return (
        <div onClick={onClick} className="item-settings" style={style}>
            {icon}
            <h2>{title}</h2>
            <p>{description}</p>
        </div>
    );
}

ItemSettings.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};
