import React, { useState, useEffect, useContext } from "react";

import Context from "./Context";
import REQUESTS from "../../../../../server/requests";

import ICONS from "../../../../../config/icons";

import IconButton from "../../../../elements/IconButton";
import TableHeadComponent from "../../../../elements/TableHeadComponent";
import CsvLinkComponent from "../../../../elements/CsvLinkComponent";

import ViewSettingsDrawer from "./ViewSettingsDrawer";
import FilterDrawer from "./FilterDrawer";

export default function TableHead() {
  const { setVisibleViewSettingsDrawer, setVisibleFilterDrawer, selectedMovieTypeId } =
    useContext(Context);

  const [isFilterActive, setIsFilterActive] = useState(false);

  const [clickedCsv, setClickedCsv] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (selectedMovieTypeId) {
      setIsFilterActive(true);

      return;
    }
    setIsFilterActive(false);
  }, [selectedMovieTypeId]);

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);
      function callback(data) {
        const newList = data?.map((list) => {
          let type;
          let vod_geners;

          if (list.type === "movie") {
            type = "movie";
          } else if (list.type === "tv") {
            type = "tv";
          } else {
            type = "-";
          }

          if (list.vod_geners && list.vod_geners?.length > 0) {
            const geners = list.vod_geners.map((item) => item.genre.name);

            vod_geners = geners.join(",");
          } else {
            vod_geners = "-";
          }

          return {
            Name: list?.name,
            Overview: list?.overview,
            Type: type,
            Genres: vod_geners,
            Price: list?.price,
          };
        });

        done(newList);
        setDataList(newList);

        setClickedCsv(false);
      }

      function errorCallback() {
        setClickedCsv(false);
      }

      REQUESTS.VOD.MOVIES.GET({}, callback, errorCallback);
    }
  };

  return (
    <>
      <TableHeadComponent>
        <CsvLinkComponent
          filename="Movies-list.csv"
          data={dataList}
          onClick={onClicCsv}
        />
        <IconButton
          icon={ICONS.SETTINGS}
          onClick={() => setVisibleViewSettingsDrawer(true)}
        />
        <IconButton
          active={isFilterActive}
          icon={ICONS.FILTER}
          onClick={() => setVisibleFilterDrawer(true)}
        />
      </TableHeadComponent>

      <ViewSettingsDrawer />

      <FilterDrawer />
    </>
  );
}
//
