import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Select } from "antd";

import NewsContext from "./NewsContext";

import { usePromotionsCategoriesOptions } from "../../../../hooks/options";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";

import ButtonComponent from "../../../elements/ButtonComponent";

export default function FilterDrawer() {
  const { visibleFilterDrawer, setVisibleFilterDrawer, setSelectedCategoryId } =
    useContext(NewsContext);

  const translate = useSelector((state) => state.globalState.translate);

  const categoresOptions = usePromotionsCategoriesOptions();

  const [selectedCategory, setSelectedCategory] = useState(null);

  const onClickFilter = () => {
    setSelectedCategoryId(selectedCategory);

    setVisibleFilterDrawer(false);
  };
  return (
    <Drawer
      title={translate["Filter"] || EN["Filter"]}
      placement="right"
      visible={visibleFilterDrawer}
      onClose={() => setVisibleFilterDrawer(false)}
      destroyOnClose={true}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h1>{translate["Select filter parameters"] || EN["Select filter parameters"]}</h1>

        <p>{translate["Category"] || EN["Category"]}:</p>
        <Select
          showArrow
          allowClear={true}
          value={selectedCategory}
          onChange={setSelectedCategory}
          options={categoresOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <ButtonComponent
          text={translate["Filter"] || EN["Filter"]}
          icon={ICONS.ARROW_RIGHT_OUTLINED}
          onClick={onClickFilter}
        />
      </div>
    </Drawer>
  );
}
