import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import ServiceContext from "./ServiceContext";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";

import PageHeader from "../../../elements/PageHeader";
import HeaderItem from "../../../elements/HeaderItem";
import AddButtonComponent from "../../../elements/AddButtonComponent";

export default function ServicesHeader() {

    const translate = useSelector((state) => state.globalState.translate);

    const { total, setVisibleServiceDrawer } = useContext(ServiceContext);

    const [statistics, setStatistics] = useState({
        ordered: 0,
        services: 0,
    });

    useEffect(() => {
        REQUESTS.ECOMMERCE.SERVICES.STATISTICS(setStatistics);
    }, [total]);

    return (
        <PageHeader title={translate['Services'] || EN['Services']}>
            <div className="info-section">
                <HeaderItem title={translate['Services'] || EN['Services']} total={statistics.services} />
                <HeaderItem title={translate['Ordered'] || EN['Ordered']} total={statistics.ordered} />
            </div>

            <AddButtonComponent
                text={translate['Add Service'] || EN['Add Service']}
                onClick={() => setVisibleServiceDrawer(true)}
            />
        </PageHeader>
    );
}
