import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import uuid from "react-uuid";

import { Layout, notification } from "antd";

import Sidebar from "./Sidebar";

import Header from "./Header";

import { API_HOST } from "../../server/urls";
import { actions } from "../../store";
import NAVIGATORS from "../../config/navigators";

import "./_root.scss";

export let socket = null;

export default function Root() {
  const navigate = useNavigate();

  const uploadingList = useSelector(
    (state) => state.globalState.uploading_list
  );

  const dispatch = useDispatch();

  const {
    setNewMessageInRedux,
    setSeenChatGuestInRedux,
    setSocketIsConnectedInRedux
  } = bindActionCreators(actions, dispatch);
  const socketIsConnected = useSelector(
    (state) => state.globalState.socketIsConnected
  );

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    connect();
  }, [socketIsConnected]);

  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnload);
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [uploadingList]);

  const showM3U8Error = (err) => {
    notification.error({ description: err });
  };

  const connect = () => {
    if (!socketIsConnected) {
      socket = socketIOClient(API_HOST, {
        auth: {
          token: localStorage.getItem("TOKEN")
        }
      });

      socket.on("connect", (data) => {
        console.log("connect");
        setSocketIsConnectedInRedux(true);
      });

      socket.on("connect_error", (err) => {
        setSocketIsConnectedInRedux(false);
      });

      socket.on("is_online", (data) => {
        console.log(data, " is_online");
      });

      socket.on("seen", (data) => {
        setSeenChatGuestInRedux(data);
      });

      socket.on("error_message", (data) => {
        showM3U8Error(data);
      });

      socket.on("message", (data) => {
        let location = window.location.href.split("/");
        location = location[location.length - 1];

        if (location !== "messages") {
          if (data.message_type === "messages") {
            openMessageNotification(data);
          }
        }

        if (location !== "orders-products") {
          if (
            data.message_type === "product" &&
            data.message &&
            data.message.completed == "0"
          ) {
            openOrderNotification(data);
          }
        }

        if (location !== "orders-services") {
          if (
            data.message_type === "service" &&
            data.message &&
            data.message.completed == "0"
          ) {
            openOrderNotification(data);
          }
        }

        setNewMessageInRedux(data);
      });
    } else {
      socket.disconnect();
    }
  };

  const openMessageNotification = (message) => {
    const key = uuid();
    const args = {
      key,
      message: `New message from ${message.sender_name}`,
      description: (
        <div>
          <p className="text-ellipsis">{message.message}</p>
        </div>
      ),

      className: "notification-new-message",

      duration: 15,

      onClick: () => {
        navigate(NAVIGATORS.MESSAGES, {
          state: { conversationId: message.conversation_id }
        });

        notification.close(key);
      }
    };
    notification.open(args);
  };

  const openOrderNotification = (message) => {
    const key = uuid();
    const args = {
      key,
      message: `New order from ${message.sender_name}`,
      description: (
        <div className="order-notification-style">
          <img
            src={
              message.message.image
                ? message.message.image[0]
                : message.message.logo[0]
            }
          />

          <div>
            <p className="text-ellipsis">
              <span style={{ color: "#2f6bff", marginRight: 5 }}>
                {message.message.quantity}
              </span>
              {message.message.name}
            </p>
            <p className="text-ellipsis">
              Total price
              {message.message.quantity
                ? message.message.price * message.message.quantity
                : message.message.price}
            </p>
          </div>
        </div>
      ),

      className: "notification-new-message",

      duration: 15,

      onClick: () => {
        if (message.message_type == "product") {
          navigate(NAVIGATORS.ORDERS_PRODUCTS);
        } else {
          navigate(NAVIGATORS.ORDERS_SERVICES);
        }

        notification.close(key);
      }
    };
    notification.open(args);
  };

  const handleCollapsed = () => {
    setCollapsed((collapsed) => !collapsed);
  };

  const beforeUnload = (e) => {
    if (uploadingList.length) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar collapsed={collapsed} handleCollapsed={handleCollapsed} />

      <Layout
        className="site-layout"
        style={{ marginLeft: collapsed ? 80 : 250, transition: "0.3s" }}
      >
        <Header collapsed={collapsed} handleCollapsed={handleCollapsed} />

        <Layout.Content>
          <div className="pages-content">
            <Outlet />
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
