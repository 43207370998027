import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import REQUESTS from "../../../../../server/requests";
import { Form, Input, notification, Table } from "antd";

import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";
import confirmModal from "../../../../../config/confirmModal";

import ErrorMessage from "../../../../elements/ErrorMessage";
import ButtonComponent from "../../../../elements/ButtonComponent";
import AcceptRejectBtnComponent from "../../../../elements/AcceptRejectBtnComponent";
import CompletedComponent from "../../../../elements/CompletedComponent";
import LongText from "../../../../elements/LongText";

import AddOrdersDrawer from "../AddOrdersDrawer";
import Currency from "../../../../elements/Currency";
import { dateFormat, parseYearMonthDayHoursMinutes } from "../../../../../config/formats";

export default function OrdersHost({ currentHost, setUpdateOrdersTable }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [orderProductsList, setOrderProductsList] = useState([]);
  const [visibleOrdersDrawer, setVisibleOrdersDrawer] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [selecteOrder, setSelectedOrder] = useState(null);

  const [form] = Form.useForm();

  const hostId = useRef(null);

  if (currentHost && currentHost.guest !== null) {
    hostId.current = currentHost.guest.id;
  }
  const host_id = hostId.current;

  useEffect(() => {
    getOrderProductsList();
  }, [host_id, sort]);

  const columns = [
    {
      width: 60,
      fixed: "left",
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },

    {
      title: translate["Name"] || EN["Name"],
      width: 100,
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => <p className="text-ellipsis">{text}</p>,
    },

    {
      title: translate["Type"] || EN["Type"],
      width: 100,
      dataIndex: "type",
      key: "type",
      render: (text, record, index) => record.type,
    },

    {
      title: translate["Price"] || EN["Price"],
      width: 100,
      dataIndex: "price",
      key: "price",
      render: (text, record, index) => (
        <p className="currency-center">
          <Currency>{parseFloat(record.price).toFixed(2)}</Currency>
        </p>
      ),
    },

    {
      title: translate["Messages"] || EN["Messages"],
      width: 100,
      dataIndex: "message",
      key: "message",
      render: (text, record, index) => (
        <LongText textAlign={"center"} paddingLeft={0} width={100}>
          {record.message ? record.message : translate["No message"] || EN["No message"]}
        </LongText>
      ),
    },

    {
      title: translate["Status"] || EN["Status"],
      width: 110,
      dataIndex: "completed",
      key: "completed",
      render: (text, record, index) => <CompletedComponent check={record.completed} />,
    },

    {
      title: translate["Reserved Date"] || EN["Reserved Date"],
      dataIndex: "reserved_date",
      key: "reserved_date",
      render: (text, record, index) => (
        <p className="text-ellipsis txt-tb-r">
          {record.reserved_date
            ? parseYearMonthDayHoursMinutes(record.reserved_date)
            : ""}
        </p>
      ),
    },

    {
      title: translate["Accepted Date"] || EN["Accepted Date"],
      dataIndex: "accepted_date",
      key: "accepted_date",
      render: (text, record, index) => (
        <p className="text-ellipsis txt-tb-r">
          {record.accepted_date
            ? parseYearMonthDayHoursMinutes(record.accepted_date)
            : ""}
        </p>
      ),
    },

    {
      title: translate["Rejected Date"] || EN["Rejected Date"],
      dataIndex: "rejected_date",
      key: "rejected_date",
      render: (text, record, index) => (
        <p className="text-ellipsis txt-tb-r">
          {record.rejected_date
            ? parseYearMonthDayHoursMinutes(record.rejected_date)
            : ""}
        </p>
      ),
    },

    {
      title: "",
      width: 150,
      fixed: "right",
      dataIndex: "action",
      align: "center",
      render: (text, record, index) => (
        <div>
          {record.completed === "0" ? (
            <div style={{ display: "flex" }}>
              <AcceptRejectBtnComponent
                icon={ICONS.ACCEPT}
                onClick={(e) => onChangeDefaultProducts(e, record, "1")}
              />
              <AcceptRejectBtnComponent
                icon={ICONS.REJECT}
                onClick={(e) => onChangeDefaultProducts(e, record, "2")}
              />
            </div>
          ) : (
            <AcceptRejectBtnComponent
              icon={record.completed === "1" ? ICONS.REJECT : ICONS.ACCEPT}
              onClick={(e) =>
                onChangeDefaultProducts(e, record, record.completed === "1" ? "2" : "1")
              }
            />
          )}
        </div>
      ),
    },
  ];

  const getOrderProductsList = () => {
    if (!host_id) {
      return;
    }
    const query = {
      sort,
    };

    query.filter = JSON.stringify({
      guest_id: host_id,
    });

    function callback(data) {
      setOrderProductsList(data);
      setLoading(false);
      setUpdateOrdersTable((bool) => !bool);
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.ORDERS.GET(query, callback, errorCallback);
  };

  const onFinish = (values) => {
    let productsId = selecteOrder.id;
    let completed = selecteOrder.status;

    form.resetFields();

    const body = {
      order_id: productsId,
      completed: completed,
      message: values.message,
    };

    function callback() {
      getOrderProductsList();
      setSelectedOrder(null);
    }

    function errorCallback(err) {
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
    }

    REQUESTS.ORDERS.COMPLETED(body, callback, errorCallback);
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  useEffect(() => {
    selecteOrder && openConfirmModal();
  }, [selecteOrder]);

  const openConfirmModal = () => {
    confirmModal({
      title: `${translate["Change this order status"] || EN["Change this order status"]}`,
      content: (
        <Form
          form={form}
          layout="vertical"
          name="servicesOrders-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={translate["Messages"] || EN["Messages"]}
            name="message"
            tooltip={{
              title: "message",
              icon: ICONS.INFO,
            }}
          >
            <Input.TextArea rows={5} onResize={false} />
          </Form.Item>
          {/* <ErrorMessage>{errorText}</ErrorMessage> */}
        </Form>
      ),
      action: () => form.submit(),
    });
  };

  const onChangeDefaultProducts = (e, record, status) => {
    setSelectedOrder({ ...record, status });
  };

  const handleTableChange = (sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
  };

  return (
    <>
      <div className="df_attach_title">
        {currentHost && currentHost.guest && (
          <ButtonComponent
            text={translate["Attach Products"] || EN["Attach Products"]}
            icon={ICONS.ATTACH_WHITE}
            onClick={() => setVisibleOrdersDrawer(true)}
          />
        )}
      </div>

      <div className="ordersScroll">
        <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          dataSource={orderProductsList}
          scroll={{ x: 1200, y: true }}
          pagination={false}
          onChange={handleTableChange}
        />
      </div>

      <AddOrdersDrawer
        current={currentHost}
        getData={getOrderProductsList}
        visible={visibleOrdersDrawer}
        onClose={() => setVisibleOrdersDrawer(false)}
      />
    </>
  );
}
