import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";

import { actions } from "../../../store";

import ICONS from "../../../config/icons";

import TitleComponent from "../../elements/TitleComponent";
import Page from "../../elements/Page";

import HotelStaffDrawer from "./HotelStaffDrawer";

import OrganizationInfoDrawer from "./OrganizationInfoDrawer";
import UserAccountsDrawer from "./UserAccountsDrawer";
import ItemSettings from "./ItemSettings";
import FeedbackSettingsDrawer from "./FeedbackSettingsDrawer";
import SettingsTvChannelsDrawer from "./SettingsTvChannelsDrawer";
import PreferenceDrawer from "./PreferenceDrawer";
import GuiDrawer from "./GuiDrawer";
import CountryDrawer from "./CountryDrawer";
import EN from "../../../config/en";
import LanguagesDrawer from "./languages/LanguagesDrawer";
import AirportSetingsDrawer from "./airport/AirportSettingsDrawer";
import PmsDrawer from "./PmsDrawer";

export default function SettingsPage() {
  const { isOpenProfileDrawer, profile } = useSelector((state) => state.globalState);

  const dispatch = useDispatch();

  const { setOpenProfileDrawerInRedux } = bindActionCreators(actions, dispatch);

  const translate = useSelector((state) => state.globalState.translate);

  const [showOrganizationInfoModal, setShowOrganizationInfoModal] = useState(false);
  const [isVisibleUserAccountsDrawer, setIsVisibleUserAccountsDrawer] = useState(false);
  const [isVisibleTheHotelStaffDrawer, setIsVisibleTheHotelStaffDrawer] = useState(false);
  const [feedbackSettingsModal, setFeedbackSettingsModal] = useState(false);
  const [airportSettingsModal, setAirportSettingsModal] = useState(false);
  const [tvChannelsModal, setTvChannelsModal] = useState(false);
  const [preferenceModal, setPreferenceModal] = useState(false);
  const [guiModal, setGuiModal] = useState(false);
  const [countryModal, setCountryModal] = useState(false);
  const [pmsModal, setPmsModal] = useState(false);

  const [isVisibleLanguagesDrawer, setIsVisibleLanguagesDrawer] = useState(false);

  useEffect(() => {
    if (isOpenProfileDrawer) {
      setIsVisibleUserAccountsDrawer(true);
    }
  }, [isOpenProfileDrawer]);

  useEffect(() => {
    setOpenProfileDrawerInRedux(isVisibleUserAccountsDrawer);
  }, [isVisibleUserAccountsDrawer]);

  const onClickorganizationInfo = () => {
    setShowOrganizationInfoModal(true);
  };

  const onClickUserAccounts = () => {
    setIsVisibleUserAccountsDrawer(true);
  };

  const onClickFeedbackSettings = () => {
    setFeedbackSettingsModal(true);
  };

  const onClickAirportSettings = () => {
    setAirportSettingsModal(true);
  };

  const onClickTvChannels = () => {
    setTvChannelsModal(true);
  };

  const onClickPreferenceSettings = () => {
    setPreferenceModal(true);
  };

  const onClickGuiSettings = () => {
    setGuiModal(true);
  };

  const onClickCountrySettings = () => {
    setCountryModal(true);
  };

  const onClickPmsSettings = () => {
    setPmsModal(true);
  };

  return (
    <Page>
      <div className="page-body ">
        <TitleComponent>{translate["Settings"] || EN["Settings"]}</TitleComponent>

        <div className="app-items-settings">
          <div className="section-items-settings">
            <ItemSettings
              icon={ICONS.HOTEL}
              title={translate["Organization Info"] || EN["Organization Info"]}
              description={
                translate["Edit and manage basic information about organization"] ||
                EN["Edit and manage basic information about organization"]
              }
              onClick={onClickorganizationInfo}
            />

            <ItemSettings
              icon={ICONS.USER_ACCOUNTS}
              title={translate["User account"] || EN["User account"]}
              description={
                translate["Manage information about user accounts"] ||
                EN["Manage information about user accounts"]
              }
              onClick={onClickUserAccounts}
            />

            <ItemSettings
              icon={ICONS.USERS}
              title={translate["Hotel staff"] || EN["Hotel staff"]}
              description={
                translate["Manage information about organization employees"] ||
                EN["Manage information about organization employees"]
              }
              onClick={() => setIsVisibleTheHotelStaffDrawer(true)}
            />

            <ItemSettings
              icon={ICONS.TV}
              title={translate["Tv Channels"] || EN["Tv Channels"]}
              description={translate["Manage TV Channels"] || EN["Manage TV Channels"]}
              onClick={onClickTvChannels}
            />

            <ItemSettings
              icon={ICONS.AIRPLANE}
              title={translate["Airport settings"] || EN["Airport settings"]}
              description={
                translate["Manage display of nearby airports"] ||
                EN["Manage display of nearby airports"]
              }
              onClick={onClickAirportSettings}
            />

            <ItemSettings
              icon={ICONS.FEEDBACK_SETTINGS}
              title={translate["Feedback Settings"] || EN["Feedback Settings"]}
              description={
                translate["Manage display of feedback parameters"] ||
                EN["Manage display of feedback parameters"]
              }
              onClick={onClickFeedbackSettings}
            />

            <ItemSettings
              icon={ICONS.PREFERENCE}
              title={translate["Preference"] || EN["Preference"]}
              description={translate["Preference"] || EN["Preference"]}
              onClick={onClickPreferenceSettings}
            />

            <ItemSettings
              icon={ICONS.GUI}
              title={translate["GUI"] || EN["GUI"]}
              description={translate["GUI"] || EN["GUI"]}
              onClick={onClickGuiSettings}
            />

            <ItemSettings
              icon={ICONS.PREFERENCE}
              title={
                translate["PMS"] || EN["PMS"] 
              }
              description={translate["Integrate with other PMS systems"] ||
                EN["Integrate with other PMS systems"]}
              onClick={onClickPmsSettings}
            />

            {profile?.is_super_admin && (
              <ItemSettings
                icon={ICONS.COUNTRIES}
                title={translate["Countries"] || EN["Countries"]}
                description={translate["Countries"] || EN["Countries"]}
                onClick={onClickCountrySettings}
              />
            )}
            {profile?.is_super_admin && (
              <ItemSettings
                icon={ICONS.LANGUAGES}
                title={translate["Languages"] || EN["Languages"]}
                description={translate["Languages"] || EN["Languages"]}
                onClick={() => setIsVisibleLanguagesDrawer(true)}
              />
            )}
          </div>
        </div>

        <OrganizationInfoDrawer
          visible={showOrganizationInfoModal}
          onClose={() => setShowOrganizationInfoModal(false)}
        />

        <UserAccountsDrawer
          visible={isVisibleUserAccountsDrawer}
          onClose={() => setIsVisibleUserAccountsDrawer(false)}
        />

        <HotelStaffDrawer
          visible={isVisibleTheHotelStaffDrawer}
          onClose={() => setIsVisibleTheHotelStaffDrawer(false)}
        />

        <FeedbackSettingsDrawer
          visible={feedbackSettingsModal}
          onClose={() => setFeedbackSettingsModal(false)}
        />

        <AirportSetingsDrawer
          visible={airportSettingsModal}
          onClose={() => setAirportSettingsModal(false)}
        />

        <SettingsTvChannelsDrawer
          visible={tvChannelsModal}
          onClose={() => setTvChannelsModal(false)}
        />

        <PreferenceDrawer
          visible={preferenceModal}
          onClose={() => setPreferenceModal(false)}
        />

        <GuiDrawer visible={guiModal} onClose={() => setGuiModal(false)} />

        <CountryDrawer visible={countryModal} onClose={() => setCountryModal(false)} />

        <LanguagesDrawer
          visible={isVisibleLanguagesDrawer}
          onClose={() => {
            setIsVisibleLanguagesDrawer(false);
          }}
        />
        <PmsDrawer visible={pmsModal} onClose={() => setPmsModal(false)} />
      </div>
    </Page>
  );
}
