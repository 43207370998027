import { useEffect, useState } from "react";
import { Drawer, Form, Input, Select, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FileChooser from "../../../../elements/FileChooser";
import Translation from "../../../../elements/Translation";
import { useSelector } from "react-redux";
import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";

const StoryDrawer = ({ visible, onClose, country, addMusic, editable, loading }) => {
  const [form] = Form.useForm();
  const [imageSrc, setImageSrc] = useState(null);
  const [descriptionTranslations, setDescriptionTranslations] = useState([]);
  const [nameTranslation, setNameTranslation] = useState([]);
  const [countryValue, setCountryValue] = useState(null);

  const translate = useSelector((state) => state.globalState.translate);

  const handleFinish = (values) => {
    console.log(countryValue);

    const body = {
      feedbacks: values.feedbacks,
      rating: values.rating,
      name: values.name,
      image: imageSrc,
    };

    if (countryValue) {
      body.country = countryValue;
    }

    addMusic(body);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "image", value: null }]);
  };

  const selectFile = (file) => {
    setImageSrc(file?.file_url);
    form.setFields([{ name: "image", value: file }]);
  };

  const onChangeNameTranslation = (value, id) => {
    const name = {
      text: value,
      language_id: id,
    };
    const newTranslations = [...nameTranslation];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = name;
    } else {
      newTranslations.push(name);
    }

    setNameTranslation(newTranslations);
  };

  const onChangeTranslation = (value, id) => {
    const description = {
      text: value,
      language_id: id,
    };
    const newTranslations = [...descriptionTranslations];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = description;
    } else {
      newTranslations.push(description);
    }

    setDescriptionTranslations(newTranslations);
  };
  const setCategories = (editable, genres) => {
    if (editable) {
      const newGenres = editable.map((item) => {
        const genre = genres.find((genre) => genre.value === item.category_id);

        return genre;
      });
      return newGenres;
    }
  };

  useEffect(() => {
    if (editable) {
      form.setFieldsValue({
        // title: editable.title,
        // text: editable.text,
        // image: editable.image,
        name: editable.name,
        rating: editable.rating,
        country: editable.country,
        feedbacks: editable.feedbacks,
      });

      setImageSrc(editable.icon);
    }
  }, [visible, editable]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setImageSrc(null);
      setDescriptionTranslations([]);
      setNameTranslation([]);
      setCategories(null);
    }
  }, [visible]);

  return (
    <Drawer
      width={550}
      open={visible}
      destroyOnClose={true}
      title={
        editable
          ? translate["Edit Customer Story"] || EN["Edit Customer Story"]
          : translate["Add customer story"] || EN["Add customer story"]
      }
      onClose={() => {
        onClose();
        form.resetFields();
        selectFile(null);
      }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            onClick={() => {
              form.resetFields();
              onClose();
            }}
            style={{ marginRight: 8 }}
          >
            {translate["Cancel"] || EN["Cancel"]}
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            {editable ? translate["Edit"] || EN["Edit"] : translate["Add"] || EN["Add"]}
          </Button>
        </div>
      }
    >
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item
          label="Icon"
          name="icon"
          rules={[
            {
              required: true,
              message: `${
                translate["Please choose a Image"] || EN["Please choose a Image"]
              }`,
            },
          ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>
        <Form.Item
          name="name"
          label={translate["Name"] || EN["Name"]}
          tooltip={{
            title: translate["Please input name"] || EN["Please input name"],
            icon: ICONS.INFO,
          }}
          rules={[
            {
              required: true,
              message: `${translate["Please input name"] || EN["Please input name"]}`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="rating"
          label={translate["Rating"] || EN["Rating"]}
          rules={[
            {
              pattern: new RegExp(/^[0-5]$/),
              message:
                translate["Please enter a valid number"] ||
                EN["Please enter a valid number"],
            },
            {
              required: true,
              message: translate["Please enter a rating"] || EN["Please enter a rating"],
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="country" label={translate["Country"] || EN["Country"]}>
          <Select
            options={country}
            // mode="multiple"
            // placeholder="Select a Cauntry"
            showSearch
            allowClear
            filterOption={(input, option) => {
              return option?.label?.toLowerCase()?.includes(input?.toLowerCase());
            }}
            onChange={(value) => {
              console.log(value);

              const countryName = country?.find((item) => item?.value === value);
              console.log(countryName?.label);
              setCountryValue(countryName?.label);

              //   if (value && value?.length) {
              //     console.log(countryName);

              //   }
            }}
          />
        </Form.Item>
        <Form.Item name="feedbacks" label={translate["Feedback"] || EN["Feedback"]}>
          <TextArea />
        </Form.Item>
        {/* <Form.Item label="Icon" name="icon">
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>
        <Translation
          render={(item, index) => {
            const name = nameTranslation.find(
              (translation) => translation.language_id === item.id
            )?.text;
            return (
              <Form.Item
                label={translate["Title"] || EN["Title"]}
                name="title"
                tooltip={{
                  title: "Info Title",
                  icon: ICONS.INFO,
                }}
              >
                <Input
                  value={name}
                  onChange={(e) => {
                    onChangeNameTranslation(e.target.value, item.id);
                    // setNameTranslation(e.target.value, item.id);
                  }}
                />
              </Form.Item>
            );
          }}
        />
        {/* <Form.Item label="Name" name="name">
          <Input />
        </Form.Item> */}
        {/* <Form.Item label="URL" name="url">
          <Input />
        </Form.Item> */}
        {/* <Form.Item label="Category" name="category_id">
          <Select
            options={genres}
            mode="multiple"
            placeholder="Select a category"
            showSearch
            allowClear
            filterOption={(input, option) => {
              return option?.label?.toLowerCase()?.includes(input?.toLowerCase());
            }}
            onChange={(value) => {
              if (value || value?.length) {
                setCategoriesId(value);
              }
            }}
          />
        </Form.Item> */}
        {/* <Translation
          render={(item, index) => {
            const description = descriptionTranslations.find(
              (translation) => translation.language_id === item.id
            )?.text;
            return (
              <Form.Item
                label={translate["Text"] || EN["Text"]}
                name="text"
                tooltip={{
                  title: "Info Title",
                  icon: ICONS.INFO,
                }}
              >
                <TextArea
                  value={description}
                  onChange={(e) => {
                    onChangeTranslation(e.target.value, item.id);
                  }}
                />
              </Form.Item>
            );
          }}
        /> */}

        {/* <Form.Item label="Description" name="description">
            <TextArea />
          </Form.Item> */}
        {/* </Translation> */}
      </Form>
    </Drawer>
  );
};

export default StoryDrawer;
