import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker, Space } from "antd";
import REQUESTS from "../../../server/requests";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

import EN from "../../../config/en";
import { returnShortMonthName } from "../../../config/formats";

import TitleComponent from "../../elements/TitleComponent";

export default function ProductsCharts() {

  const translate = useSelector((state) => state.globalState.translate);

  const [statistics, setStatistics] = useState([]);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
    getDashboardCharts();
  }, [selectedYear]);

  const getDashboardCharts = () => {
    const query = {
      year: selectedYear,
      type: "product",
    };
    REQUESTS.DASHBOARD.STATISTICS_ORDER(query, (data) => {
      setStatistics(data);
    });
  };

  const data_barChart = statistics.map((item) => {
    return {
      month:  returnShortMonthName(item.month),
      count: item.count,
      price: item.price,
    };
  });

  function onChange(date, dateString) {
    setSelectedYear(dateString);
  }

  return (
    <div className="app_content_charts">
      <div className="content_charts">
        <div className="bg_fff">
          <div className="title_and_select_df">
            <TitleComponent>{translate['Products'] || EN['Products']}</TitleComponent>
            <Space direction="vertical">
              <DatePicker onChange={onChange} picker="year" />
            </Space>
          </div>
          <ResponsiveContainer width="100%" aspect={2/1}>
          <LineChart
            width={730} 
            height={250}
            data={data_barChart}
            margin={{
              top: 20,
              right: 20,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis  allowDecimals={false} dataKey="price"/>
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="price" stroke="#8884d8" />
            <Line type="monotone" dataKey="count" stroke="#82ca9d" />
          </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
