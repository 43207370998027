import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { message, Table } from "antd";

import confirmModal from "../../../../../../config/confirmModal";
import ICONS from "../../../../../../config/icons";
import EN from "../../../../../../config/en";
import REQUESTS from "../../../../../../server/requests";

import AddButtonComponent from "../../../../../elements/AddButtonComponent";
import TableButtons from "../../../../../elements/TableButtons";
import ImageComponent from "../../../../../elements/ImageComponent";

import EpisodeDrawer from "./EpisodeDrawer";
import SeasonDrawer from "./SeasonDrawer";

export default function Seasons({ id, movie }) {
    const translate = useSelector((state) => state.globalState.translate);

    const [seasonDrawer, setSeasonDrawer] = useState({
        visible: false,
        item: null,
    });

    const [episodeDrawer, setEpisodeDrawer] = useState({
        visible: false,
        item: null,
    });

    const [activeSeason, setActiveSeason] = useState(null);

    const [seasons, setSeasons] = useState([]);

    const [episodes, setEpisodes] = useState([]);

    useEffect(() => {
        if (activeSeason) {
            getEpisodes();
        }
    }, [activeSeason]);

    useEffect(() => {
        if (id) {
            getSeasons((data) => {
                setActiveSeason(data[0]);
            });
        }
    }, [id]);

    const seasonsColumns = [
        {
            title: "#",
            dataIndex: "id",
            align: "center",
            render: (text, record, index) => index + 1,
        },
        {
            dataIndex: "poster_path",
            render: (text, record, index) => <ImageComponent src={text} className="tableImage" />,
        },
        {
            title: translate["Name"] || EN["Name"],
            dataIndex: "name",
        },
        {
            title: translate["Episodes"] || EN["Episodes"],
            dataIndex: "episodes_count",
        },

        {
            title: "",
            dataIndex: "action",
            align: "center",
            fixed: "right",
            width: 60,
            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    style={{ minWidth: 130 }}
                    buttons={[
                        // { key: "play", text: translate['Play'] || EN['Play'], icon: ICONS.PLAY },
                        {
                            key: "edit_season",
                            text: translate["Edit"] || EN["Edit"],
                            icon: ICONS.EDIT,
                        },
                        {
                            key: "delete_season",
                            text: translate["Delete"] || EN["Delete"],
                            icon: ICONS.DELETE,
                        },
                    ]}
                />
            ),
        },
    ];

    const episodesColumns = [
        {
            title: "#",
            dataIndex: "id",
            align: "center",
            render: (text, record, index) => index + 1,
        },

        {
            dataIndex: "still_path",
            render: (text, record, index) => <ImageComponent src={text} className="tableImage" />,
        },

        {
            title: translate["Name"] || EN["Name"],
            dataIndex: "name",
        },

        {
            title: translate["Url"] || EN["Url"],
            dataIndex: "url",
        },

        {
            title: "",
            dataIndex: "action",
            align: "center",
            fixed: "right",
            width: 60,
            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    style={{ minWidth: 130 }}
                    buttons={[
                        // { key: "play", text: translate['Play'] || EN['Play'], icon: ICONS.PLAY },
                        {
                            key: "edit_episode",
                            text: translate["Edit"] || EN["Edit"],
                            icon: ICONS.EDIT,
                        },
                        {
                            key: "delete_episode",
                            text: translate["Delete"] || EN["Delete"],
                            icon: ICONS.DELETE,
                        },
                    ]}
                />
            ),
        },
    ];

    const handleMenuClick = (e, item) => {
        switch (e.key) {
            // case "play":
            //     setCurrentMoves(item);
            //     setVisiblePlayDrawer(true);
            //     break;
            case "edit_season":
                setSeasonDrawer({
                    visible: true,
                    item: item,
                });
                break;

            case "delete_season":
                confirmModal({
                    title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
                        item.name
                    }`,
                    action: () => deleteSeason(item),
                });
                break;

            case "edit_episode":
                setEpisodeDrawer({
                    visible: true,
                    item: item,
                });
                break;

            case "delete_episode":
                confirmModal({
                    title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
                        item.name
                    }`,
                    action: () => deleteEpisode(item),
                });
                break;

            default:
                break;
        }
    };

    const deleteSeason = (item) => {
        REQUESTS.VOD.MOVIES.TV_SHOWS.SEASONS.DELETE(
            item.id,
            (data) => {
                getSeasons();
            },
            (err) => {
                message.error(err);
            }
        );
    };

    const deleteEpisode = (item) => {
        REQUESTS.VOD.MOVIES.TV_SHOWS.EPISODES.DELETE(
            item.id,
            (data) => {
                getEpisodes();
                getSeasons();
            },
            (err) => {
                message.error(err);
            }
        );
    };

    const getSeasons = (callback) => {
        const body = {
            filter: JSON.stringify({
                vod_id: id,
            }),
        };

        REQUESTS.VOD.MOVIES.TV_SHOWS.SEASONS.GET(body, (data) => {
            setSeasons(data);
            if (callback) {
                callback(data);
            }
        });
    };

    const getEpisodes = () => {
        const body = {
            filter: JSON.stringify({
                seasons_id: activeSeason.id,
            }),
        };
        REQUESTS.VOD.MOVIES.TV_SHOWS.EPISODES.GET(body, (data) => {
            setEpisodes(data);
        });
    };

    const activeRowClassName = (record, index) => {
        if (activeSeason && activeSeason.id) {
            if (record.id === activeSeason.id) {
                return "active-season-row";
            } else {
                return "season-row";
            }
        }
    };

    const addSeasonInMovie = (item) => {
        const newArr = [...seasons];

        newArr.push(item);

        setSeasons(newArr);

        setSeasonDrawer({
            visible: false,
            item: null,
        });
    };

    return (
        <div className="seasons-section">
            <div>
                <div className="season-episode-tables-header">
                    <h2>{translate["Season"] || EN["Season"]}</h2>

                    <AddButtonComponent
                        text={translate["Add Season"] || EN["Add Season"]}
                        onClick={() =>
                            setSeasonDrawer({
                                visible: true,
                                item: null,
                            })
                        }
                    />
                </div>

                <Table
                    rowKey="id"
                    columns={seasonsColumns}
                    dataSource={seasons}
                    pagination={false}
                    rowClassName={activeRowClassName}
                    bordered
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => setActiveSeason(record),
                        };
                    }}
                />
            </div>

            <div>
                <div className="season-episode-tables-header">
                    <h2>
                        {activeSeason && activeSeason.name}{" "}
                        {translate["Episodes"] || EN["Episodes"]}
                    </h2>

                    <AddButtonComponent
                        text={translate["Add Episode"] || EN["Add Episode"]}
                        onClick={() =>
                            setEpisodeDrawer({
                                visible: true,
                                item: null,
                            })
                        }
                    />
                </div>

                <Table
                    rowKey="id"
                    columns={episodesColumns}
                    dataSource={episodes}
                    pagination={false}
                    bordered
                />
            </div>

            <SeasonDrawer
                vod_id={id}
                visible={seasonDrawer.visible}
                season={seasonDrawer.item}
                getSeasons={getSeasons}
                addSeasonInMovie={addSeasonInMovie}
                onClose={() => {
                    setSeasonDrawer({
                        visible: false,
                        item: null,
                    });
                }}
            />

            <EpisodeDrawer
                movie={movie}
                season_id={activeSeason ? activeSeason.id : null}
                season={activeSeason}
                visible={episodeDrawer.visible}
                episode={episodeDrawer.item}
                getEpisodes={getEpisodes}
                getSeasons={getSeasons}
                onClose={() => {
                    setEpisodeDrawer({
                        visible: false,
                        item: null,
                    });
                }}
            />
        </div>
    );
}
