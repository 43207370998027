export const setProfileInRedux = (profile) => {
    return (dispatch) => {
        dispatch({
            type: "profile",
            payload: profile,
        });
    };
};

export const setOrganizationInfoInRedux = (info) => {
    return (dispatch) => {
        dispatch({
            type: "organization_info",
            payload: info,
        });
    };
};

export const setOpenProfileDrawerInRedux = (bool) => {
    return (dispatch) => {
        dispatch({
            type: "profile_drawer",
            payload: bool,
        });
    };
};

export const setUploadingListInRedux = (video) => {
    return (dispatch) => {
        dispatch({
            type: "uploading_list",
            payload: video,
        });
    };
};

export const setNewMessageInRedux = (message) => {
    return (dispatch) => {
        dispatch({
            type: "new_message",
            payload: message,
        });
    };
};

export const setSeenChatGuestInRedux = (seen) => {
    return (dispatch) => {
        dispatch({
            type: "seenChatGuest",
            payload: seen,
        });
    };
};

export const setSocketIsConnectedInRedux = (bool) => {
    return (dispatch) => {
        dispatch({
            type: "socketIsConnected",
            payload: bool,
        });
    };
};

export const setLanguages = (language) => {
    return (dispatch) => {
        dispatch({
            type: "languages",
            payload: language,
        });
    };
};

export const setTranslate = (word) => {
    return (dispatch) => {
        dispatch({
            type: "translate",
            payload: word,
        });
    };
};

export const setGetTranslation = () => {
    return (dispatch) => {
        dispatch({
            type: "getTranslation",
            payload: "",
        });
    };
};

export const setActiveLanguages = (list) => {
    return (dispatch) => {
        dispatch({
            type: "activeLanguages",
            payload: list,
        });
    };
};
