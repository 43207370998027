import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../../../server/requests";

import TvChannelsContext from "./TvChannelsContext";

import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";

import ErrorMessage from "../../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../../elements/SaveButtonComponent";

export default function ImportFileDrawer() {
  const { visibleImportDrawer, setVisibleImportDrawer } = useContext(TvChannelsContext);
  const [form] = Form.useForm();

  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const onFinish = (values) => {
    setLoading(true);
    setErrorText(null);

    let body = {
      url: values.url,
    };

    function callback() {
      setLoading(false);
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    // if (category) {
    //     body.id = category.id;

    //     REQUESTS.TV_CHANNELS_CATEGORIES.EDIT(body, callback, errorCallback);
    // } else {
    REQUESTS.TV_CHANNELS.ADD_FILE(body, callback, errorCallback);
    // }

    notification.success({ description: "m3u8 file procesing starting" });
    setLoading(false);
    setVisibleImportDrawer();
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  useEffect(() => {
    if (visibleImportDrawer) {
      form.resetFields();

      // if (category) {
      // form.setFields([{ name: "url", value: category.url }]);
      // }
    } else {
      setErrorText(null);
    }
  }, [visibleImportDrawer]);

  return (
    <Drawer
      title="Import m3u file"
      placement="right"
      onClose={() => {
        setVisibleImportDrawer(false);
        form.resetFields();
      }}
      visible={visibleImportDrawer}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Url"] || EN["Url"]}
          tooltip={{
            title: "Import m3u file",
            icon: ICONS.INFO,
          }}
          name="url"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Url"] || EN["Url"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
