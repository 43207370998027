import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../../../../server/requests";

import EN from "../../../../../../config/en";
import ICONS from "../../../../../../config/icons";

import ErrorMessage from "../../../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../../../elements/SaveButtonComponent";

import FileChooser from "../../../../../elements/FileChooser";
import Translation from "../../../../../elements/Translation";
import TextArea from "antd/lib/input/TextArea";

export default function NewGalleryDrawer({ visible, onClose, id, getList, getInfoList }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [galleryType, setGalleryType] = useState(null);

  const [descriptionTranslations, setDescriptionTranslations] = useState([]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setErrorText(null);
      setImageSrc(null);
      setGalleryType(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);
    setErrorText(null);

    try {
      let body = {
        room_id: id,
        description: descriptionTranslations,
        file: values.file,
        file_type: galleryType,
      };

      function callback() {
        setLoading(false);
        onClose();
        getList();
      }

      function errorCallback(err) {
        console.log(err);

        // setErrorText(err);
        notification.error({
          message:
            typeof err === "string"
              ? err
              : translate["An error occurred"] || EN["An error occurred"],
        });
        setLoading(false);
      }

      REQUESTS.BOOKING.ROOM.GALLERY.ADD(body, callback, errorCallback);
    } catch (e) {
      console.log(e);
    }
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    setGalleryType(item.file_type);
    form.setFields([{ name: "file", value: item.file_url }]);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "file", value: null }]);
  };

  const onChangeTranslation = (value, iso_cod) => {
    const description = {
      text: value,
      iso_code: iso_cod,
    };

    const newTranslations = [...descriptionTranslations];

    const index = newTranslations.findIndex((i) => i.iso_code === iso_cod);

    if (index >= 0) {
      newTranslations[index] = description;
    } else {
      newTranslations.push(description);
    }

    setDescriptionTranslations(newTranslations);
  };

  return (
    <Drawer
      title={translate["Galleries"] || EN["Galleries"]}
      placement="right"
      width={600}
      onClose={() => {
        onClose();
        setDescriptionTranslations([]);
        setErrorText(null);
      }}
      visible={visible}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="product-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Gallery"] || EN["Gallery"]}
          tooltip={{
            title: "Choose an gallery",
            icon: ICONS.INFO,
          }}
          name="file"
          rules={[
            {
              required: true,
              message: `${translate["Please choose"] || EN["Please choose"]} ${
                translate["Gallery"] || EN["Gallery"]
              }`,
            },
          ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image", "video"]}
          />
        </Form.Item>

        <Translation
          render={(item) => {
            const description = descriptionTranslations.find(
              (translation) => translation.iso_code === item.iso_code
            )?.text;
            return (
              <Form.Item
                label={translate["Text"] || EN["Text"]}
                name="description"
                tooltip={{ title: "Info Title", icon: ICONS.INFO }}
              >
                <TextArea
                  value={description || ""}
                  onChange={(e) => onChangeTranslation(e.target.value, item.iso_code)}
                />
              </Form.Item>
            );
          }}
        />

        {/* <Form.Item
          label={translate["Description"] || EN["Description"]}
          tooltip={{
            title: "Gallery description",
            icon: ICONS.INFO,
          }}
          name="description"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Description"] || EN["Description"]
              }`,
            },
          ]}
        >
          <Input.TextArea rows={4} onResize={false} />
        </Form.Item> */}

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
