import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Input, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import TvChannelsContext from "./TvChannelsContext";

import EN from "../../../../../config/en";
import REQUESTS from "../../../../../server/requests";

let timeout = null;

export default function EpgSearch({ onChangeEpg }) {
  const { currentChannel } = useContext(TvChannelsContext);

  const translate = useSelector((state) => state.globalState.translate);

  const [search, setSearch] = useState("");

  const [text, setText] = useState("");

  const [list, setList] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (currentChannel) {
      REQUESTS.EPG.CHANNELS.GET(
        { search: JSON.stringify({ _id: currentChannel.epg_id }) },
        (data) => {
          if (isMounted) setText(data[0].display_name);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [currentChannel]);

  const onChange = (event) => {
    const value = event.target.value;
    setSearch(value);
    setText(value);

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      REQUESTS.EPG.CHANNELS.GET({ search: JSON.stringify({ url: value }) }, (data) => {
        setList(data);
      });
    }, 500);
  };

  const select = (item) => {
    setText(item.display_name);
    onChangeEpg(item._id);
    setSearch("");
    setList([]);
  };

  return (
    <div className="epg-search">
      <Input
        value={text}
        onChange={onChange}
        placeholder={translate["Search epg ..."] || EN["Search epg ..."]}
        suffix={
          <Tooltip title={translate["Search epg ..."] || EN["Search epg ..."]}>
            <SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />
          </Tooltip>
        }
      />

      <div className="container" style={{ display: search.length ? "block" : "none" }}>
        {list.length > 0 ? (
          list.map((item) => {
            return (
              <div key={item.id} className="item" onClick={() => select(item)}>
                {item.display_name}
              </div>
            );
          })
        ) : (
          <p className="empty">{translate["List is empty"] || EN["List is empty"]}</p>
        )}
      </div>
    </div>
  );
}
