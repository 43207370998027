const BUTTON_CLASSES = {
    // a
    APP_BUTTON: "app-button",
    // b
    BLUE: "blue-button",
    // c
    // d
    // e
    // f
    // g
    GREY: "grey-button",
    // h
    // i
    // j
    // k
    // l
    // m
    // n
    // o
    // p
    // q
    // r
    // s
    // t
    TRANSPARENT: "transparent-button",
    // u
    // v
    // w
    // x
    // y
    // z
};

export default BUTTON_CLASSES;
