import EN from "../../config/en";

const initialState = {
    profile: {
        name: "",
        surname: "",
        image: "",
    },
    organizationInfo: {},
    isOpenProfileDrawer: false,
    uploading_list: [],
    new_message: null,
    seenChatGuest: null,
    socketIsConnected: false,
    languages: [],
    translate: EN,

    getTranslation: false,

    activeLanguages: [],
};

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case "profile":
            return { ...state, profile: action.payload };
        case "socketIsConnected":
            return { ...state, socketIsConnected: action.payload };

        case "organization_info":
            return { ...state, organizationInfo: action.payload };

        case "profile_drawer":
            return { ...state, isOpenProfileDrawer: action.payload };

        case "uploading_list":
            let newArr = [...state.uploading_list];

            const index = newArr.findIndex((x) => x.id === action.payload.id);

            index === -1
                ? newArr.push(action.payload)
                : (newArr[index].progress = action.payload.progress);

            if (action.payload.progress === 100) {
                newArr.splice(index, 1);
            }

            return { ...state, uploading_list: newArr };

        case "new_message":
            return { ...state, new_message: action.payload };

        case "seenChatGuest":
            return { ...state, seenChatGuest: action.payload };

        case "languages":
            return { ...state, languages: action.payload };

        case "activeLanguages":
            return { ...state, activeLanguages: action.payload };

        case "translate":
            return { ...state, translate: action.payload };

        case "getTranslation":
            return { ...state, getTranslation: !state.getTranslation };

        default:
            return state;
    }
};

export default globalReducer;
