import axios from "axios";

const request = (method, url, body, callback, errorCallback, notTimeout) => {
  let myAxios = axios.create();

  if (!notTimeout) {
    myAxios.defaults.timeout = 30000;
  }

  myAxios.defaults.headers.common["Authorization"] = localStorage.getItem("TOKEN");
  myAxios[method](url, method === "delete" ? { data: body } : body)
    .then((response) => {
      if (response.data.error) {
        if (
          response.data.message === "Network Error" ||
          response.data.message === "Forbidden" ||
          response.data.message === "Forbidden"
        ) {
          localStorage.clear();
          window.location.href = "#";
          return;
        }

        if (errorCallback) {
          if (response.data?.message === "Forbiden") {
            localStorage.clear();
            window.location.href = "#/login";
            return;
          }
          errorCallback(response.data.message);
        }
      } else {
        callback(response.data.message);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error.message);
      }
    });
};

export default request;
