import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Form, Input, notification } from "antd";

import REQUESTS from "../../../server/requests";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";
import NAVIGATORS from "../../../config/navigators";
import { APP_VERSION } from "../../../config/config";

import Logo from "../../elements/Logo";
import ErrorMessage from "../../elements/ErrorMessage";
import ButtonComponent from "../../elements/ButtonComponent";

import "./_login_registration.scss";

export default function CheckEmailPage({}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();

  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      password: values.password,
      re_password: values.re_password,
      code: values.code,
    };

    if (location.state) {
      body.email = location.state.email;
    }

    function callback(data) {
      setLoading(false);
      navigate(NAVIGATORS.LOGIN);
    }

    function errorCallback(err) {
      setLoading(false);

      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });

      // if (typeof err === "string") {
      //     setErrorText(err);
      // } else if (typeof err.message === "string") {
      //     setErrorText(err.message);
      // }
    }

    REQUESTS.EMPLOYERS.CONFIRM_PASSWORD(body, callback, errorCallback);
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  const backToLogin = () => {
    navigate(NAVIGATORS.LOGIN);
  };

  const onClickResetPassword = () => {
    form.submit();
  };

  return (
    <div className="page">
      <div className="image-section">
        <div className="app-version">
          <span>App Version: {APP_VERSION}</span>
        </div>
      </div>

      <div className="form-section">
        <div className="container">
          <Logo showTitle={true} />

          <div className="form-style">
            <h1>{translate["Check your email"] || EN["Check your email"]}</h1>
            <p className="text-in-reset-password">
              {translate[
                "Instruction for password reset have been sent to following email address: max.rosari@ristv.com"
              ] ||
                EN[
                  "Instruction for password reset have been sent to following email address: max.rosari@ristv.com"
                ]}
            </p>
            <Form
              form={form}
              layout="vertical"
              name="check-form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item label={translate["Code"] || EN["Code"]}>
                <Form.Item
                  name="code"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: `${
                        translate["Please input your"] || EN["Please input your"]
                      } ${translate["Code"] || EN["Code"]}`,
                    },
                    {
                      pattern: new RegExp(/^\d+$/),
                      message: `${
                        translate["Code must be a number"] || EN["Code must be a number"]
                      }`,
                    },
                  ]}
                >
                  <Input
                    className="input-style"
                    type="text"
                    onPressEnter={onClickResetPassword}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item label={translate["Password"] || EN["Password"]}>
                <Form.Item
                  name="password"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: `${
                        translate["Please input your"] || EN["Please input your"]
                      } ${translate["Password"] || EN["Password"]}`,
                    },
                  ]}
                  autoComplete="new-password"
                >
                  <Input.Password
                    className="input-style"
                    autoComplete="new-password"
                    onPressEnter={onClickResetPassword}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item label={translate["Confirm password"] || EN["Confirm password"]}>
                <Form.Item
                  name="re_password"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: `${
                        translate["Please input your"] || EN["Please input your"]
                      } ${translate["Password"] || EN["Password"]}`,
                    },
                  ]}
                  autoComplete="new-password"
                >
                  <Input.Password
                    className="input-style"
                    autoComplete="new-password"
                    onPressEnter={onClickResetPassword}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <div className="reset-password-buttones">
                  <ButtonComponent
                    iconLeft={true}
                    text={translate["Back to login"] || EN["Back to login"]}
                    icon={ICONS.ARROW_LEFT_OUTLINED}
                    onClick={backToLogin}
                    style={{ width: 175 }}
                  />

                  <ButtonComponent
                    loading={loading}
                    text={translate["Reset password"] || EN["Reset password"]}
                    icon={ICONS.ARROW_RIGHT_OUTLINED}
                    onClick={onClickResetPassword}
                    style={{ width: 175 }}
                  />
                </div>
              </Form.Item>
            </Form>
            {/* <ErrorMessage>{errorText}</ErrorMessage> */}
          </div>
        </div>
      </div>
    </div>
  );
}
