import { useContext, useState } from "react";
import { Form, Select, Switch, Checkbox, Radio } from "antd";
import { useSelector } from "react-redux";
import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";
import Context from "./Context";
import { useRadioCategoriesOptions } from "../../../../hooks/options";

const RadioSection = () => {
  const {
    enableRadio,
    setEnableRadio,
    accessAllRadioCategories,
    setAccessAllRadioCategories,
  } = useContext(Context);

  const categoriesOptions = useRadioCategoriesOptions();

  const translate = useSelector((state) => state.globalState.translate);

  return (
    <div>
      <div className="switch-block">
        <p>{translate["Radio"] || EN["Radio"]}</p>
        <Switch
          checked={enableRadio}
          onChange={() => setEnableRadio((value) => !value)}
        />
      </div>

      {enableRadio && (
        <div className="packages-sections-setyle">
          <Checkbox
            style={{ marginTop: 10, marginBottom: 20 }}
            checked={accessAllRadioCategories}
            onChange={(e) => setAccessAllRadioCategories(e.target.checked)}
          >
            {translate["Access to all radio genres"] || EN["Access to all radio genres"]}
          </Checkbox>

          <Form.Item
            label={translate["Categories"] || EN["Categories"]}
            tooltip={{
              title: "Categories",
              icon: ICONS.INFO,
            }}
          >
            <Form.Item name="radio" noStyle>
              <Select
                mode="multiple"
                disabled={accessAllRadioCategories}
                options={categoriesOptions}
              />
            </Form.Item>
          </Form.Item>
        </div>
      )}
    </div>
    // </Form>
  );
};

export default RadioSection;
