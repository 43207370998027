import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import EN from "../../../config/en";
import NAVIGATORS from "../../../config/navigators";

import Currency from "../../elements/Currency";

export default React.memo(function ({ item }) {

    const translate = useSelector((state) => state.globalState.translate);

    const navigate = useNavigate();

    let title, color;

    switch (item.message.completed) {
        case "0":
            title = translate['Pending'] || EN['Pending'];
            color = "grey";
            break;

        case "1":
            title = translate['Accepted'] || EN['Accepted'];
            color = "green";

            break;

        case "2":
            title = translate['Rejected'] || EN['Rejected'];
            color = "red";

            break;
    }

    const onClick = () => {
        if (item.message.completed == 0) {
            if (item.message_type === "vod") {
                return 
            } 
             navigate(NAVIGATORS.ORDERS);  
        }
    };

    return (
        <div
            className={`product-service ${item.message.completed == 0 && "pending"}`}
            style={{ borderColor: color }}
            onClick={onClick}
        >
            <div className="title" style={{ color }}>
                {title}
            </div>
            {item.message.name}
            <Currency>{item.message.price}</Currency>
        </div>
    );
});
