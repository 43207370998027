import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import NewsContext from "./NewsContext";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";

import HeaderItem from "../../../elements/HeaderItem";
import PageHeader from "../../../elements/PageHeader";
import AddButtonComponent from "../../../elements/AddButtonComponent";

export default function NewsHeader() {

    const translate = useSelector((state) => state.globalState.translate);
    
    const { total, setVisibleNewsDrawer } = useContext(NewsContext);

    const [totalValue, setTotalValue] = useState(0);

    useEffect(() => {
        REQUESTS.PROMOTIONS.NEWS.STATISTICS((data) => {
            setTotalValue(data.count);
        });
    }, [total]);

    return (
        <PageHeader title={translate['News'] || EN['News']}>
            <HeaderItem title={translate['Total'] || EN['Total']} total={totalValue} />

            <AddButtonComponent
                text={translate['Add News'] || EN['Add News']}
                onClick={() => setVisibleNewsDrawer(true)}
            />
        </PageHeader>
    );
}
