import React, { useState, useEffect, useContext} from "react";
import { useSelector } from "react-redux";
import Context from "./Context";

import REQUESTS from "../../../../../server/requests";

import EN from "../../../../../config/en";

import HeaderItem from "../../../../elements/HeaderItem";
import PageHeader from "../../../../elements/PageHeader";
import AddButtonComponent from "../../../../elements/AddButtonComponent";

export default function Head() {

    const translate = useSelector((state) => state.globalState.translate);

    const {setVisibleGenreDrawer, total} = useContext(Context);
    const [totalValue, setTotalValue] = useState(0);

    useEffect(() => {
        REQUESTS.VOD.GENRES.STATISTICS((data) => {
            setTotalValue(data.count);
        });
    }, [total]);

    return (
        <PageHeader title={translate['Genres'] || EN['Genres']}>
            <HeaderItem title={translate['Total'] || EN['Total']} total={totalValue} />

            <AddButtonComponent
                text={translate['Add Genre'] || EN['Add Genre']}
                onClick={() => setVisibleGenreDrawer(true)} 
            />
        </PageHeader>
    );
}
