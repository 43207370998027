import React from "react";

export default function TableHeadComponent({ title, children }) {
    return (
        <div className="table-head-component">
            <p>{title}</p>

            <div className="buttons-section">{children}</div>
        </div>
    );
}
