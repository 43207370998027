import { useState } from "react";

import Page from "../../elements/Page";

import Head from "./Head";
import MediaLibraryDrawer from "./Drawer";

import MediaLibrary from "./MediaLibrary";

import "./_media-library.scss";

export default function MediaLibraryPage() {
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  return (
    <Page>
      <Head setVisibleDrawer={setVisibleDrawer} />

      <div className="page-body">
        <MediaLibrary />
      </div>

      {visibleDrawer && (
        <MediaLibraryDrawer
          visible={visibleDrawer}
          onClose={() => setVisibleDrawer(false)}
        />
      )}
    </Page>
  );
}
