import { useState, useEffect } from "react";
import { Drawer, Form, Input, InputNumber, Button, Select } from "antd";
import Translation from "../../../../../elements/Translation";
import { useSelector } from "react-redux";
import EN from "../../../../../../config/en";
import ICONS from "../../../../../../config/icons";

const FacilitiesPageDrawer = ({ open, onClose, options, save, loading, editable }) => {
  const [form] = Form.useForm();
  const translate = useSelector((state) => state.globalState.translate);

  const [nameTranslation, setNameTranslation] = useState([]);

  const handleFinish = (values) => {
    const body = {
      title: nameTranslation,
      price: values.price,
    };

    if (values?.children) {
      body.children = values.children;
    }

    save(body);
  };

  const onChangeNameTranslation = (value, id) => {
    const name = {
      text: value,
      iso_code: id,
    };

    const newTranslations = [...nameTranslation];
    const index = newTranslations.findIndex((i) => i.iso_code === id);

    if (index >= 0) {
      newTranslations[index] = name;
    } else {
      newTranslations.push(name);
    }

    setNameTranslation(newTranslations);
  };

  useEffect(() => {
    if (editable) {
      form.setFieldsValue({
        title: editable.title,
        price: editable.price,
        children: editable.property?.map((item) => item.title),
      });

      const editableTitle = editable?.title_translations?.map((item) => {
        return {
          iso_code: item.iso_code,
          text: item.text,
        };
      });

      setNameTranslation(editableTitle);
    } else {
      form.resetFields();
      setNameTranslation([]);
    }
  }, [editable]);

  return (
    <Drawer
      width={550}
      open={open}
      destroyOnClose={true}
      title={
        editable
          ? translate["Edit Facility"] || EN["Edit Facility"]
          : translate["Add Facility"] || EN["Add Facility"]
      }
      onClose={() => {
        onClose();
        form.resetFields();
        setNameTranslation([]);
      }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            {translate["Cancel"] || EN["Cancel"]}
          </Button>
          <Button onClick={() => form.submit()} type="primary" loading={loading}>
            {translate["Save"] || EN["Save"]}
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Translation
          render={(item) => {
            const name = nameTranslation?.find(
              (translation) => translation.iso_code === item.id
            )?.text;
            return (
              <Form.Item
                label={translate["Title"] || EN["Title"]}
                name="title"
                tooltip={{ title: "Info Title", icon: ICONS.INFO }}
              >
                <Input
                  value={name || ""}
                  onChange={(e) => onChangeNameTranslation(e.target.value, item.iso_code)}
                />
              </Form.Item>
            );
          }}
        />
        <Form.Item
          label={translate["Price"] || EN["Price"]}
          name="price"
          rules={[
            { required: true },
            {
              pattern: new RegExp(/^\d+$/),
              message:
                translate["Price must be a number"] || EN["Price must be a number"],
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        {editable && (
          <Form.Item label="Children" name="children">
            <Select
              options={options}
              mode="multiple"
              showSearch
              filterOption={(input, option) => {
                return option?.label?.toLowerCase()?.includes(input?.toLowerCase());
              }}
            />
          </Form.Item>
        )}
      </Form>
    </Drawer>
  );
};

export default FacilitiesPageDrawer;
