import { useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "antd";

import Context from "./Context";

import EN from "../../../../config/en";
import Checkbox from "antd/lib/checkbox/Checkbox";

export default function ViewSettingsDrawer() {
  const {
    visibleViewSettingsDrawer,
    setVisibleViewSettingsDrawer,
    viewSettingsValues,
    setViewSettingsValues,
  } = useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const onChangeSettingsValues = (event) => {
    viewSettingsValues[event.target.name] = event.target.checked;
    setViewSettingsValues({ ...viewSettingsValues });

    localStorage.setItem("PACKAGES_COLUMNS", JSON.stringify(viewSettingsValues));
  };

  return (
    <Drawer
      title={translate["View settings"] || EN["View settings"]}
      placement="right"
      onClose={() => setVisibleViewSettingsDrawer(false)}
      visible={visibleViewSettingsDrawer}
      destroyOnClose={true}
    >
      <h2>{translate["Show columns"] || EN["Show columns"]}</h2>

      <div className="view-settings-checkboxes">
        <Checkbox
          name="is_default"
          checked={viewSettingsValues.is_default}
          onChange={onChangeSettingsValues}
        >
          {translate["Default"] || EN["Default"]}
        </Checkbox>

        <Checkbox
          name="name"
          checked={viewSettingsValues.name}
          onChange={onChangeSettingsValues}
        >
          {translate["Name"] || EN["Name"]}
        </Checkbox>

        <Checkbox
          name="access_to_TV_Channels"
          checked={viewSettingsValues.access_to_TV_Channels}
          onChange={onChangeSettingsValues}
        >
          {translate["Tv Channels"] || EN["Tv Channels"]}
        </Checkbox>

        <Checkbox
          name="access_to_Movies"
          checked={viewSettingsValues.access_to_Movies}
          onChange={onChangeSettingsValues}
        >
          {translate["Vod"] || EN["Vod"]}
        </Checkbox>

        <Checkbox
          name="access_to_Services_and_products"
          checked={viewSettingsValues.access_to_Services_and_products}
          onChange={onChangeSettingsValues}
        >
          {translate["E-Commerce"] || EN["E-Commerce"]}
        </Checkbox>

        <Checkbox
          name="access_to_Widget"
          checked={viewSettingsValues.access_to_Widget}
          onChange={onChangeSettingsValues}
        >
          {translate["Widgets"] || EN["Widgets"]}
        </Checkbox>

        <Checkbox
          name="send_messages"
          checked={viewSettingsValues.send_messages}
          onChange={onChangeSettingsValues}
        >
          {translate["Send messages"] || EN["Send messages"]}
        </Checkbox>

        <Checkbox
          name="view_Billing_Information"
          checked={viewSettingsValues.view_Billing_Information}
          onChange={onChangeSettingsValues}
        >
          {translate["View Bill"] || EN["View Bill"]}
        </Checkbox>

        <Checkbox
          name="news_Views"
          checked={viewSettingsValues.news_Views}
          onChange={onChangeSettingsValues}
        >
          {translate["News"] || EN["News"]}
        </Checkbox>

        <Checkbox
          name="view_promotions"
          checked={viewSettingsValues.view_promotions}
          onChange={onChangeSettingsValues}
        >
          {translate["Promotions"] || EN["Promotions"]}
        </Checkbox>

        <Checkbox
          name="feedback_sccess"
          checked={viewSettingsValues.feedback_sccess}
          onChange={onChangeSettingsValues}
        >
          {translate["Access"] || EN["Access"]}
        </Checkbox>

        <Checkbox
          name="createdAt"
          checked={viewSettingsValues.createdAt}
          onChange={onChangeSettingsValues}
        >
          {translate["Date"] || EN["Date"]}
        </Checkbox>
      </div>
    </Drawer>
  );
}
