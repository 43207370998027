import { Divider, Space, Tabs } from "antd";
import { useSelector } from "react-redux";

export default function Translation({ render }) {
  const { activeLanguages } = useSelector((state) => state.globalState);

  const sortedLanguages = [...activeLanguages].sort((a, b) => {
    if (a.iso_code === "en") return -1;
    if (b.iso_code === "en") return 1;
    return 0;
  });

  const renderElement = (item, index) => {
    if (render) {
      return render(item, index);
    }
  };

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        tabPosition={"top"}
        items={sortedLanguages.map((item, index) => {
          return {
            label: (
              <Space>
                <img src={item?.icon} style={{ width: 30 }} />{" "}
                <p style={{ margin: 0 }}>{item.name}</p>
              </Space>
            ),
            key: item.iso_code,
            children: renderElement(item, index),
          };
        })}
      />
    </>
  );
}
