import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Drawer, Table, Input, message } from "antd";

import HotelStaffContext from "./HotelStaffContext";

import REQUESTS from "../../../server/requests";

import ICONS from "../../../config/icons";
import { dateFormat } from "../../../config/formats";
import confirmModal from "../../../config/confirmModal";
import LOCAL_STORAGE_VALUES from "../../../config/localStorageValues";
import EN from "../../../config/en";

import TableButtons from "../../elements/TableButtons";
import AddButtonComponent from "../../elements/AddButtonComponent";

import UserDrawer from "./UserDrawer";
import ImageComponent from "../../elements/ImageComponent";
import TableHead from "./TableHead";

export default function HotelStaffDrawer({ visible, onClose }) {
  const { translate, profile } = useSelector((state) => state.globalState);

  const [loading, setLoading] = useState(false);

  const [employersList, setEmployersList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [roleSearch, setRoleSearch] = useState("");

  const [isVisibleTheUserDrawer, setIsVisibleTheUserDrawer] = useState(false);
  const [visibleViewSettingsDrawer, setVisibleViewSettingsDrawer] = useState(false);
  const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [search, setSearch] = useState({
    name: "",
    surname: "",
    email: "",
  });

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [columns, setColumns] = useState([]);

  const [viewSettingsValues, setViewSettingsValues] = useState({
    id: true,
    image: true,
    name: true,
    surname: true,
    email: true,
    employers_roles: true,
    e_commercial_managers: true,
    createdAt: true,
    action: true,
  });

  const configureTableColumns = () => {
    const newColumns = [];

    newColumns.push({
      fixed: "left",
      width: 60,
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    });

    if (viewSettingsValues.image) {
      newColumns.push({
        width: 100,
        title: "",
        dataIndex: "image",
        render: (text, record, index) => (
          <ImageComponent src={text} className="tableImage" />
        ),
      });
    }

    if (viewSettingsValues.name) {
      newColumns.push({
        title: translate["Name"] || EN["Name"],
        dataIndex: "name",
        key: "name",
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <> {ICONS.SEARCHOUTLINED} </>;
        },
        onFilter: (value, record) => {
          return record.name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.surname) {
      newColumns.push({
        title: translate["Surname"] || EN["Surname"],
        dataIndex: "surname",
        key: "surname",
        width: 130,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <> {ICONS.SEARCHOUTLINED} </>;
        },
        onFilter: (value, record) => {
          return record.surname.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.email) {
      newColumns.push({
        title: translate["Email"] || EN["Email"],
        dataIndex: "email",
        key: "email",
        width: 200,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <> {ICONS.SEARCHOUTLINED} </>;
        },
        onFilter: (value, record) => {
          return record.email.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.employers_roles) {
      newColumns.push({
        title: translate["Roles"] || EN[["Roles"]],
        render: (text, record, index) => renderEmployerRoles(record.employers_roles),
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={roleSearch[0]}
              onChange={(e) => {
                setRoleSearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <> {ICONS.SEARCHOUTLINED} </>;
        },
        onFilter: (value, record) => {
          return record.email.toLowerCase().includes(value.toLowerCase());
        },
      });
    }
    if (viewSettingsValues.e_commercial_managers) {
      newColumns.push({
        title: translate["E-commerce catagory"] || EN["E-commerce catagory"],
        render: (text, record, index) =>
          renderEcommerceCategory(record.e_commercial_managers),
        // filterDropdown: ({ confirm }) => {
        //   return (
        //     <Input
        //       allowClear
        //       autoFocus
        //       placeholder="Type text here"
        //       value={roleSearch[0]}
        //       onChange={(e) => {
        //         setRoleSearch(e.target.value ? [e.target.value] : []);
        //         confirm({ closeDropdown: false });
        //       }}
        //       onPressEnter={() => {
        //         confirm();
        //       }}
        //       onBlur={() => {
        //         confirm();
        //       }}
        //     />
        //   );
        // },
        // filterIcon: () => {
        //   return <> {ICONS.SEARCHOUTLINED} </>;
        // },
        // onFilter: (value, record) => {
        //   return record.e_commercial_managers.toLowerCase().includes(value.toLowerCase());
        // },
      });
    }

    if (profile.is_super_admin) {
      newColumns.push({
        title: translate["Organization"] || EN["Organization"],
        dataIndex: "organization",
        key: "organization",
        render: (text, record, index) => (
          <span>{record?.organization?.name || "N/A"}</span>
        ),
      });
    }

    if (viewSettingsValues.createdAt) {
      newColumns.push({
        title: translate["Date"] || EN["Date"],
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: true,
        render: (text, record, index) => dateFormat(text),
      });
    }

    newColumns.push({
      fixed: "right",
      width: 60,
      title: "",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={[
            { key: "edit", text: translate["Edit"] || EN["Edit"], icon: ICONS.EDIT },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE,
            },
          ]}
        />
      ),
    });

    setColumns(newColumns);
  };

  useEffect(() => {
    configureTableColumns();
  }, [viewSettingsValues, currentPage]);

  useEffect(() => {
    let HOTEL_STAFF_COLUMNS = localStorage.getItem(
      LOCAL_STORAGE_VALUES.HOTEL_STAFF_COLUMNS
    );

    if (HOTEL_STAFF_COLUMNS) {
      HOTEL_STAFF_COLUMNS = JSON.parse(HOTEL_STAFF_COLUMNS);
      setViewSettingsValues(HOTEL_STAFF_COLUMNS);
    }
  }, []);

  const renderEmployerRoles = (employers_roles) => {
    if (employers_roles) {
      const list = employers_roles.map((item) => item.role.name);

      return <p>{list.join()}</p>;
    }

    return <p>N/A</p>;
  };

  const renderEcommerceCategory = (e_commercial_managers) => {
    if (e_commercial_managers) {
      const list = e_commercial_managers.map((item) => item.e_commercial_catagory.name);
      return <p>{list.join()}</p>;
    }

    return <p>N/A</p>;
  };

  const delteUser = (item) => {
    REQUESTS.EMPLOYERS.DELETE(item.id, getEmployersList, (err) => {
      message.error(err);
    });
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setCurrentUser(item);
        setIsVisibleTheUserDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
            item.name
          } ${item.surname}`,
          action: () => delteUser(item),
        });
        break;

      default:
        break;
    }
  };

  const getEmployersList = () => {
    setLoading(true);

    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage,
    };

    if (search.name || search.surname || search.email) {
      query.search = JSON.stringify({
        name: search.name && search.name[0],
        surname: search.surname && search.surname[0],
        email: search.email && search.email[0],
      });
    }

    if (roleSearch[0]) {
      query.role_name = roleSearch[0];
      if (query.pagination) {
        delete query.pagination;
        delete query.limit;
      }
    }

    function callback(data) {
      if (data.rows) {
        setLimit(data.limit);
        setTotal(data.count);
        setEmployersList(data.rows);
      } else {
        setEmployersList(data);
      }

      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.EMPLOYERS.GET(query, callback, errorCallback);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    setSearch(filters);
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  useEffect(() => {
    if (visible) {
      getEmployersList();
    }
  }, [visible, currentPage, limit, sort]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getEmployersList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [search]);

  useEffect(() => {
    let timeout = null;
    if (roleSearch[0]) {
      if (roleSearch[0]?.length > 2) {
        timeout = setTimeout(() => {
          getEmployersList();
        }, 1000);
      }
    } else {
      getEmployersList();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [roleSearch]);

  useEffect(() => {
    if (!isVisibleTheUserDrawer) {
      setCurrentUser(null);
    }
  }, [isVisibleTheUserDrawer]);

  return (
    <HotelStaffContext.Provider
      value={{
        isVisibleTheUserDrawer,
        setIsVisibleTheUserDrawer,
        currentUser,
        getEmployersList,
        visibleViewSettingsDrawer,
        setVisibleViewSettingsDrawer,
        visibleFilterDrawer,
        setVisibleFilterDrawer,
        viewSettingsValues,
        setViewSettingsValues,
      }}
    >
      <Drawer
        title={translate["Hotel staff"] || EN["Hotel staff"]}
        placement="right"
        width={800}
        onClose={onClose}
        visible={visible}
        style={{
          overflow: "unset",
        }}
        destroyOnClose={true}
      >
        <div className="global_header-title-drawer">
          <div className="title-in-drawer">
            <h2>{translate["Manage user accounts"] || EN["Manage user accounts"]}</h2>
          </div>

          <AddButtonComponent
            text={translate["Add User"] || EN["Add User"]}
            onClick={() => setIsVisibleTheUserDrawer(true)}
          />
        </div>

        <div className="settings_icon_pt">
          <TableHead />
        </div>

        <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          dataSource={employersList}
          onChange={handleTableChange}
          scroll={{ x: 1500 }}
          pagination={{
            position: ["topCenter", "bottomCenter"],
            current: currentPage,
            total: total,
            showSizeChanger: true,
          }}
          // sticky={["top"]}
        />

        <UserDrawer />
      </Drawer>
    </HotelStaffContext.Provider>
  );
}
