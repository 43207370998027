import React from "react";
import PropTypes from "prop-types";
import ICONS from "../../config/icons";
import { Button } from "antd";

/**
 * @param {node} icon -> icons.js
 * @param {string} buttonClass -> button-classes.js
 * @param {string} text
 * @param {func} onClick.isRequired
 *
 *
 * @returns
 */

export default function AddButtonComponent({ text, onClick, style, buttonClass }) {
  return (
    <Button style={style} className={`button-component ${buttonClass}`} onClick={onClick}>
      {text}
      {ICONS.ARROW_RIGHT_OUTLINED}
    </Button>
  );
}

AddButtonComponent.propTypes = {
  text: PropTypes.string,
  buttonClass: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
