import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Select } from "antd";

import TvChannelsContext from "./TvChannelsContext";

import {
  useCountriesOptions,
  useLanguagesOptions,
  usePublishedStatusOptions,
  useTizenPlayerOptions,
} from "../../../../../hooks/options";

import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";

import ButtonComponent from "../../../../elements/ButtonComponent";

export default function FilterDrawer() {
  const {
    visibleFilterDrawer,
    setVisibleFilterDrawer,
    setSelectedLanguageId,
    setSelectedCountryId,
    setSelectedPublishedStatus,
    setSelectedPlayerId,
  } = useContext(TvChannelsContext);

  const translate = useSelector((state) => state.globalState.translate);

  const languagesOptions = useLanguagesOptions();
  const countriesOptions = useCountriesOptions();
  const publishedStatusOptions = usePublishedStatusOptions();
  const playerOptions = useTizenPlayerOptions();

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const onClickFilter = () => {
    setSelectedLanguageId(selectedLanguage);
    setSelectedCountryId(selectedCountry);
    setSelectedPublishedStatus(selectedStatus);
    setSelectedPlayerId(selectedPlayer);
    setVisibleFilterDrawer(false);
  };

  return (
    <Drawer
      title={translate["Filter"] || EN["Filter"]}
      placement="right"
      visible={visibleFilterDrawer}
      onClose={() => setVisibleFilterDrawer(false)}
      destroyOnClose={true}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h1>{translate["Select filter parameters"] || EN["Select filter parameters"]}</h1>

        <p>{translate["Language"] || EN["Language"]}:</p>
        <Select
          showArrow
          allowClear={true}
          value={selectedLanguage}
          onChange={setSelectedLanguage}
          options={languagesOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate["Country"] || EN["Country"]}:</p>
        <Select
          showArrow
          allowClear={true}
          value={selectedCountry}
          onChange={setSelectedCountry}
          options={countriesOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate["Player"] || EN["Player"]}:</p>
        <Select
          showArrow
          allowClear={true}
          value={selectedPlayer}
          onChange={setSelectedPlayer}
          options={playerOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate["Status"] || EN["Status"]}:</p>
        <Select
          showArrow
          allowClear={true}
          value={selectedStatus}
          onChange={setSelectedStatus}
          options={publishedStatusOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <ButtonComponent
          text={translate["Filter"] || EN["Filter"]}
          icon={ICONS.ARROW_RIGHT_OUTLINED}
          onClick={onClickFilter}
        />
      </div>
    </Drawer>
  );
}
