import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, Select, Checkbox, notification } from "antd";

import ProductContext from "./ProductContext";

import { useEcommerceCategoriesOptions } from "../../../../hooks/options";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";
import FileChooser from "../../../elements/FileChooser";
import Translation from "../../../elements/Translation";
import TagComponent from "../../../elements/TagComponent";

export default function ProductDrawer() {
  const {
    visibleProductDrawer,
    setVisibleProductDrawer,
    currentProduct,
    getProductsList,
  } = useContext(ProductContext);

  const translate = useSelector((state) => state.globalState.translate);

  const categoriesOptions = useEcommerceCategoriesOptions();

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  const [form] = Form.useForm();
  const [nameTranslations, setNameTranslations] = useState([]);
  const [descriptionTranslations, setDescriptionTranslations] = useState([]);

  const setTags = (tags) => {
    const filteredTags = tags.filter((tag) => {
      if (tag && tag !== "") {
        return tag;
      }
    });
    return filteredTags;
  };

  useEffect(() => {
    if (visibleProductDrawer) {
      if (currentProduct) {
        form.setFields([
          { name: "attach_slider", value: currentProduct.attach_slider },
          { name: "price", value: currentProduct.price },
          { name: "category_id", value: currentProduct.category_id },
          { name: "image", value: currentProduct.image },
          { name: "tags", value: setTags(currentProduct.tags) },
        ]);

        setNameTranslations(currentProduct.name_translations || []);

        setDescriptionTranslations(currentProduct.description_translations || []);

        if (currentProduct.image) {
          setImageSrc(currentProduct.image);
        }
      }
    } else {
      setDescriptionTranslations([]);
      setNameTranslations([]);
      setErrorText(null);
    }
  }, [visibleProductDrawer]);

  useEffect(() => {
    if (!visibleProductDrawer && currentProduct) {
      form.resetFields();
      setImageSrc(null);
    }
  }, [visibleProductDrawer]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    let body = {
      attach_slider: values.attach_slider,
      price: values.price,
      category_id: values.category_id,
      image: imageSrc,

      name: JSON.stringify(nameTranslations || []),
      description: JSON.stringify(descriptionTranslations || []),
      tags: values.tags?.join(","),
    };

    function callback() {
      setLoading(false);
      setVisibleProductDrawer(false);
      getProductsList();
      form.resetFields();
      setImageSrc(null);
    }

    function errorCallback(err) {
      setLoading(false);
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
    }

    if (currentProduct) {
      body.id = currentProduct.id;

      REQUESTS.ECOMMERCE.PRODUCTS.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.ECOMMERCE.PRODUCTS.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    form.setFields([{ name: "image", value: item.file_url }]);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "image", value: null }]);
  };

  const onChangeName = (text, id) => {
    const name = {
      text,
      language_id: id,
    };

    const newTranslations = [...nameTranslations];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = name;
    } else {
      newTranslations.push(name);
    }

    setNameTranslations(newTranslations);
  };

  const onChangeDesctiption = (text, id) => {
    const description = {
      text,
      language_id: id,
    };

    const newTranslations = [...descriptionTranslations];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = description;
    } else {
      newTranslations.push(description);
    }

    setDescriptionTranslations(newTranslations);
  };

  return (
    <Drawer
      title={
        currentProduct
          ? translate["Edit Product"] || EN["Edit Product"]
          : translate["Add New Product"] || EN["Add New Product"]
      }
      placement="right"
      width={600}
      onClose={() => {
        setVisibleProductDrawer(false);
        form.resetFields();
      }}
      visible={visibleProductDrawer}
      destroyOnClose
    >
      <h2>{translate["Product information"] || EN["Product information"]}</h2>
      <Form
        form={form}
        layout="vertical"
        name="product-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          attach_slider: false,
        }}
      >
        <Form.Item
          label={translate["Image"] || EN["Image"]}
          tooltip={{
            title: "Choose an image",
            icon: ICONS.INFO,
          }}
          name="image"
          rules={[
            {
              required: true,
              message: `${translate["Please choose"] || EN["Please choose"]} ${
                translate["Image"] || EN["Image"]
              }`,
            },
          ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        {visibleProductDrawer && (
          <Translation
            render={(item, index) => {
              const name = nameTranslations.find(
                (translation) => translation.language_id === item.id
              )?.text;
              const description = descriptionTranslations.find(
                (translation) => translation.language_id === item.id
              )?.text;

              return (
                <div>
                  <Form.Item
                    label={translate["Name"] || EN["Name"]}
                    tooltip={{
                      title: "Service name",
                      icon: ICONS.INFO,
                    }}
                    rules={[
                      {
                        required: true,
                        message: `${translate["Please input"] || EN["Please input"]} ${
                          translate["Name"] || EN["Name"]
                        }`,
                      },
                    ]}
                  >
                    <Input
                      value={name}
                      onChange={(e) => onChangeName(e.target.value, item.id)}
                    />
                  </Form.Item>

                  <Form.Item
                    label={translate["Description"] || EN["Description"]}
                    tooltip={{
                      title: "Service description",
                      icon: ICONS.INFO,
                    }}
                    rules={[
                      {
                        required: true,
                        message: `${translate["Please input"] || EN["Please input"]} ${
                          translate["Description"] || EN["Description"]
                        }`,
                      },
                    ]}
                  >
                    <Input.TextArea
                      value={description}
                      onChange={(e) => onChangeDesctiption(e.target.value, item.id)}
                      rows={5}
                    />
                  </Form.Item>
                </div>
              );
            }}
          />
        )}

        <Form.Item
          label={translate["Price"] || EN["Price"]}
          tooltip={{
            title: "Product Price",
            icon: ICONS.INFO,
          }}
          name="price"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Price"] || EN["Price"]
              }`,
            },
            {
              pattern: /^[0-9]*$/,
              message: `${translate["Please input valid"] || EN["Please input valid"]} ${
                translate["Price"] || EN["Price"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Category"] || EN["Category"]}
          tooltip={{
            title: "Choose category",
            icon: ICONS.INFO,
          }}
          name="category_id"
          rules={[
            {
              required: true,
              message: `${translate["Please select"] || EN["Please select"]} ${
                translate["Category"] || EN["Category"]
              }`,
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            showArrow
            allowClear={true}
            options={categoriesOptions}
          />
        </Form.Item>
        <div>
          <TagComponent type="e_commercial" form={form} />
        </div>

        <Form.Item name="attach_slider" noStyle valuePropName="checked">
          <Checkbox style={{ marginBottom: 25, color: "#abb0c0" }}>
            {translate["Attach Slider"] || EN["Attach Slider"]}
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
