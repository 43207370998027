import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";

import HeaderItem from "../../../elements/HeaderItem";
import PageHeader from "../../../elements/PageHeader";
import AddButtonComponent from "../../../elements/AddButtonComponent";

import Context from "./Context";

export default function Head() {
  const translate = useSelector((state) => state.globalState.translate);

  const { total, setVisibleDrawer } = useContext(Context);
  const [totalValue, setTotalValue] = useState(0);

  useEffect(() => {
    REQUESTS.INFO_CENTER.INFO.STATISTICS((data) => {
      setTotalValue(data);
    });
  }, [total]);

  return (
    <PageHeader title={translate["Info Center"] || EN["Info Center"]}>
      <HeaderItem title={translate["Total"] || EN["Total"]} total={totalValue} />
      <AddButtonComponent
        text={translate["Add Info"] || EN["Add Info"]}
        onClick={() => setVisibleDrawer(true)}
      />
    </PageHeader>
  );
}
