import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Select } from "antd";
import Context from "./Context";
import { useMovieTypesOptions } from "../../../../../hooks/options";

import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";

import ButtonComponent from "../../../../elements/ButtonComponent";

export default function FilterDrawer() {
  const { visibleFilterDrawer, setVisibleFilterDrawer, setSelectedMovieTypeId } =
    useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const movieTypesOptions = useMovieTypesOptions();

  const [selectedMovieType, setSelectedMovieType] = useState(null);

  const onClickFilter = () => {
    setSelectedMovieTypeId(selectedMovieType);
    setVisibleFilterDrawer(false);
  };

  return (
    <Drawer
      title={translate["Filter"] || EN["Filter"]}
      placement="right"
      visible={visibleFilterDrawer}
      onClose={() => setVisibleFilterDrawer(false)}
      destroyOnClose={true}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h1>{translate["Select filter parameters"] || EN["Select filter parameters"]}</h1>
        <p>{translate["Type"] || EN["Type"]}:</p>

        <Select
          showArrow
          allowClear={true}
          value={selectedMovieType}
          onChange={setSelectedMovieType}
          options={movieTypesOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <ButtonComponent
          text={translate["Filter"] || EN["Filter"]}
          icon={ICONS.ARROW_RIGHT_OUTLINED}
          onClick={onClickFilter}
        />
      </div>
    </Drawer>
  );
}
