import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../../../server/requests";

import ICONS from "../../../../../config/icons";
import EN from "../../../../../config/en";

import SaveButtonComponent from "../../../../elements/SaveButtonComponent";
import ErrorMessage from "../../../../elements/ErrorMessage";

export default function ClientQuickMessageDrawer({ visible, onClose, getData, current }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (current) {
        form.setFields([{ name: "message", value: current.message }]);
      }
    } else {
      setErrorText(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);
    setErrorText(null);

    const body = {
      type: "guest",
      message: values.message,
      message_type: "messages",
    };

    function callback() {
      setLoading(false);
      onClose();

      getData && getData();
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (current) {
      body.id = current.id;

      REQUESTS.QUICK_MESSAGES.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.QUICK_MESSAGES.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  return (
    <Drawer
      title={
        current
          ? translate["Edit Quick Messages"] || EN["Edit Quick Messages"]
          : translate["Add Quick Messages"] || EN["Add Quick Messages"]
      }
      placement="right"
      visible={visible}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="quick-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Quick messages"] || EN["Quick messages"]}
          tooltip={{
            title: "Quick messages",
            icon: ICONS.INFO,
          }}
          name="message"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Quick messages"] || EN["Quick messages"]
              }`,
            },
          ]}
        >
          <Input.TextArea rows={4} onResize={false} />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>

        {/* <ErrorMessage>{errorText}</ErrorMessage> */}
      </Form>
    </Drawer>
  );
}
