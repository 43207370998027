import { useContext } from "react";
import { useSelector } from "react-redux";

import { Drawer, Checkbox } from "antd";

import HotelStaffContext from "./HotelStaffContext";

import EN from "../../../config/en";
import LOCAL_STORAGE_VALUES from "../../../config/localStorageValues";

export default function ViewSettingsDrawer() {
  const {
    visibleViewSettingsDrawer,
    setVisibleViewSettingsDrawer,
    viewSettingsValues,
    setViewSettingsValues,
  } = useContext(HotelStaffContext);

  const onChangeSettingsValues = (event) => {
    viewSettingsValues[event.target.name] = event.target.checked;
    setViewSettingsValues({ ...viewSettingsValues });

    localStorage.setItem(
      LOCAL_STORAGE_VALUES.HOTEL_STAFF_COLUMNS,
      JSON.stringify(viewSettingsValues)
    );
  };
  const translate = useSelector((state) => state.globalState.translate);

  return (
    <Drawer
      title={translate["View settings"] || EN["View settings"]}
      placement="right"
      width={400}
      onClose={() => setVisibleViewSettingsDrawer(false)}
      visible={visibleViewSettingsDrawer}
      destroyOnClose={true}
    >
      <h2>{translate["Show columns"] || EN["Show columns"]}</h2>

      <div className="view-settings-checkboxes">
        <Checkbox
          name="image"
          checked={viewSettingsValues.image}
          onChange={onChangeSettingsValues}
        >
          {translate["Image"] || EN["Image"]}
        </Checkbox>

        <Checkbox
          name="name"
          checked={viewSettingsValues.name}
          onChange={onChangeSettingsValues}
        >
          {translate["Name"] || EN["Name"]}
        </Checkbox>

        <Checkbox
          name="surname"
          checked={viewSettingsValues.surname}
          onChange={onChangeSettingsValues}
        >
          {translate["Surname"] || EN["Surname"]}
        </Checkbox>

        <Checkbox
          name="email"
          checked={viewSettingsValues.email}
          onChange={onChangeSettingsValues}
        >
          {translate["Email"] || EN["Email"]}
        </Checkbox>

        <Checkbox
          name="employers_roles"
          checked={viewSettingsValues.employers_roles}
          onChange={onChangeSettingsValues}
        >
          {translate["Roles"] || EN["Roles"]}
        </Checkbox>

        <Checkbox
          name="e_commercial_managers"
          checked={viewSettingsValues.e_commercial_managers}
          onChange={onChangeSettingsValues}
        >
          {translate["E-commerce catagory"] || EN["E-commerce catagory"]}
        </Checkbox>

        <Checkbox
          name="createdAt"
          checked={viewSettingsValues.createdAt}
          onChange={onChangeSettingsValues}
        >
          {translate["Date"] || EN["Date"]}
        </Checkbox>
      </div>
    </Drawer>
  );
}
