import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, InputNumber, notification, Select, Switch } from "antd";

import TvChannelsContext from "./TvChannelsContext";

import {
  useCountriesOptions,
  useLanguagesOptions,
  useTizenPlayerOptions,
} from "../../../../../hooks/options";

import REQUESTS from "../../../../../server/requests";
import ErrorMessage from "../../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../../elements/SaveButtonComponent";

import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";
import EpgSearch from "./EpgSearch";
import FileChooser from "../../../../elements/FileChooser";
import Translation from "../../../../elements/Translation";

export default function AddNewChannelDrawer() {
  const {
    visibleChannelDrawer,
    setVisibleChannelDrawer,
    currentChannel,
    getChannelList,
  } = useContext(TvChannelsContext);

  const { profile, translate } = useSelector((state) => state.globalState);

  const countriesOptions = useCountriesOptions();

  const languagesOptions = useLanguagesOptions();

  const tizenPlayerOptions = useTizenPlayerOptions();

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  const [groupsOption, setGroupsOption] = useState([]);

  const [udpAddressIsRequired, setUdpAddressIsRequired] = useState(true);
  const [httpAddressIsRequired, setHttpAddressIsRequired] = useState(true);

  const [numberIsUsged, setNumberIsUsged] = useState(false);

  const [nameTranslations, setNameTranslations] = useState([]);

  const [descriptionTranslations, setDescriptionTranslations] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (visibleChannelDrawer) {
      getGroupsOption();
      setNumberIsUsged(false);

      if (currentChannel) {
        const categories_id = currentChannel.tv_categories.map(
          (item) => item.tv_categories_id
        );

        form.setFields([
          { name: "name", value: currentChannel.name },
          { name: "channel_number", value: currentChannel.Channel_number },
          { name: "description", value: currentChannel.description },
          { name: "udp_url", value: currentChannel.udp_url },
          { name: "epg_id", value: currentChannel.epg_id },
          { name: "languages_id", value: currentChannel.languages_id },
          { name: "country_id", value: currentChannel.country_id },
          { name: "published_status", value: currentChannel.published_status },
          { name: "rating_pg", value: currentChannel.rating_pg },
          { name: "tizen_player", value: currentChannel.tizen_player },
          { name: "url", value: currentChannel.url },
          { name: "categories_id", value: categories_id },
          { name: "icon", value: currentChannel.icon },
        ]);

        setNameTranslations(currentChannel.name_translations || []);

        setDescriptionTranslations(currentChannel.description_translations || []);

        if (currentChannel.icon) {
          setImageSrc(currentChannel.icon);
        }
      }
    } else {
      setErrorText(null);

      setDescriptionTranslations([]);

      setNameTranslations([]);
    }
  }, [visibleChannelDrawer]);

  useEffect(() => {
    if (!visibleChannelDrawer && currentChannel) {
      form.resetFields();
      setImageSrc(null);
    }
  }, [visibleChannelDrawer]);

  useEffect(() => {
    if (!currentChannel) {
      getLastNumber();
    }
  }, [currentChannel, visibleChannelDrawer]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      Channel_number: values.channel_number,
      udp_url: values.udp_url,
      tizen_player: values.tizen_player,
      epg_id: values.epg_id,
      is_protected: false,
      languages_id: values.languages_id,
      country_id: values.country_id,
      published_status: values.published_status,
      published_status: values.published_status,
      published_status: values.published_status,
      rating_pg: values.rating_pg,
      url: values.url,
      icon: values.icon,
      categories_id: JSON.stringify(values.categories_id),
      organization_id: profile?.organization_id,
      name: JSON.stringify(nameTranslations || []),
      description: JSON.stringify(descriptionTranslations || []),
    };

    function callback() {
      setLoading(false);
      setVisibleChannelDrawer();
      getChannelList();
      form.resetFields();
      setImageSrc(null);
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (currentChannel) {
      body.id = currentChannel.id;

      REQUESTS.TV_CHANNELS.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.TV_CHANNELS.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  const getGroupsOption = () => {
    REQUESTS.TV_CHANNELS_CATEGORIES.GET({}, (data) => {
      const newArr = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setGroupsOption(newArr);
    });
  };

  const onChangeEpg = (epg) => {
    form.setFields([{ name: "epg_id", value: epg }]);
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    form.setFields([{ name: "icon", value: item.file_url }]);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "icon", value: null }]);
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (changedFields[0].name[0] === "udp_url") {
      if (changedFields[0].value?.length > 0) {
        setHttpAddressIsRequired(false);
        // form.validateFields(["url", "udp_url"], { hideErrors: true });
      } else {
        setHttpAddressIsRequired(true);
      }
    }

    if (changedFields[0].name[0] === "url") {
      if (changedFields[0].value?.length > 0) {
        setUdpAddressIsRequired(false);
      } else {
        setUdpAddressIsRequired(true);
      }
    }
  };

  const checkNumber = (value) => {
    if (!value) {
      return;
    }

    const body = {
      Channel_number: value,
    };

    if (currentChannel) {
      body.id = currentChannel.id;
    }

    REQUESTS.TV_CHANNELS.CHECK_NUMBER(
      body,
      (data) => {
        setNumberIsUsged(false);
      },

      (err) => {
        setNumberIsUsged(true);
      }
    );
  };

  const getLastNumber = () => {
    const query = {
      sort: ["Channel_number", "DESC"],
      limit: 1,
      pagination: 1,
      page: 1,
    };

    REQUESTS.TV_CHANNELS.GET(query, (data) => {
      if (data?.rows?.[0]) {
        form.setFields([
          { name: "channel_number", value: data.rows?.[0].Channel_number + 1 },
        ]);

        return;
      }
    });
  };

  const onChangeName = (text, id) => {
    const name = {
      text,
      language_id: id,
    };

    const newTranslations = [...nameTranslations];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = name;
    } else {
      newTranslations.push(name);
    }

    setNameTranslations(newTranslations);
  };

  const onChangeDesctiption = (text, id) => {
    const description = {
      text,
      language_id: id,
    };

    const newTranslations = [...descriptionTranslations];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = description;
    } else {
      newTranslations.push(description);
    }

    setDescriptionTranslations(newTranslations);
  };

  return (
    <Drawer
      title={
        currentChannel
          ? translate["Edit Channel"] || EN["Edit Channel"]
          : translate["Add New Channel"] || EN["Add New Channel"]
      }
      placement="right"
      width={600}
      onClose={() => {
        setVisibleChannelDrawer(false);
        setLoading(false);
        setErrorText(null);
        form.resetFields();
        setImageSrc(null);
      }}
      visible={visibleChannelDrawer}
      destroyOnClose={true}
    >
      <Form
        initialValues={{
          published_status: false,
        }}
        form={form}
        layout="vertical"
        name="channel-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
      >
        <Form.Item
          label={translate["Image"] || EN["Image"]}
          tooltip={{
            title: "Choose an image",
            icon: ICONS.INFO,
          }}
          name="icon"
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        {visibleChannelDrawer && (
          <Translation
            render={(item, index) => {
              const name = nameTranslations.find(
                (translation) => translation.language_id === item.id
              )?.text;
              const description = descriptionTranslations.find(
                (translation) => translation.language_id === item.id
              )?.text;

              return (
                <div>
                  <Form.Item
                    label={translate["Name"] || EN["Name"]}
                    tooltip={{
                      title: "Xhannel name",
                      icon: ICONS.INFO,
                    }}
                    rules={[
                      {
                        required: true,
                        message: `${translate["Please input"] || EN["Please input"]} ${
                          translate["Name"] || EN["Name"]
                        }`,
                      },
                    ]}
                  >
                    <Input
                      value={name}
                      onChange={(e) => onChangeName(e.target.value, item.id)}
                    />
                  </Form.Item>

                  <Form.Item
                    label={translate["Description"] || EN["Description"]}
                    tooltip={{
                      title: "Channel description",
                      icon: ICONS.INFO,
                    }}
                    rules={[
                      {
                        required: true,
                        message: `${translate["Please input"] || EN["Please input"]} ${
                          translate["Description"] || EN["Description"]
                        }`,
                      },
                    ]}
                  >
                    <Input.TextArea
                      value={description}
                      onChange={(e) => onChangeDesctiption(e.target.value, item.id)}
                      rows={5}
                    />
                  </Form.Item>
                </div>
              );
            }}
          />
        )}

        <Form.Item
          label={translate["Epg id"] || EN["Epg id"]}
          tooltip={{
            title: "Search Epg Id",
            icon: ICONS.INFO,
          }}
          name="epg_id"
        >
          <EpgSearch onChangeEpg={onChangeEpg} />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "space-between", gap: 20 }}>
          <Form.Item
            label={translate["Channel number"] || EN["Channel number"]}
            tooltip={{
              title: "Channel Number",
              icon: ICONS.INFO,
            }}
            name="channel_number"
            rules={[
              {
                required: true,
                message: `${translate["Please input"] || EN["Please input"]} ${
                  translate["Channel number"] || EN["Channel number"]
                }`,
              },
            ]}
            style={{ width: "50%" }}
            validateStatus={numberIsUsged && "warning"}
            help={numberIsUsged ? "This number is already used" : null}
          >
            <InputNumber
              controls={false}
              style={{
                width: "100%",
              }}
              onChange={(e) => checkNumber(e)}
            />
          </Form.Item>

          <Form.Item
            label={translate["Rating pg"] || EN["Rating pg"]}
            tooltip={{
              title: "Rating pg",
              icon: ICONS.INFO,
            }}
            name="rating_pg"
            style={{ width: "50%" }}
          >
            <InputNumber
              controls={false}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </div>

        <Form.Item
          label={translate["Categories"] || EN["Categories"]}
          tooltip={{
            title: "Choose category",
            icon: ICONS.INFO,
          }}
          name="categories_id"
          rules={[
            {
              required: true,
              message: `${translate["Please select"] || EN["Please select"]} ${
                translate["Categories"] || EN["Categories"]
              }`,
            },
          ]}
        >
          <Select mode="multiple" showArrow allowClear={true} options={groupsOption} />
        </Form.Item>

        <Form.Item
          label={translate["Language"] || EN["Language"]}
          tooltip={{
            title: "Language",
            icon: ICONS.INFO,
          }}
        >
          <Form.Item name="languages_id" noStyle>
            <Select
              showSearch
              options={languagesOptions}
              optionFilterProp="label"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label={translate["Country"] || EN["Country"]}
          tooltip={{
            title: "Choose a country",
            icon: ICONS.INFO,
          }}
        >
          <Form.Item name="country_id" noStyle>
            <Select
              showSearch
              options={countriesOptions}
              optionFilterProp="label"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label={translate["Player"] || EN["Player"]}
          tooltip={{
            title: "Choose player",
            icon: ICONS.INFO,
          }}
          name="tizen_player"
          rules={[
            {
              required: true,
              message: `${translate["Please select"] || EN["Please select"]} ${
                translate["Tizen player"] || EN["Tizen player"]
              }`,
            },
          ]}
        >
          <Select showArrow allowClear={true} options={tizenPlayerOptions} />
        </Form.Item>

        <Form.Item
          label={translate["HTTP address"] || EN["HTTP address"]}
          tooltip={{
            title: "HTTP address",
            icon: ICONS.INFO,
          }}
          name="url"
          rules={[
            {
              required: httpAddressIsRequired,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["HTTP address"] || EN["HTTP address"]
              }`,
            },
          ]}
        >
          <Input type="url" />
        </Form.Item>

        <Form.Item
          label={translate["UDP address"] || EN["UDP address"]}
          tooltip={{
            title: "UDP address",
            icon: ICONS.INFO,
          }}
          name="udp_url"
          rules={[
            {
              required: udpAddressIsRequired,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["UDP address"] || EN["UDP address"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Published Status"] || EN["Published Status"]}
          tooltip={{
            title: "Published Status",
            icon: ICONS.INFO,
          }}
        >
          <Form.Item name="published_status" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <div className="buttons-in-user-accounts">
            <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
          </div>
        </Form.Item>
      </Form>
      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
