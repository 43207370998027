import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Context from "./package-drawer/Context";
import { Table, Switch, Input, notification } from "antd";
import REQUESTS from "../../../server/requests";

import ICONS from "../../../config/icons";
import confirmModal from "../../../config/confirmModal";
import { dateFormat } from "../../../config/formats";

import Check from "../../elements/Check";
import TableButtons from "../../elements/TableButtons";

import PackegesHeader from "./PackegesHeader";
import PackageDrawer from "./package-drawer/PackageDrawer";

import TableHead from "./package-drawer/TableHead";

import "./_packeges.scss";
import Page from "../../elements/Page";
import EN from "../../../config/en";

export default function PackegesPage({}) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [packegesList, setPackegesList] = useState([]);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [nameSearch, setNameSearch] = useState(null);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPackege, setCurrentPackege] = useState(null);

  const [selectedDefault, setSelectedDefault] = useState(null);
  const [selectedTVChannels, setSelectedTVChannels] = useState(null);
  const [selectedMovies, setSelectedMovies] = useState(null);
  const [selectedEcommerce, setSelectedEcommerce] = useState(null);
  const [selectedWidgets, setSelectedWidgets] = useState(null);
  const [selectedSendMessages, setSelectedSendMessages] = useState(null);
  const [selectedViewBuillingInformation, setSelectedViewBuillingInformation] =
    useState(null);

  const [selectedNewsViews, setSelectedNewsViews] = useState(null);
  const [selectedViewPromotions, setSelectedViewPromotions] = useState(null);
  const [selectedFeedbackAccess, setSelectedFeedbackAccess] = useState(null);

  const [visiblePackageDrawer, setVisiblePackageDrawer] = useState(false);
  const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);
  const [visibleViewSettingsDrawer, setVisibleViewSettingsDrawer] = useState(false);

  const [viewSettingsValues, setViewSettingsValues] = useState({
    id: true,
    is_default: true,
    name: true,
    access_to_TV_Channels: true,
    access_to_Movies: true,
    access_to_Services_and_products: true,
    access_to_Widget: true,
    send_messages: true,
    view_Billing_Information: true,
    news_Views: true,
    view_promotions: true,
    feedback_sccess: true,
    createdAt: true,
    action: true,
    access_to_music: true,
    access_to_radio: true,
  });

  useEffect(() => {
    if (!visiblePackageDrawer) {
      setCurrentPackege(null);
    }
  }, [visiblePackageDrawer]);

  useEffect(() => {
    getPackegesList();
  }, [
    currentPage,
    limit,
    sort,
    selectedDefault,
    selectedTVChannels,
    selectedMovies,
    selectedEcommerce,
    selectedWidgets,
    selectedSendMessages,
    selectedViewBuillingInformation,
    selectedNewsViews,
    selectedViewPromotions,
    selectedFeedbackAccess,
  ]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getPackegesList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [nameSearch]);

  useEffect(() => {
    configureTableColumns();
  }, [viewSettingsValues, currentPage]);

  useEffect(() => {
    let PACKAGES_COLUMNS = localStorage.getItem("PACKAGES_COLUMNS");

    if (PACKAGES_COLUMNS) {
      PACKAGES_COLUMNS = JSON.parse(PACKAGES_COLUMNS);
      setViewSettingsValues(PACKAGES_COLUMNS);
    }
  }, []);

  const [columns, setColumns] = useState([]);

  const configureTableColumns = () => {
    const newColumns = [];
    newColumns.push({
      fixed: "left",
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    });

    if (viewSettingsValues.is_default) {
      newColumns.push({
        title: translate["Default"] || EN["Default"],
        dataIndex: "is_default",
        key: "is_default",
        width: 100,
        sorter: true,
        render: (text, record, index) => (
          <Switch
            checked={record.is_default}
            onChange={(e) => onChangeDefault(e, record)}
          />
        ),
      });
    }

    if (viewSettingsValues.name) {
      newColumns.push({
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: true,
        render: (text, record, index) => <p className="text-ellipsis">{text}</p>,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <> {ICONS.SEARCHOUTLINED} </>;
        },
        onFilter: (value, record) => {
          return record.name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }
    if (viewSettingsValues.access_to_TV_Channels) {
      newColumns.push({
        title: translate["Tv Channels"] || EN["Tv Channels"],
        dataIndex: "access_to_TV_Channels",
        sorter: true,
        width: 130,
        key: "access_to_TV_Channels",
        render: (text, record, index) => {
          return record.access_to_TV_Channels.enabled ? (
            record.access_to_TV_Channels.access ? (
              <p>{translate["Full access"] || EN["Full access"]}</p>
            ) : (
              <p>{translate["Partial access"] || EN["Partial access"]}</p>
            )
          ) : (
            <Check check={false} />
          );
        },
      });
    }

    if (viewSettingsValues.access_to_Movies) {
      newColumns.push({
        title: translate["Vod"] || EN["Vod"],
        dataIndex: "access_to_Movies",
        sorter: true,
        width: 130,
        key: "access_to_Movies",
        render: (text, record, index) =>
          record.access_to_Movies.enabled ? (
            record.access_to_Movies.access ? (
              <p>{translate["Full access"] || EN["Full access"]}</p>
            ) : (
              <p>{translate["Partial access"] || EN["Partial access"]}</p>
            )
          ) : (
            <Check check={false} />
          ),
      });
    }

    if (viewSettingsValues?.access_to_music) {
      newColumns.push({
        title: translate["Music"] || EN["Music"],
        dataIndex: "access_to_music",
        sorter: true,
        width: 130,
        key: "access_to_music",
        render: (text, record, index) =>
          record.access_to_music.enabled ? (
            record.access_to_music.access ? (
              <p>{translate["Full access"] || EN["Full access"]}</p>
            ) : (
              <p>{translate["Partial access"] || EN["Partial access"]}</p>
            )
          ) : (
            <Check check={false} />
          ),
      });
    }

    if (viewSettingsValues.access_to_radio) {
      newColumns.push({
        title: translate["Radio"] || EN["Radio"],
        dataIndex: "access_to_radio",
        sorter: true,
        width: 130,
        key: "access_to_radio",
        render: (text, record, index) =>
          record.access_to_radio.enabled ? (
            record.access_to_radio.access ? (
              <p>{translate["Full access"] || EN["Full access"]}</p>
            ) : (
              <p>{translate["Partial access"] || EN["Partial access"]}</p>
            )
          ) : (
            <Check check={false} />
          ),
      });
    }

    if (viewSettingsValues.access_to_Services_and_products) {
      newColumns.push({
        title: translate["E-Commerce"] || EN["E-Commerce"],
        dataIndex: "access_to_Services_and_products",
        sorter: true,
        width: 140,
        key: "access_to_Services_and_products",
        render: (text, record, index) => {
          return record.access_to_Services_and_products.enabled ? (
            record.access_to_Services_and_products.access ? (
              <p>{translate["Full access"] || EN["Full access"]}</p>
            ) : (
              <p>{translate["Partial access"] || EN["Partial access"]}</p>
            )
          ) : (
            <Check check={false} />
          );
        },
      });
    }

    if (viewSettingsValues.access_to_Widget) {
      newColumns.push({
        title: translate["Widgets"] || EN["Widgets"],
        dataIndex: "access_to_Widget",
        sorter: true,
        width: 130,
        key: "access_to_Widget",
        render: (text, record, index) =>
          record.access_to_Widget.enabled ? (
            record.access_to_Widget.access ? (
              <p>{translate["Full access"] || EN["Full access"]}</p>
            ) : (
              <p>{translate["Partial access"] || EN["Partial access"]}</p>
            )
          ) : (
            <Check check={false} />
          ),
      });
    }

    if (viewSettingsValues.send_messages) {
      newColumns.push({
        title: translate["Send messages"] || EN["Send messages"],
        dataIndex: "send_messages",
        key: "send_messages",
        sorter: true,
        width: 200,
        render: (text, record, index) => <Check check={record.send_messages} />,
      });
    }

    if (viewSettingsValues.view_Billing_Information) {
      newColumns.push({
        title: translate["View Bill"] || EN["View Bill"],
        dataIndex: "view_Billing_Information",
        key: "view_Billing_Information",
        sorter: true,
        width: 130,
        render: (text, record, index) => (
          <Check check={record.view_Billing_Information} />
        ),
      });
    }

    if (viewSettingsValues.news_Views) {
      newColumns.push({
        title: translate["News"] || EN["News"],
        dataIndex: "news_Views",
        key: "news_Views",
        sorter: true,
        width: 130,
        render: (text, record, index) => <Check check={record.news_Views} />,
      });
    }

    if (viewSettingsValues.view_promotions) {
      newColumns.push({
        title: translate["Promotions"] || EN["Promotions"],
        dataIndex: "view_promotions",
        key: "view_promotions",
        sorter: true,
        width: 130,
        render: (text, record, index) => <Check check={record.view_promotions} />,
      });
    }

    if (viewSettingsValues.feedback_sccess) {
      newColumns.push({
        title: translate["Access"] || EN["Access"],
        dataIndex: "feedback_sccess",
        key: "feedback_sccess",
        sorter: true,
        width: 130,
        render: (text, record, index) => <Check check={record.feedback_sccess} />,
      });
    }

    if (viewSettingsValues.createdAt) {
      newColumns.push({
        title: translate["Date"] || EN["Date"],
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: true,
        render: (text, record, index) => dateFormat(text),
      });
    }

    newColumns.push({
      fixed: "right",
      title: "",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          style={{ minWidth: 130 }}
          buttons={[
            { key: "edit", text: translate["Edit"] || EN["Edit"], icon: ICONS.EDIT },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE,
            },
          ]}
        />
      ),
    });

    setColumns(newColumns);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setCurrentPackege(item);
        setVisiblePackageDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
            item.name
          }`,
          action: () => deletePackege(item),
        });
        break;

      default:
        break;
    }
  };

  const deletePackege = (item) => {
    REQUESTS.PACKAGES.DELETE(item.id, getPackegesList, (err) => {
      // notification.error({ description: translate['This is default package] || EN['This is default package]});
      notification.error({ description: err });
    });
  };

  const onChangeDefault = (checked, selected) => {
    function callback() {
      getPackegesList();
    }

    function errorCallback(err) {}

    REQUESTS.PACKAGES.DEFAULT(selected.id, callback, errorCallback);
  };

  const getPackegesList = () => {
    setLoading(true);

    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage,
    };

    if (nameSearch) {
      query.search = JSON.stringify({ name: nameSearch });
    }

    let filter = {};

    if (selectedDefault != null) {
      filter.is_default = selectedDefault;
    }

    if (selectedTVChannels != null) {
      filter.access_to_TV_Channels = selectedTVChannels;
    }

    if (selectedMovies != null) {
      filter.access_to_Movies = selectedMovies;
    }

    if (selectedEcommerce != null) {
      filter.access_to_Services_and_products = selectedEcommerce;
    }

    if (selectedWidgets != null) {
      filter.access_to_Widget = selectedWidgets;
    }

    if (selectedSendMessages != null) {
      filter.send_messages = selectedSendMessages;
    }

    if (selectedViewBuillingInformation != null) {
      filter.view_Billing_Information = selectedViewBuillingInformation;
    }

    if (selectedNewsViews != null) {
      filter.news_Views = selectedNewsViews;
    }

    if (selectedViewPromotions != null) {
      filter.view_promotions = selectedViewPromotions;
    }

    if (selectedFeedbackAccess != null) {
      filter.feedback_sccess = selectedFeedbackAccess;
    }

    const size = Object.keys(filter).length;

    if (size) {
      query.filter = JSON.stringify(filter);
    }

    function callback(data) {
      setPackegesList(data.rows);
      setLimit(data.limit);
      setTotal(data.count);
      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.PACKAGES.GET(query, callback, errorCallback);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    if (Object.values(filters)[0]) {
      setNameSearch(Object.values(filters)[0].join(""));
    } else {
      setNameSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  return (
    <Context.Provider
      value={{
        total,
        setVisiblePackageDrawer,
        currentPackege,
        visiblePackageDrawer,
        getPackegesList,
        setVisibleViewSettingsDrawer,
        setVisibleFilterDrawer,
        visibleFilterDrawer,
        visibleViewSettingsDrawer,
        viewSettingsValues,
        setViewSettingsValues,
        selectedDefault,
        setSelectedDefault,
        selectedTVChannels,
        setSelectedTVChannels,
        selectedMovies,
        setSelectedMovies,
        selectedEcommerce,
        setSelectedEcommerce,
        selectedWidgets,
        setSelectedWidgets,
        selectedSendMessages,
        setSelectedSendMessages,
        selectedViewBuillingInformation,
        setSelectedViewBuillingInformation,
        selectedNewsViews,
        setSelectedNewsViews,
        selectedViewPromotions,
        setSelectedViewPromotions,
        selectedFeedbackAccess,
        setSelectedFeedbackAccess,
      }}
    >
      <Page>
        <PackegesHeader />

        <div className="page-body">
          <TableHead />
          <Table
            rowKey="id"
            loading={loading}
            columns={columns}
            dataSource={packegesList}
            onChange={handleTableChange}
            scroll={{ x: "max-content", y: null }}
            pagination={{
              position: ["topCenter", "bottomCenter"],
              current: currentPage,
              total: total,
              showSizeChanger: true,
            }}
            sticky={{
              offsetHeader: 60,
              offsetScroll: 0,
            }}
          />

          <PackageDrawer />
        </div>
      </Page>
    </Context.Provider>
  );
}
