import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Table, Input, Select, Tag } from "antd";

import REQUESTS from "../../../../server/requests";

import ICONS from "../../../../config/icons";
import confirmModal from "../../../../config/confirmModal";
import { dateFormat } from "../../../../config/formats";

import TableButtons from "../../../elements/TableButtons";
import AddButtonComponent from "../../../elements/AddButtonComponent";

import { useOrganizationsOptions } from "../../../../hooks/options";

import EN from "../../../../config/en";
import LanguageDrawer from "./LanguageDrawer";
import ImageComponent from "../../../elements/ImageComponent";
import IMAGES from "../../../../config/images";

export default function LanguagesDrawer({ visible, onClose }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const [data, setData] = useState(false);
  const [current, setCurrent] = useState(null);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [nameSearch, setNameSearch] = useState(null);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const organizationsOptions = useOrganizationsOptions();

  const [selectedOrganization, setSelectedOrganization] = useState(null);

  useEffect(() => {
    if (organizationsOptions) {
      setSelectedOrganization(organizationsOptions[0]?.value);
    }
  }, [organizationsOptions]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      if (visible) {
        getLanguages();
      }
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [visible, nameSearch, currentPage, limit, sort, selectedOrganization]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      fixed: "left",
      key: "id",

      width: 50,
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    },

    {
      title: translate["Name"] || EN["Name"],
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 200,
      render: (text, record, index) => (
        <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
          <img
            src={record.icon}
            onError={(e) => (e.target.src = IMAGES.IMAGE_ERROR)}
            width={40}
            className="tableImage"
          />
          <p className="text-ellipsis">{text}</p>
        </div>
      ),

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            allowClear
            autoFocus
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: () => {
        return <>{ICONS.SEARCHOUTLINED}</>;
      },
      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: translate["Iso code"] || EN["Iso code"],
      dataIndex: "iso_code",
      key: "iso_code",
      width: 80,
      align: "center",
    },

    {
      title: translate["Status"] || EN["Status"],
      dataIndex: "is_active",
      key: "is_active",
      width: 80,
      align: "center",
      sorter: true,
      render: (text, record, index) =>
        record.is_active ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Disabled</Tag>
        ),
    },

    {
      title: translate["Date"] || EN["Date"],
      key: "createdAt",
      sorter: true,
      dataIndex: "createdAt",
      align: "center",

      width: 120,

      render: (text, record, index) => dateFormat(text),
    },

    {
      title: "",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      width: 60,

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          style={{ minWidth: 130 }}
          buttons={[
            { key: "edit", text: translate["Edit"] || EN["Edit"], icon: ICONS.EDIT },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE,
            },
          ]}
        />
      ),
    },
  ];
  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setCurrent(item);
        setVisibleDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
            item.name
          }`,
          action: () => onDelete(item),
        });
        break;

      default:
        break;
    }
  };
  const onDelete = (item) => {
    REQUESTS.LANGUAGES.DELETE(item.id, getLanguages);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    if (Object.values(filters)[0]) {
      setNameSearch(Object.values(filters)[0].join(""));
    } else {
      setNameSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const getLanguages = () => {
    setLoading(true);
    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage,
      filter: JSON.stringify({
        organization_id: selectedOrganization,
      }),
    };

    if (nameSearch) {
      query.search = JSON.stringify({ name: nameSearch });
    }

    function callback(data) {
      setData(data.rows);
      setLimit(data.limit);
      setTotal(data.count);
      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }
    REQUESTS.LANGUAGES.GET(query, callback, errorCallback);
  };

  return (
    <Drawer
      title={translate["Languages Settings"] || EN["Languages Settings"]}
      placement="right"
      width={900}
      visible={visible}
      onClose={onClose}
      destroyOnClose={true}
    >
      <div className="global_header-title-drawer" style={{ marginBottom: 20 }}>
        <div></div>
        <AddButtonComponent
          text={translate["Add Language"] || EN["Add Language"]}
          onClick={() => setVisibleDrawer(true)}
        />
      </div>

      <Table
        rowKey="id"
        title={() => (
          <div>
            <Select
              showArrow
              value={selectedOrganization}
              onChange={setSelectedOrganization}
              options={organizationsOptions}
              style={{ width: 300 }}
              placeholder={translate["Select Organization"] || EN["Select Organization"]}
            />
          </div>
        )}
        loading={loading}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        pagination={{
          position: ["topCenter", "bottomCenter"],
          current: currentPage,
          total: total,
          showSizeChanger: true,
        }}
        sticky
      />
      <LanguageDrawer
        visible={visibleDrawer}
        onClose={() => {
          setVisibleDrawer(false);
          setCurrent(null);
        }}
        current={current}
        getData={getLanguages}
      />
    </Drawer>
  );
}
