import React from "react";
import { Layout } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import LanguageBlock from "./LanguageBlock";
import ProfileBlock from "./ProfileBlock";
import UploadingList from "./UploadingList";
import Logo from "../elements/Logo";

export default function Header({ collapsed, handleCollapsed }) {
  return (
    <Layout.Header
      className="root-header"
      style={{
        padding: 0,
        left: collapsed ? 80 : 250,
        transition: "0.3s",
        width: `calc(100% - ${collapsed ? 80 : 250}px)`,
      }}
    >
      <div className="icon-uploader-section">
        {collapsed ? (
          <MenuUnfoldOutlined className="trigger" onClick={handleCollapsed} />
        ) : (
          <MenuFoldOutlined className="trigger" onClick={handleCollapsed} />
        )}

        <UploadingList />
        <div className="root-header-logo">{/* <Logo /> */}</div>
      </div>
      <div className="icon-uploader-section">
        <LanguageBlock />
        <ProfileBlock />
      </div>
    </Layout.Header>
  );
}
