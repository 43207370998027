import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import EN from "../../config/en";

export default function Logo({ showTitle = true }) {
  const [show, setShow] = useState(true);

  const translate = useSelector((state) => state.globalState.translate);

  const LOGO_IMAGE = process.env.REACT_APP_LOGO || "{{APP_LOGO}}";

  console.log("LOGO_IMAGE", LOGO_IMAGE);

  useEffect(() => {
    if (showTitle) {
      setTimeout(() => {
        setShow(showTitle);
      }, 200);
    } else {
      setShow(showTitle);
    }
  }, [showTitle]);

  return (
    <div
      style={{
        width: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <img
          src={LOGO_IMAGE}
          alt="hotel smarters logo"
          style={{
            width: 100,
            height: "auto",
          }}
        />
      </div>

      <h1
        style={{
          fontSize: 20,
          display: show ? "block" : "none",
        }}
        className="app-name"
      >
        {translate["RIS"] || EN["RIS"]}
      </h1>
    </div>
  );
}
