import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Input } from "antd";

import NewsContext from "./NewsContext";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";
import confirmModal from "../../../../config/confirmModal";
import { dateFormat } from "../../../../config/formats";

import ImageComponent from "../../../elements/ImageComponent";
import TableButtons from "../../../elements/TableButtons";
import LongText from "../../../elements/LongText";
import Page from "../../../elements/Page";

import NewsHeader from "./NewsHeader";
import NewsDrawer from "./NewsDrawer";

import TableHead from "./TableHead";

import "./_news.scss";

export default function NewsPage({}) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [newsList, setNewsList] = useState([]);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [titleSearch, setTitleSearch] = useState(null);
  const [categorySearch, setCategorySearch] = useState("");

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentNews, setCurrentNews] = useState(null);

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const [visibleNewsDrawer, setVisibleNewsDrawer] = useState(false);

  const [visibleViewSettingsDrawer, setVisibleViewSettingsDrawer] =
    useState(false);
  const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);

  const [columns, setColumns] = useState([]);

  const [viewSettingsValues, setViewSettingsValues] = useState({
    id: true,
    image: true,
    title: true,
    news_category: true,
    description: true,
    createdAt: true,
    action: true
  });

  const configureTableColumns = () => {
    const newColumns = [];
    newColumns.push({
      width: 60,
      fixed: "left",
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index
    });

    if (viewSettingsValues.image) {
      newColumns.push({
        width: 100,
        dataIndex: "image",
        key: "image",
        render: (text, record, index) => (
          <ImageComponent src={text} className="tableImage" />
        )
      });
    }

    if (viewSettingsValues.title) {
      newColumns.push({
        title: translate["Title"] || EN["Title"],
        dataIndex: "title",
        key: "title",
        sorter: true,
        render: (text, record, index) => {
          let title = "";

          const translations = record?.title_translations;

          for (let i = 0; i < translations.length; i++) {
            if (translations[i].is_default) {
              title = translations[i].text;
              break;
            }

            if (translations[i].text) title = translations[i].text;
          }

          return <LongText>{title || "N/A"}</LongText>;
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <> {ICONS.SEARCHOUTLINED} </>;
        },

        onFilter: (value, record) => {
          return record.title.toLowerCase().includes(value.toLowerCase());
        }
      });
    }

    if (viewSettingsValues.news_category) {
      newColumns.push({
        title: translate["Category"] || EN["Category"],
        dataIndex: "categori_id",
        key: "categori_id",
        render: (text, record, index) =>
          record.categori_id ? record.news_category.name : "-",
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={categorySearch[0]}
              onChange={(e) => {
                setCategorySearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.news_category?.name
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      });
    }

    if (viewSettingsValues.description) {
      newColumns.push({
        title: translate["Description"] || EN["Description"],
        dataIndex: "description",
        key: "description",
        sorter: true,
        render: (text, record, index) => {
          let title = "";

          const translations = record?.description_translations;
          for (let i = 0; i < translations.length; i++) {
            if (translations[i].is_default) {
              title = translations[i].text;
              break;
            }

            if (translations[i].text) title = translations[i].text;
          }

          return <LongText>{title || "N/A"}</LongText>;
        }
      });
    }

    if (viewSettingsValues.createdAt) {
      newColumns.push({
        title: translate["Date"] || EN["Date"],
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: true,
        render: (text, record, index) => dateFormat(text)
      });
    }

    newColumns.push({
      width: 60,
      fixed: "right",
      title: "",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          style={{ minWidth: 130 }}
          buttons={[
            {
              key: "edit",
              text: translate["Edit"] || EN["Edit"],
              icon: ICONS.EDIT
            },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE
            }
          ]}
        />
      )
    });
    setColumns(newColumns);
  };

  useEffect(() => {
    configureTableColumns();
  }, [viewSettingsValues, currentPage]);

  useEffect(() => {
    let NEWS_COLUMNS = localStorage.getItem("NEWS_COLUMNS");

    if (NEWS_COLUMNS) {
      NEWS_COLUMNS = JSON.parse(NEWS_COLUMNS);
      setViewSettingsValues(NEWS_COLUMNS);
    }
  }, []);

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setCurrentNews(item);
        setVisibleNewsDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${
            translate["Do you want to delete"] || EN["Do you want to delete"]
          } ${item.title}`,
          action: () => deleteNews(item)
        });
        break;

      default:
        break;
    }
  };

  const deleteNews = (item) => {
    REQUESTS.PROMOTIONS.NEWS.DELETE(item.id, getNewsList);
  };

  const getNewsList = () => {
    setLoading(true);

    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage
    };

    if (categorySearch[0]) {
      query.category_name = categorySearch[0];
    }

    if (titleSearch) {
      query.search = JSON.stringify({ title: titleSearch });
    }

    if (selectedCategoryId) {
      query.filter = JSON.stringify({ categori_id: selectedCategoryId });
    }

    function callback(data) {
      setNewsList(data.rows);
      setLimit(data.limit);
      setTotal(data.count);
      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.PROMOTIONS.NEWS.GET(query, callback, errorCallback);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    if (Object.values(filters)[0]) {
      setTitleSearch(Object.values(filters)[0].join(""));
    } else {
      setTitleSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  useEffect(() => {
    if (!visibleNewsDrawer) {
      setCurrentNews(null);
    }
  }, [visibleNewsDrawer]);

  useEffect(() => {
    getNewsList();
  }, [currentPage, limit, sort, selectedCategoryId]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getNewsList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [titleSearch]);

  useEffect(() => {
    let timeout = null;
    if (categorySearch[0]) {
      if (categorySearch[0]?.length > 2) {
        timeout = setTimeout(() => {
          getNewsList();
        }, 1000);
      }
    } else {
      getNewsList();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [categorySearch]);

  return (
    <NewsContext.Provider
      value={{
        total,
        visibleNewsDrawer,
        setVisibleNewsDrawer,
        currentNews,
        getNewsList,
        visibleViewSettingsDrawer,
        setVisibleViewSettingsDrawer,
        visibleFilterDrawer,
        setVisibleFilterDrawer,
        viewSettingsValues,
        setViewSettingsValues,
        selectedCategoryId,
        setSelectedCategoryId
      }}
    >
      <Page>
        <NewsHeader />

        <div className="page-body">
          <TableHead />
          <Table
            defaultExpandAllRows={true}
            loading={loading}
            rowKey="id"
            columns={columns}
            dataSource={newsList}
            onChange={handleTableChange}
            pagination={{
              position: ["topCenter", "bottomCenter"],
              current: currentPage,
              total: total,
              showSizeChanger: true
            }}
            sticky={{
              offsetHeader: 60,
              offsetScroll: 0
            }}
          />

          <NewsDrawer />
        </div>
      </Page>
    </NewsContext.Provider>
  );
}
