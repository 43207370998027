import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, Tabs } from "antd";
import EN from "../../../../../config/en";
import FromHandTabPane from "./from-hand/FromHandTabPane";
import FromTmdbTabPane from "./FromTmdbTabPane";

export default function AddMovieDrawer({ visible, onClose }) {
  const [activeKey, setActiveKey] = useState("1");
  const translate = useSelector((state) => state.globalState.translate);

  useEffect(() => {
    setActiveKey("1");
  }, [visible]);

  const onChange = (active) => {
    setActiveKey(active);
  };

  return (
    <Drawer
      title={translate["Add New Movies"] || EN["Add New Movies"]}
      placement="right"
      width={850}
      onClose={onClose}
      visible={visible}
      destroyOnClose={true}
    >
      <div style={{ overflow: "auto" }}>
        <Tabs
          activeKey={activeKey}
          centered
          style={{ height: "100%" }}
          onChange={onChange}
        >
          <Tabs.TabPane tab={translate["From TMDB"] || EN["From TMDB"]} key="1">
            <FromTmdbTabPane activeKey={activeKey} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={translate["Add Movie"] || EN["Add Movie"]} key="2">
            <FromHandTabPane activeKey={activeKey} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Drawer>
  );
}
