import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import ProductContext from "./ProductContext";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";

import PageHeader from "../../../elements/PageHeader";
import HeaderItem from "../../../elements/HeaderItem";
import AddButtonComponent from "../../../elements/AddButtonComponent";

export default function ProductsHeader() {

    const translate = useSelector((state) => state.globalState.translate);

    const { total, setVisibleProductDrawer } = useContext(ProductContext);

    const [statistics, setStatistics] = useState({
        ordered: 0,
        products: 0,
    });

    useEffect(() => {
        REQUESTS.ECOMMERCE.PRODUCTS.STATISTICS((data) => {
            setStatistics(data);
        });
    }, [total]);

    return (
        <PageHeader title={translate['Products'] || EN['Products']}>
            <div className="info-section">
                <HeaderItem title={translate['Total'] || EN['Total']} total={statistics.products} />
                <HeaderItem title={translate['Ordered'] || EN['Ordered']} total={statistics.ordered} />
            </div>
            
        
            <AddButtonComponent
                text={translate['Add Product'] || EN['Add Product']}
                onClick={() => setVisibleProductDrawer(true)}
            />
        </PageHeader>
    );
}
