import { Modal } from "antd";
import MediaLibrary from "../pages/media-library/MediaLibrary";

export default function ChooseFromMediaLibrary({ types, visible, onClose, selectFile }) {
    return (
        <Modal
            title="Choose image"
            placement="right"
            width={1000}
            onCancel={onClose}
            visible={visible}
        >
            <MediaLibrary types={types} chooser={true} selectFile={selectFile} />
        </Modal>
    );
}
