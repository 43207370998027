import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Empty, message } from "antd";

import REQUESTS from "../../../../server/requests";

import ICONS from "../../../../config/icons";
import EN from "../../../../config/en";
import confirmModal from "../../../../config/confirmModal";

import TableButtons from "../../../elements/TableButtons";
import LongText from "../../../elements/LongText";

import TVDrawer from "./TVDrawer";
import AddDeviceModal from "../../../elements/AddDeviceModal";
import { QrcodeOutlined } from "@ant-design/icons";
import QRActivateModal from "../../../elements/QRActivateModal";

export default function SubTable({ roomId, packagesOption }) {
  console.log("roomId", roomId);

  const [devices, setDevices] = useState([]);

  const translate = useSelector((state) => state.globalState.translate);

  const [tvDrawer, setTvDrawer] = useState({
    visible: false,
    item: null,
  });

  const [showAddDeviceModal, setShowAddDeviceModal] = useState(false);
  const [deviceData, setDeviceData] = useState(null);
  const [showActivateModal, setShowActivateModal] = useState(false);

  useEffect(() => {
    getDevices();
  }, []);

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setTvDrawer({
          visible: true,
          item: item.device,
        });
        break;

      case "delete":
        confirmModal({
          title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
            item.device.name
          }`,
          action: () => deleteTV(item),
        });
        break;

      default:
        break;
    }
  };

  const getDevices = () => {
    REQUESTS.ROOMS.DEVICES.GET({ id: roomId }, (data) => {
      setDevices(data);
    });
  };

  const deleteTV = (item) => {
    REQUESTS.ROOMS.DEVICES.DELETE(item.devices_id, getDevices, (err) => {
      message.error(err);
    });
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className="add-tv-section">
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
            }}
            type="primary"
            icon={ICONS.PLUS}
            onClick={
              () => setShowAddDeviceModal(true)
              // setTvDrawer({
              //   visible: true,
              //   item: null,
              // })
            }
          >
            {translate["Add Device"] || EN["Add Device"]}
          </Button>
          <Button
            type="secondary"
            icon={<QrcodeOutlined />}
            onClick={() => {
              setShowActivateModal(true);
            }}
          >
            {translate["Activate from QR code"] || EN["Activate from QR code"]}
          </Button>
        </div>

        <table className="sub-table">
          <thead>
            <tr>
              <th>#</th>
              <th>{translate["TV name"] || EN["TV name"]}</th>
              <th>{translate["Model"] || EN["Model"]}</th>
              <th>{translate["IP"] || EN["IP"]}</th>
              <th>{translate["Device Id"] || EN["Device Id"]}</th>
              <th>{translate["Package"] || EN["Package"]}</th>
              <th>{translate["Description"] || EN["Description"]}</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {devices.map((item, index) => {
              const device = item.device;
              let defaultPackage = packagesOption.find(
                (item) => item.value === device.package_id
              );

              return (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{device.name}</td>
                  <td>{device.model || "-"}</td>
                  <td>{device.ip || "-"}</td>
                  <td>{device.device_id || "-"}</td>

                  {device.package_id ? <td>{defaultPackage?.label}</td> : <td>{"-"}</td>}

                  <td>
                    <LongText textAlign={"left"} paddingLeft={0}>
                      {device.description || "-"}
                    </LongText>
                  </td>

                  <td>
                    <TableButtons
                      handleMenuClick={(e) => handleMenuClick(e, item)}
                      style={{ minWidth: 130 }}
                      buttons={[
                        {
                          key: "edit",
                          text: translate["Edit"] || EN["Edit"],
                          icon: ICONS.EDIT,
                        },
                        {
                          key: "delete",
                          text: translate["Delete"] || EN["Delete"],
                          icon: ICONS.DELETE,
                        },
                      ]}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {devices.length === 0 && <Empty style={{ marginTop: 20 }} />}
      </div>

      <TVDrawer
        roomId={roomId}
        visible={tvDrawer.visible}
        tv={tvDrawer.item}
        getDevices={getDevices}
        onClose={() => {
          setTvDrawer({
            visible: false,
            item: null,
          });
        }}
        deviceData={deviceData}
      />

      <AddDeviceModal
        open={showAddDeviceModal}
        onClose={() => setShowAddDeviceModal(false)}
        getData={(data) => {
          setDeviceData(data);
          setTvDrawer({
            visible: true,
            item: null,
          });
          setShowAddDeviceModal(false);
        }}
        skip={() => {
          setTvDrawer({ visible: true });
          setShowAddDeviceModal(false);
        }}
      />
      <QRActivateModal
        show={showActivateModal}
        onClose={() => setShowActivateModal(false)}
        roomId={roomId}
      />
    </>
  );
}
