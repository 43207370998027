import React, { memo } from "react";
import { useSelector } from "react-redux";

import EN from "../../../../config/en";

import PageHeader from "../../../elements/PageHeader";

import AddButtonComponent from "../../../elements/AddButtonComponent";

export default memo(function Head({ openDrawer }) {
    const translate = useSelector((state) => state.globalState.translate);

    return (
        <PageHeader title={translate["Companies"] || EN["Companies"]}>
            <div></div>
            <AddButtonComponent
                text={translate["Add Company"] || EN["Add Company"]}
                onClick={openDrawer}
            />
        </PageHeader>
    );
});
