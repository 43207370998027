import { PlusCircleOutlined } from "@ant-design/icons";

export default function AddConfigButton({ onClick, text }) {
    return (
        <div onClick={onClick} className="add-config-button">
            <PlusCircleOutlined style={{ fontSize: 20 }} />
            <p>{text}</p>
        </div>
    );
}
