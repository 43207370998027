import React, { useContext, useState, useEffect } from "react";
import REQUESTS from "../../../../server/requests";

import Context from "./Context";

import ICONS from "../../../../config/icons";
import IconButton from "../../../elements/IconButton";
import TableHeadComponent from "../../../elements/TableHeadComponent";
import CsvLinkComponent from "../../../elements/CsvLinkComponent";

import ViewSettingsDrawer from "./ViewSettingsDrawer";
import FilterDrawer from "./FilterDrawer";

export default function TableHead() {
  const { setVisibleViewSettingsDrawer, setVisibleFilterDrawer, selectedPackagesId } =
    useContext(Context);

  const [isFilterActive, setIsFilterActive] = useState(false);

  const [clickedCsv, setClickedCsv] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (selectedPackagesId) {
      setIsFilterActive(true);

      return;
    }

    setIsFilterActive(false);
  }, [selectedPackagesId]);

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);

      function callback(data) {
        const newList = data.map((list) => {
          let packageName;

          if (list.package) {
            packageName = list.package.name;
          } else {
            packageName = "-";
          }
          return {
            Name: list.name,
            Room_counter: list.room_count,
            Devices_counter: list.devices_count,
            Package: packageName,
          };
        });

        done(newList);

        setDataList(newList);

        setClickedCsv(false);
      }

      function errorCallback() {
        setClickedCsv(false);
      }

      REQUESTS.ROOMS.GROUPS.GET({}, callback, errorCallback);
    }
  };

  return (
    <>
      <TableHeadComponent>
        <CsvLinkComponent
          filename="Organization-Category-List.csv"
          data={dataList}
          onClick={onClicCsv}
        />
        <IconButton
          icon={ICONS.SETTINGS}
          onClick={() => setVisibleViewSettingsDrawer(true)}
        />
        <IconButton
          active={isFilterActive}
          icon={ICONS.FILTER}
          onClick={() => setVisibleFilterDrawer(true)}
        />
      </TableHeadComponent>

      <ViewSettingsDrawer />

      <FilterDrawer />
    </>
  );
}
