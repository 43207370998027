import React, { useContext, useState, useEffect } from "react";
import Context from "./Context";
import REQUESTS from "../../../../server/requests";

import ICONS from "../../../../config/icons";

import IconButton from "../../../elements/IconButton";
import TableHeadComponent from "../../../elements/TableHeadComponent";
import CsvLinkComponent from "../../../elements/CsvLinkComponent";

import FilterDrawer from "./FilterDrawer";
import ViewSettingsDrawer from "./ViewSettingsDrawer";

export default function TableHead() {
  const {
    setVisibleViewSettingsDrawer,
    setVisibleFilterDrawer,
    selectedCategoryId,
    infoTypeId,
  } = useContext(Context);

  const [isFilterActive, setIsFilterActive] = useState(false);

  const [clickedCsv, setClickedCsv] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (selectedCategoryId || infoTypeId) {
      setIsFilterActive(true);
      return;
    }
    setIsFilterActive(false);
  }, [selectedCategoryId, infoTypeId]);

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);

      function callback(data) {
        const newList = data.map((list) => {
          return {
            // Cover: list.cover,
            Title: list.title,
            Type: list.type,
            // Galleries: list.information_galeries.length,
            Category: list.informations_category?.name,
            Description: list.description.replace(/(<([^>]+)>|&nbsp;)/gi, ""),
            // Data: list.createdAt,
          };
        });
        done(newList);
        setDataList(newList);

        setClickedCsv(false);
      }

      function errorCallback() {
        setClickedCsv(false);
      }

      REQUESTS.INFO_CENTER.INFO.GET({}, callback, errorCallback);
    }
  };
  return (
    <>
      <TableHeadComponent>
        <CsvLinkComponent
          filename="Info-Center-List.csv"
          data={dataList}
          onClick={onClicCsv}
        />
        <IconButton
          icon={ICONS.SETTINGS}
          onClick={() => setVisibleViewSettingsDrawer(true)}
        />
        <IconButton
          active={isFilterActive}
          icon={ICONS.FILTER}
          onClick={() => setVisibleFilterDrawer(true)}
        />
      </TableHeadComponent>

      <ViewSettingsDrawer />
      <FilterDrawer />
    </>
  );
}
