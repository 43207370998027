import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, notification, Select } from "antd";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";

import { useInfoCenterCategoriesOptions } from "../../../../hooks/options";

import Context from "./Context";
import FileChooser from "../../../elements/FileChooser";
import Translation from "../../../elements/Translation";
import TagComponent from "../../../elements/TagComponent";

export default function InfoDrawer() {
  const { visibleDrawer, setVisibleDrawer, currentInfo, setCurrentInfo, getInfoList } =
    useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const categoriesOptions = useInfoCenterCategoriesOptions();

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  const [form] = Form.useForm();

  const [titleTranslations, setTitleTranslations] = useState([]);

  const [descriptionTranslations, setDescriptionTranslations] = useState([]);

  const [tagOptions, setTagOptions] = useState([]);

  const onFinish = (values) => {
    console.log("Success:", values);
    // return;

    setLoading(true);

    setErrorText(null);

    const body = {
      title: values.title,
      description: values.description,
      categori_id: values.categori_id,
      cover: values.cover,
      title: JSON.stringify(titleTranslations || []),
      description: JSON.stringify(descriptionTranslations || []),
      tags: values.tags?.join(","),
    };

    function callback() {
      setLoading(false);
      setVisibleDrawer();
      getInfoList();

      form.resetFields();
      setCurrentInfo(null);
      setImageSrc(null);
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (currentInfo) {
      body.id = currentInfo.id;

      REQUESTS.INFO_CENTER.INFO.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.INFO_CENTER.INFO.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    form.setFields([{ name: "cover", value: item.file_url }]);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "cover", value: null }]);
  };

  const onChangeTitle = (text, id) => {
    const name = {
      text,
      language_id: id,
    };

    const newTranslations = [...titleTranslations];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = name;
    } else {
      newTranslations.push(name);
    }

    setTitleTranslations(newTranslations);
  };

  const onChangeDesctiption = (text, id) => {
    const description = {
      text,
      language_id: id,
    };

    const newTranslations = [...descriptionTranslations];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = description;
    } else {
      newTranslations.push(description);
    }

    setDescriptionTranslations(newTranslations);
  };

  useEffect(() => {
    if (visibleDrawer) {
      if (currentInfo) {
        form.setFields([
          { name: "title", value: currentInfo.title },
          { name: "description", value: currentInfo.description },
          { name: "categori_id", value: currentInfo.categori_id },
          { name: "cover", value: currentInfo.cover },
        ]);

        if (currentInfo.tags) {
          form.setFields([{ name: "tags", value: currentInfo.tags }]);
        }

        setTitleTranslations(currentInfo.title_translations || []);

        setDescriptionTranslations(currentInfo.description_translations || []);

        if (currentInfo.cover) {
          setImageSrc(currentInfo.cover);
        }
      }
    } else {
      setErrorText(null);
      setDescriptionTranslations([]);
      setTitleTranslations([]);
    }
  }, [visibleDrawer]);

  useEffect(() => {
    if (!visibleDrawer && currentInfo) {
      form.resetFields();
      setCurrentInfo(null);
      setImageSrc(null);
    }
  }, [visibleDrawer]);

  return (
    <Drawer
      title={
        currentInfo
          ? translate["Edit Info"] || EN["Edit Info"]
          : translate["Add New Info"] || EN["Add New Info"]
      }
      placement="right"
      width={600}
      onClose={() => () => {
        setVisibleDrawer(false);
        form.resetFields();
        setCurrentInfo(null);
        setImageSrc(null);
      }}
      visible={visibleDrawer}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Image"] || EN["Image"]}
          tooltip={{
            title: "Choose an image",
            icon: ICONS.INFO,
          }}
          name="cover"
          rules={[
            {
              required: currentInfo ? false : true,
              message: `${translate["Please choose"] || EN["Please choose"]} ${
                translate["Image"] || EN["Image"]
              }`,
            },
          ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        {visibleDrawer && (
          <Translation
            render={(item, index) => {
              const title = titleTranslations.find(
                (translation) => translation.language_id === item.id
              )?.text;
              const description = descriptionTranslations.find(
                (translation) => translation.language_id === item.id
              )?.text;

              return (
                <div>
                  <Form.Item
                    label={translate["Title"] || EN["Title"]}
                    tooltip={{
                      title: "Info Title",
                      icon: ICONS.INFO,
                    }}
                    rules={[
                      {
                        required: true,
                        message: `${translate["Please input"] || EN["Please input"]} ${
                          translate["Title"] || EN["Title"]
                        }`,
                      },
                    ]}
                  >
                    <Input
                      value={title}
                      onChange={(e) => onChangeTitle(e.target.value, item.id)}
                    />
                  </Form.Item>

                  <Form.Item
                    label={translate["Description"] || EN["Description"]}
                    tooltip={{
                      title: "Info description",
                      icon: ICONS.INFO,
                    }}
                    rules={[
                      {
                        required: true,
                        message: `${translate["Please input"] || EN["Please input"]} ${
                          translate["Description"] || EN["Description"]
                        }`,
                      },
                    ]}
                  >
                    <Input.TextArea
                      value={description}
                      onChange={(e) => onChangeDesctiption(e.target.value, item.id)}
                      rows={5}
                    />
                  </Form.Item>
                </div>
              );
            }}
          />
        )}
        {/* <Form.Item
          // name="tags"
          label={translate["Tags"] || EN["Tags"]}
          tooltip={{
            title: translate["Choose tags"] || EN["Choose tags"],
            icon: ICONS.INFO,
          }}
        > */}
        <div>
          <TagComponent type={"information"} form={form} />
        </div>
        {/* </Form.Item> */}

        <Form.Item
          label={translate["Category"] || EN["Category"]}
          tooltip={{
            title: "Choose category",
            icon: ICONS.INFO,
          }}
          name="categori_id"
          rules={[
            {
              required: true,
              message: `${translate["Please select"] || EN["Please select"]} ${
                translate["Category"] || EN["Category"]
              }`,
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            showArrow
            allowClear={true}
            options={categoriesOptions}
          />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
