import { useState, useEffect } from "react";
import { Modal, Spin } from "antd";
import QRCodeGenerator from "./QRCodeGenerator";
import REQUESTS from "../../server/requests";

const QRActivateModal = ({ show, onClose, roomId }) => {
  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(false);

  const getAsyncCode = (id) => {
    setLoading(true);
    try {
      const success = (res) => {
        setLoading(false);
        setKey(res.key);
      };

      const error = (err) => {
        setLoading(false);
        console.log(err);
      };

      const body = {
        room_id: id,
      };

      REQUESTS.ROOMS.GENERATE_MOBILE_KEY(body, success, error);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (show) {
      getAsyncCode(roomId);
    }
  }, [roomId, show]);

  useEffect(() => {
    if (!show) {
      setKey("");
    }
  }, [show]);

  return (
    <Modal
      open={show}
      onCancel={() => {
        onClose();
        setKey("");
      }}
      footer={null}
      title={"QR Code"}
      bodyStyle={{ textAlign: "center" }}
    >
      {loading ? <Spin /> : <QRCodeGenerator value={key} />}
    </Modal>
  );
};

export default QRActivateModal;
