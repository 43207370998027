import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "antd";
import EN from "../../../../../config/en";
import Player from "../../../../elements/Player";

export default function PlayMovieDrawer({ visible, movie, onClose }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [url, setUrl] = useState("");

  useEffect(() => {
    if (movie && movie.url) {
      setUrl(movie.url);
    } else {
      setUrl("");
    }
  }, [movie]);
  return (
    <Drawer
      title={translate["Play Movie"] || EN["Play Movie"]}
      placement="top"
      width={500}
      height={1000}
      onClose={() => {
        onClose();
        setUrl("");
      }}
      visible={visible}
      destroyOnClose={true}
    >
      <Player url={url} />
    </Drawer>
  );
}
