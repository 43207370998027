import { useSelector } from "react-redux";
import EN from "../../../../../../config/en";
import IMAGES from "../../../../../../config/images";
import ImageComponent from "../../../../../elements/ImageComponent";

export default function TmdbMovie({ item, onClick }) {

    const translate = useSelector((state) => state.globalState.translate);

    return (
        <div className="tmbd-movie" onClick={() => onClick(item)}>
            <ImageComponent
                src={item.poster}
                onError={(e) => (e.target.src = IMAGES.MOVIE)}
                onClick={(e) => e.stopPropagation()}
            />

            <div className="info">
                <p className="name">{item.title}</p>
                <p className="type">
                    {translate['Type'] || EN['Type']}: {item.type}
                </p>
                <p className="date">
                    {translate['Date'] || EN['Date']}: {item.date}
                </p> 
            </div>
        </div>
    );
}
