import React from "react";
import { useSelector } from "react-redux";
import { Card } from "antd";
import ICONS from "../../../config/icons";
import EN from "../../../config/en";

export default React.memo(function ({ item }) {

   const translate = useSelector((state) => state.globalState.translate);

    return (
        <Card
            className="monitoring-item"
            style={{ borderColor: item.is_online ? "#14746f" : "#ced4da" }}
            hoverable
        >
            <Card.Meta
                title={
                    <div className="icon-name">
                        {ICONS.TV_APP}
                        <p className="text-ellipsis">{item.name}</p>
                    </div>
                }
                description={
                    <div className="monitoring-info">
                        {item.room ? (
                            <p className="text-ellipsis">{`${item.room?.location_name} (${item.room?.guest?.name})`}</p>
                        ) : (
                            <p className="text-ellipsis">{translate['No guest'] || EN['No guest']}</p>
                        )}

                        <div>
                            <b>Device id:</b>
                            <span>{item.device_id}</span>
                        </div>

                        <div>
                            <b>Device ip:</b>
                            <span>{item.ip}</span>
                        </div>

                        <div>
                            <b>Device model:</b>
                            <span>{item.model}</span>
                        </div>
                    </div>
                }
            />
        </Card>
    );
});
