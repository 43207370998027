import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Input, Switch } from "antd";

import Context from "./Context";

import REQUESTS from "../../../../../server/requests";

import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";
import { dateFormat } from "../../../../../config/formats";
import confirmModal from "../../../../../config/confirmModal";
import TableButtons from "../../../../elements/TableButtons";
import LongText from "../../../../elements/LongText";
import Page from "../../../../elements/Page";

import EpgLinksHeader from "./EpgLinksHeader";
import EpgLinksDrawer from "./EpgLinksDrawer";
import TableHead from "./TableHead";
import LOCAL_STORAGE_VALUES from "../../../../../config/localStorageValues";

export default function EpgLinksPage() {
    const translate = useSelector((state) => state.globalState.translate);

    const [loading, setLoading] = useState(false);
    const [epgLinksList, setEpgLinksList] = useState([]);

    const [sort, setSort] = useState(["id", "DESC"]);
    const [nameSearch, setNameSearch] = useState(null);

    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [currentEpgLinks, setCurrentEpgLinks] = useState(null);

    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);
    const [visibleViewSettingsDrawer, setVisibleViewSettingsDrawer] = useState(false);

    const [selectedStatus, setSelectedStatus] = useState(null);

    const [columns, setColumns] = useState([]);

    const [viewSettingsValues, setViewSettingsValues] = useState({
        id: true,
        enable: true,
        name: true,
        url: true,
        createdAt: true,
        action: true,
    });

    useEffect(() => {
        let EPG_LINKS_COLUMNS = localStorage.getItem(LOCAL_STORAGE_VALUES.EPG_LINKS_COLUMNS);

        if (EPG_LINKS_COLUMNS) {
            EPG_LINKS_COLUMNS = JSON.parse(EPG_LINKS_COLUMNS);
            setViewSettingsValues(EPG_LINKS_COLUMNS);
        }
    }, []);

    const configureTableColumns = () => {
        const newColumns = [];

        newColumns.push({
            title: "#",
            dataIndex: "id",
            align: "center",
            fixed: "left",
            key: "id",
            render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
        });

        if (viewSettingsValues.enable) {
            newColumns.push({
                width: 100,
                title: translate["Enable"] || EN["Enable"],
                dataIndex: "enable",
                key: "enable",
                sorter: true,
                render: (text, record, index) => (
                    <p className={{ textAlign: "center" }}>
                        <Switch
                            defaultChecked={record.enable}
                            onChange={(e) => onChangeEnable(e, record)}
                        />
                    </p>
                ),
            });
        }

        if (viewSettingsValues.name) {
            newColumns.push({
                title: translate["Name"] || EN["Name"],
                dataIndex: "name",
                key: "name",
                sorter: true,
                render: (text, record, index) => <p>{text}</p>,
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
                    return (
                        <Input
                            allowClear
                            autoFocus
                            placeholder="Type text here"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    );
                },
                filterIcon: () => {
                    return <>{ICONS.SEARCHOUTLINED}</>;
                },
                onFilter: (value, record) => {
                    return record.name.toLowerCase().includes(value.toLowerCase());
                },
            });
        }

        if (viewSettingsValues.url) {
            newColumns.push({
                title: translate["Url"] || EN["Url"],
                dataIndex: "url",
                width: 250,
                key: "url",
                sorter: true,
                render: (text, record, index) => <LongText width={250}>{text}</LongText>,
            });
        }

        if (viewSettingsValues.createdAt) {
            newColumns.push({
                key: "createdAt",
                sorter: true,
                title: translate["Date"] || EN["Date"],
                dataIndex: "createdAt",
                render: (text, record, index) => dateFormat(text),
            });
        }

        newColumns.push({
            title: "",
            dataIndex: "action",
            align: "center",
            fixed: "right",
            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    style={{ minWidth: 130 }}
                    buttons={[
                        { key: "edit", text: translate["Edit"] || EN["Edit"], icon: ICONS.EDIT },
                        {
                            key: "delete",
                            text: translate["Delete"] || EN["Delete"],
                            icon: ICONS.DELETE,
                        },
                    ]}
                />
            ),
        });

        setColumns(newColumns);
    };

    const handleMenuClick = (e, item) => {
        switch (e.key) {
            case "edit":
                setCurrentEpgLinks(item);
                setVisibleDrawer(true);
                break;

            case "delete":
                confirmModal({
                    title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
                        item.name
                    }`,
                    action: () => deleteItem(item),
                });
                break;

            default:
                break;
        }
    };

    const deleteItem = (item) => {
        REQUESTS.EPG.DELETE(item.id, getEpgLinksList);
    };

    const onChangeEnable = (checked, selected) => {
        let body = {
            enable: checked,
            id: selected.id,
        };

        function callback() {
            getEpgLinksList();
        }

        function errorCallback(err) {}

        REQUESTS.EPG.ENABLE(body, callback, errorCallback);
    };

    const getEpgLinksList = () => {
        setLoading(true);

        const query = {
            sort,
            limit,
            pagination: 1,
            page: currentPage,
        };

        if (nameSearch) {
            query.search = JSON.stringify({ name: nameSearch });
        }

        if (selectedStatus !== null) {
            query.filter = JSON.stringify({ enable: selectedStatus });
        }

        function callback(data) {
            setEpgLinksList(data.rows);
            setLimit(data.limit);
            setTotal(data.count);
            setLoading(false);

            if (data.rows.length === 0 && currentPage !== 1) {
                setCurrentPage((current) => current - 1);
            } else {
                setCurrentPage(data.currentPage);
            }
        }

        function errorCallback() {
            setLoading(false);
        }

        REQUESTS.EPG.GET(query, callback, errorCallback);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }
        if (Object.values(filters)[0]) {
            setNameSearch(Object.values(filters)[0].join(""));
        } else {
            setNameSearch(null);
        }

        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    useEffect(() => {
        if (!visibleDrawer) {
            setCurrentEpgLinks(null);
        }
    }, [visibleDrawer]);

    useEffect(() => {
        getEpgLinksList();
    }, [currentPage, limit, sort, selectedStatus]);

    useEffect(() => {
        const searchTimeout = setTimeout(() => {
            getEpgLinksList();
        }, 1000);

        return () => {
            clearTimeout(searchTimeout);
        };
    }, [nameSearch]);

    useEffect(() => {
        configureTableColumns();
    }, [viewSettingsValues, currentPage]);

    return (
        <Context.Provider
            value={{
                total,
                visibleDrawer,
                setVisibleDrawer,
                currentEpgLinks,
                getEpgLinksList,
                visibleViewSettingsDrawer,
                setVisibleViewSettingsDrawer,
                visibleFilterDrawer,
                setVisibleFilterDrawer,
                viewSettingsValues,
                setViewSettingsValues,
                selectedStatus,
                setSelectedStatus,
            }}
        >
            <Page>
                <EpgLinksHeader />

                <div className="page-body">
                    <TableHead />

                    <Table
                        rowKey="id"
                        loading={loading}
                        columns={columns}
                        dataSource={epgLinksList}
                        onChange={handleTableChange}
                        size="small"
                        pagination={{
                            position: ["topCenter", "bottomCenter"],
                            current: currentPage,
                            total: total,
                            showSizeChanger: true,
                        }}
                        sticky={{
                            offsetHeader: 60,
                            offsetScroll: 0,
                        }}
                    />

                    <EpgLinksDrawer />
                </div>
            </Page>
        </Context.Provider>
    );
}
