import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, notification, Select } from "antd";
import Context from "./Context";
import REQUESTS from "../../../../server/requests";

import { usePackagesOptions } from "../../../../hooks/options";

import EN from "../../../../config/en";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";

export default function AttachPackageDrawer() {
  const { visibleAttachDrawer, setVisibleAttachDrawer, currentAttach, getGroupsList } =
    useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);
  const packagesOptions = usePackagesOptions();

  useEffect(() => {
    if (visibleAttachDrawer) {
      form.resetFields();
    } else {
      setErrorText(null);
    }
  }, [visibleAttachDrawer]);

  useEffect(() => {
    if (visibleAttachDrawer) {
      if (currentAttach.group_package_id) {
        form.setFields([
          { name: "group_package_id", value: currentAttach.group_package_id },
        ]);
      }
    }
  }, [currentAttach]);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      group_id: currentAttach.id,
      group_package_id: values.group_package_id,
    };

    function callback() {
      setLoading(false);
      setVisibleAttachDrawer(false);
      getGroupsList();
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    REQUESTS.ROOMS.PACKAGES.ADD(body, callback, errorCallback);
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  return (
    <Drawer
      title={translate["Add Package"] || EN["Add Package"]}
      placement="right"
      width={400}
      onClose={() => {
        setVisibleAttachDrawer(false);
        form.resetFields();
      }}
      visible={visibleAttachDrawer}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="groups-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Package"] || EN["Package"]}
          name="group_package_id"
          rules={[
            {
              required: true,
              message: `${translate["Please select"] || EN["Please select"]} ${
                translate["Package"] || EN["Package"]
              }`,
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            showArrow
            allowClear={true}
            options={packagesOptions}
          />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
