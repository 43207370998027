import React from "react";

export default function HeaderItem({ title, total }) {
   return (
      <div className="header-item">
         <h3>{title}</h3>
         <p>{total}</p>
      </div>
   );
}
