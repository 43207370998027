import React from 'react';
import {useSelector} from "react-redux";
import { Result, Button } from 'antd';
import { useNavigate } from "react-router-dom";
import EN from '../../config/en';

export default function NoFound (){

   const translate = useSelector((state) => state.globalState.translate);

   const navigate = useNavigate();

   return(
      <Result
         status="404"
         title="404"
         subTitle={translate['Sorry, the page you visited does not exist'] || EN['Sorry, the page you visited does not exist']}
         extra={<Button type="primary" onClick={() => navigate(-1)}> {translate['Go Back'] || EN['Go Back']} </Button>}
      />
   );
} 