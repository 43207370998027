import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import REQUESTS from "../../../../../server/requests";

import EN from "../../../../../config/en";

import HeaderItem from "../../../../elements/HeaderItem";
import PageHeader from "../../../../elements/PageHeader";
import AddButtonComponent from "../../../../elements/AddButtonComponent";

export default function LiveTVCategoriesHeader({ onClick, total }) {

    const translate = useSelector((state) => state.globalState.translate);

    const [totalValue, setTotalValue] = useState(0);

    useEffect(() => {
        REQUESTS.TV_CHANNELS_CATEGORIES.STATISTICS((data) => {
            setTotalValue(data.count);
        });
    }, [total]);

    return (
        <PageHeader title={translate['Live Tv Categories'] || EN['Live Tv Categories']}>
            <HeaderItem title={translate['Total'] || EN['Total']} total={totalValue} />

            <AddButtonComponent
                text={translate['Add Category']|| EN['Add Category']}
                onClick={onClick}
            />
        </PageHeader>
    );
}
