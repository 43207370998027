import React, { useState } from "react";
import REQUESTS from "../../../../server/requests";

import ICONS from "../../../../config/icons";
import IconButton from "../../../elements/IconButton";
import TableHeadComponent from "../../../elements/TableHeadComponent";
import CsvLinkComponent from "../../../elements/CsvLinkComponent";

import ViewSettingsDrawer from "./ViewSettingsDrawer";

export default function TableHead({ values, setValues }) {
  const [clickedCsv, setClickedCsv] = useState(false);
  const [dataList, setDataList] = useState([]);

  const [isVisibleViewSettingsDrawer, setIsVisibleViewSettingsDrawer] = useState(false);

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv((prev) => !prev);

      function callback(data) {
        const newList = data.map((list) => {
          let packageName;

          if (list.package) {
            packageName = list.package.name;
          } else {
            packageName = "-";
          }
          return {
            City: list.city,
            Email: list.email_address,
            name: list.name,
            Region: list.region,
            Phone: list.phone_number,
            Website: list.website,
          };
        });
        done(newList);
        setDataList(newList);
        setClickedCsv(false);
      }

      function errorCallback() {
        setClickedCsv(false);
      }

      REQUESTS.ORGANIZATIONS.GET({}, callback, errorCallback);
    }
  };

  return (
    <>
      <TableHeadComponent>
        <CsvLinkComponent
          filename="Organization-Companies-List.csv"
          data={dataList}
          onClick={onClicCsv}
        />
        <IconButton
          icon={ICONS.SETTINGS}
          onClick={() => setIsVisibleViewSettingsDrawer(true)}
        />
        {/* <IconButton
                    active={isFilterActive}
                    icon={ICONS.FILTER}
                    //   onClick={() => setVisibleFilterDrawer(true)}
                /> */}
      </TableHeadComponent>

      <ViewSettingsDrawer
        values={values}
        setValues={setValues}
        visible={isVisibleViewSettingsDrawer}
        onClose={() => setIsVisibleViewSettingsDrawer(false)}
      />

      {/* <FilterDrawer /> */}
    </>
  );
}
