import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, Input, notification, Select, Switch } from "antd";

import REQUESTS from "../../../../server/requests";

import ICONS from "../../../../config/icons";
import EN from "../../../../config/en";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";
import { useOrganizationsOptions } from "../../../../hooks/options";
import FileChooser from "../../../elements/FileChooser";

export default function LanguageDrawer({ visible, onClose, current, getData }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  const organizationsOptions = useOrganizationsOptions();

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();

      if (current) {
        form.setFields([
          { name: "name", value: current.name },
          { name: "iso_code", value: current.iso_code },
          { name: "organization_id", value: current.organization_id },
          { name: "icon", value: current.icon },
          { name: "is_active", value: current.is_active },
        ]);

        setImageSrc(current.icon);
      }
    } else {
      setErrorText(null);
      setImageSrc(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      name: values.name,
      organization_id: values.organization_id,
      iso_code: values.iso_code,
      icon: values.icon,
      is_active: values.is_active,
    };

    function callback() {
      setLoading(false);
      onClose();
      getData();
      form.resetFields();
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (current) {
      body.id = current.id;
      REQUESTS.LANGUAGES.CHANGE(body, callback, errorCallback);
    } else {
      REQUESTS.LANGUAGES.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    form.setFields([{ name: "icon", value: item.file_url }]);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "icon", value: null }]);
  };

  return (
    <Drawer
      title={
        current
          ? translate["Edit Language"] || EN["Edit Language"]
          : translate["Add Language"] || EN["Add Language"]
      }
      placement="right"
      width={600}
      visible={visible}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="gui-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          is_active: true,
        }}
      >
        <Form.Item
          label={translate["Image"] || EN["Image"]}
          tooltip={{
            title: "Choose an image",
            icon: ICONS.INFO,
          }}
          rules={[
            {
              required: true,
              message: `${translate["Please select"] || EN["Please select"]} ${
                translate["Image"] || EN["Image"]
              }`,
            },
          ]}
          name="icon"
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        <Form.Item
          label={translate["Enabled"] || EN["Enabled"]}
          name="is_active"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label={translate["Name"] || EN["Name"]}
          tooltip={{
            title: "Language name",
            icon: ICONS.INFO,
          }}
          name="name"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Name"] || EN["Name"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Iso code"] || EN["Iso code"]}
          tooltip={{
            title: "Iso code",
            icon: ICONS.INFO,
          }}
          name="iso_code"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Iso code"] || EN["Iso code"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Organization"] || EN["Organization"]}
          tooltip={{
            title: "Organization name",
            icon: ICONS.INFO,
          }}
          name="organization_id"
          rules={[
            {
              required: true,
              message: `${translate["Please select"] || EN["Please select"]} ${
                translate["Organization"] || EN["Organization"]
              }`,
            },
          ]}
        >
          <Select
            showArrow
            options={organizationsOptions}
            placeholder={translate["Select Organization"] || EN["Select Organization"]}
          />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>
      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
