import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import REQUESTS from "../../../server/requests";

import EN from "../../../config/en";

import HeaderItem from "../../elements/HeaderItem";
import PageHeader from "../../elements/PageHeader";

export default function Header (){

   const translate = useSelector((state) => state.globalState.translate);

   const [statistics, setStatistics] = useState({
      devices: 0,
      offline: 0,
      online: 0
   });

   useEffect(() => {
      REQUESTS.MONITORING.ONLINE_DEVICES({}, (data) => {
         setStatistics(data.statistics);
      });
   }, []);

   return(
      <PageHeader  title={translate['Monitoring'] || EN['Monitoring']}>
         <div className="info-section">
            <HeaderItem title={translate['Device'] || EN['Device']} total={statistics.devices} />
            <HeaderItem title={translate['Offline'] || EN['Offline']} total={statistics.offline} />
            <HeaderItem title={translate['Online']  || EN['Online']}  total={statistics.online} />
         </div>
      </PageHeader>
   )
}