import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Input, Tag } from "antd";

import ProductContext from "./ProductContext";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";
import { dateFormat } from "../../../../config/formats";
import confirmModal from "../../../../config/confirmModal";

import ImageComponent from "../../../elements/ImageComponent";
import TableButtons from "../../../elements/TableButtons";
import LongText from "../../../elements/LongText";
import Page from "../../../elements/Page";
import Check from "../../../elements/Check";

import TableHead from "./TableHead";
import FilterDrawer from "./FilterDrawer";
import ProductDrawer from "./ProductDrawer";
import ProductsHeader from "./ProductsHeader";
import ViewSettingsDrawer from "./ViewSettingsDrawer";
import Currency from "../../../elements/Currency";

export default function ProductsPage({}) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [productsList, setProductsList] = useState([]);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [nameSearch, setNameSearch] = useState(null);
  const [categorySearch, setCategorySearch] = useState("");

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentProduct, setCurrentProduct] = useState(null);

  const [selectedCategoryid, setSelectedCategoryid] = useState(null);

  const [selectedPublishedStatus, setSelectedPublishedStatus] = useState(null);

  const [visibleProductDrawer, setVisibleProductDrawer] = useState(false);

  const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);

  const [visibleViewSettingsDrawer, setVisibleViewSettingsDrawer] = useState(false);

  const [viewSettingsValues, setViewSettingsValues] = useState({
    id: true,
    image: true,
    name: true,
    price: true,
    e_commercial_catagory: true,
    description: true,
    attach_slider: true,
    createdAt: true,
    action: true,
  });

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (!visibleProductDrawer) {
      setCurrentProduct(null);
    }
  }, [visibleProductDrawer]);

  useEffect(() => {
    getProductsList();
  }, [currentPage, limit, sort, selectedCategoryid, selectedPublishedStatus]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getProductsList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [nameSearch]);

  useEffect(() => {
    let timeout = null;
    if (categorySearch[0]) {
      if (categorySearch[0]?.length > 2) {
        timeout = setTimeout(() => {
          getProductsList();
        }, 1000);
      }
    } else {
      getProductsList();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [categorySearch]);

  useEffect(() => {
    configureTableColumns();
  }, [viewSettingsValues, currentPage]);

  useEffect(() => {
    let PRODUCTS_COLUMNS = localStorage.getItem("PRODUCTS_COLUMNS");

    if (PRODUCTS_COLUMNS) {
      PRODUCTS_COLUMNS = JSON.parse(PRODUCTS_COLUMNS);
      setViewSettingsValues(PRODUCTS_COLUMNS);
    }
  }, []);

  const configureTableColumns = () => {
    const newColumns = [];

    newColumns.push({
      title: "#",
      dataIndex: "id",
      fixed: "left",
      width: 50,
      key: "id",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    });

    if (viewSettingsValues.image) {
      newColumns.push({
        title: translate["Image"] || EN["Image"],
        dataIndex: "image",
        key: "image",
        render: (text, record, index) => (
          <ImageComponent src={text} className="tableImage" />
        ),
      });
    }

    if (viewSettingsValues.name) {
      newColumns.push({
        title: translate["Name"] || EN["Name"],
        dataIndex: "name",
        key: "name",
        sorter: true,

        render: (text, record, index) => {
          let title = "";

          const translations = record?.name_translations;

          for (let i = 0; i < translations.length; i++) {
            if (translations[i].is_default) {
              title = translations[i].text;
              break;
            }

            if (translations[i].text) title = translations[i].text;
          }

          return <p className="text-ellipsis">{title || "N/A"}</p>;
        },

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <>
              <Input
                allowClear
                autoFocus
                placeholder="Type text here"
                value={selectedKeys[0]}
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                  confirm({ closeDropdown: false });
                }}
                onPressEnter={() => {
                  confirm();
                }}
                onBlur={() => {
                  confirm();
                }}
              />
            </>
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.e_commercial_catagory) {
      newColumns.push({
        title: translate["Category"] || EN["Category"],
        dataIndex: "e_commercial_catagory",
        key: "e_commercial_catagory",
        // width: 170,
        render: (text, record, index) => (
          <p className="txt-tb-l">
            {record.e_commercial_catagory ? record.e_commercial_catagory.name : "-"}{" "}
          </p>
        ),
        filterDropdown: ({ confirm }) => {
          return (
            <>
              <Input
                allowClear
                autoFocus
                placeholder="Type text here"
                value={categorySearch[0]}
                onChange={(e) => {
                  setCategorySearch(e.target.value ? [e.target.value] : []);
                  confirm({ closeDropdown: false });
                }}
                onPressEnter={() => {
                  confirm();
                }}
                onBlur={() => {
                  confirm();
                }}
              />
            </>
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.e_commercial_catagory?.name
            .toLowerCase()
            .includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.description) {
      newColumns.push({
        title: translate["Description"] || EN["Description"],
        dataIndex: "description",
        key: "description",
        sorter: true,
        align: "center",
        ellipsis: true,
        // width: 200,
        render: (text, record, index) => {
          let title = "";

          const translations = record?.description_translations;
          for (let i = 0; i < translations.length; i++) {
            if (translations[i].is_default) {
              title = translations[i].text;
              break;
            }

            if (translations[i].text) title = translations[i].text;
          }

          return <LongText>{title || "N/A"}</LongText>;
        },
      });
    }

    newColumns.push({
      title: translate["Tags"] || EN["Tags"],
      dataIndex: "tags",
      key: "tags",
      // sorter: true,
      align: "center",
      width: 200,
      render: (text, record, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
            }}
          >
            {text?.map((tag) => {
              return (
                <Tag key={tag} color="blue">
                  {tag ? tag : "N/A"}
                </Tag>
              );
            })}
          </div>
        );
      },
    });

    if (viewSettingsValues.attach_slider) {
      newColumns.push({
        title: translate["Attach Slider"] || EN["Attach Slider"],
        dataIndex: "attach_slider",
        key: "attach_slider",
        sorter: true,
        align: "center",
        // width: 200,
        render: (text, record, index) => {
          <Check check={record.attach_slider} />;
        },
      });
    }

    if (viewSettingsValues.price) {
      newColumns.push({
        title: translate["Price"] || EN["Price"],
        dataIndex: "price",
        // width: 120,
        key: "price",
        sorter: true,
        render: (text, record, index) => (
          <Currency>{parseFloat(record.price).toFixed(2)}</Currency>
        ),
      });
    }

    if (viewSettingsValues.createdAt) {
      newColumns.push({
        title: translate["Date"] || EN["Date"],
        // width: 120,
        dataIndex: "createdAt",
        key: "createdAt",
        align: "center",
        sorter: true,
        render: (text, record, index) => dateFormat(text),
      });
    }

    newColumns.push({
      title: "",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      align: "center",
      // width: 100,
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={[
            {
              key: "edit",
              text: translate["Edit"] || EN["Edit"],
              icon: ICONS.EDIT,
            },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE,
            },
          ]}
        />
      ),
    });

    setColumns(newColumns);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setCurrentProduct(item);
        setVisibleProductDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${translate["Do you want to delete"] || EN["Do you want to delete"]}  ${
            item.name
          }`,
          action: () => deleteProduct(item),
        });
        break;

      default:
        break;
    }
  };

  const deleteProduct = (item) => {
    REQUESTS.ECOMMERCE.PRODUCTS.DELETE(item.id, getProductsList);
  };

  const getProductsList = () => {
    setLoading(true);

    const query = {
      sort,
      pagination: 1,
      limit,
      page: currentPage,
    };

    if (nameSearch) {
      query.search = JSON.stringify({ name: nameSearch });
    }

    if (categorySearch[0]) {
      query.category_name = categorySearch[0];
    }

    if (selectedCategoryid) {
      query.filter = JSON.stringify({
        category_id: selectedCategoryid,
      });
    }

    if (selectedPublishedStatus != null) {
      query.filter = JSON.stringify({
        attach_slider: selectedPublishedStatus,
      });
    }

    function callback(data) {
      setProductsList(data.rows);
      setLimit(data.limit);
      setTotal(data.count);
      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.ECOMMERCE.PRODUCTS.GET(query, callback, errorCallback);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    if (Object.values(filters)[0]) {
      setNameSearch(Object.values(filters)[0].join(""));
    } else {
      setNameSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  return (
    <ProductContext.Provider
      value={{
        total,
        visibleProductDrawer,
        setVisibleProductDrawer,
        visibleViewSettingsDrawer,
        setVisibleViewSettingsDrawer,
        viewSettingsValues,
        setViewSettingsValues,
        currentProduct,
        getProductsList,
        visibleFilterDrawer,
        setVisibleFilterDrawer,
        selectedCategoryid,
        setSelectedCategoryid,
        selectedPublishedStatus,
        setSelectedPublishedStatus,
      }}
    >
      <Page>
        <ProductsHeader />

        <div className="page-body">
          <TableHead />

          <Table
            rowKey="id"
            loading={loading}
            columns={columns}
            dataSource={productsList}
            onChange={handleTableChange}
            scroll={{ x: 1500 }}
            pagination={{
              position: ["topCenter", "bottomCenter"],
              current: currentPage,
              total: total,
              showSizeChanger: true,
            }}
            // sticky={{
            //   offsetHeader: 160,

            //   offsetScroll: 0,
            // }}
          />
        </div>

        <ProductDrawer />

        <FilterDrawer />

        <ViewSettingsDrawer />
      </Page>
    </ProductContext.Provider>
  );
}
