import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ICONS from "../../config/icons";
import { Button } from "antd";
import EN from "../../config/en";

/**
 * @param {node} icon -> icons.js
 * @param {string} type
 * @param {boolean} loading
 * @param {string} buttonClass -> button-classes.js
 * @param {string} text
 * @param {boolean} disabled
 * @param {func} onClick.isRequired
 *
 * @returns
 */

export default function SaveButtonComponent({
    type,
    onClick,
    block,
    buttonClass,
    loading,
    style,
    disabled,
}) {

   const translate = useSelector((state) => state.globalState.translate);

    return (
        <Button
            disabled={disabled}
            loading={loading}
            block={block}
            type={type}
            className={`button-component ${buttonClass}`}
            onClick={onClick}
            style={style}
        >
            {translate['Save'] || EN['Save']}
            {ICONS.CHECK}
        </Button>
    );
}

SaveButtonComponent.propTypes = {
    type: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    buttonClass: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};
