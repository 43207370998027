import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip, Modal } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";

import ICONS from "../../config/icons";
import confirmModal from "../../config/confirmModal";
import EN from "../../config/en";

import ImageComponent from "./ImageComponent";

export default function GalleryComponent({ item, deleteGallery }) {
  const [visibleVideo, setVisibleVideo] = useState(false);
  const [currentVideoSrc, setCurrentVideoSrc] = useState(null);

  const translate = useSelector((state) => state.globalState.translate);

  const openVideo = () => {
    if (document.getElementById("galleryVideoId")) {
      document.getElementById("galleryVideoId").src = item.file;
    }
    setVisibleVideo(true);
    setCurrentVideoSrc(item.file);
  };

  const closeVideo = () => {
    if (document.getElementById("galleryVideoId")) {
      document.getElementById("galleryVideoId").src = "";
    }

    setVisibleVideo(false);
    setCurrentVideoSrc(null);
  };

  const onClickDelete = () => {
    confirmModal({
      title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
        translate["Gallery"] || EN["Gallery"]
      }`,
      action: () => deleteGallery(item),
    });
  };

  const renderComponent = () => {
    switch (item.file_type) {
      case "image":
        return <ImageComponent src={item.file} className="image-gallery" />;
      case "video":
        return (
          <>
            <div className="video-gallery" onClick={openVideo}>
              <PlayCircleOutlined />
            </div>

            <Modal
              title={translate["Video"] || EN["Video"]}
              visible={visibleVideo}
              footer={null}
              onCancel={closeVideo}
            >
              {currentVideoSrc && (
                <video
                  id="galleryVideoId"
                  src={currentVideoSrc}
                  controls
                  style={{ width: "100%", height: "100%" }}
                />
              )}
            </Modal>
          </>
        );

      default:
        break;
    }
  };

  return (
    <div className="gallery-style">
      {renderComponent()}

      <div className="gallery-description">
        <Tooltip title={item.description}>
          <span>{item.description}</span>
        </Tooltip>
      </div>

      <div className="gallery-remove" onClick={onClickDelete}>
        {ICONS.X}
      </div>
    </div>
  );
}
