import React, { useEffect, useState } from "react";
import { Checkbox, Drawer, Form, message } from "antd";
import ICONS from "../../../config/icons";
import SaveButtonComponent from "../../elements/SaveButtonComponent";
import REQUESTS from "../../../server/requests";

export default function SettingsTvChannelsDrawer({ visible, onClose }) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    function callback() {
      onClose();
      message.success("Your changes have been saved successfully");
    }

    function errorCallback(err) {
      setLoading(false);
      message.error(err.message);
    }

    REQUESTS.TV_CHANNELS_SETTINGS.EDIT(values, callback, errorCallback);
  };

  const getSettings = () => {
    REQUESTS.TV_CHANNELS_SETTINGS.GET((res) => {
      form.setFields([
        {
          name: "coaxial",
          value: res?.coaxial,
        },

        {
          name: "iptv",
          value: res?.iptv,
        },
      ]);
    });
  };

  useEffect(() => {
    if (visible) {
      getSettings();
    } else {
      setLoading(false);
    }
  }, [visible]);

  return (
    <div>
      <Drawer
        title="Manage TV Channels"
        placement="right"
        width={400}
        onClose={() => {
          onClose();
          form.resetFields();
        }}
        visible={visible}
        destroyOnClose={true}
      >
        <div className="global_header-title-drawer">
          <div className="title-in-drawer">
            <Form form={form} layout="vertical" name="user-form" onFinish={onFinish}>
              <Form.Item
                name="coaxial"
                valuePropName="checked"
                style={{ marginBottom: 0 }}
              >
                <Checkbox>Coaxial</Checkbox>
              </Form.Item>

              <Form.Item name="iptv" valuePropName="checked">
                <Checkbox>Iptv</Checkbox>
              </Form.Item>

              <Form.Item>
                <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
