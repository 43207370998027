import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Progress } from "antd";

import REQUESTS from "../../../server/requests";

import IMAGES from "../../../config/images";
import NAVIGATORS from "../../../config/navigators";

import "./welcome.scss";
import { APP_VERSION } from "../../../config/config";

let interval = null;

export default function WelcomePage() {
    const navigate = useNavigate();

    const [percent, setPercent] = useState(0);

    useEffect(() => {
        interval = setInterval(() => {
            setPercent((percent) => percent + 1);
        }, [10]);
    }, []);

    const goToDashboardContentPage = () => {
        navigate(NAVIGATORS.DASHBOARD_CONTENT);
    };

    const goToLoginPage = () => {
        navigate(NAVIGATORS.LOGIN);
    };

    useEffect(() => {
        if (percent == 100) {
            clearInterval(interval);

            const TOKEN = localStorage.getItem("TOKEN");
            const REMEMBER_ME = localStorage.getItem("REMEMBER_ME");

            setTimeout(() => {
                if (TOKEN && REMEMBER_ME) {
                    try {
                        REQUESTS.EMPLOYERS.PROFILE.GET(goToDashboardContentPage, goToLoginPage);
                    } catch (error) {
                        goToLoginPage();
                    }
                } else {
                    goToLoginPage();
                }
            }, 100);
        }
    }, [percent]);

    return (
        <div className="welcome-page">
            <img src={IMAGES.BACKGROUND} className="welcome-background" />

            <div className="container">
                <div className="progress-block">
                    <Progress
                        style={{ width: 500 }}
                        strokeColor={{
                            "0%": "#7367C1",
                            "100%": "#4FC2E5",
                        }}
                        showInfo={false}
                        percent={percent}
                    />
                </div>

                <footer>
                    <p>App version: {APP_VERSION}</p>
                </footer>
            </div>
        </div>
    );
}
