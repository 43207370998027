import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Form, Input, Checkbox, notification } from "antd";

import REQUESTS from "../../../server/requests";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";
import IMAGES from "../../../config/images";
import NAVIGATORS from "../../../config/navigators";

import Logo from "../../elements/Logo";
import ErrorMessage from "../../elements/ErrorMessage";
import ButtonComponent from "../../elements/ButtonComponent";

import "./_login_registration.scss";
import { APP_VERSION } from "../../../config/config";

export default function LoginPage() {
  const navigate = useNavigate();

  const translate = useSelector((state) => state.globalState.translate);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      email: values.email,
      password: values.password,
    };

    function callback(data) {
      setLoading(false);

      localStorage.setItem("TOKEN", data);

      localStorage.setItem("REMEMBER_ME", values.remember ? true : false);

      navigate(NAVIGATORS.DASHBOARD_CONTENT);
    }

    function errorCallback(err) {
      setLoading(false);

      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });

      // if (typeof err === "string") {
      //   setErrorText(err);
      // } else if (typeof err.message === "string") {
      //   setErrorText(err.message);
      // }
    }

    REQUESTS.EMPLOYERS.LOGIN(body, callback, errorCallback);
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  const onClickLogin = () => {
    form.submit();
  };

  return (
    <div className="page">
      <div className="image-section">
        <div className="app-version">
          <span>App Version: {APP_VERSION}</span>
        </div>
      </div>

      <div className="form-section">
        <div className="container">
          <Logo showTitle={false} />

          <div className="form-style">
            <h1>{translate["Sign in"] || EN["Sign in"]}</h1>
            <Form
              form={form}
              layout="vertical"
              name="login-form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item label={translate["Email address"] || EN["Email address"]}>
                <Form.Item
                  name="email"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: `${
                        translate["Please input your"] || EN["Please input your"]
                      } ${translate["Email address"] || EN["Email address"]}`,
                    },
                  ]}
                >
                  <Input
                    className="input-style"
                    autoComplete="new-password"
                    onPressEnter={onClickLogin}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item label={translate["Password"] || EN["Password"]}>
                <Form.Item
                  name="password"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: `${
                        translate["Please input your"] || EN["Please input your"]
                      } ${translate["Password"] || EN["Password"]}`,
                    },
                  ]}
                  autoComplete="new-password"
                >
                  <Input.Password
                    className="input-style"
                    autoComplete="new-password"
                    onPressEnter={onClickLogin}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <div className="checkbox-forgotPassword">
                  <Checkbox>{translate["Remember me"] || EN["Remember me"]}</Checkbox>

                  <Link to={NAVIGATORS.RESET_PASSWORD}>
                    {translate["Forgot password"] || EN["Forgot password"]}
                  </Link>
                </div>
              </Form.Item>

              <Form.Item>
                <ButtonComponent
                  loading={loading}
                  text={translate["Log in"] || EN["Log in"]}
                  icon={ICONS.ARROW_RIGHT_OUTLINED}
                  onClick={onClickLogin}
                />
              </Form.Item>
            </Form>

            {/* <ErrorMessage>{errorText}</ErrorMessage> */}
          </div>
        </div>
      </div>
    </div>
  );
}
