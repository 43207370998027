import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import Context from "./Context";

import REQUESTS from "../../../../../server/requests";

import EN from "../../../../../config/en";

import HeaderItem from "../../../../elements/HeaderItem";
import PageHeader from "../../../../elements/PageHeader";
import AddButtonComponent from "../../../../elements/AddButtonComponent";

export default function EpgLinksHeader() {
  const { total, setVisibleDrawer } = useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const [totalValue, setTotalValue] = useState(0);

  useEffect(() => {
    REQUESTS.EPG.STATISTICS((data) => {
      setTotalValue(data.count);
    });
  }, [total]);

  return (
    <PageHeader title={translate["Epg Links"] || EN["Epg Links"]}>
      <HeaderItem title={translate["Total"] || EN["Total"]} total={totalValue} />

      <AddButtonComponent
        text={translate["Add Epg Link"] || EN["Add Epg Link"]}
        onClick={() => setVisibleDrawer(true)}
      />
    </PageHeader>
  );
}
