import { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import REQUESTS from "../../../server/requests";

import EN from "../../../config/en";
import confirmModal from "../../../config/confirmModal";

import { Table, Button, Drawer, notification } from "antd";
import ICONS from "../../../config/icons";
import { actions } from "../../../store";

export default function TranslationDrawer({ current, onClose }) {
  const dispatch = useDispatch();

  const translate = useSelector((state) => state.globalState.translate);

  const { setGetTranslation } = bindActionCreators(actions, dispatch);

  const [words, setWords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentWord, setCurrentWord] = useState(null);
  const [newWord, setNewWord] = useState({ key: "", value: "" });

  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      align: "center",
      key: "key",
      render: (text, record, index) => {
        return (
          <input
            defaultValue={record.key}
            className="editInput"
            onBlur={() => save(record, "key")}
            onClick={() => onClickWord(record)}
            onChange={(e) => onChangeValue(e, index, "key")}
            // onKeyDown={onEnterSend}
          />
        );
      },
    },

    {
      title: "Value",
      dataIndex: "value",
      align: "center",
      key: "value",
      render: (text, record, index) => (
        <input
          defaultValue={record.value}
          className="editInput"
          onBlur={() => save(record, "value")}
          onClick={() => onClickWord(record)}
          onChange={(e) => onChangeValue(e, index, "value")}
          disabled={record.key.length === 0 ? true : false}
        />
      ),
    },

    {
      title: "",
      dataIndex: "action",
      fixed: "right",
      align: "center",
      render: (text, record, index) => (
        <Button icon={ICONS.DELETE} onClick={() => deleteWord(record)} />
      ),
    },
  ];

  const onClickWord = (item) => {
    const word = { ...item };
    setCurrentWord(word);
  };

  const onChangeValue = (event, index, type) => {
    words[index][type] = event.target.value;
  };

  const onChangeNewWord = (event, type) => {
    setNewWord((prev) => ({
      ...prev,
      [type]: event.target.value,
    }));
  };

  const save = (item, type) => {
    setTimeout(() => {
      if (currentWord) {
        function callback() {
          setLoading(false);
          setGetTranslation();

          const changedWord = { ...currentWord };

          notification.success({
            message: (
              <div>
                <span style={{ color: "red" }}>{changedWord[type]}</span>
                <span style={{ marginLeft: 5, marginRight: 5 }}>changed to</span>
                <span style={{ color: "green" }}>{item[type]}</span>
              </div>
            ),
            description: ` `,
            style: {
              width: "max-content",
            },
          });
        }

        function errorCallback(err) {
          setLoading(false);
        }

        if (type === "key") {
          if (item.key !== currentWord.key) {
            setLoading(true);

            const body = {
              key: currentWord.key,
              new_key: item.key,
            };

            REQUESTS.TRANSLATIONS.UPDATE_KEY(body, callback, errorCallback);
          }
        } else {
          if (item.value !== currentWord.value) {
            setLoading(true);

            const body = {
              languages_id: current.id,
              value: item.value,
              key: item.key,
            };

            REQUESTS.TRANSLATIONS.UPDATE_VALUE(body, callback, errorCallback);
          }
        }
      }
    }, 500);
  };

  const deleteWord = (item) => {
    function callback() {
      getWords();
    }

    function errorCallback() {
      setLoading(false);
    }

    confirmModal({
      title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} key: ${
        item.key
      }, value: ${item.value} `,
      action: () => {
        setLoading(true);
        REQUESTS.TRANSLATIONS.DELETE(item.key, callback, errorCallback);
      },
    });
  };

  const addNewWord = () => {
    function callback() {
      setNewWord({
        key: "",
        value: "",
      });

      getWords();
    }

    function errorCallback(err) {
      console.log(err);
    }

    const body = {
      languages_id: current?.id,
      key: newWord.key,
      value: newWord.value,
    };

    REQUESTS.TRANSLATIONS.ADD(body, callback, errorCallback);
  };

  const getWords = () => {
    if (current) {
      setLoading(true);
      function callback(data) {
        let arr = [];

        const translations = data.translations || {};

        for (let i in translations) {
          arr.unshift({ key: i, value: translations[i] });
        }

        setWords(arr);
        setLoading(false);
        setGetTranslation();
      }

      function errorCallback() {
        setLoading(false);
      }

      REQUESTS.TRANSLATIONS.GET({ languages_id: current.id }, callback, errorCallback);
    }
  };

  useEffect(() => {
    if (current) {
      getWords();
    } else {
      setWords([]);
      setNewWord({
        key: "",
        value: "",
      });
    }
  }, [current]);

  return (
    <Drawer
      title={`Word for translation into ${current?.name}`}
      placement="right"
      width={600}
      onClose={onClose}
      visible={current ? true : false}
      destroyOnClose={true}
    >
      <Table
        rowKey="key"
        rowClassName={() => "editable-row"}
        title={() => (
          <div className="new-word-section">
            <input
              value={newWord.key}
              placeholder="Enter key"
              onChange={(e) => onChangeNewWord(e, "key")}
            />

            <input
              value={newWord.value}
              placeholder="Enter value"
              onChange={(e) => onChangeNewWord(e, "value")}
              disabled={newWord.key.length === 0 ? true : false}
            />

            <Button
              onClick={addNewWord}
              type="primary"
              icon={ICONS.PLUS}
              disabled={newWord.key.length === 0 ? true : false}
            />
          </div>
        )}
        bordered
        loading={loading}
        columns={columns}
        dataSource={words}
        size="small"
        pagination={false}
      />
    </Drawer>
  );
}
