import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Checkbox, notification } from "antd";

import REQUESTS from "../../../server/requests";

import EN from "../../../config/en";

import ErrorMessage from "../../elements/ErrorMessage";

import SaveButtonComponent from "../../elements/SaveButtonComponent";

export default function WetherDrawer({
  visible,
  onClose,
  tvApplicationConfig,
  getConfig,
}) {
  const translate = useSelector((state) => state.globalState.translate);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (tvApplicationConfig.items) {
        const { weather_picture, display_weather, display_city } =
          tvApplicationConfig.items;

        form.setFields([
          { name: "weather_picture", value: weather_picture },
          { name: "display_weather", value: display_weather },
          { name: "display_city", value: display_city },
        ]);
      }
    } else {
      setErrorText(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      display_weather: values.display_weather,
      weather_picture: values.weather_picture,
      display_city: values.display_city,
    };

    function callback() {
      setLoading(false);
      onClose();
      getConfig();
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (tvApplicationConfig.items) {
      REQUESTS.TV_APPLICATION.CONFIG.EDIT(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  return (
    <Drawer
      title={translate["Wether Edit"] || EN["Wether Edit"]}
      placement="right"
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      visible={visible}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="edit-text-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          display_weather: true,
          weather_picture: true,
          display_city: true,
        }}
      >
        <Form.Item name="display_weather" valuePropName="checked" noStyle>
          <Checkbox style={{ width: "100%" }}>
            {" "}
            {translate["Show weather"] || EN["Show weather"]}{" "}
          </Checkbox>
        </Form.Item>

        <Form.Item name="weather_picture" valuePropName="checked" noStyle>
          <Checkbox style={{ width: "100%", margin: 0 }}>
            {translate["Show picture"] || EN["Show picture"]}
          </Checkbox>
        </Form.Item>

        <Form.Item name="display_city" valuePropName="checked">
          <Checkbox style={{ width: "100%", margin: 0 }}>
            {translate["Show City"] || EN["Show City"]}
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>

        {/* <ErrorMessage>{errorText}</ErrorMessage> */}
      </Form>
    </Drawer>
  );
}
