import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import REQUESTS from "../../../server/requests";
import { Empty, Input, Select, Switch } from "antd";
import EN from "../../../config/en";

import LabelComponent from "../../elements/LabelComponent";
import Page from "../../elements/Page";

import { useLocationsOptions } from "../../../hooks/options";

import Header from "./Header";
import Monitoring from "./Monitoring";
import "./_monitoring.scss";

export default function MonitoringPage() {

    const translate = useSelector((state) => state.globalState.translate);

    const locationsOption = useLocationsOptions();

    const [devices, setDevices] = useState([]);
    const [search, setSearch] = useState("");
    const [isOnline, setIsOnline] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);

    useEffect(() => {
        getOnlineDevices();
    }, [isOnline, selectedLocation]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            getOnlineDevices();
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [search]);

    const getOnlineDevices = () => {
        const query = {};

        if (search) {
            query.search = JSON.stringify({
                device_id: search,
                ip: search,
                model: search,
                name: search,
            });
        }

        let filter = {
            is_online: isOnline,
        };

        if (selectedLocation)
            filter = {
                ...filter,
                room_id: selectedLocation,
            };

        query.filter = JSON.stringify(filter);

        REQUESTS.MONITORING.ONLINE_DEVICES(query, (data) => {
            setDevices(data.data);
        });
    };

    return (
        <Page>
            <Header/>
            <div className="page-body">
                <header className="monitoring-header">
                    <div className="monitoring-box">
                        <div>
                            <LabelComponent>{translate['Search'] || EN['Search']}</LabelComponent>
                            <Input.Search
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                style={{ width: 220 }}
                            />
                        </div>

                        <div>
                            <LabelComponent>{translate['Locations'] || EN['Locations']}</LabelComponent>
                            <Select
                                showArrow
                                allowClear={true}
                                options={locationsOption}
                                value={selectedLocation}
                                onChange={setSelectedLocation}
                                style={{ width: 200 }}
                            />
                        </div>

                        <div>
                            <LabelComponent>{translate['Online'] || EN['Online']}</LabelComponent>

                            <Switch
                                defaultChecked
                                checked={isOnline}
                                onChange={() => setIsOnline((value) => !value)}
                            />
                        </div>
                    </div>
                </header>

                {devices.length > 0 ? (
                    <div className="card-body">
                        { devices.map((item) => <Monitoring key={item.id} item={item} />)}
                    </div>
                ) : (
                    <div className="empty-block">
                        <Empty />
                    </div>
                )}
            </div>
        </Page>
    );
}
