import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, notification } from "antd";
import Context from "./Context";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";

export default function CategoryDrawer() {
  const { currentGroups, visibleGroupsDrawer, getGroupsList, setVisibleGroupsDrawer } =
    useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (visibleGroupsDrawer) {
      if (currentGroups) {
        form.setFields([{ name: "name", value: currentGroups.name }]);
      }
    } else {
      setErrorText(null);
    }
  }, [visibleGroupsDrawer]);

  useEffect(() => {
    if (!visibleGroupsDrawer && currentGroups) {
      form.resetFields();
    }
  }, [visibleGroupsDrawer]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      name: values.name,
    };

    function callback() {
      setLoading(false);
      setVisibleGroupsDrawer(false);
      getGroupsList();
      form.resetFields();
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (currentGroups) {
      body.id = currentGroups.id;
      REQUESTS.ROOMS.GROUPS.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.ROOMS.GROUPS.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  return (
    <Drawer
      title={
        currentGroups
          ? translate["Edit Category"] || EN["Edit Category"]
          : translate["Add Category"] || EN["Add Category"]
      }
      placement="right"
      width={400}
      onClose={() => {
        setVisibleGroupsDrawer(false);
        form.resetFields();
      }}
      visible={visibleGroupsDrawer}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="groups-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Name"] || EN["Name"]}
          tooltip={{
            title: "Category name",
            icon: ICONS.INFO,
          }}
          name="name"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Name"] || EN["Name"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
