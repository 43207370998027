import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../../../../server/requests";

import EN from "../../../../../../config/en";
import ICONS from "../../../../../../config/icons";

import ErrorMessage from "../../../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../../../elements/SaveButtonComponent";
import FileChooser from "../../../../../elements/FileChooser";

export default function SeasonDrawer({
  visible,
  onClose,
  season,
  getSeasons,
  vod_id,
  addSeasonInMovie,
}) {
  const translate = useSelector((state) => state.globalState.translate);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (visible) {
      if (season) {
        form.setFields([{ name: "name", value: season.name }]);

        if (season.poster_path) {
          setImageSrc(season.poster_path);
        }
      }
    } else {
      form.resetFields();
      setErrorText(null);
      setImageSrc(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);
    setErrorText(null);

    const body = { vod_id, name: values.name, poster_path: values.poster_path };

    function callback() {
      setLoading(false);
      getSeasons();

      onClose();
      form.resetFields();
      setImageSrc(null);
    }

    function errorCallback(err) {
      //   setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (season) {
      body.id = season.id;

      REQUESTS.VOD.MOVIES.TV_SHOWS.SEASONS.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.VOD.MOVIES.TV_SHOWS.SEASONS.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    form.setFields([{ name: "poster_path", value: item.file_url }]);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "poster_path", value: null }]);
  };

  return (
    <Drawer
      title={
        season
          ? translate["Edit Season"] || EN["Edit Season"]
          : translate["Add Season"] || EN["Add Season"]
      }
      placement="right"
      width={600}
      onClose={() => {
        onClose();
        form.resetFields();
        setImageSrc(null);
      }}
      visible={visible}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Image"] || EN["Image"]}
          tooltip={{
            title: "Choose an image",
            icon: ICONS.INFO,
          }}
          name="poster_path"
          rules={[
            {
              required: season ? false : true,
              message: `${translate["Please choose"] || EN["Please choose"]} ${
                translate["Image"] || EN["Image"]
              }`,
            },
          ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        <Form.Item
          label={translate["Name"] || EN["Name"]}
          tooltip={{
            title: "Season name",
            icon: ICONS.INFO,
          }}
          name="name"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Name"] || EN["Name"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
