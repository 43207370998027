import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Input } from "antd";
import Context from "./Context";
import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";
import confirmModal from "../../../../config/confirmModal";
import { dateFormat } from "../../../../config/formats";

import TableButtons from "../../../elements/TableButtons";
import Page from "../../../elements/Page";

import Head from "./Head";
import CategoryDrawer from "./CategoryDrawer";

import AttachPackageDrawer from "./AttachPackageDrawer";
import TableHead from "./TableHead";
import LOCAL_STORAGE_VALUES from "../../../../config/localStorageValues";

export default function OrganizationsCategoriesPage() {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [groupsList, setGroupsList] = useState([]);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({ name: "" });

  const [packegSearch, setPackegSearch] = useState("");

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentGroups, setCurrentGroups] = useState(null);
  const [currentAttach, setCurrentAttach] = useState(null);

  const [visibleGroupsDrawer, setVisibleGroupsDrawer] = useState(false);
  const [visibleAttachDrawer, setVisibleAttachDrawer] = useState(false);

  const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);
  const [visibleViewSettingsDrawer, setVisibleViewSettingsDrawer] = useState(false);

  const [selectedPackagesId, setSelectedPackagesId] = useState(null);

  const [columns, setColumns] = useState([]);

  const [viewSettingsValues, setViewSettingsValues] = useState({
    id: true,
    name: true,
    room_count: true,
    devices_count: true,
    package: true,
    createdAt: true,
    action: true,
  });

  useEffect(() => {
    let ORGANIZATIONS_CATEGORIES_COLUMNS = localStorage.getItem(
      LOCAL_STORAGE_VALUES.ORGANIZATIONS_CATEGORIES_COLUMNS
    );

    if (ORGANIZATIONS_CATEGORIES_COLUMNS) {
      ORGANIZATIONS_CATEGORIES_COLUMNS = JSON.parse(ORGANIZATIONS_CATEGORIES_COLUMNS);
      setViewSettingsValues(ORGANIZATIONS_CATEGORIES_COLUMNS);
    }
  }, []);

  const configureTableColumns = () => {
    const newColumns = [];

    newColumns.push({
      width: 60,
      align: "center",
      fixed: "left",
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    });

    if (viewSettingsValues.name) {
      newColumns.push({
        title: translate["Name"] || EN["Name"],
        dataIndex: "name",
        key: "name",
        sorter: true,
        render: (text, record, index) => (
          <p className="text-ellipsis" style={{ width: 160 }}>
            {text}
          </p>
        ),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },

        onFilter: (value, record) => {
          return record.name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.package) {
      newColumns.push({
        title: translate["Package"] || EN["Package"],
        dataIndex: "package",
        key: "package",
        render: (text, record, index) => (
          <p>{record.package ? record.package.name : "-"}</p>
        ),
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={packegSearch[0]}
              onChange={(e) => {
                setPackegSearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.package?.name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.room_count) {
      newColumns.push({
        title: translate["Location Counter"] || EN["Location Counter"],
        dataIndex: "room_count",
        key: "room_count",
        sorter: true,
        width: 200,
        render: (text, record, index) => <p className="txt-tb-r">{record.room_count}</p>,
      });
    }

    if (viewSettingsValues.devices_count) {
      newColumns.push({
        title: translate["Devices Counter"] || EN["Devices Counter"],
        dataIndex: "devices_count",
        key: "devices_count",
        sorter: true,
        width: 200,
        render: (text, record, index) => (
          <p className="txt-tb-r">{record.devices_count}</p>
        ),
      });
    }

    if (viewSettingsValues.createdAt) {
      newColumns.push({
        title: translate["Date"] || EN["Date"],
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: true,
        render: (text, record, index) => dateFormat(text),
      });
    }

    newColumns.push({
      width: 60,
      fixed: "right",
      title: "",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          style={{ minWidth: 130 }}
          buttons={[
            {
              key: "attach",
              text: translate["Attach Package"] || EN["Attach Package"],
              icon: ICONS.ATTACH,
            },
            { key: "edit", text: translate["Edit"] || EN["Edit"], icon: ICONS.EDIT },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE,
            },
          ]}
        />
      ),
    });
    setColumns(newColumns);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "attach":
        setCurrentAttach(item);
        setVisibleAttachDrawer(true);
        break;

      case "edit":
        setCurrentGroups(item);
        setVisibleGroupsDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
            item.name
          }`,
          action: () => deleteGroups(item),
        });
        break;

      default:
        break;
    }
  };

  const deleteGroups = (item) => {
    REQUESTS.ROOMS.GROUPS.DELETE(item.id, getGroupsList);
  };

  const getGroupsList = () => {
    setLoading(true);

    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage,
    };

    if (search.name) {
      query.search = JSON.stringify({ name: search.name && search.name[0] });
    }

    if (packegSearch[0]) {
      query.packages_name = packegSearch[0];
    }

    if (selectedPackagesId) {
      query.filter = JSON.stringify({ group_package_id: selectedPackagesId });
    }

    function callback(data) {
      setGroupsList(data.rows);
      setLimit(data.limit);
      setTotal(data.count);

      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.ROOMS.GROUPS.GET(query, callback, errorCallback);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  useEffect(() => {
    if (!visibleGroupsDrawer) {
      setCurrentGroups(null);
    }
  }, [visibleGroupsDrawer]);

  useEffect(() => {
    getGroupsList();
  }, [currentPage, limit, sort, selectedPackagesId]);

  useEffect(() => {
    let timeout = null;
    if (packegSearch[0]) {
      if (packegSearch[0]?.length > 2) {
        timeout = setTimeout(() => {
          getGroupsList();
        }, 1000);
      }
    } else {
      getGroupsList();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [packegSearch]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getGroupsList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [search]);

  useEffect(() => {
    configureTableColumns();
  }, [viewSettingsValues, currentPage]);

  const openDrawer = useCallback(() => {
    setVisibleGroupsDrawer(true);
  }, []);

  return (
    <Context.Provider
      value={{
        total,
        currentGroups,
        currentAttach,
        getGroupsList,
        visibleViewSettingsDrawer,
        setVisibleViewSettingsDrawer,
        visibleFilterDrawer,
        setVisibleFilterDrawer,
        viewSettingsValues,
        setViewSettingsValues,
        setSelectedPackagesId,
        setVisibleGroupsDrawer,
        visibleGroupsDrawer,
        visibleAttachDrawer,
        setVisibleAttachDrawer,
        selectedPackagesId,
      }}
    >
      <Page>
        <Head total={total} openDrawer={openDrawer} />

        <div className="page-body">
          <TableHead />

          <Table
            loading={loading}
            rowKey="id"
            columns={columns}
            dataSource={groupsList}
            onChange={handleTableChange}
            pagination={{
              position: ["topCenter", "bottomCenter"],
              current: currentPage,
              total: total,
              showSizeChanger: true,
            }}
            sticky={{
              offsetHeader: 60,
              offsetScroll: 0,
            }}
          />

          <CategoryDrawer />

          <AttachPackageDrawer />
        </div>
      </Page>
    </Context.Provider>
  );
}
