import { useEffect, useState } from "react";
import { Drawer, Form, Input, Select, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FileChooser from "../../../../elements/FileChooser";
import Translation from "../../../../elements/Translation";
import { useSelector } from "react-redux";
import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";

const MusicDrawer = ({ visible, onClose, genres, addMusic, editable, loading }) => {
  const [form] = Form.useForm();
  const [imageSrc, setImageSrc] = useState(null);
  const [descriptionTranslations, setDescriptionTranslations] = useState([]);
  const [nameTranslation, setNameTranslation] = useState([]);
  const [audioSrc, setAudioSrc] = useState(null);
  const [categoriesId, setCategoriesId] = useState(null);

  const translate = useSelector((state) => state.globalState.translate);

  const handleFinish = (values) => {
    const body = {
      name: JSON.stringify(nameTranslation || []) || values.name,
      description: JSON.stringify(descriptionTranslations || []) || values.description,
      icon: imageSrc,
      url: audioSrc,
    };

    console.log("categoriesId", categoriesId);
    if (categoriesId && categoriesId.length > 0) {
      body.category_id = categoriesId?.join(",");
    } else {
      console.log("values.category_id", values.category_id);

      body.category_id = values.category_id?.join(",");
    }

    addMusic(body);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "image", value: null }]);
  };

  const selectFile = (file) => {
    setImageSrc(file?.file_url);
    form.setFields([{ name: "image", value: file }]);
  };

  const selectAudio = (file) => {
    setAudioSrc(file?.file_url);
    form.setFields([{ name: "url", value: file }]);
  };

  const replaceAudio = () => {
    setAudioSrc(null);
    form.setFields([{ name: "url", value: null }]);
  };

  const onChangeNameTranslation = (value, id) => {
    const name = {
      text: value,
      language_id: id,
    };
    const newTranslations = [...nameTranslation];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = name;
    } else {
      newTranslations.push(name);
    }

    setNameTranslation(newTranslations);
  };

  const onChangeTranslation = (value, id) => {
    const description = {
      text: value,
      language_id: id,
    };
    const newTranslations = [...descriptionTranslations];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = description;
    } else {
      newTranslations.push(description);
    }

    setDescriptionTranslations(newTranslations);
  };

  const setCategories = (editable, genres) => {
    if (editable) {
      const newGenres = editable?.map((item) => {
        const genre = genres.find((genre) => genre.value === item.category_id);

        return genre?.value;
      });

      return newGenres;
    }
  };

  const makeAudioName = (url) => {
    const name = url.split("/").pop();
    const cleanName = name
      .replace(/file|%|&|@|#|\d|\./g, "")
      .replace(/-_/g, "")
      .replace(/\.[^/.]+$/, "")
      .replace(/mp3$/, "");
    return cleanName;
  };

  useEffect(() => {
    if (editable) {
      form.setFieldsValue({
        name: editable?.name,
        description: editable.description,
        url: editable.url,
        category_id: setCategories(editable?.musics_categories, genres),
      });

      setNameTranslation(editable?.name_translations ? editable?.name_translations : []);
      setDescriptionTranslations(
        editable?.description_translations ? editable?.description_translations : []
      );

      setImageSrc(editable?.icon);
      setAudioSrc(editable?.url);
    }
  }, [visible, editable]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setImageSrc(null);
      setAudioSrc(null);
      setDescriptionTranslations([]);
      setNameTranslation([]);
      setCategories(null);
    }
  }, [visible]);

  return (
    <Drawer
      width={550}
      open={visible}
      destroyOnClose
      onClose={() => {
        onClose();
        form.resetFields();
        selectFile(null);
        replaceAudio();
        setCategoriesId(null);
      }}
      title={
        editable
          ? translate["Edit Music"] || EN["Edit Music"]
          : translate["Add music"] || EN["Add music"]
      }
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            onClick={() => {
              form.resetFields();
              onClose();
            }}
            style={{ marginRight: 8 }}
          >
            {translate["Cancel"] || EN["Cancel"]}
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            {translate["Save"] || EN["Save"]}
          </Button>
        </div>
      }
    >
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item
          label={translate["Icon"] || EN["Icon"]}
          name="icon"
          rules={[
            {
              required: !editable ? true : false,
              message: translate["Please input icon"] || EN["Please input icon"],
            },
          ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>
        <Translation
          render={(item, index) => {
            const name = nameTranslation?.find(
              (translation) => translation.language_id === item.id
            )?.text;
            return (
              <Form.Item
                label={translate["Name"] || EN["Name"]}
                name="name"
                rules={[
                  {
                    required: true,
                    message: translate["Please input name"] || EN["Please input name"],
                  },
                ]}
                tooltip={{
                  title: translate["Info title"] || EN["Info title"],
                  icon: ICONS.INFO,
                }}
              >
                <Input
                  value={name}
                  onChange={(e) => {
                    onChangeNameTranslation(e.target.value, item.id);
                  }}
                />
              </Form.Item>
            );
          }}
        />

        <Form.Item
          label={translate["Attach file"] || EN["Attach file"]}
          name="url"
          tooltip={{
            title: translate["Url"] || EN["Url"],
            icon: ICONS.INFO,
          }}
          rules={[
            {
              required: !editable ? true : false,
              message: translate["Please choose file"] || EN["Please choose file"],
            },
          ]}
        >
          <div>
            <FileChooser
              src={audioSrc}
              onChange={selectAudio}
              replace={replaceAudio}
              types={["audio"]}
            />
            {audioSrc && <span>{makeAudioName(audioSrc)}</span>}
          </div>
        </Form.Item>
        <Form.Item label="Category" name="category_id">
          <Select
            options={genres}
            mode="multiple"
            placeholder={translate["Select category"] || EN["Select category"]}
            showSearch
            allowClear
            // value={form.getFieldValue("category_id")}
            filterOption={(input, option) => {
              return option?.label?.toLowerCase()?.includes(input?.toLowerCase());
            }}
            onChange={(value) => {
              // form.setFieldValue({ category_id: value });
              setCategoriesId(value);
            }}
          />
        </Form.Item>
        <Translation
          render={(item, index) => {
            const description = descriptionTranslations.find(
              (translation) => translation.language_id === item.id
            )?.text;
            return (
              <Form.Item
                label={translate["Description"] || EN["Description"]}
                name="description"
                tooltip={{
                  title: "Info Title",
                  icon: ICONS.INFO,
                }}
              >
                <TextArea
                  value={description}
                  onChange={(e) => {
                    onChangeTranslation(e.target?.value, item?.id);
                  }}
                />
              </Form.Item>
            );
          }}
        />
      </Form>
    </Drawer>
  );
};

export default MusicDrawer;
