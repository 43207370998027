export const parseYearMonthDayHoursMinutes = (date) => {
    let newDate = new Date(date);
    let year = newDate.getFullYear();
    let hours = newDate.getHours();
    let month = newDate.getMonth() + 1;
    let minutes = newDate.getMinutes();
    let day = newDate.getDate();
    let seconds = newDate.getSeconds();

    if (day < 10) {
        day = "0" + day;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (month < 10) {
        month = "0" + month;
    }
    if (hours < 10) {
        hours = "0" + hours;
    }

    if (seconds < 10) {
        seconds = "0" + seconds;
    }

    return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const dateFormat = (date) => {
    let newDate = new Date(date);
    let year = newDate.getFullYear();
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();

    if (day < 10) {
        day = "0" + day;
    }

    if (month < 10) {
        month = "0" + month;
    }

    return `${day}-${month}-${year}`;
};

export const dateFormatBySlash = (date) => {
    let newDate = new Date(date);
    let year = newDate.getFullYear();
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();

    if (day < 10) {
        day = "0" + day;
    }

    if (month < 10) {
        month = "0" + month;
    }

    return `${day}/${month}/${year}`;
};

export const formatTime = (minute) => {
    if (minute < 60) {
        return `${minute} minute`;
    } else if (minute === 60) {
        return "1 hour";
    } else {
        let hour = parseInt(minute / 60);
        let _minute = minute % 60;

        return `${hour} hour ${_minute} minute`;
    }
};

export const messageDate = (date) => {
    let newDate = new Date(date);
    let year = newDate.getFullYear();
    let hours = newDate.getHours();
    let month = newDate.getMonth() + 1;
    let minutes = newDate.getMinutes();
    let day = newDate.getDate();
    let seconds = newDate.getSeconds();

    if (day < 10) {
        day = "0" + day;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (month < 10) {
        month = "0" + month;
    }
    if (hours < 10) {
        hours = "0" + hours;
    }

    if (seconds < 10) {
        seconds = "0" + seconds;
    }

    return `${day}.${month} ${hours}:${minutes}`;
};

export const returnShortMonthName = (item) => {
    const month_list = {
        "0" : "Jan",
        "1" : "Feb",
        "2" : "Mar",
        "3" : "Apr",
        "4" : "May",
        "5" : "Jun",
        "6" : "Jul",
        "7" : "Aug",
        "8" : "Sep",
        "9" : "Oct",
       "10" : "Nov",
       "11" : "Dec",
    }

    return month_list[item];
} 