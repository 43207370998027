import { useSelector } from "react-redux";
import { Drawer } from "antd";

import Checkbox from "antd/lib/checkbox/Checkbox";

import EN from "../../../../config/en";
import LOCAL_STORAGE_VALUES from "../../../../config/localStorageValues";

export default function ViewSettingsDrawer({ visible, onClose, values, setValues }) {
  const translate = useSelector((state) => state.globalState.translate);

  const onChangeSettingsValues = (event) => {
    values[event.target.name] = event.target.checked;
    setValues({ ...values });

    localStorage.setItem(
      LOCAL_STORAGE_VALUES.ORGANIZATIONS_COMPANIES_COLUMNS,
      JSON.stringify(values)
    );
  };

  return (
    <Drawer
      title={translate["View settings"] || EN["View settings"]}
      placement="right"
      onClose={onClose}
      visible={visible}
      destroyOnClose={true}
    >
      <h2>{translate["Show columns"] || EN["Show columns"]}</h2>

      <div className="view-settings-checkboxes">
        <Checkbox name="name" checked={values.name} onChange={onChangeSettingsValues}>
          {translate["Name"] || EN["Name"]}
        </Checkbox>

        <Checkbox name="email" checked={values.email} onChange={onChangeSettingsValues}>
          {translate["Email"] || EN["Email"]}
        </Checkbox>

        <Checkbox name="phone" checked={values.phone} onChange={onChangeSettingsValues}>
          {translate["Phone"] || EN["Phone"]}
        </Checkbox>

        <Checkbox name="region" checked={values.region} onChange={onChangeSettingsValues}>
          {translate["Region"] || EN["Region"]}
        </Checkbox>

        <Checkbox name="city" checked={values.city} onChange={onChangeSettingsValues}>
          {translate["City"] || EN["City"]}
        </Checkbox>

        <Checkbox
          name="website"
          checked={values.website}
          onChange={onChangeSettingsValues}
        >
          {translate["Website"] || EN["Website"]}
        </Checkbox>

        <Checkbox name="date" checked={values.date} onChange={onChangeSettingsValues}>
          {translate["Date"] || EN["Date"]}
        </Checkbox>
      </div>
    </Drawer>
  );
}
