import { useContext, useState } from "react";
import { Form, Select, Switch, Checkbox } from "antd";
import { useSelector } from "react-redux";
import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";
import Context from "./Context";
import { useMusicCategoriesOption } from "../../../../hooks/options";

const MusicSection = () => {
  const {
    enableMusic,
    setEnableMusic,
    accessAllMusicCategories,
    setAccessAllMusicCategories,
  } = useContext(Context);

  const categoriesOptions = useMusicCategoriesOption();

  const translate = useSelector((state) => state.globalState.translate);

  return (
    <div>
      <div className="switch-block">
        <p>{translate["Music"] || EN["Music"]}</p>
        <Switch
          checked={enableMusic}
          onChange={() => setEnableMusic((value) => !value)}
        />
      </div>

      {enableMusic && (
        <div className="packages-sections-setyle">
          <Checkbox
            style={{ marginTop: 10, marginBottom: 20 }}
            checked={accessAllMusicCategories}
            onChange={(e) => setAccessAllMusicCategories(e.target.checked)}
          >
            {translate["Access to all music genres"] || EN["Access to all music genres"]}
          </Checkbox>

          <Form.Item
            label={translate["Categories"] || EN["Categories"]}
            tooltip={{
              title: "Categories",
              icon: ICONS.INFO,
            }}
          >
            <Form.Item name="music" noStyle>
              <Select
                mode="multiple"
                disabled={accessAllMusicCategories}
                options={categoriesOptions}
              />
            </Form.Item>
          </Form.Item>
        </div>
      )}
    </div>
    // </Form>
  );
};

export default MusicSection;
