import React, { useContext, useState, useEffect } from "react";
import Context from "./Context";
import ICONS from "../../../config/icons";
import IconButton from "../../elements/IconButton";
import TableHeadComponent from "../../elements/TableHeadComponent";
import CsvLinkComponent from "../../elements/CsvLinkComponent";
import ViewSettingsDrawer from "./ViewSettingsDrawer";
import FilterDrawer from "./FilterDrawer";

export default function TableHead() {
  const {
    setVisibleViewSettingsDrawer,
    setVisibleFilterDrawer,
    selectedPublishedStatus,
    selectedHostId,
    selectedLocationNameId,
    ordersTypeId,
    productList,
  } = useContext(Context);

  const [isFilterActive, setIsFilterActive] = useState(false);

  useEffect(() => {
    const isAnyFilterActive =
      ordersTypeId ||
      selectedLocationNameId ||
      selectedHostId ||
      selectedPublishedStatus != null;

    setIsFilterActive(isAnyFilterActive);
  }, [ordersTypeId, selectedHostId, selectedLocationNameId, selectedPublishedStatus]);

  const onClickCsv = (event, done) => {
    if (productList.length > 0) {
      const newList = productList.map(mapProductToCsv);

      done(newList);
    } else {
      done([]);
    }
  };

  const mapProductToCsv = (list) => ({
    Name: list.name,
    Host_name: list.guest ? list.guest.name : "-",
    Location_name: list.room ? list.room.location_name : "-",
    Price: list.price,
    Completed: list.completed,
    Quantity: list.quantity,
  });

  return (
    <>
      <TableHeadComponent>
        <CsvLinkComponent
          filename="Orders-Products-List.csv"
          data={[]}
          onClick={onClickCsv}
        />

        <IconButton
          icon={ICONS.SETTINGS}
          onClick={() => setVisibleViewSettingsDrawer(true)}
        />

        <IconButton
          active={isFilterActive}
          icon={ICONS.FILTER}
          onClick={() => setVisibleFilterDrawer(true)}
        />
      </TableHeadComponent>

      <ViewSettingsDrawer />
      <FilterDrawer />
    </>
  );
}
