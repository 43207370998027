import { useEffect, useState } from "react";
import { Drawer, Form, Input, Button } from "antd";
import FileChooser from "../../../../elements/FileChooser";
import ICONS from "../../../../../config/icons";
import { useSelector } from "react-redux";
import EN from "../../../../../config/en";
import TagComponent from "../../../../elements/TagComponent";

const RadioGenresDrawer = ({ visible, onClose, handleValues, editable, loading }) => {
  const translation = useSelector((state) => state.globalState.translate);

  const [form] = Form.useForm();

  const [imageSrc, setImageSrc] = useState(null);

  const onFinish = (values) => {
    const body = {
      name: values.name,
      image: imageSrc,
    };

    if (values?.tags) {
      body.tags = values.tags.join(",");
    }

    handleValues(body);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "image", value: null }]);
  };

  const selectFile = (file) => {
    setImageSrc(file?.file_url);
    form.setFields([{ name: "image", value: file }]);
  };

  useEffect(() => {
    if (editable) {
      form.setFieldsValue({
        name: editable.name,
        description: editable.description,
      });

      const editableTags = editable?.tags.map((tag) => {
        if (tag !== "") {
          return tag;
        } else {
          return null;
        }
      });

      form.setFieldsValue({
        tags: editableTags?.filter((tag) => tag !== null),
      });

      setImageSrc(editable.image);
    } else {
      form.resetFields();
      setImageSrc(null);
    }
  }, [visible, editable]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setImageSrc(null);
    }
  }, [visible]);

  return (
    <Drawer
      title={editable ? translation["Edit Genre"] || EN["Edit Genre"] : "Add Genre"}
      visible={visible}
      onClose={() => {
        onClose();
        form.resetFields();
        setImageSrc(null);
      }}
      width={400}
      destroyOnClose={true}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            {translation["Cancel"] || EN["Cancel"]}
          </Button>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            onClick={() => {
              form.submit();
            }}
          >
            {editable
              ? translation["Edit"] || EN["Edit"]
              : translation["Add"] || EN["Add"]}
          </Button>
        </div>
      }
    >
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Form.Item
          label={translation["Image"] || EN["Image"]}
          // label="Image"
          tooltip={{
            title: translation["Choose file"] || EN["Choose file"],
            icon: ICONS.INFO,
          }}
          name="icon"
          rules={[
            {
              required: editable ? false : true,
              message: `${translation["Please choose"] || EN["Please choose"]} ${
                translation["Image"] || EN["Image"]
              }`,
            },
          ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        <Form.Item
          label={translation["Name"] || EN["Name"]}
          name="name"
          tooltip={{
            title: translation["Please input name"] || EN["Please input name"],
            icon: ICONS.INFO,
          }}
          rules={[
            {
              required: true,
              message: translation["Please input name"] || EN["Please input name"],
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div>
          <TagComponent type="radio" form={form} />
        </div>
      </Form>
    </Drawer>
  );
};

export default RadioGenresDrawer;
