import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import EN from "../../config/en";
import ICONS from "../../config/icons";
import IMAGES from "../../config/images";
import NAVIGATORS from "../../config/navigators";
import confirmModal from "../../config/confirmModal";
import REQUESTS from "../../server/requests";

import { actions } from "../../store";
import { Badge, Dropdown, Menu } from "antd";
import { ProfileOutlined } from "@ant-design/icons";

export default function ProfileBlock() {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const profile = useSelector((state) => state.globalState.profile);

    const socketIsConnected = useSelector((state) => state.globalState.socketIsConnected);

    const translate = useSelector((state) => state.globalState.translate);

    const { setProfileInRedux, setOpenProfileDrawerInRedux } = bindActionCreators(
        actions,
        dispatch
    );

    useEffect(() => {
        REQUESTS.EMPLOYERS.PROFILE.GET((data) => {
            setProfileInRedux(data);
        });
    }, []);

    const onClick = ({ key }) => {
        switch (key) {
            case "logout":
                confirmModal({
                    title: translate['Logout Confirm'] || EN['Logout Confirm'],
                    action: () => {
                        localStorage.clear();
                        navigate(NAVIGATORS.LOGIN);
                    },
                });
                break;

            case "profile":
                document.getElementById("settingsId").click();
                setOpenProfileDrawerInRedux(true);
                break;

            default:
                break;
        }
    };

    const menu = (
        <Menu onClick={onClick}>
            <Menu.Item key="profile" title="Profile" icon={<ProfileOutlined />}>
                {translate['Profile'] || EN['Profile']}
            </Menu.Item>

            <Menu.Item key="logout" title="Logout" icon={ICONS.LOGOUT} style={{ color: "#bc4749" }}>
                {translate['Logout'] || EN['Logout']}
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            <div id="profile-block">
                <div
                    style={{
                        backgroundImage: `url(${profile.image})`,
                    }}
                    className="profile-image"
                    onError={(e) => (e.target.src = IMAGES.IMAGE_ERROR)}
                />
                <div style={{ textAlign: "start" }}>
                    <p className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                        {`${profile.name} ${profile.surname}`}
                    </p>
                    <div className="profile-online">
                        <Badge
                            status={socketIsConnected ? "success" : "error"}
                            text={`You are ${socketIsConnected ? "online" : "offline"}`}
                        />
                    </div>
                </div>
            </div>
        </Dropdown>
    );
}
