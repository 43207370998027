import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Select } from "antd";
import Context from "./Context";
import { usePublishedStatusOptions } from "../../../../hooks/options";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";

import ButtonComponent from "../../../elements/ButtonComponent";

export default function FilterDrawer() {
  const {
    visibleFilterDrawer,
    setVisibleFilterDrawer,
    setSelectedDefault,
    setSelectedTVChannels,
    setSelectedMovies,
    setSelectedEcommerce,
    setSelectedWidgets,
    setSelectedSendMessages,
    setSelectedViewBuillingInformation,
    setSelectedNewsViews,
    setSelectedViewPromotions,
    setSelectedFeedbackAccess,
  } = useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const publishedStatusOptions = usePublishedStatusOptions();

  const [statusDefault, setStatusDefault] = useState(null);
  const [statusTVChannels, setStatusTVChannels] = useState(null);
  const [statusMovies, setStatusMovies] = useState(null);
  const [statusEcommerce, setStatusEcommerce] = useState(null);
  const [statusWidgets, setStatusWidgets] = useState(null);
  const [statusSendMessages, setStatusSendMessages] = useState(null);
  const [statusViewBuillingInformation, setStatusViewBuillingInformation] =
    useState(null);
  const [statusNewsViews, setStatusNewsViews] = useState(null);
  const [statusViewPromotions, setStatusViewPromotions] = useState(null);
  const [statusFeedbackAccess, setStatusFeedbackAccess] = useState(null);

  const onClickFilter = () => {
    setSelectedDefault(statusDefault);
    setSelectedTVChannels(statusTVChannels);
    setSelectedMovies(statusMovies);
    setSelectedEcommerce(statusEcommerce);
    setSelectedWidgets(statusWidgets);
    setSelectedSendMessages(statusSendMessages);
    setSelectedViewBuillingInformation(statusViewBuillingInformation);
    setSelectedNewsViews(statusNewsViews);
    setSelectedViewPromotions(statusViewPromotions);
    setSelectedFeedbackAccess(statusFeedbackAccess);

    setVisibleFilterDrawer(false);
  };
  return (
    <Drawer
      title={translate["Filter"] || EN["Filter"]}
      placement="right"
      visible={visibleFilterDrawer}
      onClose={() => setVisibleFilterDrawer(false)}
      destroyOnClose={true}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h1>{translate["Select filter parameters"] || EN["Select filter parameters"]}</h1>

        <p>{translate["Default"] || EN["Default"]}:</p>
        <Select
          showArrow
          allowClear={true}
          value={statusDefault}
          onChange={setStatusDefault}
          options={publishedStatusOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate["Tv Channels"] || EN["Tv Channels"]}:</p>
        <Select
          showArrow
          allowClear={true}
          value={statusTVChannels}
          onChange={setStatusTVChannels}
          options={publishedStatusOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate["Vod"] || EN["Vod"]}:</p>
        <Select
          showArrow
          allowClear={true}
          value={statusMovies}
          onChange={setStatusMovies}
          options={publishedStatusOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate["E-Commerce"] || EN["E-Commerce"]}:</p>
        <Select
          showArrow
          allowClear={true}
          value={statusEcommerce}
          onChange={setStatusEcommerce}
          options={publishedStatusOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate["Widgets"] || EN["Widgets"]}:</p>
        <Select
          showArrow
          allowClear={true}
          value={statusWidgets}
          onChange={setStatusWidgets}
          options={publishedStatusOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate["Send messages"] || EN["Send messages"]}:</p>

        <Select
          showArrow
          allowClear={true}
          value={statusSendMessages}
          onChange={setStatusSendMessages}
          options={publishedStatusOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate["View Bill"] || EN["View Bill"]}:</p>

        <Select
          showArrow
          allowClear={true}
          value={statusViewBuillingInformation}
          onChange={setStatusViewBuillingInformation}
          options={publishedStatusOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate["News"] || EN["News"]}:</p>
        <Select
          showArrow
          allowClear={true}
          value={statusNewsViews}
          onChange={setStatusNewsViews}
          options={publishedStatusOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate["Promotions"] || EN["Promotions"]}:</p>
        <Select
          showArrow
          allowClear={true}
          value={statusViewPromotions}
          onChange={setStatusViewPromotions}
          options={publishedStatusOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <p>{translate["Access"] || EN["Access"]}:</p>
        <Select
          showArrow
          allowClear={true}
          value={statusFeedbackAccess}
          onChange={setStatusFeedbackAccess}
          options={publishedStatusOptions}
          style={{ width: "100%", marginBottom: 20 }}
        />

        <ButtonComponent
          text={translate["Filter"] || EN["Filter"]}
          icon={ICONS.ARROW_RIGHT_OUTLINED}
          onClick={onClickFilter}
        />
      </div>
    </Drawer>
  );
}
