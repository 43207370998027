import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Button, Drawer, Form, Input, notification, Select } from "antd";

import REQUESTS from "../../../../server/requests";
import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";
import confirmModal from "../../../../config/confirmModal";

const RoomControllerDrawer = ({ open, close, room_id }) => {
  const translate = useSelector((state) => state.globalState.translate);
  const [host, setHost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reqMethod, setReqMethod] = useState("");
  const [editableId, setEditableId] = useState(null);

  const [form] = Form.useForm();

  const handleSuccess = useCallback(
    (data) => {
      if (data) {
        setHost(data?.host);

        REQUESTS.ROOMS.ROOM_CONTROL.GET_CONTROLLER(
          `${data?.host}/room-control/device`,
          { room_id },
          (controllerData) => {
            if (controllerData?.id) {
              setEditableId(controllerData.id);
              setReqMethod("put");
            } else {
              setReqMethod("post");
            }

            form.setFields([
              { name: "ip", value: controllerData?.ip || "" },
              { name: "port", value: controllerData?.port || "" },
              { name: "protocol", value: controllerData?.protocol || "MODBUS" },
            ]);
          },
          (err) => console.log(err)
        );
      }
    },
    [room_id, form]
  );

  const getDeviceHost = useCallback(() => {
    REQUESTS.ROOMS.ROOM_CONTROL.GET_HOST({}, handleSuccess, (err) => console.log(err));
  }, [handleSuccess]);

  const handleFinish = (values) => {
    setLoading(true);
    const body = {
      ip: values.ip,
      port: values.port,
      protocol: "MODBUS",
    };

    if (reqMethod === "post") body.room_id = room_id;

    const requestHost = `${host}/room-control/device${
      reqMethod === "put" ? `/${editableId}` : ""
    }`;

    REQUESTS.ROOMS.ROOM_CONTROL.SET_CONTROLLER(
      reqMethod,
      requestHost,
      body,
      () => {
        setLoading(false);
        notification.success({
          message: translate["Room controller updated"] || EN["Room controller updated"],
        });
        close();
        form.resetFields();
      },
      (err) => {
        setLoading(false);
        notification.error({
          message: translate["An error occurred"] || EN["An error occurred"],
        });
        console.log(err);
      }
    );
  };

  const handleRemoveController = () => {
    confirmModal({
      title:
        translate["Do you want to remove the controller?"] ||
        EN["Do you want to remove the controller?"],
      action: () => {
        REQUESTS.ROOMS.ROOM_CONTROL.REMOVE_CONTROLLER(
          `${host}/room-control/device/${editableId}`,
          () => {
            notification.success({
              message: translate["Controller removed"] || EN["Controller removed"],
            });
            close();
            setEditableId(null);
            form.resetFields();
          },
          (err) => {
            notification.error({
              message: translate["An error occurred"] || EN["An error occurred"],
            });
            console.log(err);
          }
        );
      },
    });
  };

  useEffect(() => {
    if (open) getDeviceHost();
  }, [open, getDeviceHost]);

  return (
    <Drawer
      open={open}
      width={400}
      onClose={() => {
        close();
        form.resetFields();
        setEditableId(null);
        setReqMethod("");
      }}
      destroyOnClose
      title={translate["Room controller"] || EN["Room controller"]}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 5,
          }}
        >
          <Button
            onClick={() => {
              form.resetFields();
              close();
            }}
          >
            {translate["Cancel"] || EN["Cancel"]}
          </Button>
          <Button type="primary" onClick={() => form.submit()} loading={loading}>
            {translate["Save"] || EN["Save"]}
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name="ip"
          label="Ip address"
          rules={[
            {
              pattern: /^[0-9]+(?:\.[0-9]+){3}$/,
              message: "Please enter a valid IP address",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="port"
          label="Ip port"
          rules={[{ pattern: /^[0-9]{1,5}$/, message: "Please enter a valid port" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="protocol" label={translate["Protocol"] || EN["Protocol"]}>
          <Select
            value="1"
            defaultValue="1"
            disabled
            options={[{ label: "MODBUS", value: "1" }]}
          />
        </Form.Item>
      </Form>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 5,
        }}
      >
        {editableId && (
          <Button
            onClick={handleRemoveController}
            // type="danger"
            icon={ICONS.DELETE}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
            }}
          >
            {translate["Remove Controller"] || EN["Remove Controller"]}
          </Button>
        )}
      </div>
    </Drawer>
  );
};

export default RoomControllerDrawer;
