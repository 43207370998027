import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";
import FileChooser from "../../../elements/FileChooser";

export default function CategoryDrawer({
  visible,
  onClose,
  category,
  getCategoriesList,
}) {
  const translate = useSelector((state) => state.globalState.translate);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  const onFinish = (values) => {
    setLoading(true);
    setErrorText(null);

    let body = {
      name: values.name,
      description: values.description,
      image: values.image,
    };

    function callback() {
      onClose();
      getCategoriesList();
      setImageSrc(null);
      setLoading(false);
      form.resetFields();
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (category) {
      body.id = category.id;
      REQUESTS.INFO_CENTER.CATEGORIES.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.INFO_CENTER.CATEGORIES.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  useEffect(() => {
    if (visible) {
      if (category) {
        form.setFields([
          { name: "name", value: category.name },
          { name: "description", value: category.description },
          { name: "image", value: category.image },
        ]);
        if (category.image) {
          setImageSrc(category.image);
        }
      }
    } else {
      setErrorText(null);
    }
  }, [visible]);

  useEffect(() => {
    if (!visible && category) {
      form.resetFields();
    }
  }, [visible]);

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "image", value: null }]);
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    form.setFields([{ name: "image", value: item.file_url }]);
  };

  return (
    <Drawer
      title={
        category
          ? translate["Edit Category"] || EN["Edit Category"]
          : translate["Add New Category"] || EN["Add New Category"]
      }
      placement="right"
      width={400}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      visible={visible}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Image"] || EN["Image"]}
          tooltip={{
            title: "Choose an image",
            icon: ICONS.INFO,
          }}
          name="image"
          // rules={[
          //   {
          //     required: true,
          //     message: `${translate["Please choose"] || EN["Please choose"]} ${
          //       translate["Image"] || EN["Image"]
          //     }`,
          //   },
          // ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        <Form.Item
          label={translate["Name"] || EN["Name"]}
          tooltip={{
            title: "Category name",
            icon: ICONS.INFO,
          }}
          name="name"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Name"] || EN["Name"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Description"] || EN["Description"]}
          tooltip={{
            title: "Please input description",
            icon: ICONS.INFO,
          }}
          name="description"
          // rules={[
          //   {
          //     required: true,
          //     message: `${translate["Please input"] || EN["Please input"]} ${
          //       translate["Description"] || EN["Description"]
          //     }`,
          //   },
          // ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
