import React from "react";

import DashboardHeader from "./DashboardHeader";

import MapCharts from "./MapCharts";
import GuestCharts from "./GuestCharts";

import ProductsCharts from "./ProductsCharts";
import ServiceCharts from "./ServiceCharts";
import MoviesCharts from "./MoviesCharts";
import FeedbackCharts from "./FeedbackCharts";
import Page from "../../elements/Page";

import "./_dashboard.scss";

export default function DashboardPage() {
    return (
        <Page>
            <DashboardHeader />
            <div className="page-body ">
                <MapCharts />
                <GuestCharts />

                <div className="cards-section">
                    <ProductsCharts />
                    <ServiceCharts />
                    <MoviesCharts />
                </div>
                <FeedbackCharts />
            </div>
        </Page>
    );
}
