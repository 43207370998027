import React, { useContext, useEffect, useState } from "react";

import Context from "./Context";

import REQUESTS from "../../../../../server/requests";

import ICONS from "../../../../../config/icons";

import IconButton from "../../../../elements/IconButton";
import TableHeadComponent from "../../../../elements/TableHeadComponent";
import CsvLinkComponent from "../../../../elements/CsvLinkComponent";

import ViewSettingsDrawer from "./ViewSettingsDrawer";
import FilterDrawer from "./FilterDrawer";

export default function TableHead() {
  const { setVisibleViewSettingsDrawer, setVisibleFilterDrawer, selectedStatus } =
    useContext(Context);

  const [isFilterActive, setIsFilterActive] = useState(false);

  const [clickedCsv, setClickedCsv] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (selectedStatus != null) {
      setIsFilterActive(true);
      return;
    }

    setIsFilterActive(false);
  }, [selectedStatus]);

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);

      function callback(data) {
        const newList = data.map((list) => {
          return {
            Enable: list.enable,
            Name: list.name,
            Url: list.url,
          };
        });
        done(newList);
        setDataList(newList);

        setClickedCsv(false);
      }

      function errorCallback() {
        setClickedCsv(false);
      }

      REQUESTS.EPG.GET({}, callback, errorCallback);
    }
  };

  return (
    <>
      <TableHeadComponent>
        <CsvLinkComponent
          filename="LiveTv-EpgLinks-list.csv"
          data={dataList}
          onClick={onClicCsv}
        />

        <IconButton
          icon={ICONS.SETTINGS}
          onClick={() => setVisibleViewSettingsDrawer(true)}
        />
        <IconButton
          active={isFilterActive}
          icon={ICONS.FILTER}
          onClick={() => setVisibleFilterDrawer(true)}
        />
      </TableHeadComponent>

      <ViewSettingsDrawer />

      <FilterDrawer />
    </>
  );
}
