import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import REQUESTS from "../../../server/requests";

import EN from "../../../config/en";

import PageHeader from "../../elements/PageHeader";
import HeaderItem from "../../elements/HeaderItem";

export default function DashboardHeader() {

    const translate = useSelector((state) => state.globalState.translate);

    const [statistics, setStatistics] = useState({
        earnings: 0,
        news: 0,
        occupied_locations: 0,
        package: 0,
        products: 0,
        services: 0,
        vacant_locations: 0,
    });

    useEffect(() => {
        REQUESTS.DASHBOARD.STATISTICS_DASHBOARD({}, (data) => {
            setStatistics(data);
        });
    }, []);

    return (
        <PageHeader title={translate['Dashboard'] || EN['Dashboard']} contentClassName="dashboard-header">
            <HeaderItem title={translate['Earnings'] || EN['Earnings']} total={statistics.earnings} />
            <HeaderItem title={translate['News'] || EN['News']} total={statistics.news} />
            <HeaderItem title={translate['Occupied locations'] || EN['Occupied locations']} total={statistics.occupied_locations} />
            <HeaderItem title={translate['Vacant locations'] || EN['Vacant locations']} total={statistics.vacant_locations} />
            <HeaderItem title={translate['Packages'] || EN['Packages']} total={statistics.package} />
            <HeaderItem title={translate['Products'] || EN['Products']} total={statistics.products} />
            <HeaderItem title={translate['Services'] || EN['Services']} total={statistics.services} />
        </PageHeader>
    );
}
