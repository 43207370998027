import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import REQUESTS from "../../../server/requests";

import EN from "../../../config/en";

import HeaderItem from "../../elements/HeaderItem";
import PageHeader from "../../elements/PageHeader";
import AddButtonComponent from "../../elements/AddButtonComponent";

export default function ScreenMirrHead({ total, visibleAddDrawer }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [totalValue, setTotalValue] = useState(0);

  useEffect(() => {
    // REQUESTS.PROMOTIONS.NEWS.STATISTICS((data) => {
    //     setTotalValue(data.count);
    // });
  }, [total]);

  return (
    <PageHeader title={translate["Screen Mirroring"] || EN["Screen Mirroring"]}>
      <HeaderItem title={translate["Total"] || EN["Total"]} total={total} />

      <AddButtonComponent
        text={translate["Add Screen"] || EN["Add Screen"]}
        style={{ width: 180 }}
        onClick={visibleAddDrawer}
      />
    </PageHeader>
  );
}
