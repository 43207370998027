import { Dropdown, Menu, Progress } from "antd";

import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import EN from "../../config/en";

import { actions } from "../../store";
import { useEffect } from "react";

export default function UploadingList() {
    const dispatch = useDispatch();

    const uploadingList = useSelector((state) => state.globalState.uploading_list);

    const translate = useSelector((state) => state.globalState.translate);

    const { setOpenProfileDrawerInRedux } = bindActionCreators(actions, dispatch);

    const onClick = ({ key }) => {
        switch (key) {
        }
    };

    const menu = (
        <Menu onClick={onClick} className="uploading-videos-component">
            {uploadingList.map((item) => {
                return (
                    <Menu.Item key={item.id} title={item.name}>
                        <div className="uploading-video">
                            <img src={item.poster} />

                            <div>
                                <p>{item.name}</p>
                                <p className="type">Type: {item.type}</p>

                                {item.type === "TV-show" && (
                                    <>
                                        <p className="type">Season: {item.season}</p>
                                        <p className="type">Episode: {item.episode}</p>
                                    </>
                                )}
                                <Progress percent={item.progress} size="small" width={"100%"} />
                            </div>
                        </div>
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    if (uploadingList.length) {
        return (
            <Dropdown overlay={menu} trigger={["click"]}>
                <div id="uploading_item_id">
                    <p className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                        {translate['Uploading videos'] || EN['Uploading videos']} {uploadingList.length}
                    </p>
                </div>
            </Dropdown>
        );
    } else {
        return null;
    }
}
