import { Tooltip } from "antd";
import { useSelector } from "react-redux";

export default function Currency({ children }) {
    const organizationInfo = useSelector((state) => state.globalState.organizationInfo);

    return (
        <div style={{ display: "flex" }} className="txt-tb-r">
            {organizationInfo && organizationInfo.currency && (
                <Tooltip title={organizationInfo.currency.name}>
                    <p style={{ color: "#6a7082", marginRight: 5, cursor: "pointer" }}>
                        {organizationInfo.currency.symbolNative}
                    </p>
                </Tooltip>
            )}

            {children}
        </div>
    );
}
