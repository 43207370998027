import React, { useEffect, useState } from "react";

export default function Page({ children }) {
  const [blurred, setBlurred] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setBlurred(false);
    }, [500]);
  }, []);

  return <div className={`${blurred && "page-blurred"}`}>{children}</div>;
}
