import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Table, Input } from "antd";

import REQUESTS from "../../../server/requests";

import ICONS from "../../../config/icons";
import confirmModal from "../../../config/confirmModal";
import { dateFormat } from "../../../config/formats";

import TableButtons from "../../elements/TableButtons";
import Page from "../../elements/Page";
import ImageComponent from "../../elements/ImageComponent";

import HelpDrawer from "./Drawer";
import AppHead from "./Head";
import EN from "../../../config/en";
import TableHeadComponent from "../../elements/TableHeadComponent";
import CsvLinkComponent from "../../elements/CsvLinkComponent";

export default function HelpInfoPage() {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [visibleAddDrawer, setVisibleAddDrawer] = useState(false);

  const [appList, setAppList] = useState(false);
  const [currentApp, setCurrentApp] = useState(null);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [titleSearch, setTitleSearch] = useState(null);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [clickedCsv, setClickedCsv] = useState(false);

  useEffect(() => {
    if (!visibleAddDrawer) {
      setCurrentApp(null);
    }
  }, [visibleAddDrawer]);

  useEffect(() => {
    getAppList();
  }, [currentPage, limit, sort]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getAppList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [titleSearch]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      fixed: "left",
      key: "id",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    },

    {
      width: 100,
      title: "",
      dataIndex: "screen",
      render: (text, record, index) => (
        <ImageComponent src={text} className="tableImage" />
      ),
    },

    {
      title: translate["Language"] || EN["Language"],
      dataIndex: "language",
      key: "language",
      sorter: true,
      render: (text, record, index) => (
        <p className="text-ellipsis">{record.language?.name}</p>
      ),
    },

    {
      title: translate["Date"] || EN["Date"],
      key: "createdAt",
      sorter: true,
      dataIndex: "createdAt",
      render: (text, record, index) => dateFormat(text),
    },

    {
      title: "",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          style={{ minWidth: 130 }}
          buttons={[
            {
              key: "edit",
              text: translate["Edit"] || EN["Edit"],
              icon: ICONS.EDIT,
            },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE,
            },
          ]}
        />
      ),
    },
  ];
  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setCurrentApp(item);
        setVisibleAddDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
            item.name
          }`,
          action: () => deleteApp(item),
        });
        break;

      default:
        break;
    }
  };
  const deleteApp = (item) => {
    REQUESTS.HELP_INFO.DELETE(item.id, getAppList);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    if (Object.values(filters)[0]) {
      setTitleSearch(Object.values(filters)[0].join(""));
    } else {
      setTitleSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const getAppList = () => {
    setLoading(true);
    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage,
    };

    function callback(data) {
      setAppList(data.rows);
      setLimit(data.limit);
      setTotal(data.count);
      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }
    REQUESTS.HELP_INFO.GET(query, callback, errorCallback);
  };

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);

      function callback(data) {
        const newList = data.map((list) => {
          console.log(list);
          return {
            Name: list.language.name,
            Iso: list.language.iso,
          };
        });
        done(newList);
        // setDataList(newList);

        setClickedCsv(false);
      }

      function errorCallback() {
        setClickedCsv(false);
      }

      REQUESTS.HELP_INFO.GET({}, callback, errorCallback);
    }
  };

  return (
    <Page>
      <AppHead visibleAddDrawer={() => setVisibleAddDrawer(true)} total={total} />
      <div className="page-body">
        <TableHeadComponent>
          <CsvLinkComponent
            filename="Screen-mirroring-List.csv"
            //    data={dataList}
            onClick={onClicCsv}
          />
        </TableHeadComponent>
        <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          dataSource={appList}
          onChange={handleTableChange}
          pagination={{
            position: ["topCenter", "bottomCenter"],
            current: currentPage,
            total: total,
            showSizeChanger: true,
          }}
          sticky
        />
        <HelpDrawer
          visible={visibleAddDrawer}
          onClose={() => setVisibleAddDrawer(false)}
          current={currentApp}
          getAppList={getAppList}
        />
      </div>
    </Page>
  );
}
