import { Popover } from "antd";

export default function LongText({
  children = "",
  width = 200,
  textAlign = "left",
  paddingLeft = "15%"
}) {
  let text = "";

  if (typeof children === "string") {
    text = children.replace(/(<([^>]+)>|&nbsp;)/gi, "");
  }

  const content = <div dangerouslySetInnerHTML={{ __html: children }} />;

  return (
    <Popover content={content}>
      <div
        className="text-ellipsis"
        style={{
          width: width,
          textAlign: textAlign
          // paddingLeft: paddingLeft,
        }}
      >
        {text}
      </div>
    </Popover>
  );
}
