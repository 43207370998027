import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "antd";
import REQUESTS from "../../../../../../server/requests";

// import Context from "./Context";

import ICONS from "../../../../../../config/icons";
import EN from "../../../../../../config/en";
import GalleryComponent from "../../../../../elements/GalleryComponent";
import NewGalleryDrawer from "./NewGalleryDrawer";

export default function GalleriesDrawer({ open, onClose, roomInfo }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [list, setList] = useState([]);

  const [visibleNewGalleryDrawer, setVisibleNewGalleryDrawer] = useState(false);

  useEffect(() => {
    if (open) {
      getList();
    } else {
      setList([]);
    }
  }, [open]);

  const getList = () => {
    REQUESTS.BOOKING.ROOM.GALLERY.GET({}, (data) => {
      const newData = data.rows.filter((item) => item.room_id === roomInfo.id);
      setList(newData);
      console.log(newData, list, "==");
    });
  };

  const deleteGallery = (item) => {
    REQUESTS.BOOKING.ROOM.GALLERY.DELETE(item.id, () => {
      getList();
    });
  };

  return (
    <Drawer
      title={translate["Galleries"] || EN["Galleries"]}
      placement="right"
      width={710}
      onClose={onClose}
      visible={open}
      destroyOnClose={true}
    >
      <div className="list-section">
        <div className="add-component" onClick={() => setVisibleNewGalleryDrawer(true)}>
          {ICONS.PLUS}
        </div>

        {list?.map((item) => {
          return (
            <GalleryComponent key={item.id} item={item} deleteGallery={deleteGallery} />
          );
        })}
      </div>

      <NewGalleryDrawer
        id={roomInfo?.id}
        visible={visibleNewGalleryDrawer}
        onClose={() => setVisibleNewGalleryDrawer(false)}
        getList={getList}
        getInfoList={[]}
      />
    </Drawer>
  );
}
