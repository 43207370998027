import React from "react";
import PropTypes from "prop-types";

import { Button } from "antd";

/**
 * @param {node} icon -> icons.js
 * @param {string} type
 * @param {boolean} loading
 * @param {string} buttonClass -> button-classes.js
 * @param {string} text
 * @param {boolean} disabled
 * @param {func} onClick.isRequired
 *
 * @returns
 */

export default function ButtonComponent({
    text,
    type,
    icon,
    onClick,
    block,
    buttonClass,
    loading,
    style,
    iconLeft = false,
    disabled,
}) {
    return (
        <Button
            disabled={disabled}
            loading={loading}
            block={block}
            type={type}
            className={`button-component ${buttonClass}`}
            onClick={onClick}
            style={style}
        >
            {iconLeft && icon && icon}

            {text}

            {!iconLeft && icon && icon}
        </Button>
    );
}

ButtonComponent.propTypes = {
    icon: PropTypes.node,
    text: PropTypes.string,
    type: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    buttonClass: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};
