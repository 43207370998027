import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Table, Input } from "antd";

import Context from "./Context";

import REQUESTS from "../../../../../server/requests";

import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";
import { dateFormat, parseYearMonthDayHoursMinutes } from "../../../../../config/formats";

import ImageComponent from "../../../../elements/ImageComponent";
import CompletedComponent from "../../../../elements/CompletedComponent";
import Currency from "../../../../elements/Currency";
import Page from "../../../../elements/Page";

import TableHead from "./TableHead";

export default function HistoryOrders({ room_id, updateOrdersTable }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [ordersList, setOrdersList] = useState([]);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({
    name: "",
    type: "",
  });

  const [guestSearch, setGuestSearch] = useState("");

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedPublishedStatus, setSelectedPublishedStatus] = useState(null);
  const [ordersTypeId, setOrdersTypeId] = useState(null);

  const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);
  const [visibleViewSettingsDrawer, setVisibleViewSettingsDrawer] = useState(false);

  const [columns, setColumns] = useState([]);

  const [viewSettingsValues, setViewSettingsValues] = useState({
    id: true,
    image: true,
    name: true,
    type: true,
    price: true,
    completed: true,
    guest: true,
    quantity: true,
    createdAt: true,
    action: true,
    accepted_date: true,
    rejected_date: true,
  });

  const configureTableColumns = () => {
    const newColumns = [];
    newColumns.push({
      width: 60,
      fixed: "left",
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    });

    if (viewSettingsValues.image) {
      newColumns.push({
        width: 100,
        dataIndex: "image",
        key: "image",
        render: (text, record, index) => (
          <ImageComponent src={record.image} className="tableImage" />
        ),
      });
    }

    if (viewSettingsValues.name) {
      newColumns.push({
        title: translate["Name"] || EN["Name"],
        dataIndex: "name",
        key: "name",
        sorter: true,
        render: (text, record, index) => (
          <p className="text-ellipsis" style={{ width: 100 }}>
            {record.name}
          </p>
        ),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <> {ICONS.SEARCHOUTLINED} </>;
        },

        onFilter: (value, record) => {
          return record.name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.type) {
      newColumns.push({
        title: translate["Type"] || EN["Type"],
        width: 120,
        dataIndex: "type",
        key: "type",
        render: (text, record, index) => record.type,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <> {ICONS.SEARCHOUTLINED} </>;
        },

        onFilter: (value, record) => {
          return record.type.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.guest) {
      newColumns.push({
        title: translate["Guest Name"] || EN["Guest Name"],
        dataIndex: "guest",
        key: "guest",
        width: 200,
        render: (text, record, index) => (
          <p className="text-ellipsis">{record.guest?.name}</p>
        ),
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={guestSearch[0]}
              onChange={(e) => {
                setGuestSearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <> {ICONS.SEARCHOUTLINED} </>;
        },

        onFilter: (value, record) => {
          return record.guest?.name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.price) {
      newColumns.push({
        title: translate["Price"] || EN["Price"],
        width: 100,
        dataIndex: "price",
        key: "price",
        sorter: true,
        render: (text, record, index) => (
          <Currency>{parseFloat(record.price).toFixed(2)}</Currency>
        ),
      });
    }

    if (viewSettingsValues.completed) {
      newColumns.push({
        title: translate["Status"] || EN["Status"],
        dataIndex: "completed",
        key: "completed",
        sorter: true,
        width: 130,
        render: (text, record, index) => {
          <CompletedComponent check={record.completed} />;
        },
      });
    }

    if (viewSettingsValues.quantity) {
      newColumns.push({
        title: translate["Quantity"] || EN["Quantity"],
        dataIndex: "quantity",
        key: "quantity",
        sorter: true,
        width: 100,
        render: (text, record, index) => <p className="txt-tb-r">{record.quantity} </p>,
      });
    }
    if (viewSettingsValues?.createdAt) {
      newColumns.push({
        title: translate["Date"] || EN["Date"],
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: true,
        render: (text, record, index) => dateFormat(text),
      });
    }

    newColumns.push({
      title: translate["Reserved Date"] || EN["Reserved Date"],
      dataIndex: "reserved_date",
      key: "reserved_date",
      render: (text, record, index) => (
        <p className="text-ellipsis txt-tb-r">
          {record.reserved_date
            ? parseYearMonthDayHoursMinutes(record.reserved_date)
            : ""}
        </p>
      ),
    });

    if (viewSettingsValues.accepted_date) {
      newColumns.push({
        title: translate["Accepted Date"] || EN["Accepted Date"],
        dataIndex: "accepted_date",
        key: "accepted_date",
        render: (text, record, index) => (
          <p className="text-ellipsis txt-tb-r">
            {record.accepted_date
              ? parseYearMonthDayHoursMinutes(record.accepted_date)
              : "-"}
          </p>
        ),
      });
    }

    if (viewSettingsValues.rejected_date) {
      newColumns.push({
        title: translate["Rejected Date"] || EN["Rejected Date"],
        dataIndex: "rejected_date",
        key: "rejected_date",
        render: (text, record, index) => (
          <p className="text-ellipsis txt-tb-r">
            {record.rejected_date
              ? parseYearMonthDayHoursMinutes(record.rejected_date)
              : "-"}
          </p>
        ),
      });
    }

    setColumns(newColumns);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getOrdersList();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    currentPage,
    limit,
    sort,
    room_id,
    total,
    selectedPublishedStatus,
    ordersTypeId,
    updateOrdersTable,
  ]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getOrdersList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [search]);

  useEffect(() => {
    let timeout = null;
    if (guestSearch[0]) {
      if (guestSearch[0]?.length > 2) {
        timeout = setTimeout(() => {
          getOrdersList();
        }, 1000);
      }
    }
    // else {
    //     getOrdersList("0");
    // }

    return () => {
      clearTimeout(timeout);
    };
  }, [guestSearch]);

  useEffect(() => {
    configureTableColumns();
  }, [viewSettingsValues, currentPage]);

  useEffect(() => {
    let ORDERS_COLUMNS = localStorage.getItem("ORDERS_COLUMNS");

    if (ORDERS_COLUMNS) {
      ORDERS_COLUMNS = JSON.parse(ORDERS_COLUMNS);
      setViewSettingsValues(ORDERS_COLUMNS);
    }
  }, []);

  const getOrdersList = () => {
    if (!room_id) return;

    setLoading(true);

    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage,
    };

    query.filter = JSON.stringify({
      room_id: room_id,
    });

    let filter = {};

    if (selectedPublishedStatus != null) {
      filter.completed = selectedPublishedStatus;
      filter.room_id = room_id;
    }
    if (ordersTypeId) {
      filter.type = ordersTypeId;
      filter.room_id = room_id;
    }
    if (guestSearch[0]) {
      query.guest_name = guestSearch[0];
    }

    const size = Object.keys(filter).length;
    if (size) {
      query.filter = JSON.stringify(filter);
    }

    if (search.name || search.type) {
      query.search = JSON.stringify({
        name: search.name && search.name[0],
        type: search.type && search.type[0],
      });
    }
    function callback(data) {
      setOrdersList(data?.rows);
      setCurrentPage(data.currentPage);
      setTotal(data.count);
      setLimit(data.limit);
      setLoading(false);
      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.ORDERS.GET(query, callback, errorCallback);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    // if (Object.values(filters)[0]) {
    //     setNameSearch(Object.values(filters)[0].join(""));
    // } else {
    //     setNameSearch(null);
    // }
    setSearch(filters);

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  return (
    <Context.Provider
      value={{
        room_id,
        total,
        getOrdersList,
        visibleViewSettingsDrawer,
        setVisibleViewSettingsDrawer,
        viewSettingsValues,
        setViewSettingsValues,
        visibleFilterDrawer,
        setVisibleFilterDrawer,
        selectedPublishedStatus,
        setSelectedPublishedStatus,
        ordersTypeId,
        setOrdersTypeId,
      }}
    >
      <Page>
        <div className="page-body">
          <TableHead />
          <Table
            defaultExpandAllRows={true}
            loading={loading}
            rowKey="id"
            columns={columns}
            dataSource={ordersList}
            onChange={handleTableChange}
            scroll={{
              x: 1600,
              y: null,
            }}
            pagination={{
              position: ["topCenter", "bottomCenter"],
              current: currentPage,
              total: total,
              showSizeChanger: true,
            }}
            sticky={{
              offsetHeader: 60,
              offsetScroll: 0,
            }}
          />
        </div>
      </Page>
    </Context.Provider>
  );
}
