import React, { useState } from "react";

import REQUESTS from "../../../server/requests";

import TableHeadComponent from "../../elements/TableHeadComponent";
import CsvLinkComponent from "../../elements/CsvLinkComponent";

export default function FeedbackTableHead() {
  const [clickedCsv, setClickedCsv] = useState(false);
  const [dataList, setDataList] = useState([]);

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);
      function callback(data) {
        const newList = data.map((list) => {
          return {
            Questions: list.questions,
            Description: list.description.replace(/(<([^>]+)>|&nbsp;)/gi, ""),
            Data: list.createdAt,
          };
        });
        done(newList);

        setDataList(newList);

        setClickedCsv(false);
      }

      function errorCallback(err) {
        console.log(err);
        setClickedCsv(false);
      }

      REQUESTS.FEEDBACK_QUESTIONS.GET({}, callback, errorCallback);
    }
  };
  return (
    <TableHeadComponent>
      <CsvLinkComponent
        filename="Feedback-List.csv"
        data={dataList}
        onClick={onClicCsv}
      />
    </TableHeadComponent>
  );
}
