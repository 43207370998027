import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, Checkbox, notification } from "antd";

import REQUESTS from "../../../server/requests";

import SaveButtonComponent from "../../elements/SaveButtonComponent";
import ErrorMessage from "../../elements/ErrorMessage";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";
import FileChooser from "../../elements/FileChooser";

export default function LogoBackgroundDrawer({
  visible,
  onClose,
  currentItems,
  getData,
}) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);
  const [backgroundSrc, setBackgroundSrc] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();

      if (currentItems) {
        form.setFields([
          { name: "name", value: currentItems.name },
          { name: "enable", value: currentItems.enable },
          { name: "image", value: currentItems.image },
        ]);

        if (currentItems.image) {
          setImageSrc(currentItems.image);
        }

        if (currentItems.background_image) {
          setBackgroundSrc(currentItems.background_image);
        }
      }
    } else {
      setErrorText(null);
      setImageSrc(null);
      setBackgroundSrc(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);
    const body = {
      name: values.name,
      enable: values.enable,
      image: values.image,
      background_image: values.background_image,
    };

    function callback() {
      setLoading(false);
      onClose();
      getData();
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (currentItems) {
      body.id = currentItems.id;
      REQUESTS.TV_APPLICATION.TV_MENU_CHANGE(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    form.setFields([{ name: "image", value: item.file_url }]);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "image", value: null }]);
  };

  const selectBackground = (item) => {
    setBackgroundSrc(item.file_url);
    form.setFields([{ name: "background_image", value: item.file_url }]);
  };

  const repliceBackground = () => {
    setBackgroundSrc(null);
    form.setFields([{ name: "background_image", value: null }]);
  };

  return (
    <Drawer
      title={translate["Edit"] || EN["Edit"]}
      placement="right"
      width={500}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      visible={visible}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="edit-text-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          enable: false,
        }}
      >
        <Form.Item
          label={translate["Image"] || EN["Image"]}
          tooltip={{
            title: "Choose an image",
            icon: ICONS.INFO,
          }}
          name="image"
          rules={[
            {
              required: true,
              message: `${translate["Please choose"] || EN["Please choose"]} ${
                translate["Image"] || EN["Image"]
              }`,
            },
          ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        <Form.Item
          label={translate["Background"] || EN["Background"]}
          tooltip={{
            title: "Choose an background",
            icon: ICONS.INFO,
          }}
          name="background_image"
        >
          <FileChooser
            src={backgroundSrc}
            onChange={selectBackground}
            replace={repliceBackground}
            types={["image"]}
          />
        </Form.Item>

        <Form.Item
          label={translate["Name"] || EN["Name"]}
          tooltip={{
            title: "name",
            icon: ICONS.INFO,
          }}
          name="name"
        >
          <Input />
        </Form.Item>

        <Form.Item name="enable" valuePropName="checked">
          <Checkbox>{translate["Enable"] || EN["Enable"]}</Checkbox>
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>

        {/* <ErrorMessage>{errorText}</ErrorMessage> */}
      </Form>
    </Drawer>
  );
}
