import { useState, useEffect } from "react";
import EN from "../config/en";
import REQUESTS from "../server/requests";

export function usePackagesOptions() {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    REQUESTS.PACKAGES.GET({}, (data) => {
      const list = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setPackages(list);
    });
  }, []);

  return packages;
}

export function useRoomsGroupsOptions() {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    REQUESTS.ROOMS.GROUPS.GET({}, (data) => {
      const list = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setGroups(list);
    });
  }, []);

  return groups;
}

export function useEcommerceCategoriesOptions() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    REQUESTS.ECOMMERCE.CATEGORIES.GET({}, (data) => {
      const list = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setCategories(list);
    });
  }, []);

  return categories;
}

export function useOrganizationsOptions() {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.ORGANIZATIONS.GET({}, (data) => {
      const list = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setList(list);
    });
  }, []);

  return list;
}

export function useEmployersOptions() {
  const [employers, setEmployers] = useState([]);

  useEffect(() => {
    REQUESTS.EMPLOYERS.GET({}, (data) => {
      const list = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setEmployers(list);
    });
  }, []);

  return employers;
}

export function useLanguagesOptions() {
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    REQUESTS.LANGUAGES.GET({}, (data) => {
      const list = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setLanguages(list);
    });
  }, []);

  return languages;
}

export function useCountriesOptions() {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    REQUESTS.COUNTRY.GET({}, (data) => {
      const list = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setCountries(list);
    });
  }, []);

  return countries;
}

export function usePublishedStatusOptions() {
  const status = [
    {
      label: EN["Yes"],
      value: true,
    },

    {
      label: EN["No"],
      value: false,
    },
  ];

  return status;
}

export function useTizenPlayerOptions() {
  return [
    {
      label: "HTML5",
      value: "html5",
    },

    {
      label: "AVPLAYER",
      value: "avplayer",
    },
  ];
}

export function useDeviceTypeOptions() {
  return [
    {
      label: "Tizen",
      value: "tizen",
    },

    {
      label: "Webos",
      value: "webos",
    },

    {
      label: "Android",
      value: "android",
    },
  ];
}

export function useTvChannelsCategoriesOptions() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    REQUESTS.TV_CHANNELS_CATEGORIES.GET({}, (data) => {
      const list = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setCategories(list);
    });
  }, []);

  return categories;
}

export function usePromotionsCategoriesOptions() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    REQUESTS.PROMOTIONS.CATEGORIES.GET({}, (data) => {
      const list = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setCategories(list);
    });
  }, []);

  return categories;
}

export function useMediaTypesOptions() {
  return [
    {
      label: "Image",
      value: "image",
    },

    {
      label: "Video",
      value: "video",
    },

    {
      label: "Audio",
      value: "audio",
    },
  ];
}

export function useInfoCenterCategoriesOptions() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    REQUESTS.INFO_CENTER.CATEGORIES.GET({}, (data) => {
      const list = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setCategories(list);
    });
  }, []);

  return categories;
}

export function useVodCategoriesOptions() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    REQUESTS.VOD.GENRES.GET({}, (data) => {
      const list = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setCategories(list);
    });
  }, []);

  return categories;
}

export function useMovieTypesOptions() {
  const types = [
    {
      label: "Movie",
      value: "movie",
    },

    {
      label: "Tv-show",
      value: "tv",
    },
  ];

  return types;
}

export function useProductsOptions() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    REQUESTS.ECOMMERCE.PRODUCTS.GET({}, (data) => {
      const list = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setProducts(list);
    });
  }, []);

  return products;
}

export function useServicesOptions() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    REQUESTS.ECOMMERCE.SERVICES.GET({}, (data) => {
      const list = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setServices(list);
    });
  }, []);

  return services;
}

export function useVodOptions() {
  const [vod, setVod] = useState([]);
  useEffect(() => {
    REQUESTS.VOD.MOVIES.GET({}, (data) => {
      const list = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setVod(list);
    });
  }, []);
  return vod;
}

export function useOrdersOptions() {
  const types = [
    {
      label: "Product",
      value: "product",
    },

    {
      label: "Service",
      value: "service",
    },

    {
      label: "Vod",
      value: "vod",
    },
  ];

  return types;
}
export function useInfoCenterTypesOptions() {
  const types = [
    {
      label: "Local information",
      value: "local_information",
    },

    {
      label: "Hotel information",
      value: "hotel_information",
    },

    {
      label: "Useful information",
      value: "useful_info",
    },
  ];

  return types;
}
export function useDevicesSelecteList() {
  const [devicesList, setDevicesList] = useState([]);

  useEffect(() => {
    REQUESTS.ONLINE_DEVICES.GET({}, (data) => {
      const list = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setDevicesList(list);
    });
  }, []);

  return devicesList;
}

export function useLocationsOptions() {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    REQUESTS.ROOMS.GET({}, (data) => {
      const list = data.map((item) => ({
        label: item.location_name,
        value: item.id,
      }));
      setLocations(list);
    });
  }, []);

  return locations;
}

export function useHostNameOptions() {
  const [hostName, setHostName] = useState([]);
  let newArr = [];
  useEffect(() => {
    REQUESTS.ROOMS.GET({}, (data) => {
      data.map((item) => {
        if (item.guest !== null) {
          newArr.push({
            name: item.guest.name,
            id: item.guest.id,
          });
        }
      });
      const list = newArr.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setHostName(list);
    });
  }, []);
  return hostName;
}

export function useRadioCategoriesOptions() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    REQUESTS.RADIO_CATEGORIES.GET({}, (data) => {
      const list = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setCategories(list);
    });
  }, []);

  return categories;
}

export function useMusicCategoriesOption() {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    REQUESTS.MUSIC_CATEGORY.GET({}, (data) => {
      const list = data?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setCategories(list);
    });
  }, []);
  return categories;
}
