import { useEffect, useState } from "react";

import REQUESTS from "../../../../server/requests";

import ICONS from "../../../../config/icons";
import IconButton from "../../../elements/IconButton";
import TableHeadComponent from "../../../elements/TableHeadComponent";
import CsvLinkComponent from "../../../elements/CsvLinkComponent";
import { useRoomsGroupsOptions } from "../../../../hooks/options";

export default function FiltersSection({
  onClickFilter,
  onClickViewSettings,
  selectedGroupId,
}) {
  const [isFilterActive, setIsFilterActive] = useState(false);

  const [clickedCsv, setClickedCsv] = useState(false);

  const [dataList, setDataList] = useState([]);

  const groupOptions = useRoomsGroupsOptions();

  useEffect(() => {
    if (selectedGroupId) {
      setIsFilterActive(true);

      return;
    }

    setIsFilterActive(false);
  }, [selectedGroupId]);

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);

      function callback(data) {
        if (data?.length > 0) {
          const newList = data?.map((list) => {
            let category;
            let guest;

            if (list?.group_id && groupOptions.length > 0) {
              const group = groupOptions.map((item) => {
                if (item.value == list.group_id) {
                  return item.label;
                }
              });
              category = group;
            } else {
              category = "-";
            }
            if (list.guest) {
              guest = list.guest.name;
            } else {
              guest = "no guest";
            }

            return {
              Location_No: list.location_no,
              Location_Name: list.location_name,
              Category: category,
              Host_Name: guest,
              Due: list.balance,
            };
          });

          done(newList);

          setDataList(newList);

          setClickedCsv(false);
        } else {
          return;
        }
      }

      function errorCallback() {
        setClickedCsv(false);
      }
      REQUESTS.ROOMS.GET({}, callback, errorCallback);
    }
  };

  return (
    <TableHeadComponent>
      <CsvLinkComponent
        filename="Locations-List.csv"
        data={dataList}
        onClick={onClicCsv}
      />

      <IconButton icon={ICONS.SETTINGS} onClick={onClickViewSettings} />

      <IconButton active={isFilterActive} icon={ICONS.FILTER} onClick={onClickFilter} />
    </TableHeadComponent>
  );
}
