import React from "react";
import PropTypes from "prop-types";
import ICONS from "../../config/icons";

export default function Check({ check }) {
    return check ? (
        <div className="save-icon" style={{textAlign:"center"}}>{ICONS.SAVE}</div>
    ) : (
        <div className="x-icon" style={{textAlign:"center"}}>{ICONS.X}</div>
    );
}

Check.propTypes = {
    check: PropTypes.bool.isRequired,
};
