import React, { useState, useEffect } from "react";
import { Drawer, Form, Input, notification, Progress } from "antd";
import { actions } from "../../../store";

import REQUESTS from "../../../server/requests";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";

import ErrorMessage from "../../elements/ErrorMessage";
import SaveButtonComponent from "../../elements/SaveButtonComponent";
import axios from "axios";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import URLS from "../../../server/urls";

export default function MediaLibraryDrawer({ visible, onClose, current, getList }) {
  const dispatch = useDispatch();

  const translate = useSelector((state) => state.globalState.translate);

  const { setUploadingListInRedux } = bindActionCreators(actions, dispatch);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [form] = Form.useForm();

  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    } else {
      setErrorText(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    for (let i = 0; i < files.length; i++) {
      getUploadStatus(files[i]);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  const chooseFile = (event) => {
    setFiles(event.target.files);
  };

  const getUploadStatus = (file) => {
    // if (file.lastModifiedDate) {
    const fileType = file.type.split("/")[0];
    const fileId = `${file.lastModified}_${file.name}`;

    const body = {
      fileId,
      name: file.name,
      file_type: fileType,
    };

    function callback(data) {
      upload(file, fileType, fileId, data.totalChunkUploaded);
    }

    function errorCallback(err) {
      // notification.error({ description: err });
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
    }

    REQUESTS.VOD.MOVIES.UPLOAD.STATUS(body, callback, errorCallback);
    // }
  };

  const upload = (file, fileType, fileId, totalChunkUploaded) => {
    let chunk = file.slice(totalChunkUploaded);

    const myAxios = axios.create();

    myAxios.defaults.headers.common["Authorization"] = localStorage.getItem("TOKEN");

    const formData = new FormData();

    formData.append("chunk", chunk, file.name);
    formData.append("fileId", file.lastModified);

    myAxios
      .post(`${URLS.UPLOAD}`, formData, {
        headers: {
          "starting-byte": totalChunkUploaded,
          "x-file-id": `${file.lastModified}_${file.name}`,
          "chunk-size": chunk.size,
          "file-size": file.size,
        },

        onUploadProgress: (progressEvent) => {
          const total = parseFloat(progressEvent.total);
          const loaded = parseFloat(progressEvent.loaded);

          const progress = Math.floor((loaded / total) * 100);

          setUploadingListInRedux({
            id: fileId,
            type: fileType,
            name: file.name,
            poster: file.file_url,
            progress,
          });
        },
      })
      .then((response) => {
        if (response.data.error) {
        } else {
          if (response.data.message === "This file already exists") {
            notification.warn({ description: response.data.message });
          } else {
            notification.success({
              message: (
                <div className="uploading-video">
                  <div>
                    <p>{file.name}</p>
                    <Progress percent={100} size="small" width={"100%"} />
                  </div>
                </div>
              ),
              description: ` `,
              style: {
                width: "max-content",
              },
            });
          }

          if (getList) {
            getList();
          }
        }
      });

    onClose();
    if (getList) {
      getList();
    }
  };

  return (
    <Drawer
      title={translate["Add New Media Library"] || EN["Add New Media Library"]}
      placement="right"
      visible={visible}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="library-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["File"] || EN["File"]}
          tooltip={{
            title: "media library file",
            icon: ICONS.INFO,
          }}
          name="fileId"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["File"] || EN["File"]
              }`,
            },
          ]}
        >
          <Input type="file" onChange={chooseFile} multiple />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>
      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
