import React from "react";

export default function PageHeader({ title, children, contentClassName }) {
  return (
    <div className="page-header">
      <h1 className="title">{title}</h1>
      <div className={`content ${contentClassName ? contentClassName : ""}`}>
        {children}
      </div>
    </div>
  );
}
