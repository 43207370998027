import { useEffect, useState } from "react";
import { Select, Form } from "antd";
import REQUESTS from "../../server/requests";
import ICONS from "../../config/icons";
import { useSelector } from "react-redux";
import EN from "../../config/en";

const TagComponent = ({ type, form }) => {
  const [options, setOptions] = useState([]);
  const [serverOptions, setServerOptions] = useState([]);
  const [value, setValue] = useState([]);

  const translation = useSelector((state) => state.globalState.translate);

  const getTags = () => {
    try {
      function successCallBack(response) {
        const tagOption = response.map((tag) => ({
          label: tag.name,
          value: tag.name,
          key: `option-${tag.id}`,
        }));

        setServerOptions(tagOption);
        setOptions(tagOption);
      }

      function errorCallBack(err) {
        console.error(err);
      }

      REQUESTS.TAGS.GET({ type }, successCallBack, errorCallBack);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (selectedLabels) => {
    const uniqueLabels = Array.from(new Set(selectedLabels));

    const newLabels = uniqueLabels.filter(
      (label) => !serverOptions.some((option) => option.label === label)
    );

    if (newLabels.length) {
      const updatedOptions = [
        ...serverOptions,
        ...newLabels.map((label) => ({
          label,
          value: label,
          key: `new-${label}-${Date.now()}`,
        })),
      ];

      setOptions(updatedOptions);
    } else {
      setOptions(serverOptions);
    }

    setValue(uniqueLabels);

    form.setFieldsValue({ tags: uniqueLabels });
  };

  useEffect(() => {
    getTags();
  }, [type]);

  return (
    <Form.Item
      name="tags"
      label={translation["Tags"] || EN["Tags"]}
      tooltip={{
        title: translation["Choose tags"] || EN["Choose tags"],
        icon: ICONS.INFO,
      }}
    >
      <Select
        showSearch
        mode="tags"
        options={options}
        onChange={handleChange}
        value={value}
      />
    </Form.Item>
  );
};

export default TagComponent;
