import { useEffect, useState } from "react";
import REQUESTS from "../../../../server/requests";
import Page from "../../../elements/Page";
import { Table, Button, notification, Tag } from "antd";
import TableHead from "./TableHead";
import ICONS from "../../../../config/icons";

import HeaderItem from "../../../elements/HeaderItem";
import MusicGenreDrawer from "./draawer/MusicGenreDrawer";
import TableButtons from "../../../elements/TableButtons";
import ImageComponent from "../../../elements/ImageComponent";
import confirm from "antd/lib/modal/confirm";
import { useSelector } from "react-redux";
import EN from "../../../../config/en";
import AddButtonComponent from "../../../elements/AddButtonComponent";
import CsvLinkComponent from "../../../elements/CsvLinkComponent";
import FilterDrawer from "../../media-library/FilterDrawer";

const MusicGenres = () => {
  const translation = useSelector((state) => state.globalState.translate);

  const [dataSource, setDataSource] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [editable, setEditable] = useState(null);
  const [loading, setLoading] = useState(false);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  let debounceAddGenre = null;

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      fixed: "left",
      width: 50,
    },
    {
      title: translation["Image"] || EN["Image"],
      dataIndex: "image",
      key: "image",
      align: "center",

      render: (text, record) => {
        return <ImageComponent src={text} className="tableImage" />;
      },
    },
    {
      title: translation["Tags"] || EN["Tags"],
      dataIndex: "tags",
      key: "tags",
      align: "center",
      render: (text, record) => {
        return text?.map((tag) => (
          <Tag key={tag} color="blue">
            {tag ? tag : "N/A"}
          </Tag>
        ));
      },
    },

    {
      title: translation["Name"] || EN["Name"],
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      fixed: "right",
      width: 50,
      render: (text, record) => {
        return (
          <TableButtons
            handleMenuClick={(key) => handleMenuClick(key, record)}
            buttons={[
              {
                key: "edit",
                text: translation["Edit"] || EN["Edit"],
                icon: ICONS.EDIT,
              },
              {
                key: "delete",
                text: translation["Delete"] || EN["Delete"],
                icon: ICONS.DELETE,
              },
            ]}
          />
        );
      },
    },
  ];

  const getGenres = () => {
    setLoading(true);
    const query = {
      limit,
      page: currentPage,
      total,
      sort,
    };

    function successCallBack(data) {
      setLoading(false);
      setDataSource(data);
    }

    function errorCallBack(err) {
      setLoading(false);
      console.log(err);
    }

    try {
      REQUESTS.MUSIC_CATEGORY.GET(query, successCallBack, errorCallBack);
    } catch (err) {
      setLoading(false);
      throw new Error(err);
    }
  };

  const handleAddGenre = () => {
    setShowDrawer(true);
  };

  const handleGetValues = (values) => {
    setLoading(true);
    clearTimeout(debounceAddGenre);
    debounceAddGenre = setTimeout(() => {
      try {
        if (editable) {
          REQUESTS.MUSIC_CATEGORY.EDIT(
            {
              ...values,
              id: editable.id,
            },
            (data) => {
              setLoading(false);
              setShowDrawer(false);
              getGenres();
              notification.success({
                message: translation["Success"] || EN["Success"],
              });
            },
            (err) => {
              setLoading(false);
              notification.error({
                message: translation["An error occurred"] || EN["An error occurred"],
              });
              console.log(err);
            }
          );
        } else {
          REQUESTS.MUSIC_CATEGORY.ADD(
            values,
            (data) => {
              setLoading(false);

              setShowDrawer(false);
              getGenres();
              notification.success({
                message: translation["Success"] || EN["Success"],
              });
            },
            (err) => {
              setLoading(false);
              notification.error({
                message: translation["An error occurred"] || EN["An error occurred"],
              });

              console.log(err);
            }
          );
        }
      } catch (err) {
        setLoading(false);

        throw new Error(err);
      }
    }, 1000);
  };

  const handleMenuClick = ({ key }, record) => {
    switch (key) {
      case "edit":
        setEditable(record);
        setShowDrawer(true);
        break;

      case "delete":
        confirm({
          title: `${
            translation["Do you want to delete"] || EN["Do you want to delete"]
          } ${record.name}?`,
          onOk() {
            handleDelete(record.id);
          },
        });
        break;

      default:
        break;
    }
  };

  const handleDelete = (id) => {
    setLoading(true);
    try {
      REQUESTS.MUSIC_CATEGORY.DELETE(
        id,
        (data) => {
          setLoading(false);
          notification.success({
            message: translation["Success"] || EN["Success"],
          });
          getGenres();
        },
        (err) => {
          setLoading(false);
          console.log(err);
          notification.error({
            message: translation["An error occurred"] || EN["An error occurred"],
          });
        }
      );
    } catch (err) {
      setLoading(false);
      throw new Error(err);
    }
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
    setCurrentPage(pagination.current);
    setTotal(pagination.total);
  };

  useEffect(() => {
    getGenres();
  }, [currentPage, limit, total]);

  return (
    <Page>
      <div className="page-body">
        <h1>{translation["Music Genres"] || EN["Music Genres"]}</h1>
        <HeaderItem title={"Music genres"} total={dataSource?.length} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <TableHead>
            <div
              style={{
                display: "flex",
                justifyContent: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <CsvLinkComponent
                filename={"music-genres.csv"}
                onClick={(e) => {
                  console.log(e);
                }}
              />
              <FilterDrawer
                setIsFilterActive={() => {
                  console.log("filter", "active");
                }}
              />
              {/* <Button type="genres" onClick={handleAddGenre}>
                {translation["Add Genre"] || EN["Add Genre"]}
              </Button> */}
              <AddButtonComponent
                text={translation["Add Genre"] || EN["Add Genre"]}
                onClick={handleAddGenre}
              />
            </div>
          </TableHead>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          onChange={handleTableChange}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: limit,
            total: total,
            limit,
            showSizeChanger: true,
          }}
        />
      </div>
      <MusicGenreDrawer
        visible={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setEditable(null);
        }}
        getGValues={handleGetValues}
        editable={editable}
        loading={loading}
      />
    </Page>
  );
};

export default MusicGenres;
