import React, { useContext, useState, useEffect } from "react";

import REQUESTS from "../../../../server/requests";

import ProductContext from "./ProductContext";

import ICONS from "../../../../config/icons";

import IconButton from "../../../elements/IconButton";
import TableHeadComponent from "../../../elements/TableHeadComponent";
import CsvLinkComponent from "../../../elements/CsvLinkComponent";

export default function TableHead() {
  const {
    setVisibleViewSettingsDrawer,
    setVisibleFilterDrawer,
    selectedCategoryid,
    selectedPublishedStatus,
  } = useContext(ProductContext);

  const [isFilterActive, setIsFilterActive] = useState(false);

  const [clickedCsv, setClickedCsv] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (selectedCategoryid || selectedPublishedStatus !== null) {
      setIsFilterActive(true);
      return;
    }

    setIsFilterActive(false);
  }, [selectedCategoryid, selectedPublishedStatus]);

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);

      function callback(data) {
        const newList = data.map((list) => {
          let category;

          if (list.product_category) {
            category = list.product_category.name;
          } else {
            category = "-";
          }

          return {
            Name: list.name,
            Type: list.type,
            Price: list.price,
            Category: category,
            Description: list.description.replace(/(<([^>]+)>|&nbsp;)/gi, ""),
          };
        });
        done(newList);

        setDataList(newList);

        setClickedCsv(false);
      }

      function errorCallback() {
        setClickedCsv(false);
      }

      REQUESTS.ECOMMERCE.PRODUCTS.GET({}, callback, errorCallback);
    }
  };
  return (
    <TableHeadComponent>
      <CsvLinkComponent
        filename="Ecommerce-product-list.csv"
        data={dataList}
        onClick={onClicCsv}
      />

      <IconButton
        icon={ICONS.SETTINGS}
        onClick={() => setVisibleViewSettingsDrawer(true)}
      />

      <IconButton
        active={isFilterActive}
        icon={ICONS.FILTER}
        onClick={() => setVisibleFilterDrawer(true)}
      />
    </TableHeadComponent>
  );
}
