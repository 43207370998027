import { useContext } from "react";
import { useSelector } from "react-redux";

import { Checkbox, Form, Input, Select, Switch } from "antd";

import ICONS from "../../../../config/icons";
import EN from "../../../../config/en";

import { useLanguagesOptions, useVodCategoriesOptions } from "../../../../hooks/options";

import Context from "./Context";

export default function MoviesVodSection() {
  const { enableMoviesVod, setEnableMoviesVod, accessToAllMovies, setAccessToAllMovies } =
    useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const languagesOptions = useLanguagesOptions();

  const vodCategoriesOptions = useVodCategoriesOptions();

  const onSearch = (value) => {
    languagesOptions.filter((item) => {
      if (item.label.toLowerCase().includes(value.toLowerCase())) {
        return item;
      }
    });
  };

  return (
    <div>
      <div className="switch-block">
        <p>{translate["Vod"] || EN["Vod"]}</p>
        <Switch
          checked={enableMoviesVod}
          onChange={() => setEnableMoviesVod((value) => !value)}
        />
      </div>

      {enableMoviesVod && (
        <div className="packages-sections-setyle">
          <Checkbox
            style={{ marginTop: 10, marginBottom: 20 }}
            checked={accessToAllMovies}
            onChange={(e) => setAccessToAllMovies(e.target.checked)}
          >
            {translate["Access to all movies"] || EN["Access to all movies"]}
          </Checkbox>

          <Form.Item
            label={translate["Categories"] || EN["Categories"]}
            tooltip={{
              title: "Categories",
              icon: ICONS.INFO,
            }}
          >
            <Form.Item name="vod_categories" noStyle>
              <Select
                mode="multiple"
                disabled={accessToAllMovies}
                options={vodCategoriesOptions}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            label={translate["Language"] || EN["Language"]}
            tooltip={{
              title: "Language",
              icon: ICONS.INFO,
            }}
          >
            <Form.Item name="vod_languages" noStyle>
              <Select
                mode="multiple"
                options={languagesOptions}
                disabled={accessToAllMovies}
                optionFilterProp="label"
                // onChange={onChange}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            label={translate["Rating"] || EN["Rating"]}
            tooltip={{
              title: "Rating",
              icon: ICONS.INFO,
            }}
            rules={[
              {
                type: "number",
                min: 1,
                max: 10,
                message: `${
                  translate["Rating must be between 1 and 10"] ||
                  EN["Rating must be between 1 and 10"]
                }`,
              },
            ]}
          >
            <Form.Item name="vod_rating" noStyle>
              <Input type="text" disabled={accessToAllMovies} />
            </Form.Item>
          </Form.Item>
        </div>
      )}
    </div>
  );
}
