import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input, Tooltip, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import EN from "../../../../../../config/en";
import TMDB, { TMDB_IMAGE_HOST } from "../../../../../../server/tmdb";

import TmdbMovie from "./TmdbMovie";

export default function TmdbSearch({ selectMovieFromTmbd }) {

    const translate = useSelector((state) => state.globalState.translate);
    
    const [search, setSearch] = useState("");

    const [text, setText] = useState("");

    const [list, setList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const onChange = (event) => {
        const value = event.target.value;
        setSearch(value);
        setText(value);
    };

    const select = (item) => {
        setList([]);
        setSearch("");
        setText("");

        TMDB.GET(item.media_type, item.id, (data) => {
            selectMovieFromTmbd(data);
        });
    };

    useEffect(() => {
        setIsEmpty(false);
        setIsLoading(true);

        if (search.length === 0) {
            setList([]);
            return;
        }

        const timeout = setTimeout(() => {
            TMDB.SEARCH.MOVIES(search, (data) => {
                let newList = [];

                if (data.length === 0) {
                    setIsEmpty(true);
                }

                for (let i = 0; i < data.length; i++) {
                    if (data[i].media_type === "movie" || data[i].media_type === "tv")
                        newList.push({
                            id: data[i].id,
                            media_type: data[i].media_type,
                            poster: `${TMDB_IMAGE_HOST}${data[i].poster_path}`,
                            backdrop: `${TMDB_IMAGE_HOST}${data[i].backdrop_path}`,
                            title: data[i].original_title || data[i].original_name,
                            type: data[i].media_type === "tv" ? "Tv-show" : "Movie",
                            date: data[i].release_date || data[i].first_air_date,
                            // ...data[i],
                        });
                }

                setList(newList);

                setIsLoading(false);
            });
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [search]);

    return (
        <div className="tmdb-search">
            <div className="ant-form-item-label">
                <label className="ant-form-item-label label">{translate['Tmdb movies'] || EN['Tmdb movies']}</label>
            </div>

            <Input
                value={text}
                onChange={onChange}
                placeholder={translate['Search movies ...'] || EN['Search movies ...']}
                suffix={
                    <Tooltip title={translate['Search epg ...'] || EN['Search epg ...']}>
                        <SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                    </Tooltip>
                }
                allowClear={true}
            />

            <div className="container" style={{ display: search.length ? "block" : "none" }}>
                {isLoading ? (
                    <div className="loading-and-empty">
                        <Spin />
                    </div>
                ) : isEmpty ? (
                    <div className="loading-and-empty">
                        <p className="empty">{translate['List is empty'] || EN['List is empty']}</p>
                    </div>
                ) : (
                    list.map((item) => {
                        return <TmdbMovie key={item.id} item={item} onClick={select} />;
                    })
                )}
            </div>
        </div>
    );
}
