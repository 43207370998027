const DATE_FORMATER = {
    patterns: ["@WDL", "@WDS", "@MTL", "@MTS", "@DD", "@YYL", "@YYS", "@PM", "@HH", "@MM"],

    weekday: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],

    month: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],

    "@WDL": function (date) {
        return this.weekday[date.getDay()];
    },
    "@WDS": function (date) {
        return this.weekday[date.getDay()].substring(0, 3);
    },
    "@MTL": function (date) {
        return this.month[date.getMonth()];
    },
    "@MTS": function (date) {
        return this.month[date.getMonth()].substring(0, 3);
    },
    "@DD": function (date) {
        let day = date.getDate();
        if (day < 10) day = "0" + day;

        return day;
    },
    "@YYL": function (date) {
        return date.getFullYear();
    },
    "@YYS": function (date) {
        return date.getYear();
    },
    "@PM": function (date) {
        let hours = date.getHours();
        let ampm = hours >= 12 ? "AM" : "PM";
        return ampm;
    },
    "@HH": function (date, hour_format) {
        let hours = date.getHours();
        if (hours < 10) hours = "0" + hours;

        if (hour_format === "12") hours = hours % 12;

        return hours;
    },
    "@MM": function (date) {
        let minutes = date.getMinutes();
        if (minutes < 10) minutes = "0" + minutes;

        return minutes;
    },

    parse: (hour_format, display_pattenrs) => {
        let date = new Date();
        let patterns = DATE_FORMATER.patterns;

        for (let i = 0; i < patterns.length; i++) {
            let pattern = patterns[i];

            if (display_pattenrs.indexOf(pattern) != -1) {
                if (pattern === "@HH") {
                    display_pattenrs = display_pattenrs.replace(
                        pattern,
                        DATE_FORMATER[pattern](date, hour_format)
                    );
                } else {
                    display_pattenrs = display_pattenrs.replace(
                        patterns[i],
                        DATE_FORMATER[pattern](date)
                    );
                }
            }
        }

        return display_pattenrs;
    },
};

export default DATE_FORMATER;
