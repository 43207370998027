import { useContext, useState, useEffect } from "react";

import TvChannelsContext from "./TvChannelsContext";

import REQUESTS from "../../../../../server/requests";

import ICONS from "../../../../../config/icons";

import IconButton from "../../../../elements/IconButton";
import TableHeadComponent from "../../../../elements/TableHeadComponent";
import CsvLinkComponent from "../../../../elements/CsvLinkComponent";

import ViewSettingsDrawer from "./ViewSettingsDrawer";
import FilterDrawer from "./FilterDrawer";

export default function TableHead() {
  const {
    setVisibleViewSettingsDrawer,
    setVisibleFilterDrawer,
    selectedLanguageId,
    selectedCountryId,
    selectedPublishedStatus,
    selectedPlayerId,
  } = useContext(TvChannelsContext);

  const [isFilterActive, setIsFilterActive] = useState(false);

  const [clickedCsv, setClickedCsv] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (
      selectedPlayerId ||
      selectedLanguageId ||
      selectedCountryId ||
      selectedPublishedStatus != null
    ) {
      setIsFilterActive(true);

      return;
    }

    setIsFilterActive(false);
  }, [selectedLanguageId, selectedCountryId, selectedPublishedStatus, selectedPlayerId]);

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);

      function callback(data) {
        const newList = data.map((list) => {
          let tvcategories;
          let language;
          let country;

          if (list.country_id && list.country) {
            country = list.country.name;
          } else {
            country = "-";
          }

          if (list.languages_id) {
            language = list.language && list.language.name;
          } else {
            language = "-";
          }

          if (list.tv_categories && list.tv_categories.length > 0) {
            const categories = list.tv_categories.map((item) => item.tv_categori.name);
            tvcategories = categories.join(", ");
          } else {
            tvcategories = "-";
          }

          return {
            Name: list.name,
            Epg_id: list.epg_id,
            Udp_url: list.udp_url,
            Country: country,
            Languages: language?.name,
            Categories: tvcategories,
            Status: list.published_status,
            Description: list.description.replace(/(<([^>]+)>|&nbsp;)/gi, ""),
          };
        });
        done(newList);
        setDataList(newList);

        setClickedCsv(false);
      }

      function errorCallback() {
        setClickedCsv(false);
      }

      REQUESTS.TV_CHANNELS.GET({}, callback, errorCallback);
    }
  };

  return (
    <>
      <TableHeadComponent>
        <CsvLinkComponent
          filename="Manage-TV-Channels-list.csv"
          data={dataList}
          onClick={onClicCsv}
        />

        <IconButton
          icon={ICONS.SETTINGS}
          onClick={() => setVisibleViewSettingsDrawer(true)}
        />
        <IconButton
          active={isFilterActive}
          icon={ICONS.FILTER}
          onClick={() => setVisibleFilterDrawer(true)}
        />
      </TableHeadComponent>

      <ViewSettingsDrawer />

      <FilterDrawer />
    </>
  );
}
