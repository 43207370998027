import { useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "antd";

import ProductContext from "./ProductContext";

import EN from "../../../../config/en";
import Checkbox from "antd/lib/checkbox/Checkbox";

export default function ViewSettingsDrawer() {
  const {
    visibleViewSettingsDrawer,
    setVisibleViewSettingsDrawer,
    viewSettingsValues,
    setViewSettingsValues,
  } = useContext(ProductContext);

  const translate = useSelector((state) => state.globalState.translate);

  const onChangeSettingsValues = (event) => {
    viewSettingsValues[event.target.name] = event.target.checked;
    setViewSettingsValues({ ...viewSettingsValues });

    localStorage.setItem("PRODUCTS_COLUMNS", JSON.stringify(viewSettingsValues));
  };

  return (
    <Drawer
      title={translate["View settings"] || EN["View settings"]}
      placement="right"
      onClose={() => setVisibleViewSettingsDrawer(false)}
      visible={visibleViewSettingsDrawer}
      destroyOnClose={true}
    >
      <h2>{translate["Show columns"] || EN["Show columns"]}</h2>

      <div className="view-settings-checkboxes">
        <Checkbox
          name="image"
          checked={viewSettingsValues.image}
          onChange={onChangeSettingsValues}
        >
          {translate["Image"] || EN["Image"]}
        </Checkbox>

        <Checkbox
          name="name"
          checked={viewSettingsValues.name}
          onChange={onChangeSettingsValues}
        >
          {translate["Name"] || EN["Name"]}
        </Checkbox>

        <Checkbox
          name="price"
          checked={viewSettingsValues.price}
          onChange={onChangeSettingsValues}
        >
          {translate["Price"] || EN["Price"]}
        </Checkbox>

        <Checkbox
          name="e_commercial_catagory"
          checked={viewSettingsValues.e_commercial_catagory}
          onChange={onChangeSettingsValues}
        >
          {translate["Category"] || EN["Category"]}
        </Checkbox>

        <Checkbox
          name="description"
          checked={viewSettingsValues.description}
          onChange={onChangeSettingsValues}
        >
          {translate["Description"] || EN["Description"]}
        </Checkbox>

        <Checkbox
          name="attach_slider"
          checked={viewSettingsValues.attach_slider}
          onChange={onChangeSettingsValues}
        >
          {translate["Attach Slider"] || EN["Attach Slider"]}
        </Checkbox>

        <Checkbox
          name="createdAt"
          checked={viewSettingsValues.createdAt}
          onChange={onChangeSettingsValues}
        >
          {translate["Date"] || EN["Date"]}
        </Checkbox>
      </div>
    </Drawer>
  );
}
