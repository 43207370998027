import React, {useContext} from "react";
import { useSelector } from "react-redux";
import Context from "./package-drawer/Context";

import EN from "../../../config/en";

import PageHeader from "../../elements/PageHeader";
import HeaderItem from "../../elements/HeaderItem";
import AddButtonComponent from "../../elements/AddButtonComponent";

export default function PackegesHeader() {

    const translate = useSelector((state) => state.globalState.translate);

    const {total, setVisiblePackageDrawer} = useContext(Context);

    return (
        <PageHeader title={translate['Packages'] || EN['Packages']}>
            <HeaderItem title={translate['Total'] || EN['Total']} total={total} />

            <AddButtonComponent 
                text={translate['Add Package'] || EN['Add Package']} 
                onClick={() => setVisiblePackageDrawer(true)}
            />
        </PageHeader>
    );
}
