import axios from "axios";
import requestQuery from "./requestQuery";

// http://api.weatherapi.com/v1/current.json?key=5cd4055600bc479c93c60655220905&q=Armenia&aqi=yes

const WEATHER_HOST = "http://api.weatherapi.com/v1/";

const CURRENT_QUERY = `${WEATHER_HOST}current.json`;

const API_KEY = "5cd4055600bc479c93c60655220905";
const API_YES = "yes";

const WEATHER_API = {
    GET: (name, callback, errorCallback) => {
        axios
            .get(`${CURRENT_QUERY}${requestQuery({ key: API_KEY, q: name, aqi: API_YES })}`, {})
            .then((data) => {
                // callback(data.data.current);
                callback(data.data);
            });
    },
};

export default WEATHER_API;
