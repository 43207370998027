import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Input, message } from "antd";

import REQUESTS from "../../../../../server/requests";

import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";
import { dateFormat } from "../../../../../config/formats";
import confirmModal from "../../../../../config/confirmModal";
import TableButtons from "../../../../elements/TableButtons";
import Page from "../../../../elements/Page";

import LiveTVCategoriesHeader from "./LiveTVCategoriesHeader";
import LiveTVCategoryDrawer from "./LiveTVCategoryDrawer";
import TableHead from "./TableHead";
import ImageComponent from "../../../../elements/ImageComponent";

export default function CategoryPage() {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [nameSearch, setNameSearch] = useState(null);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentCategory, setCurrentCategory] = useState(null);

  const [visibleCategoryDrawer, setVisibleCategoryDrawer] = useState(false);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      fixed: "left",
      key: "id",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index
    },

    {
      width: 100,
      dataIndex: "image",
      key: "image",
      render: (text, record, index) => (
        <ImageComponent src={text} className="tableImage" />
      )
    },

    {
      title: translate["Name"] || EN["Name"],
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, record, index) => <p className="text-ellipsis">{text}</p>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            allowClear
            autoFocus
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: () => {
        return <>{ICONS.SEARCHOUTLINED}</>;
      },
      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      }
    },

    {
      key: "createdAt",
      sorter: true,
      title: translate["Date"] || EN["Date"],
      dataIndex: "createdAt",
      render: (text, record, index) => dateFormat(text)
    },

    {
      title: "",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          style={{ minWidth: 130 }}
          buttons={[
            {
              key: "edit",
              text: translate["Edit"] || EN["Edit"],
              icon: ICONS.EDIT
            },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE
            }
          ]}
        />
      )
    }
  ];

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setCurrentCategory(item);
        setVisibleCategoryDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${
            translate["Do you want to delete"] || EN["Do you want to delete"]
          } ${item.name}`,
          action: () => deleteCategory(item)
        });
        break;

      default:
        break;
    }
  };

  const deleteCategory = (item) => {
    REQUESTS.TV_CHANNELS_CATEGORIES.DELETE(
      item.id,
      getCategoriesList,
      (err) => {
        message.error(err);
      }
    );
  };

  const getCategoriesList = () => {
    setLoading(true);

    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage
    };

    if (nameSearch) {
      query.search = JSON.stringify({ name: nameSearch });
    }

    function callback(data) {
      setCategoriesList(data.rows);
      setLimit(data.limit);
      setTotal(data.count);
      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.TV_CHANNELS_CATEGORIES.GET(query, callback, errorCallback);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    if (Object.values(filters)[0]) {
      setNameSearch(Object.values(filters)[0].join(""));
    } else {
      setNameSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  useEffect(() => {
    if (!visibleCategoryDrawer) {
      setCurrentCategory(null);
    }
  }, [visibleCategoryDrawer]);

  useEffect(() => {
    getCategoriesList();
  }, [currentPage, limit, sort]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getCategoriesList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [nameSearch]);

  return (
    <Page>
      <LiveTVCategoriesHeader
        total={total}
        onClick={() => setVisibleCategoryDrawer(true)}
      />

      <div className="page-body">
        <TableHead />
        <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          dataSource={categoriesList}
          onChange={handleTableChange}
          size="small"
          pagination={{
            position: ["topCenter", "bottomCenter"],
            current: currentPage,
            total: total,
            showSizeChanger: true
          }}
          sticky={{
            offsetHeader: 60,
            offsetScroll: 0
          }}
        />

        <LiveTVCategoryDrawer
          category={currentCategory}
          visible={visibleCategoryDrawer}
          getCategoriesList={getCategoriesList}
          onClose={() => setVisibleCategoryDrawer(false)}
        />
      </div>
    </Page>
  );
}
