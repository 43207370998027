import React, { useState, useEffect, memo } from "react";
import { useSelector } from "react-redux";
import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";

import HeaderItem from "../../../elements/HeaderItem";
import PageHeader from "../../../elements/PageHeader";
import AddButtonComponent from "../../../elements/AddButtonComponent";

export default memo(function Head({ total, openDrawer }) {

    const translate = useSelector((state) => state.globalState.translate);

    const [totalValue, setTotalValue] = useState(0);

    useEffect(() => {
        REQUESTS.ROOMS.GROUPS.STATISTICS((data) => {
            setTotalValue(data.count);
        });
    }, [total]);

    return (
        <PageHeader title={translate['Categories'] || EN['Categories']}>
            <HeaderItem title={translate['Total'] || EN['Total']} total={totalValue} />

            <AddButtonComponent text={translate['Add Category'] || EN['Add Category']} onClick={openDrawer} />
        </PageHeader>
    );
});
