import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import Context from "./Context";

import REQUESTS from "../../../server/requests";

import EN from "../../../config/en";

import HeaderItem from "../../elements/HeaderItem";
import PageHeader from "../../elements/PageHeader";

export default function Header() {
    
    const { total } = useContext(Context);
    
    const translate = useSelector((state) => state.globalState.translate);

    const [statistics, setStatistics] = useState({
        paid: 0,
        paid_price_sum: 0,
        unconfirmed: 0,
        unpaid: 0,
    });

    useEffect(() => {
        REQUESTS.ORDERS.STATISTICS((data) => {
            setStatistics(data);
        });
    }, [total]);

    return (
        <PageHeader title={translate['Orders'] || EN['Orders']}>
            <div className="info-section">
                <HeaderItem title={translate['Total payments'] || EN['Total payments']} total={statistics.paid_price_sum} />
                <HeaderItem title={translate['Paid'] || EN['Paid']} total={statistics.paid} />
                <HeaderItem title={translate['Unpaid'] || EN['Unpaid']} total={statistics.unpaid} />
            </div>
        </PageHeader>
    );
}
