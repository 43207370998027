import React, { useState } from "react";
import { Image } from "antd";
import IMAGES from "../../config/images";

export default React.memo(function ImageComponent({ src, className }) {

  const [preview, setPreview] = useState(true);

  const errorImg = (e) => {
    e.target.src = IMAGES.IMAGE_ERROR;
    setPreview(false);
  };

  return (
    <Image
      src={src?src : IMAGES.IMAGE_ERROR}
      className={className}
      onError={(e) => errorImg(e)}
      preview={src? preview : false}
    />
  );
});
