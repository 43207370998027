import React, { useContext, useEffect, useState } from "react";

import REQUESTS from "../../../../server/requests";

import ServiceContext from "./ServiceContext";

import ICONS from "../../../../config/icons";
import IconButton from "../../../elements/IconButton";

import TableHeadComponent from "../../../elements/TableHeadComponent";
import CsvLinkComponent from "../../../elements/CsvLinkComponent";

import ViewSettingsDrawer from "./ViewSettingsDrawer";
import FilterDrawer from "./FilterDrawer";

export default function TableHead() {
  const {
    setVisibleViewSettingsDrawer,
    setVisibleFilterDrawer,
    selectedEmployerId,
    selectedCategoryId,
    selectedPublishedStatus,
  } = useContext(ServiceContext);

  const [isFilterActive, setIsFilterActive] = useState(false);

  const [clickedCsv, setClickedCsv] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (selectedEmployerId || selectedCategoryId || selectedPublishedStatus !== null) {
      setIsFilterActive(true);

      return;
    }

    setIsFilterActive(false);
  }, [selectedEmployerId, selectedCategoryId, selectedPublishedStatus]);

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);

      function callback(data) {
        const newList = data.map((list) => {
          let category;
          let employer;

          if (
            list.service_providers &&
            list.service_providers[0] &&
            list.service_providers[0].employer
          ) {
            employer = list.service_providers[0].employer.name;
          } else {
            employer = "-";
          }

          if (list.servic_groups_id) {
            category = list.product_category && list.product_category.name;
          } else {
            category = "-";
          }

          return {
            Name: list.name,
            Price: list.price,
            User: employer,
            Category: category,
            Type: list.type,
            Description: list.description.replace(/(<([^>]+)>|&nbsp;)/gi, ""),
            // Data: list.createdAt,
          };
        });
        done(newList);
        setDataList(newList);

        setClickedCsv(false);
      }

      function errorCallback() {
        setClickedCsv(false);
      }

      REQUESTS.ECOMMERCE.SERVICES.GET({}, callback, errorCallback);
    }
  };

  return (
    <>
      <TableHeadComponent>
        <CsvLinkComponent
          filename="Ecommerce-services-list.csv"
          data={dataList}
          onClick={onClicCsv}
        />

        <IconButton
          icon={ICONS.SETTINGS}
          onClick={() => setVisibleViewSettingsDrawer(true)}
        />

        <IconButton
          active={isFilterActive}
          icon={ICONS.FILTER}
          onClick={() => setVisibleFilterDrawer(true)}
        />
      </TableHeadComponent>

      <ViewSettingsDrawer />

      <FilterDrawer />
    </>
  );
}
