import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Input, Button, notification } from "antd";
import Page from "../../../elements/Page";
import PageHeader from "../../../elements/PageHeader";
import EN from "../../../../config/en";
import REQUESTS from "../../../../server/requests";

const SocialLinksPage = () => {
  const translate = useSelector((state) => state.globalState.translate);

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    const body = {
      facebook: values.facebook?.trim(),
      instagram: values.instagram?.trim(),
      twitter: values.twitter?.trim(),
      linkedin: values.linkedin?.trim(),
      youtube: values.youtube?.trim(),
    };

    try {
      REQUESTS.BOOKING.SOCIAL_MEDIA.ADD(
        body,
        (res) => {
          console.log(res);
          notification.success({
            message:
              translate["Social Media links updated successfully"] ||
              EN["Social Media links updated successfully"],
          });
          getSocialLinks();
        },
        (err) => {
          console.log(err);
          notification.error({
            message: translate["An error occurred"] || EN["An error occurred"],
          });
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getSocialLinks = () => {
    try {
      REQUESTS.BOOKING.SOCIAL_MEDIA.GET(
        {},
        (res) => {
          form.setFields([
            { name: "facebook", value: res?.facebook },
            { name: "instagram", value: res.instagram },
            { name: "twitter", value: res.twitter },
            { name: "linkedin", value: res.linkedin },
            { name: "youtube", value: res.youtube },
          ]);
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (err) {
      console.log(err);

      // throw new Error(err);
    }
  };

  useEffect(() => {
    getSocialLinks();
  }, []);

  return (
    <Page>
      <PageHeader title={translate["Social Media"] || EN["Social Media"]} />
      <div
        style={{
          width: "70%",
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          margin: " 20px ",
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Form.Item name="facebook" label="Facebook">
            <Input />
          </Form.Item>
          <Form.Item name="instagram" label="Instagram">
            <Input />
          </Form.Item>
          <Form.Item name="twitter" label="Twitter">
            <Input />
          </Form.Item>
          <Form.Item name="linkedin" label="Linkedin">
            <Input />
          </Form.Item>
          <Form.Item name="youtube" label="Youtube">
            <Input />
          </Form.Item>

          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "20px",
              }}
            >
              <Button type="primary" htmlType="submit">
                {translate["Save"] || EN["Save"]}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Page>
  );
};

export default SocialLinksPage;
