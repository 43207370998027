import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../server/requests";

import SaveButtonComponent from "../../elements/SaveButtonComponent";
import ErrorMessage from "../../elements/ErrorMessage";

import ICONS from "../../../config/icons";
import EN from "../../../config/en";
import Translation from "../../elements/Translation";

export default function NewFeedbackDrawer({
  visible,
  onClose,
  getFeedbackSettingsList,
  feedback,
}) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [questionsTranslations, setQuestionsTranslations] = useState([]);

  const [descriptionTranslations, setDescriptionTranslations] = useState([]);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);
    setErrorText(null);

    const body = {
      questions: JSON.stringify(questionsTranslations || []),
      description: JSON.stringify(descriptionTranslations || []),
    };

    function callback() {
      setLoading(false);
      onClose();
      getFeedbackSettingsList();
      form.resetFields();
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (feedback) {
      body.id = feedback.id;
      REQUESTS.FEEDBACK_QUESTIONS.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.FEEDBACK_QUESTIONS.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  useEffect(() => {
    if (visible) {
      if (feedback) {
        form.setFields([
          { name: "questions", value: feedback.questions },
          { name: "description", value: feedback.description },
        ]);

        setQuestionsTranslations(feedback.questions_translations || []);

        setDescriptionTranslations(feedback.description_translations || []);
      }
    } else {
      setErrorText(null);
      setDescriptionTranslations([]);
      setQuestionsTranslations([]);
    }
  }, [visible]);

  useEffect(() => {
    if (!visible && feedback) {
      form.resetFields();
    }
  }, [visible]);

  const onChangeQuestion = (text, id) => {
    const question = {
      text,
      language_id: id,
    };

    const newTranslations = [...questionsTranslations];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = question;
    } else {
      newTranslations.push(question);
    }

    setQuestionsTranslations(newTranslations);
  };

  const onChangeDesctiption = (text, id) => {
    const description = {
      text,
      language_id: id,
    };

    const newTranslations = [...descriptionTranslations];

    const i = newTranslations.findIndex((i) => i.language_id === id);

    if (i >= 0) {
      newTranslations[i] = description;
    } else {
      newTranslations.push(description);
    }

    setDescriptionTranslations(newTranslations);
  };

  return (
    <Drawer
      title={translate["Add New Feedback"] || EN["Edit Feedback"]}
      placement="right"
      width={500}
      visible={visible}
      onClose={() => {
        onClose();
        form.resetFields();
        setQuestionsTranslations([]);
        setDescriptionTranslations([]);
      }}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="feedback-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {visible && (
          <Translation
            render={(item, index) => {
              const question = questionsTranslations.find(
                (translation) => translation.language_id === item.id
              )?.text;
              const description = descriptionTranslations.find(
                (translation) => translation.language_id === item.id
              )?.text;

              return (
                <div>
                  <Form.Item
                    label={translate["Question title"] || EN["Question title"]}
                    tooltip={{
                      title: "Feedback question title",
                      icon: ICONS.INFO,
                    }}
                    rules={[
                      {
                        required: true,
                        message: `${translate["Please input"] || EN["Please input"]} ${
                          translate["Question title"] || EN["Question title"]
                        }`,
                      },
                    ]}
                  >
                    <Input
                      value={question}
                      onChange={(e) => onChangeQuestion(e.target.value, item.id)}
                    />
                  </Form.Item>

                  <Form.Item
                    label={translate["Description"] || EN["Description"]}
                    tooltip={{
                      title: "Feedback description",
                      icon: ICONS.INFO,
                    }}
                    rules={[
                      {
                        required: true,
                        message: `${translate["Please input"] || EN["Please input"]} ${
                          translate["Description"] || EN["Description"]
                        }`,
                      },
                    ]}
                  >
                    <Input.TextArea
                      value={description}
                      onChange={(e) => onChangeDesctiption(e.target.value, item.id)}
                      rows={5}
                    />
                  </Form.Item>
                </div>
              );
            }}
          />
        )}

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>

        {/* <ErrorMessage>{errorText}</ErrorMessage> */}
      </Form>
    </Drawer>
  );
}
