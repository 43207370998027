import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, Input, notification, Select } from "antd";

import REQUESTS from "../../../../server/requests";

import ICONS from "../../../../config/icons";
import EN from "../../../../config/en";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";

import { emailValidator } from "../../../../config/config";
import FileChooser from "../../../elements/FileChooser";

export default function CompanyDrawer({ visible, onClose, current, getData }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [form] = Form.useForm();

  const [imageSrc, setImageSrc] = useState(null);

  const [currenciesOptions, setCurrenciesOptions] = useState([]);

  useEffect(() => {
    if (visible) {
      getCurrencies();

      if (current) {
        form.setFields([
          { name: "time_zone", value: current.time_zone },
          { name: "name", value: current.name },
          { name: "email_address", value: current.email_address },
          { name: "phone_number", value: current.phone_number },
          { name: "website", value: current.website },
          { name: "region", value: current.region },
          { name: "city", value: current.city },
          { name: "currency_id", value: current.currency_id },
          { name: "icon", value: current.icon },
        ]);
      }
    } else {
      setErrorText(null);
    }
  }, [visible]);

  useEffect(() => {
    if (!visible && current) {
      form.resetFields();
    }
    if (!current?.icon) {
      setImageSrc(null);
    } else {
      setImageSrc(current?.icon);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    const body = {
      icon: values.icon,
      time_zone: values.time_zone,
      organization_type_id: 1,
      name: values.name,
      email_address: values.email_address,
      phone_number: values.phone_number,
      website: values.website,
      region: values.region,
      city: values.city,
      currency_id: values.currency_id,
    };

    function callback() {
      setLoading(false);
      onClose();
      getData();
      form.resetFields();
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (current) {
      body.id = current.id;
      REQUESTS.ORGANIZATIONS.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.ORGANIZATIONS.ADD(body, callback, errorCallback);
    }
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    form.setFields([{ name: "icon", value: item.file_url }]);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "icon", value: null }]);
  };

  const getCurrencies = () => {
    REQUESTS.CURRENCY.GET((data) => {
      const currencies = data.map((item) => ({
        label: `${item.name} ${
          item.symbolNative && item.symbolNative !== "" && `( ${item.symbolNative} )`
        }`,
        value: item.id,
      }));

      setCurrenciesOptions(currencies);
    });
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  return (
    <Drawer
      title={
        current
          ? translate["Edit Company"] || EN["Edit Company"]
          : translate["Add Company"] || EN["Add Company"]
      }
      placement="right"
      width={600}
      visible={visible}
      destroyOnClose={true}
      onClose={() => {
        onClose();
        form.resetFields();
        setImageSrc(null);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="gui-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          is_enabled: false,
        }}
      >
        <Form.Item
          label={translate["Image"] || EN["Image"]}
          tooltip={{
            title: "Choose an image",
            icon: ICONS.INFO,
          }}
          name="icon"
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        <Form.Item
          label={translate["Name"] || EN["Name"]}
          tooltip={{
            title: "Organization Info name",
            icon: ICONS.INFO,
          }}
          name="name"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Name"] || EN["Name"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Email address"] || EN["Email address"]}
          tooltip={{
            title: "Email Address",
            icon: ICONS.INFO,
          }}
          name="email_address"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Email"] || EN["Email"]
              }`,
            },

            {
              validator: emailValidator,
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item
          label={translate["Phone number"] || EN["Phone number"]}
          tooltip={{
            title: "Phone Number",
            icon: ICONS.INFO,
          }}
          name="phone_number"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Phone number"] || EN["Phone number"]
              }`,
            },
            {
              pattern: /^[0-9]*$/,
              message: `${
                translate["Please input only numbers"] || EN["Please input only numbers"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Website"] || EN["Website"]}
          tooltip={{
            title: "Website",
            icon: ICONS.INFO,
          }}
          name="website"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Website"] || EN["Website"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Region"] || EN["Region"]}
          tooltip={{
            title: "Region",
            icon: ICONS.INFO,
          }}
          name="region"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Region"] || EN["Region"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["City"] || EN["City"]}
          tooltip={{
            title: "City",
            icon: ICONS.INFO,
          }}
          name="city"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["City"] || EN["City"]
              } `,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Time zone"] || EN["Time zone"]}
          tooltip={{
            title: "Time zone",
            icon: ICONS.INFO,
          }}
          name="time_zone"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Currency"] || EN["Currency"]}
          tooltip={{
            title: "Currency",
            icon: ICONS.INFO,
          }}
          name="currency_id"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Currency"] || EN["Currency"]
              }`,
            },
          ]}
        >
          <Select showArrow allowClear={true} options={currenciesOptions}></Select>
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>
      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
