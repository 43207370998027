import { Modal } from "antd";
import EN from "./en";

export default function ({ title, content, okText = EN['Yes'], cancelText = EN['Cancel'], action, className="confirm-yes-button" }) {
    return Modal.confirm({
        title: `${title} ?`,
        icon: false,
        content: content,
        closable: true,
        okText: okText,

        okButtonProps: {
            className: className,
        },
        cancelText: cancelText,
        onOk: action,
    });
}
