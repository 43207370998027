import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";

import { actions } from "../../store";

import REQUESTS from "../../server/requests";

export default function Logo({ showTitle = true }) {
    const dispatch = useDispatch();

    const info = useSelector((state) => state.globalState.organizationInfo);

    const { setOrganizationInfoInRedux } = bindActionCreators(actions, dispatch);

    const [show, setShow] = useState(true);

    useEffect(() => {
        if (showTitle) {
            setTimeout(() => {
                setShow(showTitle);
            }, 200);
        } else {
            setShow(showTitle);
        }
    }, [showTitle]);

    useEffect(() => {
        REQUESTS.ORGANIZATIONS.INFO((data) => {
            setOrganizationInfoInRedux(data[0]);
        });
    }, []);

    useEffect(() => {
        const link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = info.icon;

        const organization_title = document.getElementById("organization_title");

        if (organization_title) organization_title.text = info.name;
    }, [info]);

    return (
        <div
            style={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <img src={info.icon} style={{ width: 40 }} />

            <h1
                style={{
                    fontSize: 18,
                    display: show ? "block" : "none",
                }}
                className="app-name"
            >
                {info.name}
            </h1>
        </div>
    );
}
