import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, Input } from "antd";
import REQUESTS from "../../../server/requests";
import ICONS from "../../../config/icons";
import NewMessageDrawer from "./NewMessageDrawer";
import EN from "../../../config/en";

export default React.memo(function AllMessages({
  activeChat,
  selectActiveChat,
  new_message,
}) {
  const translate = useSelector((state) => state.globalState.translate);
  const location = useLocation();

  const [conversations, setConversations] = useState([]);
  const [visibleNewMessageDrawer, setVisibleNewMessageDrawer] = useState(false);
  const [search, setSearch] = useState("");
  const [expandedRooms, setExpandedRooms] = useState({});

  useEffect(() => {
    getConversations();
  }, [new_message]);

  useEffect(() => {
    if (activeChat) {
      REQUESTS.CONVERSATIONS.SEEN({ conversation_id: activeChat.id }, () => {
        getConversations();
      });
    } else {
      getConversations();
    }
  }, [activeChat]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      getConversations();
    }, 500);

    return () => clearTimeout(timeout);
  }, [search]);

  const closeNewMessageDrawer = useCallback(() => {
    setVisibleNewMessageDrawer(false);
    getConversations();
  }, []);

  const getConversations = () => {
    const query = {
      sort: ["updatedAt", "DESC"],
    };

    if (search) {
      query.search = JSON.stringify({
        location_name: search,
        guest_name: search,
      });
    }

    REQUESTS.CONVERSATIONS.GET(query, (data) => {
      setConversations(data);

      if (location.state) {
        data.forEach((conversation) => {
          if (conversation.id === location.state.conversationId) {
            selectActiveChat(conversation);
            location.state = {};
          }
        });
      }
    });
  };

  const groupedConversations = conversations.reduce((groups, item) => {
    const roomName = item.guest_name || "Unknown Room";

    if (!groups[roomName]) {
      groups[roomName] = [];
    }
    groups[roomName].push(item);
    return groups;
  }, {});

  const toggleRoomExpansion = (roomName) => {
    setExpandedRooms((prevExpanded) => ({
      ...prevExpanded,
      [roomName]: !prevExpanded[roomName],
    }));
  };

  return (
    <div className="all-messages">
      <header>
        <Input.Search value={search} onChange={(e) => setSearch(e.target.value)} />
        <Button
          type="primary"
          icon={ICONS.PLUS}
          onClick={() => setVisibleNewMessageDrawer(true)}
        />
      </header>

      <section>
        {search && conversations.length === 0 ? (
          <div className="no-matched-search">
            <div>
              {ICONS.NOSEARCH}
              <p>
                {translate["No matched your search..."] ||
                  EN["No matched your search..."]}
              </p>
            </div>
          </div>
        ) : conversations.length === 0 ? (
          <div className="no-matched-search">
            <div>
              {ICONS.ADD_PERSON}
              <p>{translate["Not active in chat..."] || EN["Not active in chat..."]}</p>
            </div>
          </div>
        ) : (
          <div className="conversation-list-wrapper">
            {Object.keys(groupedConversations).map((roomName, i) => (
              <div key={roomName} className="room-section">
                <div
                  className={
                    expandedRooms[roomName] ? "room-header active" : "room-header"
                  }
                  onClick={() => toggleRoomExpansion(roomName)}
                >
                  {groupedConversations[roomName][i]?.not_read_count_admin > 0 && (
                    <div
                      className="message-and-not-read-count"
                      style={{
                        width: "30px",
                        marginRight: "10px",
                      }}
                    >
                      <div className="not-read-count">
                        {groupedConversations[roomName][i]?.not_read_count_admin}{" "}
                      </div>
                    </div>
                  )}
                  <div className="room-number">{roomName?.split("")[0] || "U"}</div>
                  <div className="room-name">
                    <h4>{roomName}</h4>
                  </div>
                </div>

                {expandedRooms[roomName] && (
                  <div className="conversation-list">
                    {groupedConversations[roomName].map((item) => (
                      <div
                        key={item.id}
                        className="message"
                        style={{
                          background: activeChat?.id === item.id && "#e8f8e6",
                        }}
                        onClick={() => selectActiveChat(item)}
                      >
                        <div className="round-box">
                          {item.guest?.name.substring(0, 1)}
                          <div className="online-box"></div>
                        </div>
                        <div className="user-info">
                          <h4>{item.guest?.name}</h4>
                          <div className="message-and-not-read-count">
                            <p className="text-ellipsis">
                              {item.room?.location_name}{" "}
                              <span>({item.device?.name})</span>
                            </p>
                            {item.not_read_count_admin > 0 && (
                              <div className="not-read-count">
                                {item.not_read_count_admin}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </section>

      <NewMessageDrawer
        visible={visibleNewMessageDrawer}
        onClose={closeNewMessageDrawer}
      />
    </div>
  );
});
