import { useState, useEffect } from "react";
import { Table, Typography } from "antd";
import Page from "../../../elements/Page";
import HeaderItem from "../../../elements/HeaderItem";
import EN from "../../../../config/en";
import { useSelector } from "react-redux";
import REQUESTS from "../../../../server/requests";

const BillingHistoryPage = () => {
  const translation = useSelector((state) => state.globalState.translate);

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const parseDate = (date) => {
    const day = new Date(date).getDate();
    const month = new Date(date).getMonth() + 1;
    const year = new Date(date).getFullYear();

    return `${day}/${month}/${year}`;
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 50,
      fixed: "left",
    },
    {
      title: translation["Amount"] || EN["Amount"],
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (text, record) => {
        return (
          <Typography.Text>
            {" "}
            {record?.currency ? `${record?.currency} ${text}` : `$ ${text}`}
          </Typography.Text>
        );
      },
    },
    {
      title: translation["Payment Date"] || EN["Payment Date"],

      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (text, record) => {
        return <Typography.Text>{parseDate(text)}</Typography.Text>;
      },
    },
    {
      title: translation["Customer"] || EN["Customer"],

      dataIndex: "customer",
      key: "customer",
      align: "center",

      render: (customer) => `${customer.email}`,
    },
    {
      title: translation["Payment ID"] || EN["Payment ID"],

      dataIndex: "payment_id",
      key: "payment_id",
      render: (text) => {
        return <Typography.Text copyable>{text}</Typography.Text>;
      },
    },
    {
      title: translation["Payment Method"] || EN["Payment Method"],

      dataIndex: "payment_method",
      key: "payment_method",
      align: "center",
    },
    {
      title: translation["Status"] || EN["Status"],

      dataIndex: "status",
      key: "status",
      align: "center",
    },
  ];

  const getDataSource = () => {
    setLoading(true);
    const query = {
      limit,
      page: currentPage,
      total,
    };
    try {
      const successCallBack = (data) => {
        setDataSource(data?.rows);

        setTotal(data.total);
        setLoading(false);
      };

      const errorCallBack = (error) => {
        console.log(error);
        setLoading(false);
      };

      REQUESTS.BOOKING.BILLING_HISTORY.GET(query, successCallBack, errorCallBack);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  useEffect(() => {
    getDataSource();
  }, [currentPage, limit, total]);

  return (
    <Page>
      <div className="page-body">
        <h1>{translation["Billing History Page"] || EN["Billing History Page"]}</h1>
        <HeaderItem title={"Total"} total={dataSource?.length} />
        <div
          style={{
            marginTop: 30,
          }}
        >
          <Table
            columns={columns}
            dataSource={dataSource}
            onChange={handleTableChange}
            loading={loading}
            pagination={{
              position: ["bottomCenter"],
              current: currentPage,
              pageSize: limit,
              total: total,
              limit,
              showSizeChanger: true,
            }}
          />
        </div>
      </div>
    </Page>
  );
};

export default BillingHistoryPage;
