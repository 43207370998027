import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { notification } from "antd";
import Page from "../../../elements/Page";
import PageHeader from "../../../elements/PageHeader";
import EN from "../../../../config/en";
import REQUESTS from "../../../../server/requests";
import ItemSettings from "../../settings/ItemSettings";
import ICONS from "../../../../config/icons";
import SocialAuthDrawer from "./SocialAuthDrawer";

const SocialAuthPage = () => {
  const translate = useSelector((state) => state.globalState.translate);

  const [showDrawer, setShowDrawer] = useState(false);
  const [clientData, setClientData] = useState({
    id: "",
    secret: "",
    idData: "",
    secretData: "",
  });
  const [loading, setLoading] = useState(false);

  const authCards = [
    {
      title: "Facebook",
      icon: ICONS.FACEBOOK,
      idKey: "facebook_client_id",
      secretKey: "facebook_client_secret",
      description: translate["Set facebook auth key"] || EN["Set facebook auth key"],
    },
    {
      title: "Google",
      icon: ICONS.GOOGLE,
      idKey: "google_client_id",
      secretKey: "google_client_secret",
      description: translate["Set google auth key"] || EN["Set google auth key"],
    },
  ];

  const handleSave = useCallback(
    (value) => {
      setLoading(true);
      try {
        const successCallBack = () => {
          setLoading(false);
          setShowDrawer(false);
          getSocialAuth();
          notification.success({
            message: translate["Success"] || EN["Success"],
          });
        };

        const errorCallBack = () => {
          setLoading(false);
          notification.error({
            message: translate["An error occurred"] || EN["An error occurred"],
          });
        };

        REQUESTS.BOOKING.SOCIAL_AUTH.ADD(value, successCallBack, errorCallBack);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    },
    [translate]
  );

  const handleClick = useCallback((idKey, secretKey) => {
    setShowDrawer(true);
    setClientData((prev) => ({
      ...prev,
      id: idKey,
      secret: secretKey,
    }));
  }, []);

  const getSocialAuth = useCallback(() => {
    try {
      const successCallBack = (data) => {
        setClientData((prev) => ({
          ...prev,
          idData: data[prev.id],
          secretData: data[prev.secret],
        }));
      };

      const errorCallBack = (error) => {
        console.log(error);
      };

      REQUESTS.BOOKING.SOCIAL_AUTH.GET({}, successCallBack, errorCallBack);
    } catch (e) {
      console.log(e);
    }
  }, [clientData.id, clientData.secret]);

  useEffect(() => {
    getSocialAuth();
  }, [getSocialAuth]);

  return (
    <>
      <Page>
        <PageHeader title={translate["Social Media"] || EN["Social Media"]} />
        <div style={{ padding: "20px" }}>
          <div className="section-items-settings">
            {authCards.map((card, index) => (
              <ItemSettings
                key={index}
                title={card.title}
                icon={card.icon}
                description={card.description}
                onClick={() => handleClick(card.idKey, card.secretKey)}
              />
            ))}
          </div>
        </div>
      </Page>
      <SocialAuthDrawer
        title={
          clientData.id === "facebook_client_id"
            ? `Facebook ${translate["Auth config"] || EN["Auth configs"]}`
            : `Google ${translate["Auth config"] || EN["Auth configs"]}`
        }
        open={showDrawer}
        save={handleSave}
        id={clientData.id}
        secret={clientData.secret}
        client_id={clientData.idData}
        client_secret={clientData.secretData}
        loading={loading}
        onClose={() => {
          setShowDrawer(false);
          setClientData({
            id: "",
            secret: "",
            idData: "",
            secretData: "",
          });
        }}
      />
    </>
  );
};

export default SocialAuthPage;
