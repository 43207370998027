import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Dropdown, Menu } from "antd";

import ICONS from "../../../../../config/icons";
import EN from "../../../../../config/en";
import confirmModal from "../../../../../config/confirmModal";

import REQUESTS from "../../../../../server/requests";

import ClientQuickMessageDrawer from "./ClientQuickMessageDrawer";

export default function ClientQuickMessages({ visibleClientQuickMessageDrawer }) {

    const translate = useSelector((state) => state.globalState.translate);

    const [list, setList] = useState([]);
    const [editable, setEditable] = useState(null);

    useEffect(() => {
        if (!visibleClientQuickMessageDrawer) {
            getQuickMessages();
        }
    }, [visibleClientQuickMessageDrawer]);

    const handleMenuClick = (e, item) => {
        switch (e.key) {
            case "edit":
                setEditable(item);
                break;

            case "delete":
                confirmModal({
                    title: `${translate['Do you want to delete'] || EN['Do you want to delete']}`,
                    action: () =>
                        REQUESTS.QUICK_MESSAGES.DELETE(item.id, (data) => {
                            getQuickMessages();
                        }),
                });
                break;

            default:
                break;
        }
    };

    const getQuickMessages = () => {
        const query = {};

        query.filter = JSON.stringify({
            type: "guest",
        });

        REQUESTS.QUICK_MESSAGES.GET(query, (data) => {
            setList(data);
        });
    };

    return (
        <>
            <div className="scroll-box">
                {list.length > 0 ? (
                    list.map((item) => {
                        return (
                            <div className="sample-messages-box guest" key={item.id}>
                                <div>{item.message}</div>

                                <div
                                    className="buttons-section"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Dropdown
                                        destroyPopupOnHide={true}
                                        trigger={["click"]}
                                        placement="bottomRight"
                                        className="quick-messages-buttons"
                                        overlay={
                                            <Menu
                                                onClick={(e) => handleMenuClick(e, item)}
                                                style={{ minWidth: 130 }}
                                            >
                                                <Menu.Item key="edit" icon={ICONS.EDIT}>
                                                    {translate['Edit'] || EN['Edit']}
                                                </Menu.Item>

                                                <Menu.Item key="delete" icon={ICONS.DELETE}>
                                                    {translate['Delete'] || EN['Delete']}
                                                </Menu.Item>
                                            </Menu>
                                        }
                                    >
                                        <div>{ICONS.ELLIPSIS}</div>
                                    </Dropdown>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <p className="empty">{translate['No Quick Messages'] || EN['No Quick Messages']}</p>
                )}
            </div>

            <ClientQuickMessageDrawer
                visible={editable ? true : false}
                onClose={() => setEditable(null)}
                current={editable}
                getData={getQuickMessages}
            />
        </>
    );
}
