import { useState } from "react";

import ICONS from "../../../config/icons";
import IconButton from "../../elements/IconButton";
import TableHeadComponent from "../../elements/TableHeadComponent";
import { Input } from "antd";

import FilterDrawer from "./FilterDrawer";

export default function LibraryHead({
    gridView,
    setGridView,
    setSelectedFileType,
    selectedFileType,
    search,
    setSearch,
    chooser,
}) {
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);

    return (
        <>
            <TableHeadComponent
                title={<Input.Search value={search} onChange={(e) => setSearch(e.target.value)} />}
            >
                <IconButton
                    active={gridView}
                    icon={ICONS.GRID_VIEW}
                    onClick={() => setGridView(true)}
                />
                <IconButton
                    active={!gridView}
                    icon={ICONS.LIST_VIEW}
                    onClick={() => setGridView(false)}
                />

                {!chooser && (
                    <IconButton
                        active={isFilterActive}
                        icon={ICONS.FILTER}
                        onClick={() => setVisibleFilterDrawer(true)}
                    />
                )}
            </TableHeadComponent>

            {!chooser && (
                <FilterDrawer
                    setSelectedFileType={setSelectedFileType}
                    visible={visibleFilterDrawer}
                    setIsFilterActive={setIsFilterActive}
                    selectedFileType={selectedFileType}
                    onClose={() => setVisibleFilterDrawer(false)}
                />
            )}
        </>
    );
}
