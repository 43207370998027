import { useContext } from "react";
import { useSelector } from "react-redux";
import { Drawer, Checkbox } from "antd";

import TvChannelsContext from "./TvChannelsContext";

import EN from "../../../../../config/en";
import LOCAL_STORAGE_VALUES from "../../../../../config/localStorageValues";

export default function ViewSettingsDrawer() {
  const {
    visibleViewSettingsDrawer,
    setVisibleViewSettingsDrawer,
    viewSettingsValues,
    setViewSettingsValues,
  } = useContext(TvChannelsContext);

  const translate = useSelector((state) => state.globalState.translate);

  const onChangeSettingsValues = (event) => {
    viewSettingsValues[event.target.name] = event.target.checked;
    setViewSettingsValues({ ...viewSettingsValues });

    localStorage.setItem(
      LOCAL_STORAGE_VALUES.LIVE_TV_CHANNELS_COLUMNS,
      JSON.stringify(viewSettingsValues)
    );
  };

  return (
    <Drawer
      title={translate["View settings"] || EN["View settings"]}
      placement="right"
      onClose={() => setVisibleViewSettingsDrawer(false)}
      visible={visibleViewSettingsDrawer}
      destroyOnClose={true}
    >
      <h2>{translate["Show columns"] || EN["Show columns"]}</h2>

      <div className="view-settings-checkboxes">
        <Checkbox
          name="published_status"
          checked={viewSettingsValues.published_status}
          onChange={onChangeSettingsValues}
        >
          {translate["Status"] || EN["Status"]}
        </Checkbox>

        <Checkbox
          name="icon"
          checked={viewSettingsValues.icon}
          onChange={onChangeSettingsValues}
        >
          {translate["Image"] || EN["Image"]}
        </Checkbox>

        <Checkbox
          name="name"
          checked={viewSettingsValues.name}
          onChange={onChangeSettingsValues}
        >
          {translate["Name"] || EN["Name"]}
        </Checkbox>

        <Checkbox
          name="description"
          checked={viewSettingsValues.description}
          onChange={onChangeSettingsValues}
        >
          {translate["Description"] || EN["Description"]}
        </Checkbox>

        <Checkbox
          name="epg_id"
          checked={viewSettingsValues.epg_id}
          onChange={onChangeSettingsValues}
        >
          {translate["Epg id"] || EN["Epg id"]}
        </Checkbox>

        <Checkbox
          name="url"
          checked={viewSettingsValues.url}
          onChange={onChangeSettingsValues}
        >
          {translate["Url"] || EN["Url"]}
        </Checkbox>

        <Checkbox
          name="udp_url"
          checked={viewSettingsValues.udp_url}
          onChange={onChangeSettingsValues}
        >
          {translate["Udp url"] || EN["Udp url"]}
        </Checkbox>

        <Checkbox
          name="country_id"
          checked={viewSettingsValues.country_id}
          onChange={onChangeSettingsValues}
        >
          {translate["Country"] || EN["Country"]}
        </Checkbox>

        <Checkbox
          name="languages_id"
          checked={viewSettingsValues.languages_id}
          onChange={onChangeSettingsValues}
        >
          {translate["Language"] || EN["Language"]}
        </Checkbox>

        <Checkbox
          name="tv_categories"
          checked={viewSettingsValues.tv_categories}
          onChange={onChangeSettingsValues}
        >
          {translate["Categories"] || EN["Categories"]}
        </Checkbox>

        <Checkbox
          name="tizen_player"
          checked={viewSettingsValues.tizen_player}
          onChange={onChangeSettingsValues}
        >
          {translate["Player"] || EN["Player"]}
        </Checkbox>

        <Checkbox
          name="createdAt"
          checked={viewSettingsValues.createdAt}
          onChange={onChangeSettingsValues}
        >
          {translate["Date"] || EN["Date"]}
        </Checkbox>
      </div>
    </Drawer>
  );
}
