import { useContext } from "react";
import { useSelector } from "react-redux";
import { Checkbox, Form, Select, Switch } from "antd";

import ICONS from "../../../../config/icons";
import EN from "../../../../config/en";

import { useEcommerceCategoriesOptions } from "../../../../hooks/options";

import Context from "./Context";

export default function EcommerceSection() {
  const {
    enableEcommerce,
    setEnableEcommerce,
    accessToAllServicesAndProducts,
    setAccessToAllServicesAndProducts,
  } = useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const categoriesOptions = useEcommerceCategoriesOptions();

  return (
    <div>
      <div className="switch-block">
        <p>{translate["E-Commerce"] || EN["E-Commerce"]}</p>
        <Switch
          checked={enableEcommerce}
          onChange={() => setEnableEcommerce((value) => !value)}
        />
      </div>

      {enableEcommerce && (
        <div className="packages-sections-setyle">
          <Checkbox
            style={{ marginTop: 10, marginBottom: 20 }}
            checked={accessToAllServicesAndProducts}
            onChange={(e) => setAccessToAllServicesAndProducts(e.target.checked)}
          >
            {translate["Access to all services and products"] ||
              EN["Access to all services and products"]}
          </Checkbox>

          <Form.Item
            label={translate["Categories"] || EN["Categories"]}
            tooltip={{
              title: "Categories",
              icon: ICONS.INFO,
            }}
          >
            <Form.Item name="e_commerce_categories" noStyle>
              <Select
                mode="multiple"
                disabled={accessToAllServicesAndProducts}
                options={categoriesOptions}
              />
            </Form.Item>
          </Form.Item>
        </div>
      )}
    </div>
  );
}
