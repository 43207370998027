import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Input, Tag } from "antd";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";
import { dateFormat } from "../../../../config/formats";
import confirmModal from "../../../../config/confirmModal";

import TableButtons from "../../../elements/TableButtons";
import ImageComponent from "../../../elements/ImageComponent";
import LongText from "../../../elements/LongText";
import Page from "../../../elements/Page";

import Context from "./Context";
import Head from "./Head";
import InfoDrawer from "./InfoDrawer";
import GalleriesDrawer from "./GalleriesDrawer";

import TableHead from "./TableHead";

import "../_info-center.scss";

export default function InfoCenterPage() {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [infoList, setInfoList] = useState([]);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [titleSearch, setTitleSearch] = useState(null);
  const [categorySearch, setCategorySearch] = useState("");

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentInfo, setCurrentInfo] = useState(null);
  const [currentGallery, setCurrentGallery] = useState(null);

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [infoTypeId, setInfoTypeId] = useState(null);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [visibleGalleriesDrawer, setVisibleGalleriesDrawer] = useState(false);
  const [visibleViewSettingsDrawer, setVisibleViewSettingsDrawer] = useState(false);
  const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);

  const [columns, setColumns] = useState([]);

  const [viewSettingsValues, setViewSettingsValues] = useState({
    id: true,
    cover: true,
    title: true,
    information_galeries: true,
    informations_category: true,
    description: true,
    createdAt: true,
    action: true,
  });

  const configureTableColumns = () => {
    const newColumns = [];
    newColumns.push({
      title: "#",
      width: 60,
      fixed: "left",
      dataIndex: "id",
      align: "center",
      key: "id",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    });

    if (viewSettingsValues.cover) {
      newColumns.push({
        width: 100,
        dataIndex: "cover",
        key: "cover",
        render: (text, record, index) => (
          <ImageComponent src={text} className="tableImage" />
        ),
      });
    }

    if (viewSettingsValues.title) {
      newColumns.push({
        title: translate["Title"] || EN["Title"],
        dataIndex: "title",
        key: "title",
        sorter: true,
        render: (text, record, index) => {
          let title = "";

          const translations = record?.title_translations;

          for (let i = 0; i < translations.length; i++) {
            if (translations[i].is_default) {
              title = translations[i].text;
              break;
            }

            if (translations[i].text) title = translations[i].text;
          }

          return <p className="text-ellipsis">{title || "N/A"}</p>;
        },

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.title.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.informations_category) {
      newColumns.push({
        title: translate["Category"] || EN["Category"],
        width: 150,
        dataIndex: "categori_id",
        key: "categori_id",
        render: (text, record, index) =>
          record.categori_id ? record.informations_category.name : "-",
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={categorySearch[0]}
              onChange={(e) => {
                setCategorySearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.informations_category?.name
            .toLowerCase()
            .includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.description) {
      newColumns.push({
        title: translate["Description"] || EN["Description"],
        dataIndex: "description",
        key: "description",
        // width: 200,
        render: (text, record, index) => {
          let title = "";

          const translations = record?.description_translations;
          for (let i = 0; i < translations.length; i++) {
            if (translations[i].is_default) {
              title = translations[i].text;
              break;
            }

            if (translations[i].text) title = translations[i].text;
          }

          return <LongText>{title || "N/A"}</LongText>;
        },
      });
    }

    // if (viewSettingsValues?.tags?.length) {
    newColumns.push({
      title: translate["Tags"] || EN["Tags"],
      dataIndex: "tags",
      key: "tags",
      render: (text, record, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            {record.tags.map((tag, index) => (
              <Tag key={index} color="blue">
                {tag}
              </Tag>
            ))}
          </div>
        );
      },
    });
    // }

    if (viewSettingsValues.information_galeries) {
      newColumns.push({
        key: "information_galeries",
        title: translate["Galleries"] || EN["Galleries"],
        dataIndex: "information_galeries",
        render: (text, record, index) => (
          <div
            className="gallery-length-block txt-tb-r"
            onClick={() => openGalleriesDrawer(record)}
          >
            {record.information_galeries.length}
          </div>
        ),
      });
    }

    if (viewSettingsValues.createdAt) {
      newColumns.push({
        key: "createdAt",
        sorter: true,
        title: translate["Date"] || EN["Date"],
        dataIndex: "createdAt",
        render: (text, record, index) => dateFormat(text),
      });
    }

    newColumns.push({
      title: "",
      width: 60,
      fixed: "right",
      dataIndex: "action",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          style={{ minWidth: 130 }}
          buttons={[
            {
              key: "edit",
              text: translate["Edit"] || EN["Edit"],
              icon: ICONS.EDIT,
            },
            {
              key: "gallery",
              text: translate["Gallery"] || EN["Gallery"],
              icon: ICONS.GALLERIES,
            },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE,
            },
          ]}
        />
      ),
    });
    setColumns(newColumns);
  };

  useEffect(() => {
    configureTableColumns();
  }, [viewSettingsValues, currentPage]);

  useEffect(() => {
    let INFO_COLUMNS = localStorage.getItem("INFO_COLUMNS");

    if (INFO_COLUMNS) {
      INFO_COLUMNS = JSON.parse(INFO_COLUMNS);
      setViewSettingsValues(INFO_COLUMNS);
    }
  }, []);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getInfoList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [titleSearch]);

  useEffect(() => {
    getInfoList();
  }, [currentPage, limit, sort, infoTypeId, selectedCategoryId]);

  useEffect(() => {
    let timeout = null;
    if (categorySearch[0]) {
      if (categorySearch[0]?.length > 2) {
        timeout = setTimeout(() => {
          getInfoList();
        }, 1000);
      }
    } else {
      getInfoList();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [categorySearch]);

  useEffect(() => {
    if (!visibleDrawer) {
      setCurrentInfo(null);
    }
  }, [visibleDrawer]);

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    if (Object.values(filters)[0]) {
      setTitleSearch(Object.values(filters)[0].join(""));
    } else {
      setTitleSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "gallery":
        openGalleriesDrawer(item);
        break;

      case "edit":
        setCurrentInfo(item);
        setVisibleDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
            item.title
          }`,
          action: () => deleteInfo(item),
        });
        break;

      default:
        break;
    }
  };

  const openGalleriesDrawer = (item) => {
    setCurrentGallery(item);
    setVisibleGalleriesDrawer(true);
  };

  const deleteInfo = (item) => {
    REQUESTS.INFO_CENTER.INFO.DELETE(item.id, getInfoList);
  };

  const getInfoList = () => {
    setLoading(true);

    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage,
    };

    if (categorySearch[0]) {
      query.category_name = categorySearch[0];
    }

    if (titleSearch) {
      query.search = JSON.stringify({ title: titleSearch });
    }

    if (selectedCategoryId) {
      query.filter = JSON.stringify({ categori_id: selectedCategoryId });
    }

    if (infoTypeId) {
      query.filter = JSON.stringify({ type: infoTypeId });
    }

    function callback(data) {
      setInfoList(data.rows);
      setLimit(data.limit);
      setTotal(data.count);
      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.INFO_CENTER.INFO.GET(query, callback, errorCallback);
  };

  return (
    <Context.Provider
      value={{
        total,
        visibleDrawer,
        setVisibleDrawer,
        currentInfo,
        setCurrentInfo,
        currentGallery,
        setCurrentGallery,
        getInfoList,
        visibleGalleriesDrawer,
        setVisibleGalleriesDrawer,
        visibleViewSettingsDrawer,
        setVisibleViewSettingsDrawer,
        viewSettingsValues,
        setViewSettingsValues,
        selectedCategoryId,
        setSelectedCategoryId,
        infoTypeId,
        setInfoTypeId,
        visibleFilterDrawer,
        setVisibleFilterDrawer,
      }}
    >
      <Page>
        <Head />

        <div className="page-body">
          <TableHead />

          <Table
            rowKey="id"
            loading={loading}
            columns={columns}
            dataSource={infoList}
            onChange={handleTableChange}
            size="small"
            pagination={{
              position: ["topCenter", "bottomCenter"],
              current: currentPage,
              total: total,
              showSizeChanger: true,
            }}
            // sticky={{
            //   offsetHeader: 60,
            //   offsetScroll: 0,
            // }}
          />
        </div>

        <InfoDrawer />
        <GalleriesDrawer />
      </Page>
    </Context.Provider>
  );
}
