import backgroundJpg from "./images/background.jpg";
import profilePng from "./images/profile.png";
import imageErrorPng from "./images/error-image.png";
import whiteImageErrorPng from "./images/white-error-image.png";
import blackPng from "./images/black.png";
import movieJpg from "./images/movie.jpg";
import messagesPng from "./images/messages.png";
import tvBg from "./images/tvBg.png";
import tv from "./images/tv.png";
import marriott from "./images/marriott.png";
import hover from "./images/hover.png";
import itemHover from "./images/itemHover.png";
import tvPagePng from "./images/tv-page.png";

const IMAGES = {
    // a
    // b
    BLACK: blackPng,
    BACKGROUND: backgroundJpg,
    // c
    // d
    // e
    // f
    // g
    // h
    HOVER: hover,
    // i
    IMAGE_ERROR: imageErrorPng,
    WHITE_IMAGE_ERROR: whiteImageErrorPng,
    ITEM_HOVER: itemHover,

    // j
    // k
    // l
    // m
    MOVIE: movieJpg,
    MESSAGES: messagesPng,
    MARRIOTT: marriott,
    // n
    // o
    // p
    PROFILE: profilePng,
    // q
    // r
    // s
    // t
    TVBG: tvBg,
    TV: tv,
    TV_PAGE: tvPagePng,
    // u
    // v
    // w
    // x
    // y
    // z
};

export default IMAGES;
