import { useEffect } from "react";
import { Form, Input, Select, Drawer, Button } from "antd";
import EN from "../../../../../../config/en";
import { useSelector } from "react-redux";

const RoomPageDrawer = ({
  open,
  close,
  handleSave,
  editable,
  facilitiesOptions,
  propertiesOptions,
  loading = { loading },
}) => {
  const translation = useSelector((state) => state.globalState.translate);

  const [form] = Form.useForm();
  const onFinish = (values) => {
    handleSave(values);
  };

  useEffect(() => {
    form.setFieldsValue({
      name: editable?.name,
      adults: editable?.adults,
      children: editable?.chld,
      twin_beds: editable?.twin_beds,
      sofa_bed: editable?.sofa_bed,
      queen_bed: editable?.queen_bed,
      size: editable?.size,

      description: editable?.description,
    });

    if (editable?.properties) {
      form.setFieldsValue({
        properties: editable?.properties.map((property) => property.id),
      });
    }

    if (editable?.facilities) {
      form.setFieldsValue({
        facilities: editable?.facilities.map((facility) => facility.id),
      });
    }
  }, [editable]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open]);

  return (
    <Drawer
      title={
        editable
          ? translation["Edit Room"] || EN["Edit Room"]
          : translation["Add Room"] || EN["Add Room"]
      }
      placement="right"
      open={open}
      onClose={() => {
        close();
        form.resetFields();
      }}
      width={600}
      destroyOnClose={true}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button onClick={close} style={{ marginRight: 8 }}>
            {translation["Cancel"] || EN["Cancel"]}
          </Button>
          <Button type="primary" onClick={() => form?.submit()} loading={loading}>
            {translation["Save"] || EN["Save"]}
          </Button>
        </div>
      }
    >
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Form.Item
          label={translation["Name"] || EN["Name"]}
          name="name"
          rules={[
            {
              required: true,
              message: translation["Field is required"] || EN["Field is required"],
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translation["Adults"] || EN["Adults"]}
          name="adults"
          rules={[
            {
              required: true,
              message: translation["Field is required"] || EN["Field is required"],
            },
            {
              pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
              message:
                translation["This Field must be a number"] ||
                EN["This Field must be a number"],
            },
            {
              validator: (_, value) =>
                value >= 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        translation["Field must be greater than 0"] ||
                          EN["Field must be greater than 0"]
                      )
                    ),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translation["Children"] || EN["Children"]}
          name="children"
          rules={[
            {
              required: true,
              message: translation["Field is required"] || EN["Field is required"],
            },
            {
              pattern: /^[0-9]+(\.[0-9]{1,2})?$/,

              message:
                translation["This Field must be a number"] ||
                EN["This Field must be a number"],
            },
            {
              validator: (_, value) =>
                value >= 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        translation["Field must be greater than 0"] ||
                          EN["Field must be greater than 0"]
                      )
                    ),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translation["Twin Beds"] || EN["Twin Beds"]}
          name="twin_beds"
          rules={[
            {
              required: true,
              message: translation["Field is required"] || EN["Field is required"],
            },
            {
              pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
              message:
                translation["This Field must be a number"] ||
                EN["This Field must be a number"],
            },
            {
              validator: (_, value) =>
                value >= 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        translation["Field must be greater than 0"] ||
                          EN["Field must be greater than 0"]
                      )
                    ),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translation["Sofa Bed"] || EN["Sofa Bed"]}
          name="sofa_bed"
          rules={[
            {
              required: true,
              message: translation["Field is required"] || EN["Field is required"],
            },
            {
              pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
              message:
                translation["This Field must be a number"] ||
                EN["This Field must be a number"],
            },
            {
              validator: (_, value) =>
                value >= 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        translation["Field must be greater than 0"] ||
                          EN["Field must be greater than 0"]
                      )
                    ),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translation["Queen Bed"] || EN["Queen Bed"]}
          name="queen_bed"
          rules={[
            {
              required: true,
              message: translation["Field is required"] || EN["Field is required"],
            },
            {
              pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
              message:
                translation["This Field must be a number"] ||
                EN["This Field must be a number"],
            },
            {
              validator: (_, value) =>
                value >= 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        translation["Field must be greater than 0"] ||
                          EN["Field must be greater than 0"]
                      )
                    ),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translation["Size"] || EN["Size"]}
          name="size"
          rules={[
            {
              required: true,
              message: translation["Field is required"] || EN["Field is required"],
            },
            {
              pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
              message:
                translation["This Field must be a number"] ||
                EN["This Field must be a number"],
            },
            {
              validator: (_, value) =>
                value >= 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        translation["Field must be greater than 0"] ||
                          EN["Field must be greater than 0"]
                      )
                    ),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translation["Price"] || EN["Price"]}
          name="price"
          rules={[
            {
              required: true,
              message: translation["Field is required"] || EN["Field is required"],
            },
            {
              pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
              message:
                translation["Price must be a number"] || EN["Price must be a number"],
            },
            {
              validator: (_, value) =>
                value >= 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        translation["Field must be greater than 0"] ||
                          EN["Field must be greater than 0"]
                      )
                    ),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translation["Properties"] || EN["Properties"]}
          name="properties"
          rules={[
            {
              required: true,
              message: translation["Field is required"] || EN["Field is required"],
            },
          ]}
        >
          <Select mode="multiple" options={propertiesOptions} />
        </Form.Item>

        <Form.Item
          label={translation["Facilities"] || EN["Facilities"]}
          name="facilities"
          rules={[
            {
              required: true,
              message: translation["Field is required"] || EN["Field is required"],
            },
          ]}
        >
          <Select mode="multiple" options={facilitiesOptions} />
        </Form.Item>
        <Form.Item
          label={translation["Description"] || EN["Description"]}
          name="description"
          rules={[
            {
              required: true,
              message: translation["Field is required"] || EN["Field is required"],
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default RoomPageDrawer;
