import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import Context from "./Context";

import { Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../../../server/requests";

import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";

import ErrorMessage from "../../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../../elements/SaveButtonComponent";
import FileChooser from "../../../../elements/FileChooser";

export default function GenresDrawer() {
  const { visibleGenreDrawer, setVisibleGenreDrawer, currentGenre, getGenresList } =
    useContext(Context);

  const translate = useSelector((state) => state.globalState.translate);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (visibleGenreDrawer) {
      if (currentGenre) {
        form.setFields([{ name: "name", value: currentGenre.name }]);

        if (currentGenre.image) {
          setImageSrc(currentGenre.image);
        }
      }
    } else {
      setErrorText(null);
    }
  }, [visibleGenreDrawer]);

  useEffect(() => {
    if (!visibleGenreDrawer && currentGenre) {
      form.resetFields();
    }
  }, [visibleGenreDrawer]);

  const onFinish = (values) => {
    setLoading(true);
    setErrorText(null);

    let body = {
      name: values.name,
      image: values.image,
    };

    function callback() {
      setVisibleGenreDrawer();
      getGenresList();
      setLoading(false);
      setImageSrc(null);

      form.resetFields();
    }

    function errorCallback(err) {
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
      setLoading(false);
    }

    if (currentGenre) {
      body.id = currentGenre.id;

      REQUESTS.VOD.GENRES.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.VOD.GENRES.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "image", value: null }]);
  };

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    form.setFields([{ name: "image", value: item.file_url }]);
  };

  return (
    <Drawer
      title={
        currentGenre
          ? translate["Edit Genre"] || EN["Edit Genre"]
          : translate["Add New Genre"] || EN["Add New Genre"]
      }
      placement="right"
      width={400}
      onClose={() => {
        setVisibleGenreDrawer(false);
        form.resetFields();
        setImageSrc(null);
      }}
      visible={visibleGenreDrawer}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Image"] || EN["Image"]}
          tooltip={{
            title: "Choose an image",
            icon: ICONS.INFO,
          }}
          name="image"
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        <Form.Item
          label={translate["Name"] || EN["Name"]}
          tooltip={{
            title: "Genres name",
            icon: ICONS.INFO,
          }}
          name="name"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Name"] || EN["Name"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
