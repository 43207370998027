import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, Input, Checkbox, Select, notification } from "antd";

import HotelStaffContext from "./HotelStaffContext";

import REQUESTS from "../../../server/requests";

import ICONS from "../../../config/icons";
import EN from "../../../config/en";

import {
  useOrganizationsOptions,
  useEcommerceCategoriesOptions,
} from "../../../hooks/options";

import ErrorMessage from "../../elements/ErrorMessage";
import SaveButtonComponent from "../../elements/SaveButtonComponent";
import FileChooser from "../../elements/FileChooser";

export default function UserDrawer() {
  const {
    isVisibleTheUserDrawer,
    setIsVisibleTheUserDrawer,
    currentUser,
    getEmployersList,
  } = useContext(HotelStaffContext);

  const { translate, profile } = useSelector((state) => state.globalState);

  const categoriesOptions = useEcommerceCategoriesOptions();

  const companiesOptions = useOrganizationsOptions();

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  const [userRoles, setUserRoles] = useState({});

  const [rolesOption, setRolesOption] = useState([]);

  const [organizations, setOrganizations] = useState([]);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);

    setErrorText(null);

    let ids = [];

    for (let key in userRoles) {
      if (userRoles[key]) {
        ids.push(key);
      }
    }

    let body = {
      name: values.name,
      surname: values.surname,
      email: values.email,
      roles_id: JSON.stringify(ids),
      image: values.image,
      e_commercial_group: JSON.stringify(values.e_commercial_group),
    };

    if (profile.is_super_admin) {
      body.organization_id = values.organization_id;
    }

    function callback() {
      setLoading(false);
      setIsVisibleTheUserDrawer(false);
      getEmployersList();
      form.resetFields();
      setImageSrc(null);
      setUserRoles({});
    }

    function errorCallback(err) {
      setLoading(false);
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
    }

    if (currentUser) {
      body.id = currentUser.id;

      if (values.password) {
        body.password = values.password;
      }

      REQUESTS.EMPLOYERS.EDIT(body, callback, errorCallback);
    } else {
      body.password = values.password;

      REQUESTS.EMPLOYERS.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
    setErrorText(null);
  };

  const getOrganization = () => {
    REQUESTS.ORGANIZATIONS.GET({}, (data) => {
      setOrganizations(data[0].name);
    });
  };

  const getRolesOption = () => {
    REQUESTS.EMPLOYERS.ROLES((data) => {
      setRolesOption(data);

      let roles = {};

      for (let i = 0; i < data.length; i++) {
        roles[data[i].id] = false;
      }

      if (currentUser && currentUser.employers_roles) {
        for (let i = 0; i < currentUser.employers_roles.length; i++) {
          roles[currentUser.employers_roles[i].role_id] = true;
        }
      }

      setUserRoles(roles);
    });
  };

  const setRole = (event, item) => {
    let roles = { ...userRoles };

    roles[item.id] = event.target.checked;

    setUserRoles(roles);
  };

  useEffect(() => {
    if (isVisibleTheUserDrawer) {
      getRolesOption();

      getOrganization();

      if (currentUser) {
        form.setFields([
          { name: "name", value: currentUser.name },
          { name: "surname", value: currentUser.surname },
          { name: "email", value: currentUser.email },
          { name: "image", value: currentUser.image },
          { name: "organization_id", value: currentUser.organization_id },
          //   { name: "password", value: currentUser.password },
        ]);

        if (currentUser.image) {
          setImageSrc(currentUser.image);
        }
      }
    } else {
      setErrorText(null);
    }
  }, [isVisibleTheUserDrawer]);

  useEffect(() => {
    if (currentUser) {
      const list = [];

      for (let i = 0; i < categoriesOptions.length; i++) {
        for (let j = 0; j < currentUser.e_commercial_managers.length; j++) {
          if (
            categoriesOptions[i].value ==
            currentUser.e_commercial_managers[j].e_commercial_group_id
          ) {
            list.push(categoriesOptions[i]);
          }
        }
      }

      const ids = list.map((item) => item.value);

      form.setFields([{ name: "e_commercial_group", value: ids }]);
    }
  }, [categoriesOptions, currentUser]);

  useEffect(() => {
    if (!isVisibleTheUserDrawer && currentUser) {
      form.resetFields();
      setImageSrc(null);
      setUserRoles({});
    }
  }, [isVisibleTheUserDrawer]);

  const selectFile = (item) => {
    setImageSrc(item.file_url);
    form.setFields([{ name: "image", value: item.file_url }]);
  };

  const repliceImage = () => {
    setImageSrc(null);
    form.setFields([{ name: "image", value: null }]);
  };

  return (
    <Drawer
      title={
        currentUser
          ? translate["Edit Account"] || EN["Edit Account"]
          : translate["Add New User"] || EN["Add New User"]
      }
      placement="right"
      width={600}
      onClose={() => {
        setIsVisibleTheUserDrawer(false);
        form.resetFields();
        setImageSrc(null);
        setUserRoles({});
      }}
      visible={isVisibleTheUserDrawer}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="user-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Image"] || EN["Image"]}
          tooltip={{
            title: "Choose an image",
            icon: ICONS.INFO,
          }}
          name="image"
          rules={[
            {
              required: currentUser ? false : true,
              message: `${translate["Please choose"] || EN["Please choose"]} ${
                translate["Image"] || EN["Image"]
              }`,
            },
          ]}
        >
          <FileChooser
            src={imageSrc}
            onChange={selectFile}
            replace={repliceImage}
            types={["image"]}
          />
        </Form.Item>

        <Form.Item
          label={translate["Name"] || EN["Name"]}
          tooltip={{
            title: "User name",
            icon: ICONS.INFO,
          }}
          name="name"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Name"] || EN["Name"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Surname"] || EN["Surname"]}
          tooltip={{
            title: "User surname",
            icon: ICONS.INFO,
          }}
          name="surname"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Surname"] || EN["Surname"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Email"] || EN["Email"]}
          tooltip={{
            title: "Email address",
            icon: ICONS.INFO,
          }}
          name="email"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Email"] || EN["Email"]
              }`,
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item
          label={translate["E-commerce catagory"] || EN["E-commerce catagory"]}
          tooltip={{
            title: "Choose E_commerce catagory",
            icon: ICONS.INFO,
          }}
          name="e_commercial_group"
          rules={[
            {
              required: true,
              message: `${translate["Please select"] || EN["Please select"]} ${
                translate["E-commerce catagory"] || EN["E-commerce catagory"]
              }`,
            },
          ]}
        >
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            showArrow
            allowClear={true}
            options={categoriesOptions}
          />
        </Form.Item>

        {profile.is_super_admin && (
          <Form.Item
            label={translate["Organization"] || EN["Organization"]}
            tooltip={{
              title: "Choose Company",
              icon: ICONS.INFO,
            }}
            name="organization_id"
            rules={[
              {
                required: true,
                message: `${translate["Please select"] || EN["Please select"]} ${
                  translate["Organization"] || EN["Organization"]
                }`,
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              showArrow
              allowClear={true}
              options={companiesOptions}
            />
          </Form.Item>
        )}

        <Form.Item
          label={translate["Password"] || EN["Password"]}
          tooltip={{
            title: "Password",
            icon: ICONS.INFO,
          }}
          name="password"
          rules={[
            {
              required: currentUser ? false : true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Password"] || EN["Password"]
              }`,
            },
          ]}
          autoComplete="new-password"
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>

        {currentUser && !profile.is_super_admin && (
          <Form.Item
            label={translate["Organization"] || EN["Organization"]}
            tooltip={{
              title: "Organization name",
              icon: ICONS.INFO,
            }}
          >
            <Input value={organizations} readOnly={true} disabled={true} />
          </Form.Item>
        )}

        {rolesOption.length > 0 && (
          <Form.Item
            label={translate["Roles"] || EN["Roles"]}
            tooltip={{
              title: "You can choose a roles",
              icon: ICONS.INFO,
            }}
          >
            <div className="roles-checkbox-section">
              {rolesOption.map((item) => {
                return (
                  <Checkbox
                    key={item.id}
                    checked={userRoles[item.id]}
                    onChange={(e) => setRole(e, item)}
                  >
                    {item.name}
                  </Checkbox>
                );
              })}
            </div>
          </Form.Item>
        )}

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
