import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker, Space } from "antd";
import REQUESTS from "../../../server/requests";
import { Chart } from "react-google-charts";

import EN from "../../../config/en";

import TitleComponent from "../../elements/TitleComponent";

export default function MapCharts() {

    const translate = useSelector((state) => state.globalState.translate);

    const [statistics, setStatistics] = useState([]);
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear);

    useEffect(() => {
        getDashboardCharts();
    }, [selectedYear]);

    const getDashboardCharts = () => {
        const query = {
            year: selectedYear,
        };
        REQUESTS.DASHBOARD.STATISTICS_GUESTS_COUNTRY(query, (data) => {
            setStatistics(data);
        });
    };

    const data = [["Country", "Guest"]];

    statistics.map((item) => {
        data.push([item.country?.name, item.count]);
    });

    function onChange(date, dateString) {
        setSelectedYear(dateString);
    }

    return (
        <div className="app_content_charts">
            <div className="content_charts">
                <div className="bg_fff">
                    <div className="title_and_select_df">
                        <TitleComponent>{translate['Guest Country'] || EN['Guest Country']}</TitleComponent>
                        <Space direction="vertical">
                            <DatePicker onChange={onChange} picker="year" />
                        </Space>
                    </div>
                </div>
                <Chart
                    chartEvents={[
                        {
                            eventName: "select",
                            callback: ({ chartWrapper }) => {
                                const chart = chartWrapper.getChart();
                                const selection = chart.getSelection();
                                if (selection.length === 0) return;
                                const region = data[selection[0].row + 1];
                            },
                        },
                    ]}
                    chartType="GeoChart"
                    width="100%"
                    height="400px"
                    data={data}
                    options={{
                        colorAxis: { colors: ["#a1d8f2", "#2f6bff"] },
                        legend: 'none',

                    }}
                />
            </div>
        </div>
    );
}
