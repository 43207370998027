import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";

import { Button, message } from "antd";
import ICONS from "../../config/icons";
import EN from "../../config/en";

export default function CsvLinkComponent({ filename, data, onClick }) {
  const translate = useSelector((state) => state.globalState.translate);
  const [csvData, setCsvData] = useState([]);
  const csvRef = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onClick({}, (done) => {
      if (done?.length > 0) {
        console.log("done", done);

        setCsvData(done);

        setTimeout(() => {
          csvRef?.current?.link?.click();
        }, 100);

        message.success(
          translate["The file is downloading"] || EN["The file is downloading"]
        );
      } else {
        message.error(
          translate["No data available to download"] ||
            EN["No data available to download"]
        );
      }
    });
  };

  return (
    <Button type="primary" className="exportBtn">
      <div className="exportBtnHid" onClick={handleClick} />
      <CSVLink
        filename={filename}
        data={csvData}
        className="btn btn-primary"
        ref={csvRef}
      >
        {ICONS.DOWNLOAD}
      </CSVLink>
    </Button>
  );
}
