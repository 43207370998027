import React, { useState, useEffect, useContext } from "react";

import Context from "./Context";

import ICONS from "../../../../config/icons";

import IconButton from "../../../elements/IconButton";
import TableHeadComponent from "../../../elements/TableHeadComponent";
import CsvLinkComponent from "../../../elements/CsvLinkComponent";

import ViewSettingsDrawer from "./ViewSettingsDrawer";
import FilterDrawer from "./FilterDrawer";
import REQUESTS from "../../../../server/requests";

export default function TableHead() {
  const {
    setVisibleViewSettingsDrawer,
    setVisibleFilterDrawer,
    selectedDefault,
    selectedTVChannels,
    selectedMovies,
    selectedEcommerce,
    selectedWidgets,
    selectedSendMessages,
    selectedViewBuillingInformation,
    selectedNewsViews,
    selectedViewPromotions,
    selectedFeedbackAccess,
  } = useContext(Context);

  const [isFilterActive, setIsFilterActive] = useState(false);

  const [clickedCsv, setClickedCsv] = useState(false);

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (
      selectedDefault != null ||
      selectedTVChannels != null ||
      selectedMovies != null ||
      selectedEcommerce != null ||
      selectedWidgets != null ||
      selectedSendMessages != null ||
      selectedViewBuillingInformation != null ||
      selectedNewsViews != null ||
      selectedViewPromotions != null ||
      selectedFeedbackAccess != null
    ) {
      setIsFilterActive(true);
      return;
    }
    setIsFilterActive(false);
  }, [
    selectedDefault,
    selectedTVChannels,
    selectedMovies,
    selectedEcommerce,
    selectedWidgets,
    selectedSendMessages,
    selectedViewBuillingInformation,
    selectedNewsViews,
    selectedViewPromotions,
    selectedFeedbackAccess,
  ]);

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);

      function callback(data) {
        const newList = data.map((list) => {
          let widget;
          let movies;
          let services_and_products;
          let tv_channels;

          if (list.access_to_Widget.enabled) {
            widget = "Partial access";
            if (list.access_to_Widget.access) {
              widget = "Full access";
            }
          } else {
            widget = "X";
          }

          if (list.access_to_Movies.enabled) {
            movies = "Partial access";
            if (list.access_to_Movies.access) {
              movies = "Full access";
            }
          } else {
            movies = "X";
          }

          if (list.access_to_TV_Channels.enabled) {
            tv_channels = "Partial access";
            if (list.access_to_TV_Channels.access) {
              tv_channels = "Full access";
            }
          } else {
            tv_channels = "X";
          }

          if (list.access_to_Services_and_products.enabled) {
            services_and_products = "Partial access";
            if (list.access_to_Services_and_products.access) {
              services_and_products = "Full access";
            }
          } else {
            services_and_products = "X";
          }

          return {
            Default: list.is_default,
            Name: list.name,
            Tv_channels: tv_channels,
            Vod: movies,
            Ecommerce: services_and_products,
            Widgets: widget,
            Send_messages: list.send_messages,
            View_Bill: list.view_Billing_Information,
            News: list.news_Views,
            Promotions: list.view_promotions,
            Access: list.feedback_sccess,
          };
        });

        done(newList);

        setDataList(newList);

        setClickedCsv(false);
      }

      function errorCallback() {
        setClickedCsv(false);
      }

      REQUESTS.PACKAGES.GET({}, callback, errorCallback);
    }
  };

  return (
    <>
      <TableHeadComponent>
        <CsvLinkComponent
          filename="Packeges-List.csv"
          data={dataList}
          onClick={onClicCsv}
        />

        <IconButton
          icon={ICONS.SETTINGS}
          onClick={() => setVisibleViewSettingsDrawer(true)}
        />

        <IconButton
          active={isFilterActive}
          icon={ICONS.FILTER}
          onClick={() => setVisibleFilterDrawer(true)}
        />
      </TableHeadComponent>

      <ViewSettingsDrawer />

      <FilterDrawer />
    </>
  );
}
