const LOCAL_STORAGE_VALUES = {
    // a
    // b
    // c
    // d
    // e
    EPG_LINKS_COLUMNS: "EPG_LINKS_COLUMNS",
    // f
    // g
    // h
    HOTEL_STAFF_COLUMNS: "HOTEL_STAFF_COLUMNS",
    HOTEL_INFO_COLUMNS: "HOTEL_INFO_COLUMNS",

    // i
    // j
    // k
    // l
    LIVE_TV_CHANNELS_COLUMNS: "LIVE_TV_CHANNELS_COLUMNS",

    // m
    MOVIE_COLUMNS: "MOVIE_COLUMNS",
    // n
    NEWS_COLUMNS: "NEWS_COLUMNS",

    // o
    ORGANIZATIONS_CATEGORIES_COLUMNS: "ORGANIZATIONS_CATEGORIES_COLUMNS",
    ORGANIZATIONS_COMPANIES_COLUMNS: "ORGANIZATIONS_COMPANIES_COLUMNS",
    // p
    PACKAGES_COLUMNS: "PACKAGES_COLUMNS",
    // q
    // r
    // s
    // t
    // u
    // v
    // w
    // x
    // y
    // z
};

export default LOCAL_STORAGE_VALUES;
