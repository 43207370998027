import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import ICONS from "../../../config/icons";
import EN from "../../../config/en";
import IMAGES from "../../../config/images";

import { actions } from "../../../store";
import REQUESTS from "../../../server/requests";
import confirmModal from "../../../config/confirmModal";

import Message from "./Message";

const Chat = ({ activeChat, selectActiveChat }) => {
  const dispatch = useDispatch();

  const translate = useSelector((state) => state.globalState.translate);
  const profile = useSelector((state) => state.globalState.profile);
  const newMessageRedux = useSelector((state) => state.globalState.new_message);
  const seenChatGuest = useSelector((state) => state.globalState.seenChatGuest);
  const socketIsConnected = useSelector((state) => state.globalState.socketIsConnected);

  const { setNewMessageInRedux } = bindActionCreators(actions, dispatch);

  const [chatList, setChatList] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const scrollToBottom = () => {
    const chatListElement = document.getElementById("chat_list");
    if (chatListElement) {
      chatListElement.scrollTop = chatListElement.scrollHeight;
    }
  };

  useEffect(() => {
    if (newMessageRedux && activeChat?.id === newMessageRedux.conversation_id) {
      setChatList((prevChatList) => [...prevChatList, newMessageRedux]);

      REQUESTS.CONVERSATIONS.SEEN({ conversation_id: activeChat.id }, () => {});
      setTimeout(() => setNewMessageInRedux(null), 1000);
    }
  }, [newMessageRedux, activeChat, setNewMessageInRedux]);

  useEffect(() => {
    scrollToBottom();
  }, [chatList]);

  const getChat = useCallback(() => {
    if (!activeChat) return;

    const body = {
      filter: JSON.stringify({ conversation_id: activeChat.id }),
    };

    REQUESTS.CONVERSATIONS.CHAT(body, (data) => {
      setChatList(data);
      scrollToBottom();
    });
  }, [activeChat]);

  useEffect(() => {
    if (activeChat) {
      getChat();
    }
  }, [activeChat, getChat]);

  useEffect(() => {
    if (seenChatGuest?.conversation_id === activeChat?.id) {
      getChat();
    }
  }, [seenChatGuest, activeChat, getChat]);

  const deleteConversation = () => {
    confirmModal({
      title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
        translate["Chat with"] || EN["Chat with"]
      } ${activeChat?.guest?.name}`,
      action: () => {
        REQUESTS.CONVERSATIONS.DELETE(activeChat.id, () => {
          selectActiveChat(null);
        });
      },
    });
  };

  const send = () => {
    if (!newMessage.trim()) return;

    const body = {
      conversation_id: activeChat.id,
      message: newMessage,
      message_type: "messages",
    };

    REQUESTS.CONVERSATIONS.NEW_MESSAGE(body, (data) => {
      setChatList((prevChatList) => [...prevChatList, data]);
      setNewMessage("");
    });
  };

  const onEnterSend = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      send();
    }
  };

  return (
    <div
      className="chat-section"
      style={{ width: `calc(100% - ${activeChat ? 500 : 250}px)` }}
    >
      {activeChat ? (
        <>
          <header>
            <div className="user-info">
              <div className="user-online"></div>
              <h1>{`${translate["Chat with"] || EN["Chat with"]} ${
                activeChat?.guest?.name
              }`}</h1>
            </div>
            <div className="delete-button" onClick={deleteConversation}>
              {ICONS.DELETE}
            </div>
          </header>

          <div id="chat_list">
            {chatList.map((item) => (
              <Message key={item.id} item={item} profile={profile} />
            ))}
          </div>

          <div className="new-message-area">
            <textarea
              disabled={!socketIsConnected}
              className={`scroll-box ${!socketIsConnected ? "offline-textarea" : ""}`}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder={
                socketIsConnected
                  ? `${translate["Write a message"] || EN["Write a message"]}...`
                  : translate["You are offline"] || EN["You are offline"]
              }
              onKeyDown={onEnterSend}
            />
            {socketIsConnected && (
              <div className="send-button" onClick={send}>
                {ICONS.SEND}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="select-conversation-section">
          <img src={IMAGES.MESSAGES} alt="message" />
          <h1>{translate["Select a Conversation"] || EN["Select a Conversation"]}</h1>
          <p>
            {translate[
              "Try selecting a conversation or searching for someone specific"
            ] || EN["Try selecting a conversation or searching for someone specific"]}
          </p>
        </div>
      )}
    </div>
  );
};

export default React.memo(Chat);
