import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import Context from "./Context";

import REQUESTS from "../../../../../server/requests";

import EN from "../../../../../config/en";

import HeaderItem from "../../../../elements/HeaderItem";
import PageHeader from "../../../../elements/PageHeader";
import AddButtonComponent from "../../../../elements/AddButtonComponent";

export default function Head() {
    const {total, setVisibleAddMovieDrawer } = useContext(Context);
    
    const translate = useSelector((state) => state.globalState.translate);

    const [statistics, setStatistics] = useState({
        movie: 0,
        tv: 0,
        total: 0,
    });
    
    useEffect(() => {
        REQUESTS.VOD.MOVIES.STATISTICS((data) => {
            setStatistics(data.count);
        });
    }, [total]);

    return (
        <PageHeader title={translate['Movies'] || EN['Movies']}>
            <div className="info-section">
                <HeaderItem title={translate['Total'] || EN['Total']} total={statistics.total} />
                <HeaderItem title={translate['Movies'] || EN['Movies']} total={statistics.movie} />
                <HeaderItem title={translate['Tv show'] || EN['Tv show']} total={statistics.tv} />
            </div>

            <AddButtonComponent
                text={translate['Add Movie'] || EN['Add Movie']}
                onClick={() => setVisibleAddMovieDrawer(true)}
            />
        </PageHeader>
    );
}
