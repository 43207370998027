import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, Input, Tooltip, notification } from "antd";

import Context from "./Context";
import REQUESTS from "../../../../../server/requests";

import EN from "../../../../../config/en";
import ICONS from "../../../../../config/icons";
import confirmModal from "../../../../../config/confirmModal";
import { dateFormat } from "../../../../../config/formats";

import TableButtons from "../../../../elements/TableButtons";
import Page from "../../../../elements/Page";
import Currency from "../../../../elements/Currency";

import Head from "./Head";
import TableHead from "./TableHead";
import PlayMovieDrawer from "./PlayMovieDrawer";
import AddMovieDrawer from "./AddMovieDrawer";

import MovieInfo from "./MovieInfo";
import EditMovieDrawer from "./EditMovieDrawer";

import "./_movies.scss";

export default function MovesPage() {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const [movesList, setMovesList] = useState([]);
  const [currentMoves, setCurrentMoves] = useState(null);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [nameSearch, setNameSearch] = useState(null);
  const [generSearch, setGenerSearch] = useState("");

  const [activeMovieForPlayer, setActiveMovieForPlayer] = useState(null);
  const [visibleAddMovieDrawer, setVisibleAddMovieDrawer] = useState(false);

  const [selectedMovieTypeId, setSelectedMovieTypeId] = useState(null);

  const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);
  const [visibleViewSettingsDrawer, setVisibleViewSettingsDrawer] = useState(false);

  const [visibleEditMovieDrawer, setVisibleEditMovieDrawer] = useState(false);

  const [viewSettingsValues, setViewSettingsValues] = useState({
    id: true,
    name: true,
    type: true,
    vod_geners: true,
    price: true,
    runtime: true,
    year: true,
    createdAt: true,
    action: true,
  });

  useEffect(() => {
    getMovesList();
  }, [currentPage, limit, sort, selectedMovieTypeId]);

  useEffect(() => {
    let timeout = null;
    if (generSearch[0]) {
      if (generSearch[0]?.length > 2) {
        timeout = setTimeout(() => {
          getMovesList();
        }, 1000);
      }
    } else {
      getMovesList();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [generSearch]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getMovesList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [nameSearch]);

  useEffect(() => {
    configureTableColumns();
  }, [viewSettingsValues, currentPage]);

  useEffect(() => {
    let MOVIE_COLUMNS = localStorage.getItem("MOVIE_COLUMNS");

    if (MOVIE_COLUMNS) {
      MOVIE_COLUMNS = JSON.parse(MOVIE_COLUMNS);
      setViewSettingsValues(MOVIE_COLUMNS);
    }
  }, []);

  const [columns, setColumns] = useState([]);

  const configureTableColumns = () => {
    const newColumns = [];

    newColumns.push({
      title: "#",
      dataIndex: "id",
      align: "center",
      key: "id",
      width: 60,
      fixed: "left",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    });

    if (viewSettingsValues.name) {
      newColumns.push({
        title: translate["Name"] || EN["Name"],
        dataIndex: "name",
        key: "name",
        sorter: true,
        render: (text, record, index) => <MovieInfo movie={record} />,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.name.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.type) {
      newColumns.push({
        key: "type",
        title: translate["Type"] || EN["Type"],
        dataIndex: "type",
        render: (text, record, index) => {
          if (record.type === "movie") {
            return <p>{translate["Movie"] || EN["Movie"]}</p>;
          } else if (record.type === "tv") {
            return <p>{translate["Tv show"] || EN["Tv show"]}</p>;
          } else {
            return "-";
          }
        },
      });
    }
    if (viewSettingsValues.vod_geners) {
      newColumns.push({
        key: "vod_geners",
        title: translate["Geners"] || EN["Geners"],
        dataIndex: "vod_geners",
        render: (text, record, index) => {
          if (record.vod_geners && record.vod_geners.length > 0) {
            const geners = record.vod_geners.map((item) => item.genre.name);

            return (
              <Tooltip title={geners.join(", ")}>
                <p style={{ maxWidth: 300, margin: "auto" }} className="text-ellipsis">
                  {geners.join(", ")}
                </p>
              </Tooltip>
            );
          } else {
            return "-";
          }
        },
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={generSearch[0]}
              onChange={(e) => {
                setGenerSearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },
        onFilter: (value, record) => {
          return record.vod_geners.toLowerCase().includes(value.toLowerCase());
        },
      });
    }

    if (viewSettingsValues.price) {
      newColumns.push({
        key: "price",
        sorter: true,
        title: translate["Price"] || EN["Price"],
        dataIndex: "price",
        render: (text, record, index) => (
          <Currency>{parseFloat(record.price).toFixed(2)}</Currency>
        ),
      });
    }

    if (viewSettingsValues.createdAt) {
      newColumns.push({
        key: "createdAt",
        sorter: true,
        title: translate["Date"] || EN["Date"],
        dataIndex: "createdAt",
        render: (text, record, index) => dateFormat(text),
      });
    }

    newColumns.push({
      title: "",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      width: 60,
      render: (text, record, index) => {
        const buttons = [
          { key: "edit", text: translate["Edit"] || EN["Edit"], icon: ICONS.EDIT },
          {
            key: "delete",
            text: translate["Delete"] || EN["Delete"],
            icon: ICONS.DELETE,
          },
        ];

        if (record.type === "movie") {
          buttons.unshift({
            key: "play",
            text: translate["Play"] || EN["Play"],
            icon: ICONS.PLAY,
          });
        }

        return (
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e, record)}
            style={{ minWidth: 130 }}
            buttons={buttons}
          />
        );
      },
    });
    setColumns(newColumns);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "play":
        if (item?.url) {
          setActiveMovieForPlayer(item);
        } else {
          notification.error({
            message: "Movie url not found",
          });
        }
        break;
      case "edit":
        setCurrentMoves(item);
        setVisibleEditMovieDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
            item.name
          }`,
          action: () => deleteMoves(item),
        });
        break;

      default:
        break;
    }
  };

  const deleteMoves = (item) => {
    REQUESTS.VOD.MOVIES.DELETE(item.id, getMovesList);
  };

  const getMovesList = () => {
    setLoading(true);

    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage,
    };

    if (nameSearch) {
      query.search = JSON.stringify({ name: nameSearch });
    }

    if (generSearch[0]) {
      query.category_name = generSearch[0];

      if (query.pagination) {
        delete query.pagination;
        delete query.limit;
      }
    }

    if (selectedMovieTypeId) {
      query.filter = JSON.stringify({
        type: selectedMovieTypeId,
      });
    }

    function callback(data) {
      if (data.rows) {
        setMovesList(data.rows);
        setLimit(data.limit);
        setTotal(data.count);
      } else {
        setMovesList(data);
      }

      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.VOD.MOVIES.GET(query, callback, errorCallback);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    if (Object.values(filters)[0]) {
      setNameSearch(Object.values(filters)[0].join(""));
    } else {
      setNameSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };
  return (
    <Context.Provider
      value={{
        total,
        visibleDrawer,
        setVisibleDrawer,
        currentMoves,
        setCurrentMoves,
        getMovesList,
        visibleAddMovieDrawer,
        setVisibleAddMovieDrawer,
        visibleViewSettingsDrawer,
        setVisibleViewSettingsDrawer,
        visibleFilterDrawer,
        setVisibleFilterDrawer,
        viewSettingsValues,
        setViewSettingsValues,
        selectedMovieTypeId,
        setSelectedMovieTypeId,
        visibleEditMovieDrawer,
        setVisibleEditMovieDrawer,
      }}
    >
      <Page>
        <Head />
        <div className="page-body">
          <TableHead />

          <Table
            rowKey="id"
            loading={loading}
            columns={columns}
            dataSource={movesList}
            onChange={handleTableChange}
            size="small"
            pagination={{
              position: ["topCenter", "bottomCenter"],
              current: currentPage,
              total: total,
              showSizeChanger: true,
            }}
            scroll={{ x: 1300 }}
            sticky={{
              offsetHeader: 60,
              offsetScroll: 0,
            }}
          />
        </div>

        <AddMovieDrawer
          visible={visibleAddMovieDrawer}
          onClose={() => setVisibleAddMovieDrawer(false)}
        />

        <PlayMovieDrawer
          visible={activeMovieForPlayer ? true : false}
          movie={activeMovieForPlayer}
          onClose={() => setActiveMovieForPlayer(null)}
        />

        <EditMovieDrawer
          visible={visibleEditMovieDrawer}
          onClose={() => {
            setVisibleEditMovieDrawer(false);
            setCurrentMoves(null);
          }}
        />
      </Page>
    </Context.Provider>
  );
}
