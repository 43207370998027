import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Table, Input, Select } from "antd";

import REQUESTS from "../../../server/requests";

import ICONS from "../../../config/icons";
import confirmModal from "../../../config/confirmModal";
import { dateFormat } from "../../../config/formats";

import TableButtons from "../../elements/TableButtons";
import AddButtonComponent from "../../elements/AddButtonComponent";

import AddCountryDrawer from "./AddCountryDrawer";
import CountryTableHead from "./CountryTableHead";

import EN from "../../../config/en";
import { useOrganizationsOptions } from "../../../hooks/options";

export default function CountryDrawer({ visible, onClose }) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);
  const [visibleAddDrawer, setVisibleAddDrawer] = useState(false);

  const [countryList, setCountryList] = useState(false);
  const [currentCountry, setCurrentCountry] = useState(null);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [nameSearch, setNameSearch] = useState(null);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const organizationsOptions = useOrganizationsOptions();

  const [selectedOrganization, setSelectedOrganization] = useState(null);

  useEffect(() => {
    if (visible && organizationsOptions) {
      setSelectedOrganization(organizationsOptions[0]?.value);
    }
  }, [organizationsOptions, visible]);

  useEffect(() => {
    if (!visibleAddDrawer) {
      setCurrentCountry(null);
    }
  }, [visibleAddDrawer]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getCountryList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [nameSearch, currentPage, limit, sort, selectedOrganization, visible]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      fixed: "left",
      key: "id",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    },

    {
      title: translate["Name"] || EN["Name"],
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, record, index) => <p className="text-ellipsis">{text}</p>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            allowClear
            autoFocus
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: () => {
        return <>{ICONS.SEARCHOUTLINED}</>;
      },
      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: translate["Date"] || EN["Date"],
      key: "createdAt",
      sorter: true,
      dataIndex: "createdAt",
      render: (text, record, index) => dateFormat(text),
    },

    {
      title: "",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          style={{ minWidth: 130 }}
          buttons={[
            {
              key: "edit",
              text: translate["Edit"] || EN["Edit"],
              icon: ICONS.EDIT,
            },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE,
            },
          ]}
        />
      ),
    },
  ];
  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setCurrentCountry(item);
        setVisibleAddDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
            item.name
          }`,
          action: () => deleteCountry(item),
        });
        break;

      default:
        break;
    }
  };
  const deleteCountry = (item) => {
    REQUESTS.COUNTRY.DELETE(item.id, getCountryList);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    if (Object.values(filters)[0]) {
      setNameSearch(Object.values(filters)[0].join(""));
    } else {
      setNameSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const getCountryList = () => {
    setLoading(true);
    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage,
      filter: JSON.stringify({
        organization_id: selectedOrganization,
      }),
    };

    if (nameSearch) {
      query.search = JSON.stringify({ name: nameSearch });
    }

    function callback(data) {
      setCountryList(data.rows);
      setLimit(data.limit);
      setTotal(data.count);
      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }
    REQUESTS.COUNTRY.GET(query, callback, errorCallback);
  };

  return (
    <Drawer
      title={translate["Country Settings"] || EN["Country Settings"]}
      placement="right"
      width={800}
      visible={visible}
      onClose={onClose}
      destroyOnClose={true}
    >
      <div className="global_header-title-drawer">
        <div className="title-in-drawer">
          <h2>{translate["Country Settings"] || EN["Country Settings"]}</h2>
        </div>

        <AddButtonComponent
          text={translate["Add Country"] || EN["Add Country"]}
          onClick={() => setVisibleAddDrawer(true)}
        />
      </div>

      <div className="settings_icon_pt">
        <CountryTableHead />
      </div>

      <Table
        rowKey="id"
        title={() => (
          <div>
            <Select
              showArrow
              value={selectedOrganization}
              onChange={setSelectedOrganization}
              options={organizationsOptions}
              style={{ width: 300 }}
              placeholder={translate["Select Organization"] || EN["Select Organization"]}
            />
          </div>
        )}
        loading={loading}
        columns={columns}
        dataSource={countryList}
        onChange={handleTableChange}
        pagination={{
          position: ["topCenter", "bottomCenter"],
          current: currentPage,
          total: total,
          showSizeChanger: true,
        }}
        sticky
      />
      <AddCountryDrawer
        visible={visibleAddDrawer}
        onClose={() => setVisibleAddDrawer(false)}
        currentCountry={currentCountry}
        getCountryList={getCountryList}
      />
    </Drawer>
  );
}
