import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, notification, Select } from "antd";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";

import ErrorMessage from "../../../elements/ErrorMessage";

import SaveButtonComponent from "../../../elements/SaveButtonComponent";

export default function TVDrawer({
  visible,
  onClose,
  getDevices,
  roomId,
  tv,
  deviceData,
}) {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState(null);

  const [packagesOption, setPackagesOption] = useState([]);

  useEffect(() => {
    if (visible) {
      getPackagesOptions();
      if (tv) {
        form.setFields([
          { name: "ip", value: tv.ip },
          { name: "name", value: tv.name },
          { name: "model", value: tv.model },
          { name: "package_id", value: tv.package_id },
          { name: "description", value: tv.description },
          { name: "device_id", value: tv.device_id },
        ]);
      }
    } else {
      form.resetFields();

      setErrorText(null);
    }
  }, [visible]);

  useEffect(() => {
    if (deviceData) {
      form.setFields([
        { name: "ip", value: deviceData?.ip },
        { name: "model", value: deviceData?.model },
        { name: "device_id", value: deviceData?.device_id },
      ]);
    }
  }, [deviceData, visible]);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log(values);
    setLoading(true);

    setErrorText(null);

    const body = {
      ip: values.ip.replace(/ /g, ""),
      room_id: roomId,
      name: values.name,
      model: values.model,
      package_id: values.package_id,
      description: values.description,
      device_id: values.device_id.replace(/ /g, ""),
    };

    function callback() {
      setLoading(false);

      onClose();
      getDevices();
      form.resetFields();
    }

    function errorCallback(err) {
      setLoading(false);
      // setErrorText(err);
      notification.error({
        message:
          typeof err === "string"
            ? err
            : translate["An error occurred"] || EN["An error occurred"],
      });
    }

    if (tv) {
      body.id = tv.id;
      REQUESTS.ROOMS.DEVICES.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.ROOMS.DEVICES.ADD(body, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  const getPackagesOptions = () => {
    REQUESTS.PACKAGES.GET({}, (data) => {
      const packages = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setPackagesOption(packages);
    });
  };

  return (
    <Drawer
      title={
        tv
          ? translate["Edit Device"] || EN["Edit Device"]
          : translate["Create device"] || EN["Create device"]
      }
      placement="right"
      width={600}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      visible={visible}
      destroyOnClose={true}
    >
      <h2>{translate["TV information"] || EN["TV information"]}</h2>
      <Form
        form={form}
        layout="vertical"
        name="tv"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={translate["Name"] || EN["Name"]}
          tooltip={{
            title: "Tv device name",
            icon: ICONS.INFO,
          }}
          name="name"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Name"] || EN["Name"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["IP"] || EN["IP"]}
          tooltip={{
            title: "Tv device ip",
            icon: ICONS.INFO,
          }}
          name="ip"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["IP"] || EN["IP"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Device Id"] || EN["Device Id"]}
          tooltip={{
            title: "Tv device id",
            icon: ICONS.INFO,
          }}
          name="device_id"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]}  ${
                translate["Device Id"] || EN["Device Id"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Model"] || EN["Model"]}
          tooltip={{
            title: "Tv device model",
            icon: ICONS.INFO,
          }}
          name="model"
          rules={[
            {
              required: true,
              message: `${translate["Please input"] || EN["Please input"]} ${
                translate["Model"] || EN["Model"]
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate["Package"] || EN["Package"]}
          tooltip={{
            title: "Tv device package",
            icon: ICONS.INFO,
          }}
          name="package_id"
        >
          <Select
            style={{ width: "100%" }}
            showArrow
            allowClear={true}
            options={packagesOption}
          />
        </Form.Item>

        <Form.Item
          label={translate["Description"] || EN["Description"]}
          tooltip={{
            title: "Tv device description",
            icon: ICONS.INFO,
          }}
        >
          <Form.Item name="description" noStyle>
            <Input.TextArea rows={4} onResize={false} />
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent loading={loading} onClick={() => form.submit()} />
        </Form.Item>
      </Form>

      {/* <ErrorMessage>{errorText}</ErrorMessage> */}
    </Drawer>
  );
}
