import { Button } from "antd";
import ICONS from "../../../config/icons";

export default function EditConfigButton({ children, onClick, className, onlyButton = false }) {
    const onClickItem = () => {
        if (!onlyButton) {
            onClick();
        }
    };

    return (
        <div className={`edit-config-button ${className}`} onClick={onClickItem}>
            {children}

            <div className="edit-button">
                <Button
                    color="white"
                    type="primary"
                    icon={ICONS.EDIT_WHITE}
                    size="small"
                    onClick={onClick}
                />
            </div>
        </div>
    );
}
