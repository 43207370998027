import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Input } from "antd";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";
import confirmModal from "../../../../config/confirmModal";
import { dateFormat } from "../../../../config/formats";

import TableButtons from "../../../elements/TableButtons";
import Page from "../../../elements/Page";

import Head from "./Head";
import HostGroupsDrawer from "./Drawer";
import TableHead from "./TableHead";

export default function HostGroupsPage() {
    const translate = useSelector((state) => state.globalState.translate);

    const [loading, setLoading] = useState(false);
    const [groupsList, setGroupsList] = useState([]);

    const [sort, setSort] = useState(["id", "DESC"]);
    const [nameSearch, setNameSearch] = useState(null);

    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [currentGroups, setCurrentGroups] = useState(null);

    const [visibleGroupsDrawer, setVisibleGroupsDrawer] = useState(false);

    const columns = [
        {
            width: 60,
            fixed: "left",
            align: "center",
            title: "#",
            dataIndex: "id",
            key: "id",
            render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
        },

        {
            title: translate["Name"] || EN["Name"],
            dataIndex: "name",
            key: "name",
            sorter: true,
            render: (text, record, index) => <p className="text-ellipsis">{text}</p>,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
                return (
                    <Input
                        allowClear
                        autoFocus
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                );
            },
            filterIcon: () => {
                return <>{ICONS.SEARCHOUTLINED}</>;
            },

            onFilter: (value, record) => {
                return record.name.toLowerCase().includes(value.toLowerCase());
            },
        },
        {
            title: translate["Date"] || EN["Date"],
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: true,
            render: (text, record, index) => dateFormat(text),
        },

        {
            width: 60,
            fixed: "right",
            title: "",
            dataIndex: "action",
            key: "action",
            align: "center",
            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    style={{ minWidth: 130 }}
                    buttons={[
                        { key: "edit", text: translate["Edit"] || EN["Edit"], icon: ICONS.EDIT },
                        {
                            key: "delete",
                            text: translate["Delete"] || EN["Delete"],
                            icon: ICONS.DELETE,
                        },
                    ]}
                />
            ),
        },
    ];

    const handleMenuClick = (e, item) => {
        switch (e.key) {
            case "edit":
                setCurrentGroups(item);
                setVisibleGroupsDrawer(true);
                break;

            case "delete":
                confirmModal({
                    title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
                        item.name
                    }`,
                    action: () => deleteGroups(item),
                });
                break;

            default:
                break;
        }
    };

    const deleteGroups = (item) => {
        REQUESTS.ROOMS.GUESTS_GROUPS.DELETE(item.id, getGroupsList);
    };

    const getGroupsList = () => {
        setLoading(true);

        const query = {
            sort,
            limit,
            pagination: 1,
            page: currentPage,
        };

        if (nameSearch) {
            query.search = JSON.stringify({ name: nameSearch });
        }

        function callback(data) {
            setGroupsList(data.rows);
            setLimit(data.limit);
            setTotal(data.count);
            setLoading(false);

            if (data.rows.length === 0 && currentPage !== 1) {
                setCurrentPage((current) => current - 1);
            } else {
                setCurrentPage(data.currentPage);
            }
        }

        function errorCallback() {
            setLoading(false);
        }

        REQUESTS.ROOMS.GUESTS_GROUPS.GET(query, callback, errorCallback);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }

        if (Object.values(filters)[0]) {
            setNameSearch(Object.values(filters)[0].join(""));
        } else {
            setNameSearch(null);
        }

        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    useEffect(() => {
        if (!visibleGroupsDrawer) {
            setCurrentGroups(null);
        }
    }, [visibleGroupsDrawer]);

    useEffect(() => {
        getGroupsList();
    }, [currentPage, limit, sort]);

    useEffect(() => {
        const searchTimeout = setTimeout(() => {
            getGroupsList();
        }, 1000);

        return () => {
            clearTimeout(searchTimeout);
        };
    }, [nameSearch]);

    return (
        <Page>
            <Head total={total} onClick={() => setVisibleGroupsDrawer(true)} />

            <div className="page-body">
                <TableHead />
                <Table
                    loading={loading}
                    rowKey="id"
                    columns={columns}
                    dataSource={groupsList}
                    onChange={handleTableChange}
                    pagination={{
                        position: ["topCenter", "bottomCenter"],
                        current: currentPage,
                        total: total,
                        showSizeChanger: true,
                    }}
                    sticky={{
                        offsetHeader: 50,
                        offsetScroll: 0,
                    }}
                />

                <HostGroupsDrawer
                    groups={currentGroups}
                    visible={visibleGroupsDrawer}
                    getGroupsList={getGroupsList}
                    onClose={() => setVisibleGroupsDrawer(false)}
                />
            </div>
        </Page>
    );
}
