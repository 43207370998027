import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactAudioPlayer from "react-audio-player";
import { Drawer, Image, Table, Pagination } from "antd";

import { PlayCircleOutlined } from "@ant-design/icons";

import { dateFormat } from "../../../config/formats";

import REQUESTS from "../../../server/requests";
import LibraryHead from "./LibraryHead";

import TableButtons from "../../elements/TableButtons";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";
import confirmModal from "../../../config/confirmModal";
import MediaDrawer from "./MediaDrawer";

export default function MediaLibrary({ chooser, types, selectFile }) {
  const translate = useSelector((state) => state.globalState.translate);

  const uploadingList = useSelector((state) => state.globalState.uploading_list);

  const [list, setList] = useState([]);
  const [tableList, setTableList] = useState([]);

  const [gridView, setGridView] = useState(true);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [search, setSearch] = useState("");
  const [editableMedia, setEditableMedia] = useState(null);
  const [playingVideo, setPlayingVideo] = useState(null);

  const [sort, setSort] = useState(["DESC"]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getMediaLibraries();
  }, [selectedFileType, uploadingList, search, currentPage, limit]);

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 60,
      align: "center",
      // render: (text, record, index) => <p>{index + 1}</p>,
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    },
    {
      title: "",
      dataIndex: "image",
      key: "image",
      // width: 100,
      render: (text, record, index) => returnComponent(record),
    },
    {
      title: translate["Name"] || EN["Name"],
      dataIndex: "name",
      key: "name",
      width: 100,
    },
    {
      title: translate["Type"] || EN["Type"],
      dataIndex: "file_type",
      key: "file_type",
    },

    {
      title: translate["Size"] || EN["Size"],
      dataIndex: "current_size",
      key: "current_size",
      render: (text, record, index) => {
        let _size = record.current_size;
        let fSExt = new Array("Bytes", "KB", "MB", "GB");

        let i = 0;
        while (_size > 900) {
          _size /= 1024;
          i++;
        }

        const exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];

        return <p>{exactSize}</p>;
      },
    },

    {
      key: "createdAt",
      sorter: true,
      title: translate["Date"] || EN["Date"],
      dataIndex: "createdAt",
      render: (text, record, index) => dateFormat(text),
    },

    {
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (text, record, index) => {
        let buttons = [];

        if (chooser) {
          buttons = [
            {
              key: "select",
              text: translate["Select"] || EN["Select"],
              icon: ICONS.SELECT,
            },
          ];
        } else {
          buttons = [
            {
              key: "edit",
              text: translate["Edit"] || EN["Edit"],
              icon: ICONS.EDIT,
            },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE,
            },
          ];
        }

        return (
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e, record)}
            buttons={buttons}
          />
        );
      },
    },
  ];

  const getMediaLibraries = () => {
    const query = {
      limit,
      pagination: 1,
      page: currentPage,
    };

    if (search) {
      query.search = JSON.stringify({
        name: search,
        file_type: search,
      });
    }

    if (sort) {
      query.sort = "id," + sort;
    }

    if (types) {
      query.filter = JSON.stringify({
        file_type: types,
      });
    } else {
      if (selectedFileType) {
        query.filter = JSON.stringify({
          file_type: selectedFileType,
        });
      }
    }

    REQUESTS.MEDIA_LIBRARY.GET(query, (data) => {
      let arr = [[], [], []];

      for (let i = 0; i < data.rows.length; i++) {
        let index = i % 3;

        arr[index].unshift(data.rows[i]);
      }

      setList(arr);
      setTableList(data.rows);
      setTotal(data.count);

      if (data.rows.length === 0 && currentPage !== 1) {
        // setCurrentPage((current) => current - 1);
        setCurrentPage(1);
      } else {
        setCurrentPage(data.currentPage);
      }
    });
  };

  const removeItem = (item) => {
    confirmModal({
      title: `${translate["Do you want to delete"] || EN["Do you want to delete"]} ${
        translate["Media"] || EN["Media"]
      }`,
      action: () => {
        REQUESTS.MEDIA_LIBRARY.DELETE(item.id, (data) => {
          getMediaLibraries();
        });
      },
    });
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setEditableMedia(item);
        break;

      case "delete":
        removeItem(item);
        break;

      case "select":
        selectFile(item);
        break;

      default:
        break;
    }
  };

  const openVideo = (item, loaded) => {
    if (loaded) return;
    setPlayingVideo(item);

    if (document.getElementById("mediaVideoId")) {
      document.getElementById("mediaVideoId").src = item.file_url;
    }
  };

  const closeVideo = () => {
    if (document.getElementById("mediaVideoId")) {
      document.getElementById("mediaVideoId").src = "";
    }
    setPlayingVideo(null);
  };

  const returnComponent = (item) => {
    switch (item.file_type) {
      case "image":
        return (
          <Image
            style={{ background: "#e1e7eb" }}
            className={gridView ? "" : "media-table-image"}
            src={item.file_url}
          />
        );

      case "video":
        const loaded = item.current_size != item.file_size;
        return (
          <div
            className={`video-component ${gridView ? "" : "media-table-video"}`}
            onClick={() => openVideo(item, loaded)}
            style={{ background: loaded && "#bc4749" }}
          >
            <div>
              <PlayCircleOutlined style={{ color: loaded && "#bc4749" }} />
            </div>

            {gridView && <p className="text-ellipsis">{item.name}</p>}
          </div>
        );

      case "audio":
        return (
          <div className={`audio-component ${gridView ? "" : "media-table-audio"} `}>
            {gridView && (
              <p className="text-ellipsis" style={{ marginLeft: 30, marginBottom: 10 }}>
                {item.name}
              </p>
            )}

            <ReactAudioPlayer style={{ width: "100%" }} src={item.file_url} controls />
          </div>
        );
    }
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    // if (Object.values(filters)[0]) {
    //   setNameSearch(Object.values(filters)[0].join(""));
    // } else {
    //   setNameSearch(null);
    // }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  let paginationDebounce = null;

  const onChange = (current, pageSize) => {
    clearTimeout(paginationDebounce);
    console.log(current, pageSize, "====");

    paginationDebounce = setTimeout(() => {
      if (current <= 0) {
        setCurrentPage(1);
      } else {
        setCurrentPage(current);
      }
      setLimit(pageSize);
    }, 300);
  };

  return (
    <div>
      <LibraryHead
        chooser={chooser}
        gridView={gridView}
        setGridView={setGridView}
        selectedFileType={selectedFileType}
        setSelectedFileType={setSelectedFileType}
        search={search}
        setSearch={setSearch}
      />

      {gridView ? (
        <div className="grid-container">
          {list.map((item, index) => {
            return (
              <div key={index} className="column">
                {item.map((_item) => {
                  let buttons = [];

                  if (chooser) {
                    buttons = [
                      {
                        key: "select",
                        text: translate["Select"] || EN["Select"],
                        icon: ICONS.SELECT,
                      },
                    ];
                  } else {
                    buttons = [
                      {
                        key: "edit",
                        text: translate["Edit"] || EN["Edit"],
                        icon: ICONS.EDIT,
                      },
                      {
                        key: "delete",
                        text: translate["Delete"] || EN["Delete"],
                        icon: ICONS.DELETE,
                      },
                    ];
                  }
                  return (
                    <div key={_item.id} className="media-component">
                      <div className="buttons">
                        <TableButtons
                          handleMenuClick={(e) => handleMenuClick(e, _item)}
                          buttons={buttons}
                        />
                      </div>
                      {returnComponent(_item)}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : (
        <Table
          dataSource={tableList}
          columns={columns}
          pagination={false}
          onChange={handleChangeTable}
          scroll={{ x: 1000 }}
        />
      )}

      <MediaDrawer
        current={editableMedia}
        getList={getMediaLibraries}
        visible={editableMedia ? true : false}
        onClose={() => setEditableMedia(null)}
      />

      <Drawer
        title={translate["Video"] || EN["Video"]}
        placement="top"
        width={500}
        height={1000}
        onClose={() => {
          closeVideo();

          setPlayingVideo(null);
        }}
        visible={playingVideo}
        destroyOnClose={true}

        // footer={null}
      >
        {playingVideo && (
          <video
            controls
            autoPlay
            id="mediaVideoId"
            src={playingVideo.file_url}
            style={{ width: "100%" }}
          />
        )}
      </Drawer>
      <Pagination
        style={{ marginTop: "20px" }}
        showSizeChanger
        onShowSizeChange={onChange}
        onChange={onChange}
        defaultCurrent={currentPage}
        total={total}
      />
    </div>
  );
}
