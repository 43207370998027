import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker, Space } from "antd";
import REQUESTS from "../../../server/requests";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

import EN from "../../../config/en";
import {returnShortMonthName} from "../../../config/formats";

import TitleComponent from "../../elements/TitleComponent";

export default function GuestCharts() {

    const translate = useSelector((state) => state.globalState.translate);

    const [statistics, setStatistics] = useState([]);
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear);

    useEffect(() => {
        getDashboardCharts();
    }, [selectedYear]);

    const getDashboardCharts = () => {
        const query = {
            year: selectedYear,
        };
        REQUESTS.DASHBOARD.STATISTICS_GUESTS(query, (data) => {
            setStatistics(data);
        });
    };

    const data_barChart = statistics.map((item) => {
        return {
            month:  returnShortMonthName(item.month),
            guests: item.guests_count,
        };
    });

    function onChange(date, dateString) {
        setSelectedYear(dateString);
    }

    return (
        <div className="app_content_charts">
            <div className="content_charts">
                <div className="bg_fff">
                    <div className="title_and_select_df">
                        <TitleComponent>{translate['Recent activity'] || EN['Recent activity']}</TitleComponent>
                        <Space direction="vertical">
                            <DatePicker onChange={onChange} picker="year" />
                        </Space>
                    </div>

                    <ResponsiveContainer width="100%" aspect={4}>
                        <BarChart
                            width={730} 
                            height={250}
                            data={data_barChart}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 0,
                                bottom: 5,
                            }}
                            barSize={13}
                        >
                            <XAxis dataKey="month" scale="point" padding={{ left: 9, right: 10 }} />
                            <YAxis allowDecimals={false} />
                            <Tooltip />
                            <Legend />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Bar
                                dataKey="guests"
                                fill="#4f82ff"
                                background={{ fill: "#eee" }}
                                radius={[10, 10, 0, 0]}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
}
