import { useEffect, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { useSelector } from "react-redux";
import EN from "../../config/en";
import { Tag } from "antd";

const QRCodeGenerator = ({ value }) => {
  const [text, setText] = useState("");
  const translation = useSelector((state) => state.globalState.translate);

  useEffect(() => {
    if (!value) return;
    setText(value?.toString());
  }, [value]);

  return (
    <div style={{ textAlign: "center" }}>
      <h2>{translation["Please scan this QR code"] || EN["Please scan this QR code"]}</h2>
      <div>
        <QRCodeCanvas
          value={text}
          size={300}
          level="H"
          includeMargin={true}
          bgColor="#fafafa"
        />
      </div>
      <Tag color="default">
        <span
          style={{
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          {translation["Key"] || EN["Key"] + ": " + text}
        </span>
      </Tag>
    </div>
  );
};

export default QRCodeGenerator;
