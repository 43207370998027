import React, { useEffect, useState } from "react";

import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../store";

import { Table, Input, Switch, Image } from "antd";

import REQUESTS from "../../../server/requests";

import EN from "../../../config/en";
import ICONS from "../../../config/icons";

import Page from "../../elements/Page";

import Head from "./Head";
import TranslationDrawer from "./Drawer";

import "./_translation.scss";
import TableHeadComponent from "../../elements/TableHeadComponent";
import CsvLinkComponent from "../../elements/CsvLinkComponent";

export default function TranslationPage() {
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.globalState.languages);
  const translate = useSelector((state) => state.globalState.translate);

  const { setLanguages } = bindActionCreators(actions, dispatch);

  const [loading, setLoading] = useState(false);

  const [sort, setSort] = useState(["is_default", "DESC"]);
  const [nameSearch, setNameSearch] = useState(null);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentLanguage, setCurrentLanguage] = useState(null);
  const [clickedCsv, setClickedCsv] = useState(false);

  useEffect(() => {
    getLanguageList();
  }, [sort]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getLanguageList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [nameSearch]);

  const columns = [
    {
      width: 60,
      title: "#",
      dataIndex: "id",
      align: "center",
      fixed: "left",
      key: "id",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index,
    },

    {
      title: translate["Language"] || EN["Language"],
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, record, index) => {
        return (
          <div className="language-icon-name">
            <div className="image">
              <Image preview={false} style={{ width: 30 }} src={record.icon} />
            </div>

            <div className="name">
              <p>{text}</p>
            </div>
          </div>
        );
      },

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            allowClear
            autoFocus
            placeholder="Type text here"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          />
        );
      },
      filterIcon: () => {
        return <>{ICONS.SEARCHOUTLINED}</>;
      },
      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: translate["Enable"] || EN["Enable"],
      dataIndex: "is_active",
      key: "is_active",
      sorter: true,
      render: (text, record, index) => (
        <div
          className="switch-block"
          onClick={(e) => e.stopPropagation()}
          style={{ textAlign: "center" }}
        >
          <Switch
            disabled={record.is_default}
            checked={record.is_active}
            onChange={(e) => onChangeEnable(e, record)}
          />
        </div>
      ),
    },
  ];

  const onChangeEnable = (checked, selected) => {
    let body = {
      is_active: checked,
      id: selected.id,
    };

    function callback() {
      getLanguageList();
    }

    function errorCallback(err) {}

    REQUESTS.LANGUAGES.CHANGE(body, callback, errorCallback);
  };

  const getLanguageList = () => {
    setLoading(true);

    const query = {
      sort,
    };

    if (nameSearch) {
      query.search = JSON.stringify({ name: nameSearch });
    }

    function callback(data) {
      setLoading(false);
      setLanguages(data);
      setTotal(data.length);
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.LANGUAGES.GET(query, callback, errorCallback);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    if (Object.values(filters)[0]) {
      setNameSearch(Object.values(filters)[0].join(""));
    } else {
      setNameSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const onClicCsv = (event, done) => {
    if (!clickedCsv) {
      setClickedCsv(true);

      function callback(data) {
        const newList = data.map((list) => {
          return {
            Name: list.name,
            Iso_code: list.iso_code,
          };
        });
        done(newList);
        // setDataList(newList);

        setClickedCsv(false);
      }

      function errorCallback() {
        setClickedCsv(false);
      }

      REQUESTS.LANGUAGES.GET({}, callback, errorCallback);
    }
  };

  return (
    <Page>
      <Head total={total} />

      <p>{translate["Language"]}</p>

      <div className="page-body">
        <TableHeadComponent>
          <CsvLinkComponent
            filename="Translation-List.csv"
            //    data={dataList}
            onClick={onClicCsv}
          />
        </TableHeadComponent>
        <Table
          defaultExpandAllRows={true}
          loading={loading}
          rowKey="id"
          className="activeRow"
          columns={columns}
          dataSource={languages}
          onChange={handleTableChange}
          pagination={{
            position: ["topCenter", "bottomCenter"],
            current: currentPage,
            total: total,
            showSizeChanger: true,
          }}
          sticky={{
            offsetHeader: 60,
            offsetScroll: 0,
          }}
          onRow={(record, rowIndex) => {
            return {
              className: "activeItem",
              onClick: (event) => {
                setCurrentLanguage(record);
              },
            };
          }}
        />

        <TranslationDrawer
          visible={currentLanguage ? true : false}
          current={currentLanguage}
          onClose={() => setCurrentLanguage(null)}
          getLanguageList={getLanguageList}
        />
      </div>
    </Page>
  );
}
